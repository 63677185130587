import axios from 'axios';
import { RequestParams, RequestPayloads, ResponsePayloads } from 'shared';

const SiteSettings = {
  base: '/admin/site-settings',
};

export function getSiteSettings(): Promise<ResponsePayloads['getSiteSettings']> {
  return axios.get(`${SiteSettings.base}`).then((res) => res.data);
}

export function getSiteSetting({
  siteSettingName,
}: RequestParams['getSiteSetting']): Promise<ResponsePayloads['getSiteSetting']> {
  return axios.get(`${SiteSettings.base}/${siteSettingName}`).then((res) => res.data);
}

export function updateSiteSetting(
  { siteSettingId }: RequestParams['updateSiteSetting'],
  payload: RequestPayloads['updateSiteSetting'],
): Promise<ResponsePayloads['updateSiteSetting']> {
  return axios.patch(`${SiteSettings.base}/${siteSettingId}`, payload).then((res) => res.data);
}
