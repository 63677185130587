import { useRef, ReactElement, useEffect } from 'react';
import classnames from 'classnames';
import FlashMessage, { FlashMessageType } from '@components/common/FlashMessage';
import Overlay from '@components/common/Overlay';
import { useGeneral } from '@hooks';

import './styles.scss';

interface Props {
  className?: string;
}

function FlashMessageOverlay(props: Props): ReactElement | null {
  const { className } = props;

  const {
    general: { flashMessage },
    stopFlashMessage,
  } = useGeneral();

  const lastMessageType = useRef(FlashMessageType.success);

  useEffect(() => {
    if (flashMessage) {
      lastMessageType.current = flashMessage.type;
    }
  }, [flashMessage]);

  return (
    <Overlay
      rootId="flash-message-root"
      showOverlay={!!flashMessage}
      className={classnames('FlashMessageOverlay', className)}
      withoutBackground
      alwaysShow
      blockScroll={false}
    >
      <FlashMessage
        type={flashMessage ? flashMessage.type : lastMessageType.current}
        text={flashMessage ? flashMessage.message : ''}
        onCloseClick={stopFlashMessage}
      />
    </Overlay>
  );
}

export default FlashMessageOverlay;
