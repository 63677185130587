import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { FullUser } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';
import { usePageChange } from '../usePageChange';

export interface RelationshipsHook {
  relationships: State['relationships']['data'];
  isLoading: State['relationships']['isLoading'];
  getRelationships: () => void;
  assignSubscriberToAgent(
    subscriber: FullUser,
    agent: FullUser,
    changeSubscriberOrganization: boolean,
    onSuccess?: () => void,
    onError?: () => void,
  ): void;
  unassignSubscriberFromAgent(relationshipId: number, onSuccess?: () => void, onError?: () => void): void;
}

export function useCurrentUserReadableRelationships(): RelationshipsHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const { data, isLoading } = useSelector<State>((state) => state.relationships) as State['relationships'];
  const dispatch = reduxDispatch as Dispatch;
  function getRelationships(): void {
    dispatch(Actions.getRelationships());
  }

  function assignSubscriberToAgent(
    subscriber: FullUser,
    agent: FullUser,
    changeSubscriberOrganization: boolean,
    onSuccess: () => void = (): void => undefined,
    onError: () => void = (): void => undefined,
  ): void {
    dispatch(Actions.assignSubscriberToAgent(subscriber, agent, changeSubscriberOrganization, onSuccess, onError));
  }

  function unassignSubscriberFromAgent(
    relationshipId: number,
    onSuccess: () => void = (): void => undefined,
    onError: () => void = (): void => undefined,
  ): void {
    dispatch(Actions.unassignSubscriberFromAgent(relationshipId, onSuccess, onError));
  }

  usePageChange(() => {
    dispatch(Actions.resetHasFetchedRelationships());
  });

  useEffect(() => {
    getRelationships();
    return (): void => {
      dispatch(Actions.resetHasFetchedRelationships());
    };
  }, []);

  return { relationships: data, isLoading, getRelationships, assignSubscriberToAgent, unassignSubscriberFromAgent };
}
