import { Action } from '../types';

export enum ActionTypes {
  ACQUIRE_LOCK = 'ACQUIRE_LOCK',
  RELEASE_LOCK = 'RELEASE_LOCK',
}
export interface AcquireLock extends Action {
  type: typeof ActionTypes.ACQUIRE_LOCK;
  payload: {
    lockId: string;
    acquirerId: string;
  };
}

export interface ReleaseLock extends Action {
  type: typeof ActionTypes.RELEASE_LOCK;
  payload: {
    lockId: string;
    releaserId: string;
  };
}
