import React, { ChangeEvent, ReactElement } from 'react';
import classnames from 'classnames';
import { Checkbox, Option } from 'shared';
import './styles.scss';

interface Props<InputElement extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  extends Omit<React.HTMLProps<InputElement>, 'onChange'> {
  onChange?: OnChange;
  error?: string;
  options?: Option[];
  formLabel: string | Node;
  underlyingClassName?: string;
  noMaxWidth?: boolean;
  labelAsOption?: boolean;
  note?: string;
  wrapLabel?: boolean;
  labelClassName?: string;
}

export type OnChange = (
  value: Checkbox | string | File | number,
  e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
) => void;

function textOnChange<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
  onChange: (
    val: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { value } = e.currentTarget;
    onChange(value, e);
  };
}

function checkedOnChange<T extends HTMLInputElement>(
  onChange: (value?: string, e?: ChangeEvent<HTMLInputElement>) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { checked } = e.currentTarget;
    onChange(checked ? 'on' : undefined, e);
  };
}

function fileOnChange<T extends HTMLInputElement>(onChange: (val?: File) => void = (): void => undefined) {
  return (e: React.ChangeEvent<T>): void => {
    const { files } = e.currentTarget;
    if (files) {
      onChange(files[0]);
    }
  };
}

function numberOnChange<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
  onChange: (val: number) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { value } = e.currentTarget;
    onChange(+value);
  };
}

function SpecialInput(props: Props<HTMLInputElement>): ReactElement {
  const {
    type,
    formLabel,
    name,
    onChange,
    value,
    labelClassName,
    wrapLabel,
    underlyingClassName,
    ...restProps
  } = props;
  const inputProps: React.HTMLProps<HTMLInputElement> = {
    type,
    name,
    value,
    ...restProps,
  };
  const id = `${name}-${value}`;
  const _onChange = onChange as OnChange;
  return (
    <div className="center SpecialInput">
      <div
        className={classnames('left max-width flex align-center gap-4', {
          'justify-center': type === 'range',
        })}
      >
        {type === 'file' && (
          <h3 className="title-label header flex">
            <strong>{formLabel}</strong>
          </h3>
        )}
        {type === 'range' && (
          <label className={classnames('label', labelClassName, { wrap: wrapLabel })} htmlFor={name}>
            {formLabel}
          </label>
        )}
        <input
          {...inputProps}
          id={id}
          className={classnames(`equi-${type}`, underlyingClassName)}
          onChange={
            type === 'checkbox'
              ? checkedOnChange(_onChange)
              : type === 'file'
              ? fileOnChange(_onChange)
              : textOnChange(_onChange)
          }
        />
        {type === 'checkbox' && (
          <label className={classnames('label', labelClassName, { wrap: wrapLabel })} htmlFor={id}>
            {formLabel}
          </label>
        )}
      </div>
    </div>
  );
}

function EquiSelect(props: Props<HTMLSelectElement>): ReactElement {
  const {
    options,
    formLabel,
    id,
    labelAsOption = true,
    wrapLabel,
    underlyingClassName,
    onChange,
    ...selectProps
  } = props;
  if (!options) {
    throw new Error('GeneralInput with select requires options');
  }
  const _onChange = onChange as OnChange;
  return (
    <>
      {!labelAsOption && (
        <label className={classnames('label', { wrap: wrapLabel })} htmlFor={id}>
          {formLabel}
        </label>
      )}
      <select {...selectProps} id={id} className={classnames(underlyingClassName)} onChange={textOnChange(_onChange)}>
        {labelAsOption && (
          <option value="" selected disabled>
            {formLabel}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.displayName}
          </option>
        ))}
      </select>
    </>
  );
}

function EquiRadio(props: Props<HTMLInputElement>): ReactElement {
  const {
    options,
    formLabel,
    onChange,
    type,
    name,
    wrapLabel,
    labelClassName,
    value: baseValue,
    ...inputProps
  } = props;
  const _onChange = onChange as OnChange;
  return (
    <div className="flex">
      <h3 className="title-label header no-flex">
        <strong>{formLabel} </strong>
      </h3>
      {options?.map(({ displayName, value }) => (
        <div key={value} className="center EquiRadioCheck no-flex">
          <div className="left max-width flex justify-center align-center">
            <input
              {...inputProps}
              name={name}
              type={type}
              id={`radio-${name}-${value}`}
              value={value}
              className={classnames(`equi-${type}`)}
              checked={value === baseValue}
              onChange={type === 'checkbox' ? checkedOnChange(_onChange) : textOnChange(_onChange)}
            />
            <label
              className={classnames('label', labelClassName, { wrap: wrapLabel })}
              htmlFor={`radio-${name}-${value}`}
            >
              {displayName}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}

const GeneralInputChild = React.forwardRef(
  <InputElement extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
    props: Props<InputElement>,
    ref: React.Ref<InputElement>,
  ): ReactElement => {
    const {
      type = 'text',
      formLabel,
      labelClassName,
      name,
      underlyingClassName,
      onChange,
      note,
      wrapLabel,
      ...restProps
    } = props;
    const selectProps = { ...props, ref } as Props<HTMLSelectElement>;
    const textareaProps = { ...props, ref } as Props<HTMLTextAreaElement>;
    const inputProps = { ...restProps, name, type, ref } as Props<HTMLInputElement>;
    const specialInputProps = {
      ...restProps,
      labelClassName,
      name,
      formLabel,
      type,
      onChange,
      ref,
      wrapLabel,
      underlyingClassName,
    } as Props<HTMLInputElement>;
    const _onChange = onChange as OnChange | undefined;
    switch (type) {
      case 'select':
        return <EquiSelect {...selectProps} />;
      case 'textarea':
        return (
          <textarea
            {...textareaProps}
            placeholder={formLabel as string}
            className={classnames(underlyingClassName)}
            onChange={textOnChange(_onChange)}
          />
        );
      case 'radio':
        return <EquiRadio {...specialInputProps} />;
      case 'checkbox':
      case 'range':
      case 'file':
        return <SpecialInput {...specialInputProps} />;
      case 'time':
      case 'date':
      case 'month':
      case 'week':
      case 'datetime-local':
        return (
          <>
            <div className="left">
              <label
                className={classnames('label min-width-120 flex align-center justify', labelClassName, {
                  wrap: wrapLabel,
                })}
                htmlFor={name}
              >
                {formLabel}
              </label>
            </div>
            <input {...inputProps} className={classnames(underlyingClassName)} onChange={textOnChange(_onChange)} />
          </>
        );
      default:
        return (
          <>
            <div className="left">
              <label className={classnames('label', labelClassName, { wrap: wrapLabel })} htmlFor={name}>
                {formLabel}
              </label>
            </div>
            {note && (
              <p className="text-sm my-0 ml-1">
                <em>{note}</em>
              </p>
            )}
            <input
              {...inputProps}
              className={classnames(underlyingClassName)}
              onChange={type === 'number' ? numberOnChange(_onChange) : textOnChange(_onChange)}
            />
          </>
        );
    }
  },
);

function GeneralInput<InputElement extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
  props: Props<InputElement>,
  ref: React.Ref<InputElement>,
): ReactElement {
  const { error, className, disabled, noMaxWidth = false, underlyingClassName, ...restProps } = props;
  return (
    <div
      className={classnames('GeneralInput', className, {
        'equi-input-error': error,
        disabled,
        'max-width': !noMaxWidth,
      })}
    >
      <GeneralInputChild
        {...restProps}
        disabled={disabled}
        underlyingClassName={classnames('equi-input', underlyingClassName)}
        ref={ref}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default React.forwardRef(GeneralInput);
