import { ReactElement } from 'react';
import EquiInput from '@components/common/OfficeUseEquiInput';
import { useOfficeUseFormFields } from '@hooks';

function TCPNotes(): ReactElement {
  const formFields = useOfficeUseFormFields();

  return (
    <div className="form-section">
      <h2 className="title">TCP Notes</h2>
      <div className="flex">
        <div className="spacer justify-center align-center"></div>
        <EquiInput type="checkbox" {...formFields.shouldOmitTCP} />
      </div>
      {!formFields.shouldOmitTCP.value && (
        <>
          <div className="flex">
            <div className="spacer justify-center align-center">
              <EquiInput wrapperClassName="justify-between" {...formFields.explainedTCPDesignationPurpose} />
            </div>
          </div>
          <div className="flex">
            <div className="spacer justify-center align-center">
              <EquiInput
                wrapperClassName="justify-between"
                {...formFields.describeEquigenesisInformationDisclosureCircumstances}
              />
            </div>
          </div>
          <div className="flex">
            <div className="spacer justify-center align-center">
              <EquiInput wrapperClassName="justify-between" {...formFields.askedClientToDesignateTCP} />
            </div>
          </div>
          <div className="flex">
            <div className="spacer justify-center align-center">
              <EquiInput wrapperClassName="justify-between" {...formFields.encouragedClientToNameTCP} />
            </div>
          </div>
          <div className="flex">
            <div className="spacer justify-center align-center">
              <EquiInput
                wrapperClassName="justify-between"
                {...formFields.encouragedClientToSelectNonInvolvedIndividual}
              />
            </div>
          </div>
          <div className="flex form-row spacer">
            <EquiInput {...formFields.tcpNotes} />
          </div>
        </>
      )}
    </div>
  );
}

export default TCPNotes;
