import { ReactElement } from 'react';

import './styles.scss';

interface Props {
  pictureUrl: string;
}

export default function UserAvatar({ pictureUrl }: Props): ReactElement {
  return <div className="UserAvatar" style={{ backgroundImage: `url(${pictureUrl})` }} />;
}
