import { ChangeEvent, ReactElement, useState } from 'react';
import {
  UserMetadata,
  validateUpdateUser,
  ValidationError,
  ValidationErrorItem,
  RequestPayloads,
  KYCFormFormatting,
  KYCFormValidation,
  KYCFormData,
  filter,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import SectionCard from '@components/common/SectionCard';
import { useCurrentUser } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

function MyProfile(): ReactElement {
  const { currentUser, updateCurrentUser } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState<(ValidationErrorItem | KYCFormData['errors'][number])[]>([]);
  const [email, setEmail] = useState(currentUser?.email || '');
  const [firstName, setFirstName] = useState(currentUser?.given_name || '');
  const [lastName, setLastName] = useState(currentUser?.family_name || '');
  const [mobilePhone, setPhoneNumber] = useState(currentUser?.user_metadata?.phone_number || '');
  function submitForm(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const user: Omit<RequestPayloads['updateUser'], 'roles'> & { user_metadata: UserMetadata } = {
      email,
      given_name: firstName,
      family_name: lastName,
      name: `${firstName} ${lastName}`,
      user_metadata: {
        phone_number: mobilePhone,
      },
    };
    try {
      validateUpdateUser(user, false);
    } catch (e) {
      setErrors((e as ValidationError).errors);
      return;
    }
    setErrors([]);
    if (user.email === currentUser?.email) {
      delete user.email;
    }
    updateCurrentUser(user);
  }
  function onPhoneChange(
    phone: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void {
    const formatted = KYCFormFormatting('mobilePhone', e as React.ChangeEvent<HTMLInputElement>)(phone);
    const validator = KYCFormValidation.mobilePhone as (
      v: typeof phone,
      f: KYCFormData,
      isSubscriber: boolean,
    ) => KYCFormData['errors'][number] | void;
    const _errors = validator(
      formatted,
      {
        firstName,
        lastName,
        email,
        mobilePhone,
        numberOfFinancialDebts: 1,
        numberOfNonFinancialDebts: 1,
        errors: (errors as unknown) as KYCFormData['errors'],
      },
      false,
    );
    setPhoneNumber(formatted);
    const newErrors = errors
      .filter(
        (err) =>
          !(err as KYCFormData['errors'][number]).fieldCausingError ||
          !(err as KYCFormData['errors'][number]).fieldCausingError.includes('mobilePhone'),
      )
      .concat(filter([_errors]));
    setErrors(newErrors);
  }
  return (
    <Page
      className="MyProfile"
      defaultBackLocation={PATHS.HOME}
      isLoading={!currentUser}
      showChildrenOnLoading={false}
      renderHeader={(): ReactElement => (
        <>
          <h1>{currentUser?.name}</h1>
          <p>{currentUser?.email}</p>
        </>
      )}
    >
      {!isEditing && <EquiButton value="Edit Profile" onClick={(): void => setIsEditing(true)} />}
      {isEditing && (
        <SectionCard className="width-60">
          <form className="max-width" method="POST" onSubmit={submitForm}>
            <div className="form-section max-width flex justify-center">
              <GeneralInput
                formLabel="First Name"
                name="given_name"
                value={firstName}
                onChange={setFirstName as OnChange}
                error={errors.find((err) => (err as ValidationErrorItem).path === 'given_name')?.message}
              />
            </div>
            <div className="form-section max-width flex justify-center">
              <GeneralInput
                formLabel="Last Name"
                name="family_name"
                value={lastName}
                onChange={setLastName as OnChange}
                error={errors.find((err) => (err as ValidationErrorItem).path === 'family_name')?.message}
              />
            </div>
            <div className="form-section max-width flex justify-center">
              <GeneralInput
                formLabel="Email"
                name="email"
                value={email}
                onChange={setEmail as OnChange}
                error={errors.find((err) => (err as ValidationErrorItem).path === 'email')?.message}
              />
            </div>
            <div className="form-section max-width flex justify-center">
              <GeneralInput
                formLabel="Mobile Phone Number"
                name="phoneNumber"
                value={mobilePhone}
                onChange={onPhoneChange as OnChange}
                error={
                  errors.find(
                    (err) =>
                      (err as KYCFormData['errors'][number]).fieldCausingError &&
                      (err as KYCFormData['errors'][number]).fieldCausingError.includes('mobilePhone'),
                  )?.message
                }
              />
            </div>
            <div className="button-container max-width flex space-around">
              <EquiButton value="Cancel" onClick={(): void => setIsEditing(false)} />
              <EquiButton value="Update" type="submit" />
            </div>
          </form>
        </SectionCard>
      )}
    </Page>
  );
}

export default MyProfile;
