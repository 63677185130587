import { ReactElement, useEffect } from 'react';
import { useAuth } from '@hooks';

export default function LogoutPage(): ReactElement | null {
  const { clearUserInfoLogout } = useAuth();
  useEffect(() => {
    clearUserInfoLogout();
  }, []);
  return null;
}
