import { useState } from 'react';

interface SortHook<T extends string> {
  field: T;
  isDescending: boolean;
  setSortField: (f: SortHook<T>['field']) => void;
}

interface State<T extends string> {
  field: SortHook<T>['field'];
  isDescending: boolean;
}

export function useSort<T extends string>(
  defaultField: SortHook<T>['field'],
  defaultIsDescending: boolean,
): SortHook<T> {
  const [{ field, isDescending }, setSortByType] = useState<State<T>>({
    field: defaultField,
    isDescending: defaultIsDescending,
  });
  function setSortField(newField: SortHook<T>['field']): void {
    let newIsDescending = false;
    if (newField === field) {
      newIsDescending = !isDescending;
    } else if (newField === 'lastUpdated' && field !== newField) {
      newIsDescending = true;
    }
    setSortByType({ field: newField, isDescending: newIsDescending });
  }
  return { field, isDescending, setSortField };
}
