import { ResponsePayloads } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  START_GET_RELATIONSHIPS = 'START_GET_RELATIONSHIPS',
  GET_RELATIONSHIPS = 'GET_RELATIONSHIPS',
  RESET_HAS_FETCHED_RELATIONSHIPS = 'RESET_HAS_FETCHED_RELATIONSHIPS',
  ASSIGN_SUBSCRIBER_TO_AGENT = 'ASSIGN_SUBSCRIBER_TO_AGENT',
  UNASSIGN_SUBSCRIBER_FROM_AGENT = 'UNASSIGN_SUBSCRIBER_FROM_AGENT',
}

export interface GetRelationships extends Action {
  type: typeof ActionTypes.GET_RELATIONSHIPS;
  payload: ResponsePayloads['getRelationships'];
}

export interface StartGetRelationships extends Action {
  type: typeof ActionTypes.START_GET_RELATIONSHIPS;
}

export interface AssignSubscriberToAgent extends Action {
  type: typeof ActionTypes.ASSIGN_SUBSCRIBER_TO_AGENT;
}
export interface UnassignSubscriberFromAgent extends Action {
  type: typeof ActionTypes.UNASSIGN_SUBSCRIBER_FROM_AGENT;
}

export interface ResetHasFetchedRelationships extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_RELATIONSHIPS;
}
