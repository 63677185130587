import { ManageOrganizationAPI } from '@api';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { AsyncAction } from '../types';
import { ActionTypes, GetOrganizations, StartGetOrganizations } from './types';

export type Actions = GetOrganizations | StartGetOrganizations;

export const Actions = {
  getOrganizations({
    failSilently,
  }: {
    failSilently?: boolean;
  }): AsyncAction<GetOrganizations | StartGetOrganizations | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_ORGANIZATIONS, payload: undefined });
        const organizations = await ManageOrganizationAPI.getOrganizations();
        dispatch({ type: ActionTypes.GET_ORGANIZATIONS, payload: organizations });
      } catch (e) {
        if (!failSilently) {
          dispatch(ApiActions.handleAxiosError(e));
        }
        return;
      }
    };
  },
};

export * from './types';
