import { KYC } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  GET_KYCS = 'GET_KYCS',
  START_GET_KYCS = 'START_GET_KYCS',
}

export interface GetKYCs extends Action {
  type: typeof ActionTypes.GET_KYCS;
  payload: KYC[];
}

export interface StartGetKYCs extends Action {
  type: typeof ActionTypes.START_GET_KYCS;
}
