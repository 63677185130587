import { createRef, ReactElement } from 'react';
import classnames from 'classnames';
import { Checkbox, isChecked } from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import EquiInput from '@components/common/KYCEquiInput';
import { useFullscreen, useKYCFormFields, useKYCFormState } from '@hooks';

import './styles.scss';

function ClientInformation(): ReactElement {
  const formFields = useKYCFormFields();
  const { isFormDisabledForCurrentUser, uploadPhotoID, form } = useKYCFormState();
  const maximizableElement = createRef<HTMLDivElement>();
  const [isFullscreen, setFullscreen] = useFullscreen(maximizableElement);
  const handleExitFullscreen = (): Promise<void> => document.exitFullscreen();
  function setHomeCountry(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, country: string): void {
    event.preventDefault();
    if (isFormDisabledForCurrentUser) {
      return;
    }

    formFields.homeCountry.onChange((event as unknown) as React.ChangeEvent<HTMLInputElement>, country);
  }
  function setMailingCountry(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, country: string): void {
    event.preventDefault();
    if (isFormDisabledForCurrentUser) {
      return;
    }
    formFields.mailingCountry.onChange((event as unknown) as React.ChangeEvent<HTMLInputElement>, country);
  }
  return (
    <div className="ClientInformation form-section">
      <div className="form-section">
        <h2 className="title">Client Information</h2>
        <div className="flex form-row">
          <EquiInput {...formFields.title} className="no-flex" noMaxWidth />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.firstName} />
          <EquiInput {...formFields.lastName} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.email} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.dateOfBirth} />
          <EquiInput {...formFields.SIN} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.homePhone} />
          <EquiInput {...formFields.mobilePhone} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.workPhone} />
          <EquiInput {...formFields.fax} />
        </div>
      </div>
      <div className="form-section">
        <h2 className="title header spacer">Identification</h2>
        <div className="flex form-row">
          <EquiInput {...formFields.driversLicenseNumber} />
          <div className="or-text justify-center align-center flex no-flex">OR</div>
          <EquiInput {...formFields.passportNumber} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.otherIDType} />
          <EquiInput {...formFields.otherIDNumber} />
        </div>
      </div>
      <div className="form-section">
        <GeneralInput
          formLabel="Photo ID"
          disabled={isFormDisabledForCurrentUser}
          onChange={uploadPhotoID as OnChange}
          type="file"
          accept="image/*,application/pdf"
          error={form?.formData?.errors.find((error) => error.fieldCausingError.includes('photoID'))?.error}
        />
        {formFields.photoID.value && (
          <div
            ref={maximizableElement}
            className={classnames('photo-id-container flex justify-center column align-center', {
              fullscreen: isFullscreen,
            })}
          >
            {(formFields.photoID.value as string).slice(-3) === 'pdf' ? (
              <>
                <iframe
                  frameBorder="none"
                  allowFullScreen
                  className={classnames('embedded-photo-id', {
                    isPDF: true,
                  })}
                  src={formFields.photoID.value as string}
                  key={formFields.photoID.value as string}
                >
                  <p>PDF cannot be displayed: iframes are not supported by your browser.</p>
                </iframe>
                {!isFullscreen ? (
                  <button onClick={setFullscreen} type="button">
                    Make Full Screen
                  </button>
                ) : (
                  <EquiButton onClick={handleExitFullscreen} type="button" value="Exit Full Screen" />
                )}
              </>
            ) : (
              <img
                className={classnames('embedded-photo-id')}
                src={formFields.photoID.value as string}
                key={formFields.photoID.value as string}
                alt="Uploaded Photo ID"
              />
            )}
          </div>
        )}
      </div>
      <div className="form-section">
        <h2 className="title header spacer">Home Address</h2>
        <div className="flex form-row">
          <EquiInput {...formFields.homeStreetAddress} />
          <EquiInput {...formFields.homeCity} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.homeProvince} />
          <EquiInput {...formFields.homePostalCode} />
        </div>
        <div className="flex form-row">
          <EquiInput {...formFields.homeCountry} />
          <div className="max-width flex button-container">
            <EquiButton
              disabled={isFormDisabledForCurrentUser}
              className={classnames({ 'read-only': isFormDisabledForCurrentUser })}
              kind={'Canada' === formFields.homeCountry.value ? ButtonType.primary : ButtonType.secondary}
              onClick={(event): void => setHomeCountry(event, 'Canada')}
              value="Canada"
            />
          </div>
        </div>
      </div>
      <div className="form-section">
        <h2 className="title header spacer">Mailing Address</h2>
        <div className="flex form-row spacer">
          <div className="no-flex">
            <EquiInput {...formFields.isMailingAddressSame} />
          </div>
        </div>
        {!isChecked(formFields.isMailingAddressSame.value as Checkbox) && (
          <>
            <div className="flex form-row">
              <EquiInput {...formFields.mailingStreetAddress} />
              <EquiInput {...formFields.mailingCity} />
            </div>
            <div className="flex form-row">
              <EquiInput {...formFields.mailingProvince} />
              <EquiInput {...formFields.mailingPostalCode} />
            </div>
            <div className="flex form-row">
              <EquiInput {...formFields.mailingCountry} />
              <div className="max-width flex button-container">
                <EquiButton
                  disabled={isFormDisabledForCurrentUser}
                  className={classnames({ 'read-only': isFormDisabledForCurrentUser })}
                  kind={'Canada' === formFields.mailingCountry.value ? ButtonType.primary : ButtonType.secondary}
                  onClick={(event): void => setMailingCountry(event, 'Canada')}
                  value="Canada"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ClientInformation;
