import { ControllingPerson, TrueFalseRadio, controllingPersonTypes } from 'shared';
import { ProtoFields } from '../types';

export const controllingPersonFields: ProtoFields<keyof ControllingPerson> = {
  isPurchaserCorporationOrTrust: {
    type: 'radio',
    formLabel: 'Is the purchaser a corporation or trust?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  controllingPersonType: {
    type: 'select',
    formLabel: 'Type of Controlling Person',
    options: controllingPersonTypes
      .concat([])
      .sort()
      .map((type) => ({ displayName: type, value: type })),
  },
};
