import { ClientInformation } from 'shared';
import { titleRadioField } from '@utils/formFields';
import { ProtoFields } from '../types';

export const clientInformationFields: ProtoFields<keyof ClientInformation> = {
  SIN: { formLabel: 'Social Insurance Number' },
  dateOfBirth: { formLabel: 'Date of Birth (YYYY-MM-DD)', type: 'date' },
  driversLicenseNumber: { formLabel: "Driver's License" },
  email: { formLabel: 'Email', type: 'email', readOnly: true },
  fax: { type: 'tel', formLabel: 'Fax' },
  faxCountryCode: { formLabel: 'Fax' },
  homeCity: { formLabel: 'City' },
  homeCountry: { formLabel: 'Country' },
  homePhone: { formLabel: 'Home Phone', type: 'tel' },
  homePhoneCountryCode: { formLabel: 'Home Phone', type: 'tel' },
  homePostalCode: { formLabel: 'Postal Code' },
  homeProvince: { formLabel: 'Province' },
  homeStreetAddress: { formLabel: 'Street Address' },
  isMailingAddressSame: {
    type: 'checkbox',
    formLabel: 'Same as Above',
  },
  mailingCity: { formLabel: 'City' },
  mailingCountry: { formLabel: 'Country' },
  mailingPostalCode: { formLabel: 'Postal Code' },
  mailingProvince: { formLabel: 'Province' },
  mailingStreetAddress: { formLabel: 'Street Address' },
  mobilePhone: { formLabel: 'Mobile Phone', type: 'tel' },
  mobilePhoneCountryCode: { formLabel: 'Mobile Phone', type: 'tel' },
  firstName: { formLabel: 'First Name and Initial(s)' },
  lastName: { formLabel: 'Last Name' },
  otherIDNumber: { formLabel: 'ID Number' },
  otherIDType: { formLabel: 'Other ID Type' },
  passportNumber: { formLabel: 'Passport Number' },
  photoID: { type: 'file', formLabel: 'Photo ID' },
  title: titleRadioField,
  workPhone: { formLabel: 'Work Phone', type: 'tel' },
  workPhoneCountryCode: { formLabel: 'Work Phone', type: 'tel' },
};
