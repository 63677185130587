import axios from 'axios';
import { ResponsePayloads, Winterpeg } from 'shared';

export function runWinterPegScenarioCEQ2013TerminationProcess(
  file: File,
  isTest: boolean,
): Promise<ResponsePayloads['runWinterPegScenarioCEQ2013TerminationProcess']> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('isTest', isTest.toString());
  return axios
    .post(`/admin/winterpeg/scenario-c/eq-2013`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export function runWinterPegScenarioCPhase1TerminationProcess(
  programName: Winterpeg.TPrograms,
  file: File,
  isTest: boolean,
): Promise<ResponsePayloads['runWinterPegScenarioCPhase1TerminationProcess']> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('programName', programName);
  formData.append('isTest', isTest.toString());
  return axios
    .post(`/admin/winterpeg/scenario-c/phase1`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export function runWinterPegScenarioCPhase2TerminationProcess(
  file: File,
  isTest: boolean,
): Promise<ResponsePayloads['runWinterPegScenarioCPhase2TerminationProcess']> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('isTest', isTest.toString());
  return axios
    .post(`/admin/winterpeg/scenario-c/phase2`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export function runWinterPegScenarioBTerminationProcess(
  file: File,
  isTest: boolean,
): Promise<ResponsePayloads['runWinterPegScenarioBTerminationProcess']> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('isTest', isTest.toString());
  return axios
    .post(`/admin/winterpeg/scenario-b`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}
