import { ReactElement } from 'react';
import classnames from 'classnames';

import './styles.scss';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
}

interface Props<T = HTMLButtonElement> extends React.ButtonHTMLAttributes<T> {
  kind?: ButtonType;
  value: string;
  className?: string;
}

function EquiButton(props: Props): ReactElement {
  const { value, kind = ButtonType.primary, className, type = 'button' } = props;
  return (
    <button {...props} type={type} className={classnames('EquiButton', `button-${kind}`, className)}>
      {value}
    </button>
  );
}

export default EquiButton;
