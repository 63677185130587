import { ReactElement } from 'react';
import EquiInput from '@components/common/SubscriptionPackageEquiInput';
import { useSubscriptionPackageFormFields } from '@hooks';

function SalespersonInformation(): ReactElement {
  const formFields = useSubscriptionPackageFormFields();
  return (
    <div className="form-section">
      <h2 className="title">Salesperson Information</h2>
      <div className="flex form-row">
        <EquiInput {...formFields.salespersonName} className="no-flex" />
        <EquiInput {...formFields.salespersonEmail} readOnly className="no-flex" />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.salespersonPhone} className="no-flex" />
        <EquiInput {...formFields.salespersonFirm} readOnly className="no-flex" />
      </div>
    </div>
  );
}

export default SalespersonInformation;
