import { KYCAPI } from '@api';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { AsyncAction } from '../types';
import { ActionTypes, GetKYCs, StartGetKYCs } from './types';

export type Actions = GetKYCs | StartGetKYCs;

export const Actions = {
  getKYCs(): AsyncAction<GetKYCs | StartGetKYCs | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_KYCS, payload: undefined });
        const kycs = await KYCAPI.getKYCs();
        dispatch({ type: ActionTypes.GET_KYCS, payload: kycs });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        return;
      }
    };
  },
};

export * from './types';
