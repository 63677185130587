import axios from 'axios';
import { RequestParams, RequestPayloads, ResponsePayloads } from 'shared';

export function getKYC(id: number): Promise<ResponsePayloads['getKYC']> {
  return axios.get(`/kycs/${id}`).then((res) => res.data);
}

export function getKYCs(): Promise<ResponsePayloads['getKYCs']> {
  return axios.get(`/kycs`).then((res) => res.data);
}

export function createKYC(formData: RequestPayloads['createKYC']): Promise<ResponsePayloads['createKYC']> {
  return axios.post('/kycs', formData).then((res) => res.data);
}

export function createKYCForExistingSubscriber({
  email,
}: RequestParams['createKYCForExistingSubscriber']): Promise<ResponsePayloads['createKYCForExistingSubscriber']> {
  return axios.post(`/users/${email}/kyc`).then((res) => res.data);
}

export function updateKYC(id: number, formData: RequestPayloads['updateKYC']): Promise<ResponsePayloads['updateKYC']> {
  return axios.patch(`/kycs/${id}`, formData).then((res) => res.data);
}

export function inviteSubscriber(id: number): Promise<ResponsePayloads['inviteSubscriber']> {
  return axios.patch(`/kycs/${id}/invite-client`).then((res) => res.data);
}

export function requestApproval(id: number): Promise<ResponsePayloads['requestApproval']> {
  return axios.patch(`/kycs/${id}/request-approval`).then((res) => res.data);
}

export function requestChanges(id: number): Promise<ResponsePayloads['changesRequestedByAgent']> {
  return axios.patch(`/kycs/${id}/changes-requested`).then((res) => res.data);
}

export function agentApproveForm(id: number): Promise<ResponsePayloads['approve']> {
  return axios.patch(`/kycs/${id}/agent-approve`).then((res) => res.data);
}

export function orgReviewerApproveForm(id: number): Promise<ResponsePayloads['approve']> {
  return axios.patch(`/kycs/${id}/org-reviewer-approve`).then((res) => res.data);
}

export function quickModification(id: number): Promise<ResponsePayloads['approve']> {
  return axios.patch(`/kycs/${id}/quick-modifications`).then((res) => res.data);
}

export function markComplete(id: number): Promise<ResponsePayloads['approve']> {
  return axios.patch(`/kycs/${id}/complete`).then((res) => res.data);
}

export function renewForm(id: number): Promise<ResponsePayloads['renew']> {
  return axios.patch(`/kycs/${id}/renew`).then((res) => res.data);
}
export function cancelRenewalProcess(id: number): Promise<ResponsePayloads['cancelRenewalProcess']> {
  return axios.patch(`/kycs/${id}/cancel-renewal`).then((res) => res.data);
}

export function uploadPhotoId(kycId: number, file: File): Promise<ResponsePayloads['uploadPhotoId']> {
  const formData = new FormData();
  formData.append('photoID', file);
  return axios
    .patch(`/kycs/${kycId}/photo-id`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export function getKYCByUserEmail(email: string): Promise<ResponsePayloads['getKYCByUserEmail']> {
  return axios.get(`/users/${email}/kyc`).then((res) => res.data);
}

export function fillWithFakeData(kycId: number): Promise<ResponsePayloads['fillWithFakeData']> {
  return axios.patch(`/kycs/${kycId}/fake-data`).then((res) => res.data);
}
