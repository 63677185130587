import { CurrentUser } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  START_GET_CURRENT_USER = 'START_GET_CURRENT_USER',
  GET_CURRENT_USER = 'GET_CURRENT_USER',
  RESET_HAS_FETCHED_CURRENT_USER = 'RESET_HAS_FETCHED_CURRENT_USER',
}

export interface StartGetCurrentUser extends Action {
  type: typeof ActionTypes.START_GET_CURRENT_USER;
}

export interface GetCurrentUser extends Action {
  type: typeof ActionTypes.GET_CURRENT_USER;
  payload: CurrentUser;
}

export interface ResetHasFetchedCurrentUser extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_CURRENT_USER;
}
