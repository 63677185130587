import { Auth0ProviderOptions } from '@auth0/auth0-react';
import { getEnvValue } from 'shared';

export const audience = getEnvValue('AUTH0_AUDIENCE');
const clientId = getEnvValue('AUTH0_CLIENT_ID');
const domain = getEnvValue('AUTH0_DOMAIN');

export const scope = 'openid profile email';

export default function getConfig(redirectUri = window.location.origin): Auth0ProviderOptions {
  if (!audience || !clientId || !domain) {
    throw new Error('Cannot connect to Auth Provider');
  }
  return { audience, clientId, domain, redirectUri, advancedOptions: { defaultScope: scope } };
}
