import { ReactElement, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BrandingContext } from '@contexts';
import { useSmartBackButton } from '@hooks';
import { ValidPath } from '@routes/lib';
import Icon from '../Icon';

import './styles.scss';

interface Props {
  forceBackLocation?: ValidPath;
  defaultLocation: ValidPath;
}

export default function BackButton({ forceBackLocation, defaultLocation }: Props): ReactElement {
  const { goBack } = useSmartBackButton(forceBackLocation, defaultLocation);
  const { showBrandingInNav } = useContext(BrandingContext);
  return (
    <Link
      to={forceBackLocation || defaultLocation}
      className={classNames('BackButton', { 'with-branding': showBrandingInNav })}
      onClick={(event: React.MouseEvent): void => {
        goBack(event);
      }}
    >
      <div className="actual-button bg-whitesmoke rounded-lg p-4 bg-opacity-50 hover:bg-opacity-100">
        <Icon type="backArrow" />
        <span>Back</span>
      </div>
    </Link>
  );
}
