import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { Timeline } from 'primereact/timeline';
import { Link, useParams } from 'react-router-dom';
import { filter, FullUser, getKYCStatesOrder, getOrderNumberOfState, Permission } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Icon from '@components/common/Icon';
import { LinkCardData, renderCards } from '@components/common/LinkCard';
import OfficeUseButton from '@components/common/OfficeUseButton';
import SectionCard from '@components/common/SectionCard';
import SectionMax80 from '@components/common/SectionMax80';
import { TableData, TableHeader, TableRow } from '@components/common/Table';
import AssignAgentToSubscriberModal from '@components/Modals/AssignAgentToSubscriberModal';
import NewSubscriptionPackageFormModal from '@components/Modals/NewSubscriptionPackageFormModal';
import UserOrganizationSection from '@components/UserOrganizationSection';
import {
  useCurrentUser,
  useCurrentUserReadableRelationships,
  useFlashMessage,
  useKYCByEmail,
  useSingleUser,
  useSubscriptionPackagesByEmail,
  useUsers,
} from '@hooks';
import { Page } from '@layouts';
import { ErrorPage } from '@pages';
import { PATHS, ValidPath } from '@routes/lib';
import { displayKYCState } from '@utils/formState';
import PageTitle from './PageTitle';

import './styles.scss';

export default function ViewSubscriberPage(): ReactElement {
  const { email } = useParams<{ email: string }>();
  const { isLoading, error, user, toggleUserBlocked, updateOrgUserRoles } = useSingleUser(email);
  const [assignAgentToSubscriberIsOpen, setAssignAgentToSubscriberIsOpen] = useState(false);
  const [subscriptionPackageFormIsOpen, setSubscriptionPackageFormIsOpen] = useState(false);
  const {
    isLoading: relationshipsIsLoading,
    relationships,
    assignSubscriberToAgent,
    unassignSubscriberFromAgent,
  } = useCurrentUserReadableRelationships();
  const { isLoading: usersIsLoading, users } = useUsers({ role: 'agent' });
  const { subscriptionPackages } = useSubscriptionPackagesByEmail(email);
  const { kyc, isLoading: isKYCLoading, createKYCForExistingSubscriber } = useKYCByEmail(email);
  const { flashWarningMessage } = useFlashMessage();
  const {
    hasAllPermissions,
    currentUser,
    doesCurrentUserManageOrgUser,
    doesCurrentUserReviewOrgUser,
    isCurrentUserEQ,
  } = useCurrentUser();
  function closeSubscriptionPackageFormModal(): void {
    setSubscriptionPackageFormIsOpen(false);
  }
  function openSubscriptionPackageFormModal(): void {
    const hasIncompleteSubscriptionForm = !!subscriptionPackages.find(
      (subPackage) => subPackage.state === 'inProgressByAgent',
    );
    if (
      hasIncompleteSubscriptionForm &&
      !confirm(
        'There is a Subscription Package already in progress for this Subscriber. It is preferred if that one is completed/continued before creating a new one. Are you sure you want to create a new Subscription Package?',
      )
    ) {
      return;
    }
    setSubscriptionPackageFormIsOpen(true);
  }

  function closeAssignAgentToSubscriberModal(): void {
    setAssignAgentToSubscriberIsOpen(false);
  }
  function openAssignAgentToSubscriberModal(): void {
    setAssignAgentToSubscriberIsOpen(true);
  }
  function onAssignAgentToSubscriberSuccess(): void {
    closeAssignAgentToSubscriberModal();
  }
  function assignAgent(agent: FullUser, changeSubscriberOrganization: boolean): void {
    if (user) {
      assignSubscriberToAgent(user, agent, changeSubscriberOrganization, onAssignAgentToSubscriberSuccess);
    }
  }
  function unassign(agent: FullUser): void {
    const relationship = relationships.find((r) => r.agentUserId === agent.user_id);
    if (relationship) {
      unassignSubscriberFromAgent(relationship.id);
    }
  }
  if (error) {
    return <ErrorPage forceBackLocation={PATHS.SUBSCRIBERS} />;
  }
  function canDoAll(permission: Permission): boolean {
    return !!kyc && hasAllPermissions([permission]);
  }
  function canDoOwn(permission: Permission): boolean {
    return !!user && !!currentUser && !!kyc && hasAllPermissions([permission]) && user.user_id === currentUser.user_id;
  }
  function canDoOwnOrgSubscriber(): boolean {
    return !!user && !!kyc && (doesCurrentUserManageOrgUser(user) || doesCurrentUserReviewOrgUser(user));
  }
  function canDoOwnSubscriber(permission: Permission): boolean {
    return (
      !!user &&
      !!kyc &&
      hasAllPermissions([permission]) &&
      !!relationships.find(
        (relationship) =>
          relationship.agentUserId === currentUser?.user_id && relationship.subscriberUserId === user.user_id,
      )
    );
  }
  const agents = filter<FullUser>(
    relationships.filter((r) => r.subscriberUserId === user?.user_id).map((r) => r.agent),
  );
  const viewKYCPermissions =
    canDoAll('read:kyc') ||
    canDoOwn('read:ownKYC') ||
    canDoOwnOrgSubscriber() ||
    canDoOwnSubscriber('read:ownSubscriberKYC'); // is agent for subscriber;
  const viewOfficeUsePermissions =
    // only if the org is using the KYC can they use the office notes section
    !user?.organization?.isNotUsingEqKYC &&
    (canDoAll('read:officeUse') || canDoOwnOrgSubscriber() || canDoOwnSubscriber('read:ownSubscriberOfficeUse')); // is agent for subscriber;
  const createNewSubscriptionPackagePermissions =
    canDoAll('create:subscriptionPackage') ||
    canDoOwnOrgSubscriber() ||
    canDoOwnSubscriber('create:ownSubscriberSubscriptionPackage');
  const viewSubscriptionPackagesPermissions =
    canDoAll('read:subscriptionPackage') ||
    canDoOwnOrgSubscriber() ||
    canDoOwnSubscriber('read:ownSubscriberSubscriptionPackage');
  const createKYCPermissions = !kyc && hasAllPermissions(['create:kyc']);
  const isKYCComplete = kyc?.state === 'complete';
  const numberOfCircles = getKYCStatesOrder(!!user?.organization?.requiresKYCReviewByReviewer).length;
  const numberOfFilledInCircles = kyc
    ? getOrderNumberOfState(kyc.state, !!user?.organization?.requiresKYCReviewByReviewer)
    : 0;
  const cards: LinkCardData[] = filter([
    viewKYCPermissions && {
      icon: 'kyc',
      title: (
        <>
          <h2 className={classnames({ 'mt-2': !!kyc })}>Client Registration Form</h2>
          {kyc && (
            <>
              <div
                className={classnames('absolute bottom-1/2 transform w-full translate-y-1.5 px-2', {
                  'text-success': isKYCComplete,
                })}
              >
                {displayKYCState(kyc.state)}
                {isKYCComplete && <i className="pi pi-check-circle ml-2" />}
              </div>
              <div className={classnames('absolute bottom-1 w-4/5')}>
                <Timeline
                  layout="horizontal"
                  className="kyc-state-timeline"
                  value={Array.from({ length: numberOfCircles - 1 }).map((_, i) =>
                    i < numberOfFilledInCircles || isKYCComplete ? true : false,
                  )}
                  marker={(item: boolean, i: number): ReactElement => (
                    <i
                      className={classnames('pi', {
                        'pi-check-circle': item,
                        'pi-circle': !item,
                        'text-warning': numberOfFilledInCircles === i,
                        'text-gray': i > numberOfFilledInCircles,
                        'text-success': i < numberOfFilledInCircles || isKYCComplete,
                      })}
                    />
                  )}
                />
              </div>
            </>
          )}
        </>
      ),
      to: `${PATHS.SUBSCRIBERS}/${user?.email}/client-registration` as ValidPath,
    },
    createNewSubscriptionPackagePermissions &&
      !!agents.length &&
      ({
        icon: 'newForm',
        title: 'New Subscription Package',
        to: '#',
        onClick: (e) => {
          e.preventDefault();
          openSubscriptionPackageFormModal();
        },
      } as LinkCardData),
    viewSubscriptionPackagesPermissions &&
      !!subscriptionPackages.length &&
      ({
        icon: 'approveForm',
        title: 'View Subscription Packages',
        to: `${PATHS.SUBSCRIBERS}/${user?.email}/subscription-packages`,
      } as LinkCardData),
    createKYCPermissions &&
      ({
        icon: 'newForm',
        title: 'Create Client Registration Form',
        to: '#',
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          createKYCForExistingSubscriber();
        },
      } as LinkCardData),
  ]);
  const headers: TableHeader[] = filter([
    { text: 'Name' },
    {
      text: 'Email',
    },
    { text: 'Organization' },
    (hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user)) && { text: '' },
  ]);
  const tableData: TableRow[] = filter(
    agents.map((agent) =>
      filter<TableData>([
        hasAllPermissions(['read:relationship', 'read:agent']) ? (
          <Link to={`${PATHS.AGENTS}/${agent.email}`} title={agent.name}>
            {agent.name}
          </Link>
        ) : (
          <span title={agent.name}>{agent.name}</span>
        ),
        <span title={agent.email}>{agent.email}</span>,
        agent.organization?.name || <em>N/A</em>,
        (hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user)) && (
          <EquiButton value="Unassign" onClick={(): void => unassign(agent)} />
        ),
      ]),
    ),
  );

  return (
    <Page
      className="ViewSubscriberPage"
      renderHeader={user ? (): ReactElement => <PageTitle user={user} /> : undefined}
      defaultBackLocation={PATHS.SUBSCRIBERS}
      isLoading={isLoading || relationshipsIsLoading || isKYCLoading || usersIsLoading}
      showChildrenOnLoading={false}
      preHeader={
        viewOfficeUsePermissions && user ? (): JSX.Element => <OfficeUseButton email={user.email} /> : undefined
      }
    >
      {renderCards(cards)}
      <SectionMax80>
        <div className="mt-10 w-full">
          <UserOrganizationSection
            assignType="Agent"
            openAssignModal={openAssignAgentToSubscriberModal}
            tableData={tableData}
            headers={headers}
            user={user}
            updateOrgUserRoles={updateOrgUserRoles}
          />
        </div>

        {user &&
          (hasAllPermissions(['create:relationship']) || doesCurrentUserManageOrgUser(user)) &&
          assignAgentToSubscriberIsOpen && (
            <AssignAgentToSubscriberModal
              onClose={closeAssignAgentToSubscriberModal}
              onSubmit={assignAgent}
              user={user}
              currentSubscriberAgents={agents}
              agents={users}
            />
          )}
        {user &&
          (canDoAll('create:subscriptionPackage') ||
            canDoOwnOrgSubscriber() ||
            canDoOwnSubscriber('create:ownSubscriberSubscriptionPackage')) &&
          subscriptionPackageFormIsOpen &&
          !!agents.length &&
          kyc && (
            <NewSubscriptionPackageFormModal
              onClose={closeSubscriptionPackageFormModal}
              onSubmit={closeSubscriptionPackageFormModal}
              user={user}
              kyc={kyc}
              currentSubscriberAgents={agents}
            />
          )}
        {((currentUser &&
          agents.map((agent) => agent.user_id).includes(currentUser.user_id) &&
          hasAllPermissions(['block:ownSubscriber'])) ||
          hasAllPermissions(['block:subscriber']) ||
          isCurrentUserEQ ||
          doesCurrentUserManageOrgUser(user)) && (
          <SectionCard className="account-toggle">
            <div className="user-detail flex max-width align-center space-between">
              <div>
                Account Active? <b>{user?.blocked ? 'No' : 'Yes'}</b>
              </div>
              <div
                onClick={
                  currentUser?.user_id !== user?.user_id
                    ? toggleUserBlocked
                    : (): void => flashWarningMessage('Warning: You cannot disable your own account.')
                }
                className={classnames(
                  currentUser?.user_id !== user?.user_id ? 'pointer' : undefined,
                  'value-item',
                  'flex items-center',
                  'group',
                )}
              >
                <span className="mr-4 group-hover:text-equigenesis-gold">
                  {user?.blocked ? 'Activate Account' : 'De-Activate Account'}
                </span>
                <Icon
                  className={classnames({ 'toggle-off': user?.blocked })}
                  type={!user?.blocked ? 'toggleOn' : 'toggleOff'}
                />
              </div>
            </div>
          </SectionCard>
        )}
      </SectionMax80>
    </Page>
  );
}
