import { BankingInformation } from 'shared';
import { ProtoFields } from '../types';

export const bankingInformationFields: ProtoFields<keyof BankingInformation> = {
  accountManager: { formLabel: 'Account Manager' },
  accountManagerEmail: { type: 'email', formLabel: 'Account Manager Email' },
  bankName: { formLabel: 'Name of Bank' },
  bankPhone: { formLabel: 'Phone Number', type: 'tel' },
  bankPhoneCountryCode: { formLabel: 'Phone Number' },
  branchNum: { formLabel: 'Branch #' },
  branchAddress: { formLabel: 'Branch Address' },
};
