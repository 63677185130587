import { ReactElement } from 'react';
import classNames from 'classnames';
import { Link, Redirect, useParams } from 'react-router-dom';
import { getHighestRole, KYCAttributes, Permission } from 'shared';
import EquiButton from '@components/common/EquiButton';
import KYC from '@components/KYC';
import KYCTimeline from '@components/KYCTimeline';
import { useCurrentUser, useCurrentUserReadableRelationships, useKYCFormState, useSingleUser } from '@hooks';
import { Page } from '@layouts';
import { ErrorPage } from '@pages';
import { PATHS, ValidPath } from '@routes/lib';
import { displayKYCState } from '@utils/formState';
import './styles.scss';

export default function KYCPage(): ReactElement {
  const { email } = useParams<{ email: string }>();
  const { isLoading, form, hasFetched, isFormForCurrentUser, fillWithFakeData } = useKYCFormState(email);
  const { isLoading: userIsLoading, error, user } = useSingleUser(email);
  const {
    hasAllPermissions,
    currentUser,
    doesCurrentUserReviewOrgUser,
    doesCurrentUserManageOrgUser,
    doesCurrentUserManageUser,
    isCurrentUserAdmin,
  } = useCurrentUser();
  const { isLoading: relationshipsIsLoading, relationships } = useCurrentUserReadableRelationships();
  if (email && !isLoading && !form && hasFetched) {
    return <Redirect to={PATHS.NOT_FOUND} />;
  }
  const isKYCForCurrentUser = !!user?.user_id && user?.user_id === currentUser?.user_id;
  const backLocation = isKYCForCurrentUser ? PATHS.HOME : (`${PATHS.SUBSCRIBERS}/${email}` as ValidPath);
  function canDoAll(permission: Permission, state: KYCAttributes['state']): boolean {
    return form?.state === state && hasAllPermissions([permission]);
  }
  function canDoOwn(permission: Permission, state: KYCAttributes['state']): boolean {
    return (
      form?.state === state &&
      (state === 'inProgress' || state === 'changesRequestedByAgent') &&
      !!user &&
      !!currentUser &&
      hasAllPermissions([permission]) &&
      user.user_id === currentUser.user_id
    );
  }
  const doesSubscriberHaveAgent = !!relationships.find(
    (relationship) => relationship.subscriberUserId === form?.subscriberUserId,
  );

  const isCurrentUserAgentForSubscriber = !!relationships.find(
    (relationship) =>
      relationship.agentUserId === currentUser?.user_id && relationship.subscriberUserId === user?.user_id,
  );

  function fillWithFakeDataHandler(): void {
    if (
      confirm(
        "This will fill the form with fake data. Doing so will erase any other entered information besides the user's name and email. This is irreversible. Are you sure you wish to proceed?",
      )
    ) {
      fillWithFakeData();
    }
  }

  function canDoOwnSubscriber(permission: Permission, state: KYCAttributes['state']): boolean {
    return (
      form?.state === state &&
      state !== 'underReviewByOrgReviewer' &&
      !!user &&
      hasAllPermissions([permission]) &&
      isCurrentUserAgentForSubscriber
    );
  }
  function canDoOwnOrgSubscriber(state: KYCAttributes['state']): boolean {
    return (
      form?.state === state && !!user && (doesCurrentUserReviewOrgUser(user) || doesCurrentUserManageOrgUser(user))
    );
  }
  function permissionCheck(state: KYCAttributes['state']): boolean {
    return (
      canDoAll('update:kyc', state) ||
      canDoOwnOrgSubscriber(state) ||
      canDoOwnSubscriber('update:ownSubscriberKYC', state) ||
      canDoOwn('update:ownKYC', state)
    );
  }
  if (error) {
    return <ErrorPage forceBackLocation={PATHS.SUBSCRIBERS} />;
  }
  const isShowingTimeline =
    !!form &&
    (isCurrentUserAgentForSubscriber || doesCurrentUserManageUser(user) || doesCurrentUserReviewOrgUser(user));
  return (
    <Page
      className="KYCPage"
      defaultBackLocation={backLocation}
      withScrollToTop
      withScrollToBottom
      preHeader={(): ReactElement => (
        <>
          {isShowingTimeline && (
            <div className="w-full sticky top-64 -mt-0">
              <div className="absolute -left-6 top-0">
                <KYCTimeline kyc={form} organization={user?.organization} />
              </div>
            </div>
          )}
          {isCurrentUserAdmin && form && form?.state !== 'complete' && (
            <div className="w-full sticky top-96">
              <div className="absolute top-0 -right-10 z-10">
                <EquiButton
                  className="w-52 admin-only"
                  value="ADMIN ONLY: Fill with Fake Data"
                  onClick={fillWithFakeDataHandler}
                />
              </div>
            </div>
          )}
        </>
      )}
      renderHeader={
        form
          ? (): ReactElement => (
              <div className={classNames({ 'ml-32': isShowingTimeline })}>
                {form?.subscriber && (
                  <h1>
                    {getHighestRole(currentUser?.roles || []) !== 'subscriber' ? (
                      <Link to={`${PATHS.SUBSCRIBERS}/${form.subscriber.email}`}>{form.subscriber.name}</Link>
                    ) : (
                      form.subscriber.name
                    )}
                  </h1>
                )}
                <h2>{displayKYCState(form.state, user?.organization)}</h2>
                {form.state === 'initialized' && isFormForCurrentUser && (
                  <p className="max-w-lg mt-0">
                    Your agent is currently preparing your Client Registration Form. They will inform you when it is
                    ready for you to edit. Please check back later.
                  </p>
                )}
                {(form.state === 'complete' || (form.state === 'quickModification' && isKYCForCurrentUser)) && (
                  <p className="max-w-lg">
                    The Client Registration Form is complete. Editing is unavailable at this time.
                    {isKYCForCurrentUser && ' If adjustments need to be made, please speak to your agent.'}
                  </p>
                )}
                {form.state === 'renewing' && isKYCForCurrentUser && (
                  <p className="max-w-lg">
                    The Client Registration Form is being prepared for renewal. Editing is unavailable at this time.
                    {isKYCForCurrentUser && ' If adjustments need to be made, please speak to your agent.'}
                  </p>
                )}
                {form.state === 'renewing' && !isKYCForCurrentUser && (
                  <p className="max-w-lg">
                    The Client Registration Form is being prepared for renewal. Once ready, please invite the client
                    using the buttons at the bottom of the form.
                  </p>
                )}
                {isKYCForCurrentUser &&
                  ![
                    'complete',
                    'quickModification',
                    'inProgress',
                    'changesRequestedByAgent',
                    'initialized',
                    'renewing',
                  ].includes(form.state) && (
                    <>
                      <p className="max-w-lg">Your information has been submitted to EquiGenesis for review.</p>
                      <p className="max-w-lg">
                        Editing is unavailable at this time. If adjustments need to be made, please speak to your agent.
                      </p>
                    </>
                  )}
              </div>
            )
          : (): ReactElement => <h1>Loading Client Registration Form...</h1>
      }
      isLoading={isLoading || relationshipsIsLoading || userIsLoading}
      showChildrenOnLoading={false}
    >
      <div className={classNames('flex flex-1 align-center justify-center width-80', { 'ml-32': isShowingTimeline })}>
        <KYC user={user} doesSubscriberHaveAgent={doesSubscriberHaveAgent} permissionCheck={permissionCheck} />
      </div>
    </Page>
  );
}
