import { createRef, ReactElement } from 'react';
import classnames from 'classnames';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { SubscriptionPackage } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Icon from '@components/common/Icon';
import { useFullscreen, useSubscriptionPackageDocuSignURL, useSubscriptionPackageFormState } from '@hooks';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';
import { displaySubscriptionPackageState } from '@utils/formState';
import './styles.scss';

function getBackLocation(slug: string, form: SubscriptionPackage | null, isMyDocumentsView: boolean): ValidPath {
  if (!slug || isMyDocumentsView) {
    return PATHS.HOME;
  }
  return (slug
    ? `${PATHS.SUBSCRIPTION_PACKAGES}/${slug}`
    : form?.kyc?.subscriber
    ? `${PATHS.SUBSCRIBERS}/${form?.kyc?.subscriber.email}`
    : PATHS.HOME) as ValidPath;
}

export default function SubscriptionPackagePageExecutedCopy(): ReactElement {
  const { pathname } = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const { isLoading, form, hasFetched } = useSubscriptionPackageFormState(slug);
  const {
    docuSignViewUrl,
    hasFetched: hasFetchedDocuSignURL,
    isLoading: isLoadingDocuSignURL,
  } = useSubscriptionPackageDocuSignURL(slug);
  if (slug && !isLoading && !form && hasFetched) {
    return <Redirect to={PATHS.NOT_FOUND} />;
  }
  const isMyDocumentsView = pathname.startsWith(PATHS.MY_DOCUMENTS);
  const maximizableElement = createRef<HTMLDivElement>();
  const [isFullscreen, setFullscreen] = useFullscreen(maximizableElement);
  const handleExitFullscreen = (): Promise<void> => document.exitFullscreen();

  const backLocation = getBackLocation(slug, form, isMyDocumentsView);
  return (
    <Page
      className="SubscriptionPackagePageExecutedCopy"
      defaultBackLocation={backLocation}
      renderHeader={
        form
          ? (): ReactElement => (
              <>
                <h1>
                  {form.kyc?.subscriber && !isMyDocumentsView && (
                    <Link to={`${PATHS.SUBSCRIBERS}/${form?.kyc?.subscriber.email}`}>{form.kyc?.subscriber?.name}</Link>
                  )}
                </h1>
                <h2>
                  {displaySubscriptionPackageState(form.state)}
                  {form.state === 'completed' && <Icon className="status" type="checkmark" />}
                  {form.state === 'voided' && <Icon className="status" type="close" />}
                </h2>
              </>
            )
          : (): ReactElement => <h1>Loading Document...</h1>
      }
      showChildrenOnLoading={false}
      isLoading={isLoading || isLoadingDocuSignURL}
    >
      {((form && form.state !== 'completed' && form.state !== 'voided') ||
        (!docuSignViewUrl && hasFetchedDocuSignURL && !isLoadingDocuSignURL)) && (
        <div>
          This Subscription Package has not been completed in DocuSign yet and, therefore, cannot be viewed in the
          portal
        </div>
      )}
      {form && (form.state === 'completed' || form.state === 'voided') && docuSignViewUrl && (
        <div
          ref={maximizableElement}
          className={classnames('photo-id-container max-width flex justify-center column align-center -mt-2', {
            fullscreen: isFullscreen,
          })}
        >
          {!isFullscreen ? (
            <EquiButton
              onClick={setFullscreen}
              type="button"
              className="-mb-11 relative z-10"
              value="Make Full Screen"
            />
          ) : (
            <EquiButton onClick={handleExitFullscreen} type="button" value="Exit Full Screen" />
          )}
          <iframe
            frameBorder="none"
            allowFullScreen
            className={classnames('embedded-photo-id border border-solid', {
              isPDF: true,
            })}
            src={docuSignViewUrl}
            key={docuSignViewUrl}
          >
            <p>Embedded Document cannot be displayed: iframes are not supported by your browser.</p>
          </iframe>
        </div>
      )}
    </Page>
  );
}
