import { State as GeneralState } from '@reducers/general';
import { Action } from '../types';

export enum ActionTypes {
  SET_SHOW_LOADING_OVERLAY = 'SET_SHOW_LOADING_OVERLAY',
  SET_SHOW_SESSION_EXPIRED_OVERLAY = 'SET_SHOW_SESSION_EXPIRED_OVERLAY',
  FLASH_MESSAGE = 'FLASH_MESSAGE',
  SET_ERROR = 'SET_ERROR',
  NOT_CONNECTED_TO_INTERNET = 'NOT_CONNECTED_TO_INTERNET',
  UPDATE_PROCESSING_PROGRESS = 'UPDATE_PROCESSING_PROGRESS',
}
export interface SetShowLoadingOverlay extends Action {
  type: typeof ActionTypes.SET_SHOW_LOADING_OVERLAY;
  payload: boolean;
}

export interface SetShowSessionExpiredOverlay extends Action {
  type: typeof ActionTypes.SET_SHOW_SESSION_EXPIRED_OVERLAY;
  payload: boolean;
}

export interface FlashMessage extends Action {
  type: typeof ActionTypes.FLASH_MESSAGE;
  payload: GeneralState['flashMessage'];
}

export interface SetError extends Action {
  type: typeof ActionTypes.SET_ERROR;
  payload: GeneralState['error'];
}

export interface NotConnectedError extends Action {
  type: typeof ActionTypes.NOT_CONNECTED_TO_INTERNET;
}

export interface UpdateProcessingProgress extends Action {
  type: typeof ActionTypes.UPDATE_PROCESSING_PROGRESS;
  payload: {
    progress?: number | null;
  };
}
