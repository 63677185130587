import { useSelector, useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface AuthHook {
  auth: State['auth'];
  startLogout(): void;
  clearUserInfoLogout(): void;
}

export function useAuth(): AuthHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const auth = useSelector<State>((state) => state.auth) as State['auth'];

  const dispatch = reduxDispatch as Dispatch;

  function startLogout(): void {
    dispatch(Actions.startLogout());
  }

  function clearUserInfoLogout(): void {
    dispatch(Actions.clearUserInfoLogout());
  }

  return { auth, startLogout, clearUserInfoLogout };
}
