import { ReactElement, useState } from 'react';
import {
  Organization,
  ValidationErrorItem,
  RequestPayloads,
  validateCreateOrganization,
  Checkbox,
  OrganizationCreationAttributes,
  isSequelizeValidationError,
  isChecked,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useCurrentUser, useManageOrganization } from '@hooks';
import { getErrors } from '@utils/formFields';

interface Props {
  organization?: Organization;
  closeModal: () => void;
  isOpen: boolean;
  onSuccess: (org: Organization) => void;
}

type State = OrganizationCreationAttributes & { errors: ValidationErrorItem[] };

function OrganizationModal({ closeModal, isOpen, organization, onSuccess }: Props): ReactElement {
  const { updateOrganization, createOrganization } = useManageOrganization();
  const { currentUser } = useCurrentUser();
  const initialFormState: State = {
    name: organization?.name || '',
    logoURL: organization?.logoURL || '',
    darkLogoURL: organization?.darkLogoURL || '',
    requiresKYCReviewByReviewer: organization?.requiresKYCReviewByReviewer || false,
    shouldBrandPortalForOrganization: organization?.shouldBrandPortalForOrganization || false,
    isNotUsingEqKYC: organization?.isNotUsingEqKYC || false,
    errors: [],
  };
  const [state, setState] = useState<typeof initialFormState>({ ...initialFormState });

  function fieldIsCheckboxField(
    field: keyof Omit<typeof initialFormState, 'errors'>,
  ): field is 'requiresKYCReviewByReviewer' | 'shouldBrandPortalForOrganization' | 'isNotUsingEqKYC' {
    return ['requiresKYCReviewByReviewer', 'shouldBrandPortalForOrganization', 'isNotUsingEqKYC'].includes(field);
  }
  function valueIsCheckboxValue(value?: string | Checkbox): value is Checkbox {
    return value === 'on' || value === undefined;
  }

  function onChange(field: keyof Omit<typeof initialFormState, 'errors'>): (value?: string | Checkbox) => void {
    return (value?: string | Checkbox): void => {
      let newState: State;
      if (fieldIsCheckboxField(field) && valueIsCheckboxValue(value)) {
        newState = { ...state, [field]: isChecked(value) };
      } else {
        newState = { ...state, [field]: value };
      }
      try {
        validateCreateOrganization(newState);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    };
  }

  function submitForm(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const updatedOrg: RequestPayloads['updateOrganization'] | RequestPayloads['createOrganization'] = state;
    try {
      validateCreateOrganization(updatedOrg);
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        setState({ ...updatedOrg, errors: e.errors });
      }
      return;
    }
    if (!organization) {
      createOrganization(updatedOrg, () => undefined, onSuccess);
    } else {
      const needToUpdateCurrentUser =
        currentUser?.organization?.id === organization.id &&
        (updatedOrg.shouldBrandPortalForOrganization !== currentUser.organization.shouldBrandPortalForOrganization ||
          updatedOrg.darkLogoURL !== currentUser.organization.darkLogoURL);
      updateOrganization(organization.id, updatedOrg, needToUpdateCurrentUser, () => undefined, onSuccess);
    }
  }
  return (
    <>
      {isOpen && (
        <Overlay showOverlay={isOpen} rootId="edit-org-overlay">
          <Modal withClose onOutsideClick={closeModal} className="flex column align-center OrganizationModal">
            {organization ? <h2>Edit Organization "{organization.name}"</h2> : <h2>Create New Organization</h2>}
            <form className="w-full" method="POST" onSubmit={submitForm}>
              <div className="form-section flex flex-col items-center">
                <GeneralInput
                  formLabel="Name"
                  name="name"
                  className="w-full max-w-sm"
                  value={state.name}
                  onChange={onChange('name') as OnChange}
                  error={getErrors(state, 'name')}
                />
                <GeneralInput
                  formLabel="Logo URL"
                  name="logoURL"
                  className="w-full max-w-sm mt-4"
                  value={state.logoURL}
                  onChange={onChange('logoURL') as OnChange}
                  error={getErrors(state, 'logoURL')}
                />
                <GeneralInput
                  formLabel="Dark Logo URL"
                  note="must be visible with a dark background behind it"
                  name="darkLogoURL"
                  className="w-full max-w-sm mt-4"
                  value={state.darkLogoURL}
                  onChange={onChange('darkLogoURL') as OnChange}
                  error={getErrors(state, 'darkLogoURL')}
                />
                <h3>Settings</h3>
                <div className="flex flex-col w-4/5 items-start">
                  <GeneralInput
                    formLabel="Require KYCs be reviewed by Org Reviewer?"
                    name="requiresKYCReviewByReviewer"
                    checked={state.requiresKYCReviewByReviewer}
                    type="checkbox"
                    onChange={onChange('requiresKYCReviewByReviewer') as OnChange}
                    error={getErrors(state, 'requiresKYCReviewByReviewer')}
                    wrapLabel
                    labelClassName="flex-shrink"
                    underlyingClassName="flex-shrink-0"
                  />
                  <GeneralInput
                    formLabel="Brand Portal for Organization?"
                    name="shouldBrandPortalForOrganization"
                    checked={state.shouldBrandPortalForOrganization || false}
                    type="checkbox"
                    className="mt-1 flex-sh"
                    onChange={onChange('shouldBrandPortalForOrganization') as OnChange}
                    error={getErrors(state, 'shouldBrandPortalForOrganization')}
                    wrapLabel
                    labelClassName="flex-shrink"
                    underlyingClassName="flex-shrink-0"
                  />
                  <GeneralInput
                    formLabel="Opt out of using EquiGenesis' KYC form?"
                    name="isNotUsingEqKYC"
                    checked={state.isNotUsingEqKYC || false}
                    type="checkbox"
                    className="mt-1 flex-sh"
                    onChange={onChange('isNotUsingEqKYC') as OnChange}
                    error={getErrors(state, 'isNotUsingEqKYC')}
                    wrapLabel
                    labelClassName="flex-shrink"
                    underlyingClassName="flex-shrink-0"
                  />
                </div>
              </div>
              <div className="button-container max-width flex space-around pt-6">
                <EquiButton value="Cancel" onClick={closeModal} />

                <EquiButton
                  value={organization ? 'Update' : 'Create'}
                  type="submit"
                  disabled={!state.name || state.errors.length > 0}
                />
              </div>
            </form>
          </Modal>
        </Overlay>
      )}
    </>
  );
}

export default OrganizationModal;
