import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import Select from 'react-select';
import { Checkbox, FullUser, isChecked } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useCurrentUser } from '@hooks';
import { GroupedOption, groupUsersByOrgForSelect, Option } from '@utils/select';

import './styles.scss';

interface Props {
  className?: string;
  onClose: () => void;
  onSubmit: (selectedUser: FullUser, changeSubscriberOrganization: boolean) => void;
  user: FullUser;
  availableSubscribers: FullUser[];
}

function AssignSubscriberToAgentModal(props: Props): ReactElement {
  const { className, onClose, user, onSubmit, availableSubscribers } = props;
  const { isCurrentUserAdmin, isCurrentUserEQ } = useCurrentUser();
  const [selectedUser, setSelectedUser] = useState<Option | null>(null);
  const [changeSubscriberOrganization, setChangeSubscriberOrganization] = useState(false);

  const sortedSubs = sortBy(availableSubscribers, (sub) => sub.family_name + sub.given_name);
  const fullSelectedUser = sortedSubs.find((u) => u.user_id === selectedUser?.value);
  function assign(): void {
    if (fullSelectedUser) {
      onSubmit(fullSelectedUser, changeSubscriberOrganization);
    }
  }
  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={true}
      className={classnames('AssignSubscriberToAgentModal', className)}
    >
      <Modal className="AssignSubscriberToAgentModalBody flex column align-center" onOutsideClick={onClose} withClose>
        <h1 className="text-center">Assign Subscriber to {user.name}</h1>
        <form className="flex column align-center w-full">
          <div className="w-full mb-4">
            <Select<Option, false, GroupedOption>
              placeholder="Pick a Subscriber"
              value={selectedUser}
              onChange={(selected): void => {
                setSelectedUser(selected);
                const fullSelectedUser = sortedSubs.find((u) => u.user_id === selected?.value);
                if (!fullSelectedUser?.organization?.id || !user?.organization?.id) {
                  setChangeSubscriberOrganization(false);
                }
              }}
              classNamePrefix="select"
              options={groupUsersByOrgForSelect(sortedSubs, 'No Organization', user?.organization)}
            />
          </div>
          {(isCurrentUserAdmin || isCurrentUserEQ) && !!user.organization?.id && (
            <>
              <GeneralInput
                type="checkbox"
                checked={changeSubscriberOrganization}
                formLabel="Change Subscriber Organization to Agent Organization"
                onChange={
                  ((val: Checkbox): void => {
                    setChangeSubscriberOrganization(isChecked(val));
                  }) as OnChange
                }
                disabled={!user.organization?.id || !fullSelectedUser?.organization?.id}
              />
              <p className="mt-4">
                If checked, this subscriber will be set as a subscriber within the new organization. All current
                organization roles (admin, reviewer, etc) will be removed.
              </p>
            </>
          )}
          <EquiButton value="Assign" onClick={assign} disabled={!selectedUser} />
        </form>
      </Modal>
    </Overlay>
  );
}

export default AssignSubscriberToAgentModal;
