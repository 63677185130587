import { ActionTypes } from '@actions';
import * as GeneralActions from '@actions/general';
import { FlashMessageType } from '@components/common/FlashMessage';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  showLoadingOverlay: boolean;
  showSessionExpiredOverlay: boolean;
  flashMessage: { type: FlashMessageType; message: string } | null;
  error: string | null;
  notConnectedToInternet: boolean;
  processingProgress: number | null;
}

const initialState: State = {
  showLoadingOverlay: false,
  showSessionExpiredOverlay: false,
  flashMessage: null,
  error: null,
  notConnectedToInternet: false,
  processingProgress: null,
};

const setShowLoadingOverlayReducer: Reducer<State> = (
  state: State = initialState,
  action: GeneralActions.SetShowLoadingOverlay,
) => {
  return {
    ...state,
    showLoadingOverlay: action.payload,
  };
};

const setShowSessionExpiredOverlayReducer: Reducer<State> = (
  state: State = initialState,
  action: GeneralActions.SetShowSessionExpiredOverlay,
) => {
  return {
    ...state,
    showSessionExpiredOverlay: action.payload,
  };
};

const setFlashMessageReducer: Reducer<State> = (state: State = initialState, action: GeneralActions.FlashMessage) => {
  return {
    ...state,
    flashMessage: action.payload,
  };
};

const setError: Reducer<State> = (state: State = initialState, action: GeneralActions.SetError) => {
  return {
    ...state,
    error: action.payload,
  };
};

const notConnectedToInternet: Reducer<State> = (state: State = initialState) => {
  return {
    ...state,
    notConnectedToInternet: true,
  };
};

const updateProcessingProgress: Reducer<State> = (
  state: State = initialState,
  action: GeneralActions.UpdateProcessingProgress,
) => {
  return {
    ...state,
    processingProgress: action.payload.progress ?? null,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.SET_SHOW_LOADING_OVERLAY]: setShowLoadingOverlayReducer,
  [ActionTypes.SET_SHOW_SESSION_EXPIRED_OVERLAY]: setShowSessionExpiredOverlayReducer,
  [ActionTypes.FLASH_MESSAGE]: setFlashMessageReducer,
  [ActionTypes.SET_ERROR]: setError,
  [ActionTypes.NOT_CONNECTED_TO_INTERNET]: notConnectedToInternet,
  [ActionTypes.UPDATE_PROCESSING_PROGRESS]: updateProcessingProgress,
};

export default createReducers(Reducers, initialState);
