import { ReactElement } from 'react';
import { Organization } from 'shared';
import Icon from '@components/common/Icon';
import OrganizationModal from '@components/Modals/OrganizationModal';
import { useCurrentUser, useModal } from '@hooks';

interface Props {
  organization: Organization;
  onUpdateSuccess: (org: Organization) => void;
}

function PageTitle({ organization, onUpdateSuccess }: Props): ReactElement {
  const { closeModal, isOpen, openModal } = useModal();
  const { isCurrentUserAdmin, isCurrentUserEQ, isCurrentUserAdminForOrg } = useCurrentUser();

  function onModalSuccess(org: Organization): void {
    onUpdateSuccess(org);
    closeModal();
  }

  return (
    <>
      <div className="center flex-col">
        <img className="ml-10 block h-10" src={organization.logoURL} alt="" />
        <h1 className="text-center w-fit-content mt-2">
          <span className="relative">
            {organization.name}
            {(isCurrentUserAdmin || isCurrentUserEQ || isCurrentUserAdminForOrg(organization)) && (
              <Icon onClick={openModal} className="edit-icon pointer absolute -right-10 bottom-2" type="edit" />
            )}
          </span>
        </h1>
      </div>
      {isOpen && (
        <OrganizationModal
          closeModal={closeModal}
          isOpen={isOpen}
          organization={organization}
          onSuccess={onModalSuccess}
        />
      )}
    </>
  );
}

export default PageTitle;
