import { ReactElement } from 'react';
import classnames from 'classnames';
import { Role, roleDisplay } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { permissions } from '@utils/permissions';

import './styles.scss';

interface Props {
  className?: string;
  shouldShow: boolean;
  onClose: () => void;
}

function UserPermissionTypesModal(props: Props): ReactElement {
  const { className, shouldShow, onClose } = props;
  const roles: Role[] = ['subscriber', 'agent', 'eq', 'admin'];
  return (
    <Overlay
      rootId="user-permission-types-overlay-root"
      showOverlay={shouldShow}
      className={classnames('UserPermissionTypesModal', className)}
    >
      <Modal className="UserPermissionsModalBody" onOutsideClick={onClose} withClose>
        <h1>User Permissions</h1>
        {roles.map((role) => (
          <div className="role-display" key={role}>
            <h3>{roleDisplay[role]}</h3>
            <ul>
              {permissions[role].map((permission) => (
                <li key={permission}>{permission}</li>
              ))}
            </ul>
          </div>
        ))}
        <EquiButton value="Close" onClick={onClose} />
      </Modal>
    </Overlay>
  );
}

export default UserPermissionTypesModal;
