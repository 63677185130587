import { ReactElement, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { filter, formatDateTime, FullUser, regexEscape } from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Icon from '@components/common/Icon';
import Table from '@components/common/Table';
import { useCurrentUser, useCurrentUserReadableRelationships, useSort, useUsers } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

type sortByOptions = 'name' | 'email';
type buttonFilterOptions = 'my-subscribers' | 'org-subscribers' | 'all-subscribers';

function buildNoResultsMessage(
  canReadAgent: boolean,
  areUsers: boolean,
  search: string,
  buttonFilter: buttonFilterOptions,
): string {
  if (!areUsers && canReadAgent) {
    return 'There are no Subscribers';
  }
  if ((!areUsers && !canReadAgent) || (areUsers && buttonFilter === 'my-subscribers')) {
    return 'You have no Subscribers';
  }
  return `No subscribers match the search filter "${search}"`;
}

export default function ViewSubscribersPage(): ReactElement {
  const { isLoading, users } = useUsers({ role: 'subscriber' });
  const {
    hasAllPermissions,
    currentUser,
    isCurrentUserAgent,
    isCurrentUserAdmin,
    isCurrentUserEQ,
    isCurrentUserOwnOrgAdmin,
    isCurrentUserOwnOrgReviewer,
    doesCurrentUserManageUser,
  } = useCurrentUser();
  const { isLoading: relationshipsIsLoading, relationships } = useCurrentUserReadableRelationships();
  const [search, setSearch] = useState<string>('');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('name', false);
  const [buttonFilter, setButtonFilter] = useState<buttonFilterOptions>('my-subscribers');
  const hasSetAfterFetchedCurrentUser = useRef(false);
  useEffect(() => {
    if (!currentUser || hasSetAfterFetchedCurrentUser.current) {
      return;
    }
    if (isCurrentUserAdmin || isCurrentUserEQ) {
      setButtonFilter('all-subscribers');
    } else if (isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer()) {
      setButtonFilter('org-subscribers');
    } else {
      setButtonFilter('my-subscribers');
    }
    hasSetAfterFetchedCurrentUser.current = true;
  }, [
    currentUser,
    isCurrentUserAgent,
    isCurrentUserAdmin,
    isCurrentUserEQ,
    isCurrentUserOwnOrgAdmin,
    isCurrentUserOwnOrgReviewer,
  ]);

  const showButtons =
    [
      isCurrentUserAdmin || isCurrentUserEQ,
      isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer(),
      isCurrentUserAgent,
    ].filter((a) => a).length > 1;

  function filterUserBasedOnButtonFilter(user: FullUser): boolean {
    if (buttonFilter === 'my-subscribers') {
      return !!relationships.find((r) => r.subscriberUserId === user.user_id && r.agentUserId === currentUser?.user_id);
    }
    if (buttonFilter === 'org-subscribers') {
      return !!user.organization?.id && user.organization?.id === currentUser?.organization?.id;
    }
    return true;
  }

  const filteredUsers = sortBy(
    users.filter(
      (user) =>
        (new RegExp(regexEscape(search?.trim()), 'i').test(user.name) ||
          new RegExp(regexEscape(search?.trim()), 'i').test(user.email)) &&
        filterUserBasedOnButtonFilter(user),
    ),
    (user) => {
      switch (field) {
        case 'email':
          return user.email.toLowerCase();
        case 'name':
        default:
          return (user.family_name.toLowerCase() || '') + (user.given_name.toLowerCase() || '');
      }
    },
  );

  if (isDescending) {
    filteredUsers.reverse();
  }
  const noResultsMsg = buildNoResultsMessage(hasAllPermissions(['read:agent']), users.length > 0, search, buttonFilter);
  return (
    <Page
      className="ViewSubscribersPage"
      renderHeader={(): ReactElement => (
        <>
          <Icon type="subscriber" />
          <h1 className="text-center">Subscribers</h1>
        </>
      )}
      defaultBackLocation={PATHS.HOME}
      isLoading={isLoading || relationshipsIsLoading}
      showChildrenOnLoading={false}
    >
      <Table
        gridClassName={classnames('table-grid-template-columns', {
          'four-cols': hasAllPermissions(['read:agent']),
        })}
        headers={filter([
          { text: 'Name', onClick: (): void => setSortField('name'), isSelected: field === 'name', isDescending },
          {
            text: 'Email',
            onClick: (): void => setSortField('email'),
            isSelected: field === 'email',
            isDescending,
          },
          { text: 'Last Logged In' },
          (hasAllPermissions(['read:agent']) && { text: 'Agent(s)' }) || false,
        ])}
        renderFilters={(): ReactElement => (
          <>
            <GeneralInput
              formLabel="Search"
              placeholder="by name or email"
              onChange={((a: string): void => setSearch(a)) as OnChange}
              value={search}
            />
            {showButtons && (
              <div className="flex items-end space-x-4">
                {isCurrentUserAgent && (
                  <EquiButton
                    value="My Subscribers"
                    kind={buttonFilter === 'my-subscribers' ? ButtonType.primary : ButtonType.secondary}
                    onClick={(): void => setButtonFilter('my-subscribers')}
                  />
                )}
                {currentUser?.organization && (isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer()) && (
                  <EquiButton
                    value={`All ${currentUser.organization.name} Subscribers`}
                    kind={buttonFilter === 'org-subscribers' ? ButtonType.primary : ButtonType.secondary}
                    onClick={(): void => setButtonFilter('org-subscribers')}
                  />
                )}
                {(isCurrentUserAdmin || isCurrentUserEQ) && (
                  <EquiButton
                    value="All Subscribers"
                    kind={buttonFilter === 'all-subscribers' ? ButtonType.primary : ButtonType.secondary}
                    onClick={(): void => setButtonFilter('all-subscribers')}
                  />
                )}
              </div>
            )}
          </>
        )}
        data={filteredUsers.map((user) =>
          filter([
            <Link to={`${PATHS.SUBSCRIBERS}/${user.email}`}>{user.name}</Link>,
            user.email,
            user.last_login ? formatDateTime(new Date(user.last_login)) : <em>never</em>,
            hasAllPermissions(['read:agent']) &&
              filter<FullUser>(
                relationships
                  .filter((relationship) => relationship.subscriberUserId === user.user_id)
                  .map((relationship) => relationship.agent),
              ).map((agent, i, arr) =>
                doesCurrentUserManageUser(agent) ? (
                  <Link key={agent.user_id} to={`${PATHS.AGENTS}/${agent.email}`}>
                    {agent.name}
                    {i !== arr.length - 1 && ', '}
                  </Link>
                ) : agent.user_id === currentUser?.user_id ? (
                  <em>you</em>
                ) : (
                  `${agent.name}${i !== arr.length - 1 ? ' , ' : ''}`
                ),
              ),
          ]),
        )}
        noResultsText={noResultsMsg}
      />
    </Page>
  );
}
