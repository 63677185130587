import { Organization } from 'shared';
import { ActionTypes } from '@actions';
import * as OrganizationsActions from '@actions/organizations';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = {
  isLoading: boolean;
  data: Organization[];
};

const initialState: State = {
  isLoading: false,
  data: [],
};

const getOrganizationsReducer: Reducer<State> = (_state, action: OrganizationsActions.GetOrganizations): State => {
  return {
    isLoading: false,
    data: action.payload,
  };
};

const startGetOrganizationsReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    isLoading: true,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return {
    ...initialState,
    hasFetched: true,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.GET_ORGANIZATIONS]: getOrganizationsReducer,
  [ActionTypes.START_GET_ORGANIZATIONS]: startGetOrganizationsReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
