import { ReactElement } from 'react';
import { Children } from '@custom-types';

interface Props {
  children: Children;
}

export default function SectionMax80({ children }: Props): ReactElement {
  return <div className="max-w-4/5 w-full">{children}</div>;
}
