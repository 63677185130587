import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { formatDateTime, regexEscape } from 'shared';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Icon from '@components/common/Icon';
import Table from '@components/common/Table';
import { useCurrentUserReadableRelationships, useUsers, useSort } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

type sortByOptions = 'name' | 'email';

export default function ViewAgentsPage(): ReactElement {
  const { isLoading, users } = useUsers({ role: 'agent' });
  const { isLoading: relationshipsIsLoading, relationships } = useCurrentUserReadableRelationships();
  const [search, setSearch] = useState<string>('');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('name', true);

  const filteredUsers = sortBy(
    users.filter(
      (user) =>
        new RegExp(regexEscape(search?.trim()), 'i').test(user.name) ||
        new RegExp(regexEscape(search?.trim()), 'i').test(user.email),
    ),
    (user) => {
      switch (field) {
        case 'email':
          return user.email.toLowerCase();
        case 'name':
        default:
          return (user.family_name.toLowerCase() || '') + (user.given_name.toLowerCase() || '');
      }
    },
  );

  if (isDescending) {
    filteredUsers.reverse();
  }
  return (
    <Page
      className="ViewAgentsPage"
      renderHeader={(): ReactElement => (
        <>
          <Icon type="agent" />
          <h1 className="text-center">Agents</h1>
        </>
      )}
      defaultBackLocation={PATHS.HOME}
      isLoading={isLoading || relationshipsIsLoading}
      showChildrenOnLoading={users.length > 0}
    >
      <Table
        gridClassName="table-grid-template-columns"
        headers={[
          { text: 'Name', onClick: (): void => setSortField('name'), isSelected: field === 'name', isDescending },
          { text: 'Email', onClick: (): void => setSortField('email'), isSelected: field === 'email', isDescending },
          { text: 'Organization' },
          { text: 'Last Logged In' },
          { text: 'Number of Subscribers' },
        ]}
        renderFilters={(): ReactElement => (
          <>
            <GeneralInput
              formLabel="Search"
              placeholder="by name or email"
              onChange={((a: string): void => setSearch(a)) as OnChange}
              value={search}
            />
          </>
        )}
        data={filteredUsers.map((user) => [
          <Link to={`${PATHS.AGENTS}/${user.email}`}>{user.name}</Link>,
          user.email,
          user.organization?.name || <em>N/A</em>,
          user.last_login ? formatDateTime(new Date(user.last_login)) : <em>never</em>,
          relationships.filter((relationship) => relationship.agentUserId === user.user_id).length.toString(),
        ])}
        noResultsText={search ? `No users with name or email containing "${search}"` : 'There are no Agents'}
      />
    </Page>
  );
}
