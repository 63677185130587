import { ReactElement } from 'react';
import Logo from '@assets/images/EQ-logo.png';

interface Props {
  size: number;
}

function Brand(props: Props): ReactElement {
  const { size } = props;
  return <img className="Brand" width={size} alt="EquiGenesis" src={Logo}></img>;
}

export default Brand;
