import { ActionTypes, AcquireLock, ReleaseLock } from './types';

export type Actions = AcquireLock | ReleaseLock;

export const Actions = {
  acquireLock(payload: { acquirerId: string; lockId: string }): AcquireLock {
    return { type: ActionTypes.ACQUIRE_LOCK, payload };
  },
  releaseLock(payload: { releaserId: string; lockId: string }): ReleaseLock {
    return { type: ActionTypes.RELEASE_LOCK, payload };
  },
};

export * from './types';
