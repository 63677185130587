import { ReactElement } from 'react';
import classnames from 'classnames';
import { Checkbox, isChecked } from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields, useKYCFormState } from '@hooks';
import './styles.scss';

function TaxResidence(): ReactElement {
  const formFields = useKYCFormFields();
  const { isFormDisabledForCurrentUser } = useKYCFormState();
  function updateHasOtherTaxResidence(e: React.ChangeEvent<HTMLInputElement>, value?: Checkbox): void {
    formFields.hasOtherTaxResidence.onChange(e, value);
    if (!value) {
      formFields.otherTaxResidence.onChange(e, undefined);
    }
  }
  function setCitizenship(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, citizenship: string): void {
    if (isFormDisabledForCurrentUser) {
      return;
    }
    formFields.citizenship.onChange((e as unknown) as React.ChangeEvent<HTMLInputElement>, citizenship);
  }
  function setCountry(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, citizenship: string): void {
    if (isFormDisabledForCurrentUser) {
      return;
    }
    formFields.birthCountry.onChange((e as unknown) as React.ChangeEvent<HTMLInputElement>, citizenship);
  }
  return (
    <div className="form-section TaxResidence">
      <h2 className="title">Tax Residence</h2>
      <div className="flex align-center form-row">
        <h3 className="title header spacer no-flex">
          <strong>Tax Residence (Check all that apply) </strong>
        </h3>
        <div className="spacer justify-center align-center no-flex">
          <EquiInput {...formFields.canada} />
        </div>
        <div className="spacer justify-center align-center no-flex">
          <EquiInput {...formFields.US} />
        </div>
        <div className="spacer justify-center align-center no-flex">
          <EquiInput {...formFields.hasOtherTaxResidence} onChange={updateHasOtherTaxResidence} />
        </div>
      </div>
      <div className="flex form-row">
        {isChecked(formFields.hasOtherTaxResidence.value as Checkbox) && (
          <>
            <EquiInput {...formFields.otherTaxResidence} />
            <div className="max-width"></div>
          </>
        )}
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.citizenship} />
        <div className="max-width flex button-container">
          <EquiButton
            disabled={isFormDisabledForCurrentUser}
            className={classnames({ 'read-only': isFormDisabledForCurrentUser })}
            kind={'Canadian' === formFields.citizenship.value ? ButtonType.primary : ButtonType.secondary}
            onClick={(event): void => setCitizenship(event, 'Canadian')}
            value="Canadian"
          />
        </div>
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.birthCountry} />
        <div className="max-width flex button-container">
          <EquiButton
            disabled={isFormDisabledForCurrentUser}
            className={classnames({ 'read-only': isFormDisabledForCurrentUser })}
            kind={'Canada' === formFields.birthCountry.value ? ButtonType.primary : ButtonType.secondary}
            onClick={(event): void => setCountry(event, 'Canada')}
            value="Canada"
          />
        </div>
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.taxIdNum} />
        <div className="max-width"></div>
      </div>
    </div>
  );
}

export default TaxResidence;
