import { ReactElement } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import './styles.scss';

interface Props {
  size?: FontAwesomeIconProps['size'];
}

function Loading(props: Props): ReactElement {
  const { size = '5x' } = props;
  return (
    <div className="Loading">
      <FontAwesomeIcon className="equi-color" icon="spinner" size={size} pulse />
    </div>
  );
}

export default Loading;
