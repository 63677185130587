import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { filter, FullUser } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Icon from '@components/common/Icon';
import SectionCard from '@components/common/SectionCard';
import SectionMax80 from '@components/common/SectionMax80';
import { TableData, TableHeader, TableRow } from '@components/common/Table';
import AssignSubscriberToAgentModal from '@components/Modals/AssignSubscriberToAgentModal';
import UserOrganizationSection from '@components/UserOrganizationSection';
import { useCurrentUser, useFlashMessage, useCurrentUserReadableRelationships, useSingleUser, useUsers } from '@hooks';
import { Page } from '@layouts';
import { ErrorPage } from '@pages';
import { PATHS } from '@routes/lib';

import './styles.scss';
import PageTitle from './PageTitle';

export default function ViewAgentPage(): ReactElement {
  const { email } = useParams<{ email: string }>();
  const { isLoading, error, user, toggleUserBlocked, updateOrgUserRoles } = useSingleUser(email);
  const { isLoading: isLoadingSubscribers, users: allSubscribers } = useUsers({ role: 'subscriber' });
  const { flashWarningMessage } = useFlashMessage();
  const {
    isLoading: relationshipsIsLoading,
    relationships,
    assignSubscriberToAgent,
    unassignSubscriberFromAgent,
  } = useCurrentUserReadableRelationships();
  const { hasAllPermissions, currentUser, doesCurrentUserManageOrgUser, isCurrentUserEQ } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const currentAgentSubscribers = filter<FullUser>(
    relationships.filter((r) => r.agentUserId === user?.user_id).map((r) => r.subscriber),
  );
  const currentAgentSubscriberUserIds = currentAgentSubscribers.map((sub) => sub.user_id);
  const availableSubscribers = allSubscribers.filter((sub) => !currentAgentSubscriberUserIds.includes(sub.user_id));
  function closeModal(): void {
    setIsOpen(false);
  }
  function openModal(): void {
    setIsOpen(true);
  }
  function onSuccess(): void {
    closeModal();
  }
  function assign(subscriber: FullUser, changeSubscriberOrganization: boolean): void {
    if (user) {
      assignSubscriberToAgent(subscriber, user, changeSubscriberOrganization, onSuccess);
    }
  }
  function unassign(subscriber: FullUser): void {
    const relationship = relationships.find((r) => r.subscriberUserId === subscriber.user_id);
    if (relationship) {
      unassignSubscriberFromAgent(relationship.id);
    }
  }
  if (error) {
    return <ErrorPage forceBackLocation={PATHS.AGENTS} />;
  }
  const headers: TableHeader[] = filter([
    { text: 'Name' },
    {
      text: 'Email',
    },
    { text: 'Organization' },
    (hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user)) && { text: '' },
  ]);
  const tableData: TableRow[] = filter(
    currentAgentSubscribers.map((subscriber) =>
      filter<TableData>([
        hasAllPermissions(['read:relationship', 'read:agent']) ? (
          <Link to={`${PATHS.SUBSCRIBERS}/${subscriber.email}`} title={subscriber.name}>
            {subscriber.name}
          </Link>
        ) : (
          <span title={subscriber.name}>{subscriber.name}</span>
        ),
        <span title={subscriber.email}>{subscriber.email}</span>,
        subscriber.organization?.name || <em>N/A</em>,
        (hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user)) && (
          <EquiButton value="Unassign" onClick={(): void => unassign(subscriber)} />
        ),
      ]),
    ),
  );
  return (
    <Page
      className="ViewAgentPage"
      renderHeader={user ? (): ReactElement => <PageTitle user={user} /> : undefined}
      defaultBackLocation={PATHS.AGENTS}
      isLoading={isLoading || relationshipsIsLoading || isLoadingSubscribers}
      showChildrenOnLoading={false}
    >
      <SectionMax80>
        <UserOrganizationSection
          assignType="Subscriber"
          openAssignModal={openModal}
          user={user}
          tableData={tableData}
          headers={headers}
          updateOrgUserRoles={updateOrgUserRoles}
        />
        {user && (hasAllPermissions(['create:relationship']) || doesCurrentUserManageOrgUser(user)) && isOpen && (
          <AssignSubscriberToAgentModal
            onClose={closeModal}
            onSubmit={assign}
            user={user}
            availableSubscribers={availableSubscribers}
          />
        )}
        {(hasAllPermissions(['block:subscriber']) || isCurrentUserEQ || doesCurrentUserManageOrgUser(user)) && (
          <SectionCard className="account-toggle">
            <div className="user-detail flex max-width align-center space-between">
              <div>
                Account Active? <b>{user?.blocked ? 'No' : 'Yes'}</b>
              </div>
              <div
                onClick={
                  currentUser?.user_id !== user?.user_id
                    ? toggleUserBlocked
                    : (): void => flashWarningMessage('Warning: You cannot disable your own account.')
                }
                className={classnames(
                  { pointer: currentUser?.user_id !== user?.user_id },
                  'value-item',
                  'flex items-center',
                  'group',
                )}
              >
                <span className="mr-4 group-hover:text-red-100">
                  {user?.blocked ? 'Activate Account' : 'De-Activate Account'}
                </span>
                <Icon
                  className={classnames({ 'toggle-off': user?.blocked })}
                  type={!user?.blocked ? 'toggleOn' : 'toggleOff'}
                />
              </div>
            </div>
          </SectionCard>
        )}
      </SectionMax80>
    </Page>
  );
}
