import { ReactElement } from 'react';
import EquiInput from '@components/common/OfficeUseEquiInput';
import { useOfficeUseFormFields } from '@hooks';

function KYCNotes(): ReactElement {
  const formFields = useOfficeUseFormFields();
  return (
    <div className="form-section">
      <h2 className="title">KYC Notes</h2>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.dealerKnownClientYearsTime} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.dealerMetClientInPerson} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.clientsInsiderStatusExplainedToHaveNoBearing} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.accreditedInvestorStatusConfirmed} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.pepStatusExplained} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.conflictsOfInterestCanvasedWithClient} />
        </div>
      </div>
      <div className="flex w-full">
        <div className="spacer justify-center align-center w-full">
          <EquiInput wrapperClassName="justify-between" {...formFields.clientsRoleExplained} />
        </div>
      </div>
      <div className="flex w-full form-row spacer">
        <EquiInput {...formFields.kycNotes} />
      </div>
    </div>
  );
}

export default KYCNotes;
