import { CreateUserReqPayload, CreateUserResPayload } from 'shared';
import { ManageUserAPI } from '@api';
import { Actions as APIActions } from '../api';
import { Actions as GeneralActions, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import { ActionTypes, AddNewUser } from './types';

export type Actions = AddNewUser;

export const Actions = {
  createUser(
    user: CreateUserReqPayload,
    onError: (e: Error) => void,
    onSuccess: (res: CreateUserResPayload) => void,
  ): AsyncAction<AddNewUser | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      dispatch({ type: ActionTypes.ADD_NEW_USER, payload: { ...user } });
      dispatch(GeneralActions.showLoadingOverlay());
      try {
        const res = await ManageUserAPI.createUser(user);
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess(res);
      } catch (e) {
        dispatch(APIActions.handleAxiosError(e));
        onError(e as Error);
      }
    };
  },
};

export * from './types';
