import { createRef, ReactElement, useState } from 'react';
import classNames from 'classnames';
import { SiteSettingName, EMPTY_SITE_SETTING } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput from '@components/common/GeneralInput';
import { useSiteSettings } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import { displaySiteSettingName } from '@utils/siteSettings';

export default function AdminSiteSettings(): ReactElement {
  const { siteSettings, isLoading, updateSiteSetting } = useSiteSettings();
  const [errors, setErrors] = useState<Partial<Record<SiteSettingName, string | undefined>>>({});
  const [changedSettings, setChangedSettings] = useState([] as SiteSettingName[]);
  return (
    <Page
      className="AdminSiteSettings"
      isLoading={isLoading}
      showChildrenOnLoading={false}
      renderHeader={(): ReactElement => <h1 className="text-center">Site Settings</h1>}
      defaultBackLocation={PATHS.ADMIN}
    >
      <div className="width-80">
        {siteSettings?.map((setting) => {
          const ref = createRef<HTMLInputElement>();
          return (
            <div key={setting.id} className="flex align-center max-width spacer">
              <GeneralInput
                ref={ref}
                formLabel={displaySiteSettingName(setting.settingName)}
                defaultValue={setting.settingValue}
                onChange={(value): void => {
                  setChangedSettings((oldList) => {
                    const filtered = oldList.filter((i) => i !== setting.settingName);
                    return value !== setting.settingValue ? filtered.concat([setting.settingName]) : filtered;
                  });
                }}
                error={errors[setting.settingName]}
                className={classNames({ 'bg-red': setting.settingValue === EMPTY_SITE_SETTING })}
              />
              <EquiButton
                value="Update"
                className={classNames('slight-top-margin spacer', {
                  darken: changedSettings.includes(setting.settingName),
                })}
                onClick={async (): Promise<void> => {
                  if (!ref.current) {
                    return;
                  }
                  const input = ref.current;
                  const error = await updateSiteSetting(setting.id, input.value);
                  setErrors({ ...errors, [setting.settingName]: error });
                  setChangedSettings((oldList) => oldList.filter((i) => i !== setting.settingName));
                }}
              />
            </div>
          );
        })}
      </div>
    </Page>
  );
}
