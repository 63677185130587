import axios from 'axios';
import { RequestPayloads, ResponsePayloads, RequestParams } from 'shared';

export function createOrganization(
  organization: RequestPayloads['createOrganization'],
): Promise<ResponsePayloads['createOrganization']> {
  return axios.post('/organizations', organization).then((res) => res.data);
}
export function updateOrganization(
  { organizationId }: RequestParams['updateOrganization'],
  organization: RequestPayloads['updateOrganization'],
): Promise<ResponsePayloads['updateOrganization']> {
  return axios.patch(`/organizations/${organizationId}`, organization).then((res) => res.data);
}

export function getOrganizations(): Promise<ResponsePayloads['getOrganizations']> {
  return axios.get(`/organizations`).then((res) => res.data);
}

export function getOrganization({
  slug,
}: RequestParams['getOrganization']): Promise<ResponsePayloads['getOrganization']> {
  return axios.get(`/organizations/${slug}`).then((res) => res.data);
}

export function createNewOrganizationUser(
  { slug }: RequestParams['createNewOrganizationUser'],
  userDetails: RequestPayloads['createNewOrganizationUser'],
): Promise<ResponsePayloads['createNewOrganizationUser']> {
  return axios.post(`/organizations/${slug}/users`, userDetails).then((res) => res.data);
}

export function addExistingUserToOrganization(
  { slug }: RequestParams['addExistingUserToOrganization'],
  user: RequestPayloads['addExistingUserToOrganization'],
): Promise<ResponsePayloads['addExistingUserToOrganization']> {
  return axios.patch(`/organizations/${slug}/users`, user).then((res) => res.data);
}

export function updateUserOrganizationalRoles(
  { slug }: RequestParams['updateUserOrganizationalRoles'],
  user: RequestPayloads['updateUserOrganizationalRoles'],
): Promise<ResponsePayloads['updateUserOrganizationalRoles']> {
  return axios.patch(`/organizations/${slug}/user-roles`, user).then((res) => res.data);
}

export function setOrgCCO(
  { slug }: RequestParams['setOrgCCO'],
  user: RequestPayloads['setOrgCCO'],
): Promise<ResponsePayloads['setOrgCCO']> {
  return axios.patch(`/organizations/${slug}/cco`, user).then((res) => res.data);
}
