import { OfficeUseFormDataAllRequired, OfficeUseFormField } from 'shared';
import { useOfficeUseFormState } from '../useOfficeUseFormState';
import { ProtoFields, Fields, Input } from './types';

export function useOfficeUseSubFormFields<T extends keyof OfficeUseFormDataAllRequired>(
  fields: ProtoFields<T>,
): Fields<T> {
  const { updateField, form } = useOfficeUseFormState();
  function createFormFields(): Fields<T> {
    return (Object.entries(fields) as [T, Input<T>][]).reduce(
      (acc, [name, { disabled, readOnly, value, type, ...restField }]) => {
        acc[name] = new OfficeUseFormField(
          { name, type, value, readOnly, ...(restField as { formLabel: string }) },
          updateField,
          (form || { formData: { errors: [] } }).formData,
          () => disabled || false,
        );
        return acc;
      },
      {} as Fields<T>,
    );
  }
  return createFormFields();
}
