import { ReactElement, useEffect, useRef, useState } from 'react';
import { Application } from '@layouts';
import { LoadingPage, NotConnectedPage } from '@pages';

function Redirecting(): ReactElement {
  const [isOnline, setIsOnline] = useState(true);
  const stillValid = useRef(true);
  useEffect(() => {
    if (stillValid.current) {
      setIsOnline(window.navigator.onLine);
    }
    return (): void => {
      stillValid.current = false;
    };
  }, [window.navigator.onLine]);
  if (!isOnline) {
    return (
      <Application>
        <NotConnectedPage noBackButton />
      </Application>
    );
  }
  return (
    <div className="App">
      <div className="app-loading main-container">
        <LoadingPage />
      </div>
    </div>
  );
}

export default Redirecting;
