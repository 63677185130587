import { ReactElement } from 'react';
import Logo from '@components/common/Logo';
import NavLink from '@components/common/NavLink';
import { useCurrentUser } from '@hooks';
import { PATHS } from '@routes/lib';
import UserDropdown from '../UserDropdown';
import './styles.scss';

function NavBar(): ReactElement {
  const {
    isCurrentUserAdmin,
    hasAllPermissions,
    isCurrentUserOwnOrgAdmin,
    isCurrentUserOwnOrgAgent,
    isCurrentUserOwnOrgReviewer,
    currentUser,
  } = useCurrentUser();
  return (
    <nav className="nav flex justify-center">
      <div className="nav-container flex container justify-between">
        <div className="flex align-center max-height">
          <NavLink to={PATHS.HOME} exact className="logo" content={<Logo size={47} />} />
          <div className="nav-left flex">
            <div className="nav-links flex">
              <NavLink to={PATHS.HOME} exact content="Home" />
            </div>
            {(hasAllPermissions(['read:relationship', 'read:subscriber']) ||
              hasAllPermissions(['read:ownRelationship', 'read:ownSubscriber'])) && (
              <div className="nav-links flex">
                <NavLink to={PATHS.SUBSCRIBERS} content="Subscribers" />
              </div>
            )}
            {hasAllPermissions(['read:relationship', 'read:agent']) && (
              <div className="nav-links flex">
                <NavLink to={PATHS.AGENTS} content="Agents" />
              </div>
            )}
            {hasAllPermissions(['read:organization']) && (
              <div className="nav-links flex">
                <NavLink to={PATHS.ORGANIZATIONS} content="All Organizations" exact />
              </div>
            )}
            {(isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgAgent() || isCurrentUserOwnOrgReviewer()) &&
              currentUser?.organization && (
                <div className="nav-links flex">
                  <NavLink
                    to={`${PATHS.ORGANIZATIONS}/${currentUser.organization.slug}`}
                    content={currentUser.organization.name}
                  />
                </div>
              )}
            {isCurrentUserAdmin && (
              <div className="nav-links flex">
                <NavLink to={PATHS.ADMIN} content="Admin Dashboard" />
              </div>
            )}
          </div>
        </div>
        <div className="nav-right flex">
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
