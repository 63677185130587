import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { filter, formatDateTime, regexEscape, SubscriptionPackage } from 'shared';
import GeneralInput from '@components/common/GeneralInput';
import Table from '@components/common/Table';
import { useCurrentUser, useProductsList, useSort, useSubscriptionPackages } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import { displaySubscriptionPackageState } from '@utils/formState';
import { displayStepInfo } from '@utils/subscriptionPackages';
import './styles.scss';

function displayMaybeEmptyItem(str?: string): string | React.ReactNode {
  if (!str) {
    return <em>(not provided)</em>;
  }
  return str;
}
type sortByOptions = 'name' | 'numberOfUnits' | 'lastUpdated';

export default function SubscriptionPackagesPage(): ReactElement {
  const { subscriptionPackages, isLoading } = useSubscriptionPackages();
  const { isCurrentUserAdmin } = useCurrentUser();
  const [statusFilter, setStatusFilter] = useState<SubscriptionPackage['state'] | 'all'>('all');
  const [productFilter, setProductFilter] = useState<string | 'all'>('all');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('lastUpdated', true);
  const [searchBy, setSearchBy] = useState('');
  const { products } = useProductsList();
  const sortedAndFiltered: SubscriptionPackage[] = sortBy(
    subscriptionPackages.filter(
      (subscriptionPackage) =>
        (statusFilter !== 'testSentToDocuSign' || isCurrentUserAdmin) &&
        (statusFilter === 'all' || statusFilter === subscriptionPackage.state) &&
        (productFilter === 'all' || productFilter === subscriptionPackage.productId) &&
        new RegExp(regexEscape(searchBy?.trim()), 'i').test(subscriptionPackage.kyc?.subscriber?.name || ''),
    ),
    (subscriptionPackage: SubscriptionPackage) => {
      switch (field) {
        case 'numberOfUnits':
          return parseInt(subscriptionPackage.formData?.unitAmount?.toString() || '0', 10);
        case 'lastUpdated':
          return subscriptionPackage.formUpdatedAt;
        case 'name':
        default:
          return (
            (subscriptionPackage.kyc?.subscriber?.family_name.toLowerCase() || '') +
            (subscriptionPackage.kyc?.subscriber?.given_name.toLowerCase() || '')
          );
      }
    },
  );
  if (isDescending) {
    sortedAndFiltered.reverse();
  }
  return (
    <Page
      className="SubscriptionPackagesPage"
      isLoading={isLoading}
      defaultBackLocation={PATHS.HOME}
      showChildrenOnLoading={false}
      renderHeader={(): ReactElement => <h1>Subscription Packages</h1>}
    >
      {!!subscriptionPackages.length && (
        <>
          <Table
            gridClassName="table-grid-template-columns"
            headers={[
              {
                text: 'Subscriber Name',
                onClick: (): void => setSortField('name'),
                isSelected: field === 'name',
                isDescending,
              },
              { text: 'Product Name' },
              {
                text: '# of Units',
                onClick: (): void => setSortField('numberOfUnits'),
                isSelected: field === 'numberOfUnits',
                isDescending,
              },
              { text: 'Status' },
              {
                text: 'Last Updated',
                onClick: (): void => setSortField('lastUpdated'),
                isSelected: field === 'lastUpdated',
                isDescending,
              },
            ]}
            data={sortedAndFiltered.map((form) => [
              <div className="grid subscriber-name">
                <span>{<Link to={`${PATHS.SUBSCRIPTION_PACKAGES}/${form.slug}`}>(View Form)</Link>}</span>
                <Link
                  to={
                    form.kyc?.subscriber ? `${PATHS.SUBSCRIBERS}/${form.kyc?.subscriber?.email}` : `${PATHS.NOT_FOUND}`
                  }
                >
                  {displayMaybeEmptyItem(form.kyc?.subscriber?.name)}
                </Link>
              </div>,
              <Link to={`${PATHS.SUBSCRIPTION_PACKAGES}/${form.slug}`}>
                {form.product?.name || form.formData.programName}
              </Link>,
              form.formData.unitAmount,
              `${displayStepInfo(form)}${displaySubscriptionPackageState(form.state)}`,
              formatDateTime(new Date(form.formUpdatedAt || '')),
            ])}
            renderFilters={(): ReactElement => (
              <>
                <GeneralInput
                  formLabel="Search"
                  className="search-input"
                  placeholder="by name"
                  onChange={(value): void => setSearchBy(value as string)}
                  name="search"
                  value={searchBy}
                />
                <GeneralInput
                  className="filter-by-product-name"
                  labelAsOption={false}
                  formLabel="Filter by Product Name"
                  onChange={(value): void => setProductFilter(value as string)}
                  name="status"
                  value={productFilter}
                  type="select"
                  options={[
                    { value: 'all', displayName: 'All' },
                    ...products.map((product) => ({ value: product.id, displayName: product.name })),
                  ]}
                />
                <GeneralInput
                  className="filter-by-status"
                  labelAsOption={false}
                  formLabel="Filter by Status"
                  onChange={(value): void => setStatusFilter(value as SubscriptionPackage['state'])}
                  name="status"
                  value={statusFilter}
                  type="select"
                  options={filter<{
                    value: SubscriptionPackage['state'] | 'all';
                    displayName: string;
                  }>([
                    { value: 'all', displayName: 'All' },
                    { value: 'inProgressByAgent', displayName: displaySubscriptionPackageState('inProgressByAgent') },
                    { value: 'sentToDocuSign', displayName: displaySubscriptionPackageState('sentToDocuSign') },
                    isCurrentUserAdmin && {
                      value: 'testSentToDocuSign',
                      displayName: displaySubscriptionPackageState('testSentToDocuSign'),
                    },
                    { value: 'completed', displayName: displaySubscriptionPackageState('completed') },
                    { value: 'voided', displayName: displaySubscriptionPackageState('voided') },
                  ])}
                />
              </>
            )}
          />
        </>
      )}
      {subscriptionPackages.length === 0 && (
        <div className="flex align-center column no-subscription-packages">
          <h1>There are no Subscription Packages to display.</h1>
        </div>
      )}
    </Page>
  );
}
