import { SubscriptionInformation } from 'shared';

import { ProtoFields } from '../types';

export function subscriptionInformationFields(selectedProgram: string): ProtoFields<keyof SubscriptionInformation> {
  return {
    unitAmount: { formLabel: 'Number Of Units (Required)' },
    programName: {
      labelAsOption: true,
      formLabel: 'Program Name (Required)',
      disabled: true,
      underlyingClassName: 'read-only',
      type: 'select',
      options: [{ displayName: selectedProgram, value: selectedProgram }],
    },
  };
}
