import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export type HistoryAction = 'PUSH' | 'POP' | 'REPLACE';

export function usePageChange(
  cb: (
    ...args: [{ pathname: string; search: string; hash: string; state: unknown; key?: string }, HistoryAction]
  ) => void,
  deps: any[] = [], // eslint-disable-line @typescript-eslint/no-explicit-any
): void {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(cb);
    return unlisten;
  }, [cb, ...deps]);
}
