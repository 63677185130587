import { SubscriptionPackageFormDataAllRequired, SubscriptionPackageFormField } from 'shared';
import { useSubscriptionPackageFormState } from '../useSubscriptionPackageFormState';
import { ProtoFields, Fields, InputWithoutName } from './types';

export function useSubscriptionPackageSubFormFields<T extends keyof SubscriptionPackageFormDataAllRequired>(
  fields: ProtoFields<T>,
): Fields<T> {
  const { updateField, form, isFormDisabledForCurrentUser } = useSubscriptionPackageFormState();
  const shouldBeReadOnlyToCurrentUser = isFormDisabledForCurrentUser;
  function createFormFields(): Fields<T> {
    return (Object.entries(fields) as [T, InputWithoutName<T>][]).reduce(
      (acc, [name, { disabled, readOnly, ...restField }]) => {
        acc[name] = new SubscriptionPackageFormField(
          { name, readOnly: shouldBeReadOnlyToCurrentUser || readOnly, ...(restField as { formLabel: string }) },
          updateField,
          (
            form || {
              formData: {
                programName: '',
                salespersonEmail: '',
                errors: [],
                salespersonName: '',
                salespersonPhone: '',
                unitAmount: 10,
              },
            }
          ).formData,
          () => disabled || false,
        );
        return acc;
      },
      {} as Fields<T>,
    );
  }
  return createFormFields();
}
