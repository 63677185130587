import { SubscriptionPackage } from 'shared';
import { ActionTypes } from '@actions';
import * as SubscriptionPackagesActions from '@actions/subscriptionPackages';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = {
  isLoading: boolean;
  data: SubscriptionPackage[];
};

const initialState: State = {
  isLoading: false,
  data: [],
};

const getSubscriptionPackagesReducer: Reducer<State> = (
  _state,
  action: SubscriptionPackagesActions.GetSubscriptionPackages,
): State => {
  return {
    isLoading: false,
    data: action.payload,
  };
};

const startGetSubscriptionPackagesReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    isLoading: true,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return { ...initialState, hasFetched: true };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.GET_SUBSCRIPTION_PACKAGES]: getSubscriptionPackagesReducer,
  [ActionTypes.START_GET_SUBSCRIPTION_PACKAGES]: startGetSubscriptionPackagesReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
