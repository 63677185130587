import { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Organization } from 'shared';
import { useCurrentUser } from '@hooks';
import Dropdown from '../Dropdown';
import './styles.scss';

interface Props {
  openSettingsModal: () => void;
  organization: Organization;
}

function OrganizationSettingsMenu({ openSettingsModal, organization }: Props): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const { hasAllPermissions, isCurrentUserAdminForOrg } = useCurrentUser();
  if (!isCurrentUserAdminForOrg(organization) && !hasAllPermissions(['create:organization'])) {
    return null;
  }
  return (
    <Dropdown
      triggerComponent={
        <FontAwesomeIcon
          className="hover:text-equigenesis-gold text-2xl"
          icon={!isOpen ? 'chevron-circle-down' : 'chevron-circle-up'}
        />
      }
      className="absolute right-0"
      menuComponent={
        <>
          <div className="whitespace-nowrap">
            {(hasAllPermissions(['create:agent', 'create:subscriber']) || isCurrentUserAdminForOrg(organization)) && (
              <a
                href="#organization-settings"
                className={classnames('organization-dropdown-item', 'nav-link')}
                onClick={openSettingsModal}
              >
                Edit Settings
              </a>
            )}
          </div>
        </>
      }
      isOpen={isOpen}
      onTriggerClick={setIsOpen}
      menuContainerClassName="-right-2"
    />
  );
}

export default OrganizationSettingsMenu;
