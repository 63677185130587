import { createContext } from 'react';
import { Organization } from 'shared';

interface BrandingContextI {
  showBrandingInNav: boolean;
  organization?: Organization | null;
}

export const BrandingContext = createContext<BrandingContextI>({
  showBrandingInNav: false,
  organization: null,
});
