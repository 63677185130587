import { ReactElement } from 'react';
import Icon from '../Icon';

import './styles.scss';

interface Props {
  onClick: () => void;
}

export default function CloseIcon({ onClick }: Props): ReactElement {
  return (
    <div className="CloseIcon" onClick={onClick}>
      <Icon className="hide-hover" type="close" />
      <Icon className="show-hover" type="closeHover" />
    </div>
  );
}
