import { SubscriptionPackage } from 'shared';

export function getCurrentEnvelopeStep(envelope: SubscriptionPackage['envelope']): number {
  return (
    envelope?.recipients?.signers?.findIndex(
      (signer) => signer.routingOrder === envelope.recipients?.currentRoutingOrder,
    ) ?? -1
  );
}

export function displayStepInfo(form: SubscriptionPackage): string {
  const { state, envelope } = form;
  if ((state !== 'testSentToDocuSign' && state !== 'sentToDocuSign') || !envelope || !envelope.recipients) {
    return '';
  }
  return `(${getCurrentEnvelopeStep(envelope)}/${envelope.recipients.signers?.length}) Signatures Collected: `;
}
