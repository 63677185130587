import { ReactElement } from 'react';
import classnames from 'classnames';
import { roleHierarchy, roleDisplay, FullUser, Role, filter } from 'shared';
import UserAvatar from '@components/common/UserAvatar';
import { State } from '@reducers/currentUser';
import './styles.scss';

interface Props {
  user: State['data'];
}

function getOrganizationRole(user: FullUser): string {
  const userOrg = user.organization?.userProperties;
  if (!userOrg) {
    return roleDisplay.agent;
  }
  const { isAdmin, isReviewer, isAgent } = userOrg;
  const orgRoles = filter<string>([isAdmin && 'Org Admin', isReviewer && 'Org Reviewer', isAgent && roleDisplay.agent]);
  return orgRoles.join(' | ');
}

export default function Trigger({ user }: Props): ReactElement | null {
  if (!user) {
    return null;
  }
  function getRoleDisplay(role: Role): string {
    if (role === 'agent' && user?.organization) {
      return getOrganizationRole(user);
    }
    return roleDisplay[role];
  }
  const roles = user.roles.concat([]).sort((a, b) => roleHierarchy.indexOf(a) - roleHierarchy.indexOf(b));
  return (
    <div className="UserDropdownTrigger nav-link">
      <div className="flex column justify-center text-center">
        <span className="name">{user.name}</span>
        <span className={classnames('roles', { 'all-four': roles.length === 4 })}>
          {roles.map(getRoleDisplay).join(' | ')}
        </span>
      </div>
      <UserAvatar pictureUrl={user.picture} />
    </div>
  );
}
