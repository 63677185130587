import { SalespersonInformation } from 'shared';
import { ProtoFields } from '../types';

export const salespersonInformationFields: ProtoFields<keyof SalespersonInformation> = {
  salespersonEmail: { type: 'email', formLabel: 'Email' },
  salespersonFirm: { formLabel: 'Name of Firm' },
  salespersonName: { formLabel: 'Full Name of Salesperson' },
  salespersonPhone: { formLabel: 'Phone (Required)', type: 'tel' },
  salespersonPhoneCountryCode: { formLabel: 'Phone (Required)', type: 'tel' },
};
