import { ReactElement } from 'react';
import classnames from 'classnames';
import { ICON_LIBRARY } from './lib';
import './styles.scss';

interface Props {
  type: keyof typeof ICON_LIBRARY;
  className?: string;
  title?: string;
  onClick?: () => void;
}

function Icon({ type, className, title, onClick }: Props): ReactElement {
  const IconComp = ICON_LIBRARY[type];
  return (
    <IconComp onClick={onClick} title={title} className={classnames('Icon', className, `icon-${type}`)}></IconComp>
  );
}

export default Icon;
