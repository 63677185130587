import { ReactElement } from 'react';
import axios, { AxiosError } from 'axios';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import { NonceProvider } from 'react-select';
import { Actions } from '@actions';
import { Application } from '@layouts';
import { dispatch } from '@store';
import { getNonce } from '@utils/domHelpers';
import AuthWrapper from '../auth/AuthWrapper';
import AuthRoute from './AuthRoute';
import { routes } from './lib';

function Router(): ReactElement {
  // needs to be done within the <Provider>
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(Actions.sessionExpired());
        }
      }
      throw error;
    },
  );

  return (
    <BrowserRouter>
      <AuthWrapper>
        <NonceProvider cacheKey="react-select" nonce={getNonce()}>
          <Application>
            <Switch>
              {routes.map(({ component: Component, path, isAuth, ...restProps }) =>
                isAuth ? (
                  <AuthRoute {...{ component: Component, path, isAuth, ...restProps }} key={path} />
                ) : (
                  <Route key={path} {...restProps} path={path} component={Component} />
                ),
              )}
              {/* Redirect all weird traffic to 404 */}
              <Redirect from="*" to="/404" />
            </Switch>
          </Application>
        </NonceProvider>
      </AuthWrapper>
    </BrowserRouter>
  );
}

export default Router;
