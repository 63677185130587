import { ReactElement } from 'react';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';

interface Props {
  forceBackLocation?: ValidPath;
  noBackButton?: boolean;
}
export default function NotConnectedPage({ forceBackLocation }: Props): ReactElement {
  return (
    <Page
      className="NotConnectedPage"
      renderHeader={(): ReactElement => <h1>You are not connected to the internet</h1>}
      defaultBackLocation={forceBackLocation || PATHS.HOME}
      doNotRecurse
      noBackButton
    >
      <p>Please check your internet connection and then try refreshing the page</p>
    </Page>
  );
}
