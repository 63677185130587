import { LeveragedTaxShelterInformation, EquiGenesisProgramsLib } from 'shared';
import { ProtoFields } from '../types';

export const leveragedTaxShelterInformationFields: ProtoFields<keyof LeveragedTaxShelterInformation> = {
  equigenesisProgramEQ2003: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2003, type: 'checkbox' },
  equigenesisProgramEQ2003v2: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2003v2, type: 'checkbox' },
  equigenesisProgramEQ2004: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2004, type: 'checkbox' },
  equigenesisProgramEQ2004v2: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2004v2, type: 'checkbox' },
  equigenesisProgramEQ2005: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2005, type: 'checkbox' },
  equigenesisProgramEQ2006: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2006, type: 'checkbox' },
  equigenesisProgramEQ2009: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2009, type: 'checkbox' },
  equigenesisProgramEQ2010: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2010, type: 'checkbox' },
  equigenesisProgramEQ2011: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2011, type: 'checkbox' },
  equigenesisProgramEQ2012: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2012, type: 'checkbox' },
  equigenesisProgramEQ2013: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2013, type: 'checkbox' },
  equigenesisProgramEQ2020: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2020, type: 'checkbox' },
  equigenesisProgramEQ2021: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2021, type: 'checkbox' },
  equigenesisProgramEQ2022: { formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2022, type: 'checkbox' },
  equigenesisProgramEQ2022MasterLP: {
    formLabel: EquiGenesisProgramsLib.equigenesisProgramEQ2022MasterLP,
    type: 'checkbox',
  },
};
