import axios from 'axios';
import { FullUser, ResponsePayloads, RequestPayloads, filter } from 'shared';

export function getRelationships(
  opts: {
    agentEmail?: string;
    subscriberEmail?: string;
  } = {},
): Promise<ResponsePayloads['getRelationships']> {
  const { agentEmail, subscriberEmail } = opts;
  const agentQuery = agentEmail ? `agentEmail=${agentEmail}` : undefined;
  const subscriberQuery = subscriberEmail ? `subscriberEmail=${subscriberEmail}` : undefined;
  const query = filter([agentQuery, subscriberQuery]).join('&');
  return axios.get(`/relationships?${query.replace(/\+/gi, '%2B').replace(/\*/gi, '')}`).then((res) => res.data);
}

export function assignSubscriberToAgent(
  subscriber: FullUser,
  agent: FullUser,
  changeSubscriberOrganization: boolean,
): Promise<ResponsePayloads['assignSubscriberToAgent']> {
  const payload: RequestPayloads['assignSubscriberToAgent'] = {
    subscriber_id: subscriber.user_id,
    agent_id: agent.user_id,
    changeSubscriberOrganization,
  };
  return axios.post('/relationships', payload).then((res) => res.data);
}

export function unassignSubscriberFromAgent(
  relationshipId: number,
): Promise<ResponsePayloads['unassignSubscriberFromAgent']> {
  return axios.delete(`/relationships/${relationshipId}`);
}
