import { TCPNotes } from 'shared';
import { OfficeUseYesNoRadioField } from '@utils/formFields';
import { ProtoFields } from '../types';

export const tcpNotesFields: ProtoFields<keyof TCPNotes> = {
  shouldOmitTCP: { formLabel: 'Should Omit TCP?', type: 'checkbox' },
  askedClientToDesignateTCP: OfficeUseYesNoRadioField(
    'Expressly asked the client to designate a TCP and, if client declined to designate a TCP, canvassed the reason(s) why the client so declined?',
  ),
  describeEquigenesisInformationDisclosureCircumstances: OfficeUseYesNoRadioField(
    'Provided  a  description  of  the  circumstances  under  which  EquiGenesis  might  disclose  information  about  the  client  or  the  client’s account to the TCP?',
  ),
  encouragedClientToNameTCP: OfficeUseYesNoRadioField(
    'Encouraged  client  to  name  a  TCP  that  the  client  trusts,  believes  to  be  mature  and  has  the  ability  to  communicate  and  engage  in potentially difficult conversations with EquiGenesis about the client’s personal situation?',
  ),
  encouragedClientToSelectNonInvolvedIndividual: OfficeUseYesNoRadioField(
    'Encouraged  client  to  select  an  individual  who  is  not  the  client’s  attorney  under  a  power  of  attorney  and  who  is  not  otherwise involved in making decisions with respect to the client’s account?',
  ),
  explainedTCPDesignationPurpose: OfficeUseYesNoRadioField('Explained to the client the purpose of designating a TCP?'),
  tcpNotes: { formLabel: 'TCP Notes', type: 'textarea' },
};
