import { setupAxiosInterceptors } from '@api/init';
import { setPreviousUserLocation } from '@utils/localStorage/userLocation';
import { Actions as GeneralActions, SetShowLoadingOverlay, SetShowSessionExpiredOverlay } from '../general';
import { AsyncAction } from '../types';
import {
  ActionTypes,
  SaveAuth,
  SessionExpired,
  SetupAxios,
  StartLogout,
  ResetHasFetchedToken,
  StartGetAuthToken,
  ResetApp,
} from './types';

export type Actions =
  | SaveAuth
  | SessionExpired
  | SetupAxios
  | StartLogout
  | ResetHasFetchedToken
  | StartGetAuthToken
  | ResetApp;

export const Actions = {
  saveAuth(payload: SaveAuth['payload']): AsyncAction<SaveAuth> {
    return async (dispatch): Promise<void> => {
      await dispatch({ type: ActionTypes.SAVE_AUTH, payload });
      await dispatch(Actions.setupAxios());
      return Promise.resolve();
    };
  },
  setupAxios(): AsyncAction<SetupAxios | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { auth } = getState();
      setupAxiosInterceptors({ dispatch, auth });
      dispatch({ type: ActionTypes.SETUP_AXIOS, payload: undefined });
    };
  },
  sessionExpired(): AsyncAction<SessionExpired | SetShowSessionExpiredOverlay | ResetApp> {
    return async (dispatch, getState): Promise<void> => {
      dispatch({ type: ActionTypes.SESSION_EXPIRED, payload: undefined });
      const { auth } = getState();
      if (auth.user) {
        setPreviousUserLocation(auth.user);
      }
      dispatch(GeneralActions.showSessionExpiredOverlay());
      dispatch(Actions.resetApp());
    };
  },
  resetApp(): ResetApp {
    return { type: ActionTypes.RESET_APP, payload: undefined };
  },
  clearUserInfoLogout(): AsyncAction<StartLogout | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      // TODO: maybe add warning?
      dispatch(GeneralActions.showLoadingOverlay());
      localStorage.clear();
      dispatch(Actions.startLogout());
    };
  },
  startLogout(): StartLogout {
    return { type: ActionTypes.START_LOGOUT, payload: undefined };
  },
  startGetToken(): StartGetAuthToken {
    return { type: ActionTypes.START_GET_AUTH_TOKEN, payload: undefined };
  },
  resetHasFetchedToken(): AsyncAction<ResetHasFetchedToken> {
    return async (dispatch, getState): Promise<void> => {
      const {
        auth: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_TOKEN, payload: undefined });
    };
  },
};

export * from './types';
