import { ReactElement } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { getHighestRole, Permission } from 'shared';
import OfficeUse from '@components/OfficeUse';
import { useCurrentUser, useCurrentUserReadableRelationships, useOfficeUseFormState, useSingleUser } from '@hooks';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';
import ErrorPage from '../ErrorPage';
import './styles.scss';

export default function OfficeUsePage(): ReactElement {
  const { email } = useParams<{ email: string }>();
  const { isLoading, form, hasFetched } = useOfficeUseFormState(email);
  const { isLoading: userIsLoading, error, user } = useSingleUser(email);
  const { hasAllPermissions, currentUser } = useCurrentUser();
  const { isLoading: relationshipsIsLoading, relationships } = useCurrentUserReadableRelationships();
  if (email && !isLoading && !form && hasFetched) {
    return <Redirect to={PATHS.NOT_FOUND} />;
  }
  const isOfficeUseForCurrentUser = user?.user_id === currentUser?.user_id;
  const backLocation = isOfficeUseForCurrentUser ? PATHS.HOME : (`${PATHS.SUBSCRIBERS}/${email}` as ValidPath);
  function canDoAll(permission: Permission): boolean {
    return hasAllPermissions([permission]);
  }
  function canDoOwnSubscriber(permission: Permission): boolean {
    return (
      !!user &&
      hasAllPermissions([permission]) &&
      !!relationships.find(
        (relationship) =>
          relationship.agentUserId === currentUser?.user_id && relationship.subscriberUserId === user.user_id,
      )
    );
  }
  function permissionCheck(): boolean {
    return canDoAll('update:officeUse') || canDoOwnSubscriber('update:ownSubscriberOfficeUse');
  }
  if (error) {
    return <ErrorPage forceBackLocation={PATHS.SUBSCRIBERS} />;
  }
  return (
    <Page
      className="OfficeUsePage"
      defaultBackLocation={backLocation}
      withScrollToTop
      withScrollToBottom
      renderHeader={
        form
          ? (): ReactElement => (
              <>
                {form?.subscriber && (
                  <h1>
                    {getHighestRole(currentUser?.roles || []) !== 'subscriber' ? (
                      <Link to={`${PATHS.SUBSCRIBERS}/${form.subscriber.email}`}>{form.subscriber.name}</Link>
                    ) : (
                      form.subscriber.name
                    )}
                  </h1>
                )}
              </>
            )
          : (): ReactElement => <h1>Loading Office Use Form...</h1>
      }
      isLoading={isLoading || relationshipsIsLoading || userIsLoading}
      showChildrenOnLoading={false}
    >
      <div className="flex flex-1 align-center justify-center width-80">
        <OfficeUse permissionCheck={permissionCheck} />
      </div>
    </Page>
  );
}
