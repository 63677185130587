import { useEffect, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  isSequelizeValidationError,
  KYC,
  KYCFormData,
  KYCFormDataAllRequired,
  KYCFormFormatting,
  KYCFormValidation,
  validateCreateUser,
  ValidationError,
  ValidationErrorItem,
} from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Loading from '@components/common/Loading';
import { useCurrentUser, useKYCFormState, useOrganizations } from '@hooks';
import { BASE_PATHS, ValidPath } from '@routes/lib';
import { Option } from '@utils/select';
import './styles.scss';

export default function StartForm(): ReactElement {
  const { isLoading, organizations } = useOrganizations({ refresh: true });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState<Option<number> | undefined | null>(undefined);
  const [createUserErrors, setCreateUserErrors] = useState<ValidationError['errors']>([]);
  const [errors, setErrors] = useState([] as KYCFormData['errors']);
  const { isCurrentUserAgent, isCurrentUserAdmin, isCurrentUserEQ, currentUser } = useCurrentUser();
  const { createKYC } = useKYCFormState();
  const history = useHistory();
  function submitNewKYC(e: React.FormEvent<HTMLFormElement>): void {
    try {
      validateCreateUser({
        firstName,
        lastName,
        email,
        organizationId: selectedOrganization?.value,
        organizationRoles: { isAdmin: false, isAgent: false, isClient: true, isReviewer: false },
      });
      if (!selectedOrganization) {
        return setCreateUserErrors([
          new ValidationErrorItem('Please select an organization', undefined, 'selectedOrganization'),
        ]);
      }
      e.preventDefault();
      createKYC(
        {
          formData: { firstName, lastName, email } as Pick<KYC, 'formData'>['formData'],
          organizationId: selectedOrganization.value,
        },
        (error) => setErrors(error.errors || []),
        () => history.replace(`${BASE_PATHS.SUBSCRIBERS}/${email}` as ValidPath),
      );
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        setCreateUserErrors(e.errors);
      }
    }
  }
  useEffect(() => {
    if (
      isCurrentUserAgent &&
      !isCurrentUserAdmin &&
      !isCurrentUserEQ &&
      organizations.length &&
      currentUser?.organization
    ) {
      setSelectedOrganization({ label: currentUser.organization.name, value: currentUser.organization.id });
    }
  }, [isCurrentUserAgent, isCurrentUserAdmin, isCurrentUserEQ, organizations.length]);
  function onChange(field: keyof KYCFormDataAllRequired, fieldChange: (value: string) => void) {
    return (value: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
      const formattedValue = KYCFormFormatting(field, e)(value);
      const validator = KYCFormValidation[field] as (
        v: typeof value,
        f: Omit<KYCFormData, 'numberOfNonFinancialDebts' | 'numberOfFinancialDebts'>,
        isSubscriber: boolean,
      ) => KYCFormData['errors'][number] | void;
      const error = validator(formattedValue, { firstName, lastName, email, errors }, true);
      const newErrors = errors.filter((error) => !error.fieldCausingError.includes(field)).concat(error ? [error] : []);
      setErrors(newErrors);
      fieldChange(value as string);
    };
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Form className="p-8" onSubmit={submitNewKYC}>
      <div className="flex form-row max-width">
        <GeneralInput
          value={firstName}
          error={errors.find((error) => error.field === 'firstName')?.message}
          onChange={onChange('firstName', setFirstName) as OnChange}
          name="firstName"
          id="firstName"
          formLabel="First Name"
        />
      </div>
      <div className="flex form-row max-width">
        <GeneralInput
          value={lastName}
          error={errors.find((error) => error.field === 'lastName')?.message}
          onChange={onChange('lastName', setLastName) as OnChange}
          name="lastName"
          id="lastName"
          formLabel="Last Name"
        />
      </div>
      <div className="flex form-row max-width">
        <GeneralInput
          error={errors.find((error) => error.field === 'email')?.message}
          value={email}
          onChange={onChange('email', setEmail) as OnChange}
          name="email"
          id="email"
          formLabel="Email"
        />
      </div>
      <div className="left w-full">
        <label className="label">Organization</label>
      </div>
      <div className="w-full text-black">
        <Select<Option<number>>
          classNamePrefix="select"
          value={selectedOrganization}
          options={organizations.map((org) => ({ label: org.name, value: org.id }))}
          onChange={(selectedOrganization): void => setSelectedOrganization(selectedOrganization)}
          isDisabled={isCurrentUserAgent && !isCurrentUserAdmin && !isCurrentUserEQ}
        />
      </div>
      <div className="mt-5">
        <EquiButton
          value="Add Subscriber"
          kind={ButtonType.secondary}
          type="submit"
          disabled={errors.length + createUserErrors.length > 0 || !email || !selectedOrganization}
        />
      </div>
    </Form>
  );
}
