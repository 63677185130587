import { isAxiosError } from 'shared';
import { SubscriptionPackageAPI } from '@api';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { AsyncAction } from '../types';
import { ActionTypes, GetSubscriptionPackages, StartGetSubscriptionPackages } from './types';

export type Actions = GetSubscriptionPackages | StartGetSubscriptionPackages;

export const Actions = {
  getSubscriptionPackages(): AsyncAction<GetSubscriptionPackages | StartGetSubscriptionPackages | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_SUBSCRIPTION_PACKAGES, payload: undefined });
        const subscriptionPackages = await SubscriptionPackageAPI.getSubscriptionPackages();
        dispatch({ type: ActionTypes.GET_SUBSCRIPTION_PACKAGES, payload: subscriptionPackages });
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      }
    };
  },
};

export * from './types';
