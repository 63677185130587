export function scrollToTop(): void {
  const main = document.querySelector('main');
  if (main) {
    main.scrollIntoView();
  }
}

export function focusErrorField(): void {
  requestAnimationFrame(() => {
    const errorInput = document.querySelector('.equi-input-error input') as HTMLInputElement | null;
    if (errorInput) {
      errorInput.focus();
    }
  });
}

export function focusClientEmailErrorField(): void {
  const errorInput = document.querySelector('input#email') as HTMLInputElement | null;
  if (errorInput) {
    errorInput.scrollIntoView({ block: 'center' });
    errorInput.focus();
  }
}

export function clearAllFormFields(): void {
  document.querySelectorAll('input, textarea, select').forEach((node) => {
    if (node.matches('input[type="checkbox"], input[type="radio"]')) {
      (node as HTMLInputElement).removeAttribute('checked');
    } else if (node.matches('select')) {
      (node.children[0] as HTMLOptionElement).selected = true;
    } else {
      (node as HTMLInputElement | HTMLTextAreaElement).value = '';
    }
  });
}

export function disablePageScrolling(): void {
  const body = document.querySelector('body');
  if (!body) {
    return;
  }
  body.classList.add('no-scroll');
}

export function enablePageScrolling(): void {
  const body = document.querySelector('body');
  if (!body) {
    return;
  }
  body.classList.remove('no-scroll');
}

function isNonceMeta(el: Element | null | { content: string }): el is { content: string } {
  return !!el && 'content' in el;
}

export function getNonce(): string {
  const cspMeta = document.querySelector('meta[property=csp-nonce]');
  if (!isNonceMeta(cspMeta)) {
    return '';
  }
  return cspMeta.content;
}
