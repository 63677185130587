import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { Role, roleHierarchy, roleDisplay } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Icon from '@components/common/Icon';
import SectionCard from '@components/common/SectionCard';
import ModifyRolesModal from '@components/Modals/ModifyRolesModal';
import { useCurrentUser, useFlashMessage, useSingleUser } from '@hooks';
import { Page } from '@layouts';
import { ErrorPage } from '@pages';
import { PATHS } from '@routes/lib';
import { buildUserDetails, userRoleIconLib } from './lib';

import './styles.scss';

export default function AdminViewSingleUser(): ReactElement | null {
  const { email } = useParams<{ email: string }>();
  const { isLoading, user, error, toggleUserBlocked, modifyRoles, deleteUser, inviteUser } = useSingleUser(email);
  const { flashWarningMessage } = useFlashMessage();
  const { currentUser } = useCurrentUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal(): void {
    setIsModalOpen(true);
  }
  function closeModal(): void {
    setIsModalOpen(false);
  }
  function submitNewRoles(modifiedRoles: { [Key in Role]: boolean }): void {
    modifyRoles(modifiedRoles, closeModal);
  }
  if (error) {
    return <ErrorPage forceBackLocation={PATHS.ADMIN_VIEW_USERS} />;
  }

  const data = buildUserDetails(user, currentUser, toggleUserBlocked, deleteUser, flashWarningMessage, inviteUser);

  return (
    <Page
      className="AdminViewSingleUser"
      renderHeader={
        user
          ? (): ReactElement => (
              <>
                <h1>{user.name}</h1>
                <p>{user.email}</p>
                <div className={classnames('role-icon-container', 'flex', 'justify-center')}>
                  {user.app_metadata.authorization?.roles
                    .concat([])
                    .sort((a, b) => roleHierarchy.indexOf(a) - roleHierarchy.indexOf(b))
                    .map((role) => (
                      <div key={role} className={`flex column align-center role-${role} role-icon`}>
                        <Icon type={userRoleIconLib[role]} />
                        <h3>{roleDisplay[role]}</h3>
                      </div>
                    ))}
                </div>
              </>
            )
          : undefined
      }
      defaultBackLocation={PATHS.ADMIN_VIEW_USERS}
      isLoading={isLoading}
      showChildrenOnLoading={false}
    >
      {user && (
        <>
          <EquiButton value="Modify User Roles" onClick={openModal} />
          <SectionCard className="user-details-container">
            {data.map((item) => (
              <div
                key={typeof item.label === 'object' ? item.label.key : item.label}
                className="user-detail flex max-width align-center space-between"
              >
                <div>{item.label}</div>
                <div onClick={item.valueOnClick} className={classnames(item.valueClassName, 'value-item')}>
                  {item.value}
                </div>
              </div>
            ))}
          </SectionCard>
          <ModifyRolesModal onClose={closeModal} shouldShow={isModalOpen} user={user} onSubmit={submitNewRoles} />
        </>
      )}
    </Page>
  );
}
