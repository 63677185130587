import { CurrentUser } from 'shared';
import { ActionTypes } from '@actions';
import * as CurrentUserActions from '@actions/currentUser';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

type UserData = CurrentUser;

export interface State {
  data: UserData | null;
  hasFetched: boolean;
}

const initialState: State = {
  hasFetched: false,
  data: null,
};

const startGetCurrentUserReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    hasFetched: true,
  };
};

const getCurrentUserReducer: Reducer<State> = (
  state = initialState,
  action: CurrentUserActions.GetCurrentUser,
): State => {
  if (!action.payload) {
    return action.payload;
  }
  const user = action.payload;

  return {
    ...state,
    data: user,
  };
};

const resetHasFetchedCurrentUserReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    hasFetched: false,
  };
};
const resetAppReducer: Reducer<State> = (): State => {
  return {
    ...initialState,
    hasFetched: true,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.START_GET_CURRENT_USER]: startGetCurrentUserReducer,
  [ActionTypes.GET_CURRENT_USER]: getCurrentUserReducer,
  [ActionTypes.RESET_HAS_FETCHED_CURRENT_USER]: resetHasFetchedCurrentUserReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
