import { ReactElement, useState } from 'react';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import { useOfficeUseFormState } from '@hooks';
import OfficeUseSubmitPDFModal from '../OfficeUseSubmitPDFModal';
import KYCNotes from './KYCNotes';
import { isFormComplete } from './lib';
import TCPNotes from './TCPNotes';
import './styles.scss';

interface Props {
  permissionCheck: () => boolean;
}

function OfficeUse(props: Props): ReactElement {
  const { permissionCheck } = props;
  const { form } = useOfficeUseFormState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal(): void {
    setIsModalOpen(true);
  }
  function closeModal(): void {
    setIsModalOpen(false);
  }

  return (
    <Form className="OfficeUse mt-10 py-8" turnOffAutoComplete={false}>
      <div className="bottom-space max-width overflow-hidden">
        <h1 className="text-center">Office Use Form</h1>
        <hr className="section-separator" />
        <KYCNotes />
        <hr className="section-separator" />
        <TCPNotes />
        <hr className="section-separator" />
      </div>
      <div className="submit-buttons-container max-width flex space-around">
        <EquiButton
          kind={ButtonType.secondary}
          onClick={openModal}
          disabled={!(permissionCheck() && isFormComplete(form?.formData))}
          value="Generate PDF With Office Use"
        />
      </div>
      <OfficeUseSubmitPDFModal onClose={closeModal} shouldShow={isModalOpen} />
    </Form>
  );
}

export default OfficeUse;
