import { ReactElement } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import Icon from '@components/common/Icon';
import SubscriptionPackage from '@components/SubscriptionPackage';
import { useSubscriptionPackageFormState } from '@hooks';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';
import { displaySubscriptionPackageState } from '@utils/formState';
import { getCurrentEnvelopeStep } from '@utils/subscriptionPackages';
import './styles.scss';

export default function SubscriptionPackagePage(): ReactElement {
  const { slug } = useParams<{ slug: string }>();
  const { isLoading, form, hasFetched } = useSubscriptionPackageFormState(slug);
  if (slug && !isLoading && !form && hasFetched) {
    return <Redirect to={PATHS.NOT_FOUND} />;
  }
  const waitingOn = form?.envelope?.recipients?.signers?.find((signer) => signer.status !== 'completed');
  const numberOfSignaturesCollected = getCurrentEnvelopeStep(form?.envelope);
  const numberOfSigners = form?.envelope?.recipients?.signers?.length;
  return (
    <Page
      className="SubscriptionPackagePage"
      defaultBackLocation={
        (form?.kyc?.subscriber ? `${PATHS.SUBSCRIBERS}/${form?.kyc?.subscriber.email}` : PATHS.HOME) as ValidPath
      }
      withScrollToTop
      withScrollToBottom
      renderHeader={
        form
          ? (): ReactElement => (
              <>
                <h1>
                  {form.kyc?.subscriber && (
                    <Link to={`${PATHS.SUBSCRIBERS}/${form?.kyc?.subscriber.email}`}>{form.kyc?.subscriber?.name}</Link>
                  )}
                </h1>
                <h2>
                  {displaySubscriptionPackageState(form.state)}
                  {form.state === 'completed' && <Icon className="status" type="checkmark" />}
                  {form.state === 'voided' && <Icon className="status" type="close" />}
                </h2>
                {form.state !== 'completed' &&
                  form.state !== 'voided' &&
                  !!waitingOn &&
                  numberOfSignaturesCollected > -1 && (
                    <h2>
                      {numberOfSignaturesCollected} of {numberOfSigners} Signatures collected: waiting for{' '}
                      {waitingOn.name} to sign
                    </h2>
                  )}
                {form.state === 'completed' && (
                  <p>
                    <Link to={`${PATHS.SUBSCRIPTION_PACKAGES}/${form.slug}/docusign`}>View DocuSign Document</Link>
                  </p>
                )}
                {form.product?.is_active === false && !['completed', 'voided'].includes(form.state) && (
                  <div className="error">
                    This form cannot be sent to DocuSign because the product is no longer available for sale.
                  </div>
                )}
              </>
            )
          : (): ReactElement => <h1>Loading Subscription Package...</h1>
      }
      showChildrenOnLoading={false}
      isLoading={isLoading}
    >
      <div className="flex flex-1 align-center justify-center width-80">
        <SubscriptionPackage />
      </div>
    </Page>
  );
}
