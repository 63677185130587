import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface OrganizationsHook {
  organizations: State['organizations']['data'];
  isLoading: State['organizations']['isLoading'];
}

export function useOrganizations(options: { refresh?: boolean; failSilently?: boolean } = {}): OrganizationsHook {
  const { refresh, failSilently } = options;
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const { isLoading, data: organizations } = useSelector<State>(
    (state) => state.organizations,
  ) as State['organizations'];

  useEffect(() => {
    if (refresh) {
      dispatch(Actions.getOrganizations({ failSilently }));
    }
  }, [refresh]);
  return { organizations, isLoading };
}
