import { KYCNotes } from 'shared';
import { OfficeUseYesNoRadioField } from '@utils/formFields';
import { ProtoFields } from '../types';

export const kycNotesFields: ProtoFields<keyof KYCNotes> = {
  accreditedInvestorStatusConfirmed: OfficeUseYesNoRadioField('Accredited Investor status confirmed?'),
  clientsInsiderStatusExplainedToHaveNoBearing: OfficeUseYesNoRadioField(
    'Explained to client that EquiGenesis does not deal in any publicly traded securities and that consequentially the client’s insider status will not have any bearing on the client’s dealings with EquiGenesis?',
  ),
  clientsRoleExplained: OfficeUseYesNoRadioField(
    'Explained client’s role in keeping know-your-client information current?',
  ),
  conflictsOfInterestCanvasedWithClient: OfficeUseYesNoRadioField(
    'Material conflicts  of  interest  canvassed in  detail  with client  (with  reference  to  conflicts  of  interest  disclosure in  offering documents,  paying  particular  attention  to  (i)  conflict  of  interest  arising  from  sale  of  proprietary  products  offered  by  connected issuers (and the lack of any known competing products in the market), (ii) direct and indirect benefits to be received  by  the  firm  and/or  affiliates  of  the  firm  in  connection  with  any  securities  offered  by  the  firm)? Dealing Representative to include detailed notes below as to how this issue was canvassed with client.',
  ),
  dealerKnownClientYearsTime: {
    type: 'number',
    formLabel: 'How long has Dealing Representative known the client? (years)',
    min: 0,
    max: 80,
    step: '0.1',
  },
  dealerMetClientInPerson: OfficeUseYesNoRadioField('Has the Dealing Representative met the client face-to-face?'),
  kycNotes: { formLabel: 'KYC Notes', type: 'textarea' },
  pepStatusExplained: OfficeUseYesNoRadioField(
    'PEP/HIO status explained to client and confirmed? If client is a PEP/HIO, source of investment funds identified? ',
  ),
};
