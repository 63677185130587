import { ReactElement } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { formatDateTime, SubscriptionPackage } from 'shared';
import Table from '@components/common/Table';
import { useCurrentUser, useSort, useSubscriptionPackagesByEmail } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import './styles.scss';

type sortByOptions = 'lastUpdated' | 'numberOfUnits';

export default function MyDocumentsPage(): ReactElement {
  const { currentUser } = useCurrentUser();
  const { subscriptionPackages, isLoading } = useSubscriptionPackagesByEmail(currentUser?.email || '', 'completed');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('lastUpdated', true);
  const sortedAndFiltered: SubscriptionPackage[] = sortBy(subscriptionPackages, (subPackage) => {
    switch (field) {
      case 'numberOfUnits':
        return subPackage.formData.unitAmount;
      case 'lastUpdated':
      default:
        return subPackage.formUpdatedAt;
    }
  });
  if (isDescending) {
    sortedAndFiltered.reverse();
  }
  return (
    <Page
      className="MyDocumentsPage"
      isLoading={isLoading}
      defaultBackLocation={PATHS.HOME}
      showChildrenOnLoading={false}
      renderHeader={(): ReactElement => <h1>My Documents</h1>}
    >
      {!!subscriptionPackages.length && (
        <>
          <Table
            gridClassName="table-grid-template-columns"
            headers={[
              { text: 'Product Name' },
              {
                text: '# of Units',
                onClick: (): void => setSortField('numberOfUnits'),
                isSelected: field === 'numberOfUnits',
                isDescending,
              },
              {
                text: 'Completed',
                onClick: (): void => setSortField('lastUpdated'),
                isSelected: field === 'lastUpdated',
                isDescending,
              },
            ]}
            data={sortedAndFiltered.map((form) => [
              <Link to={`${PATHS.MY_DOCUMENTS}/${form.slug}`}>{form.product?.name || form.formData.programName}</Link>,
              form.formData.unitAmount,
              formatDateTime(new Date(form.formUpdatedAt)),
            ])}
          />
        </>
      )}
      {subscriptionPackages.length === 0 && (
        <div className="flex align-center column no-subscription-packages">
          <h1>You have no completed documents.</h1>
        </div>
      )}
    </Page>
  );
}
