import { AxiosError } from 'axios';
import { useStore } from 'react-redux';
import { RequestPayloads, Organization, RequestParams } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface ManageOrganizationHook {
  createOrganization(
    organization: RequestPayloads['createOrganization'],
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ): void;
  createNewOrganizationUser: (
    organization: Organization,
    newOrgUser: RequestPayloads['createNewOrganizationUser'],
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ) => void;
  addExistingUserToOrganization: (
    organization: Organization,
    user: RequestPayloads['addExistingUserToOrganization'],
    onError: (e: AxiosError) => void,
    onSuccess: (res: Organization) => void,
  ) => void;
  updateOrganization(
    organizationId: RequestParams['updateOrganization']['organizationId'],
    organization: RequestPayloads['updateOrganization'],
    needToUpdateCurrentUser: boolean,
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ): void;
}

export function useManageOrganization(): ManageOrganizationHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;

  async function createOrganization(
    organization: RequestPayloads['createOrganization'],
    onError: (e: Error) => void = (): void => undefined,
    onSuccess: (res: Organization) => void = (): void => undefined,
  ): Promise<void> {
    dispatch(Actions.createOrganization(organization, onError, onSuccess));
  }
  async function updateOrganization(
    organizationId: RequestParams['updateOrganization']['organizationId'],
    organization: RequestPayloads['updateOrganization'],
    needToUpdateCurrentUser: boolean,
    onError: (e: Error) => void = (): void => undefined,
    onSuccess: (res: Organization) => void = (): void => undefined,
  ): Promise<void> {
    dispatch(Actions.updateOrganization(organizationId, organization, needToUpdateCurrentUser, onError, onSuccess));
  }
  async function createNewOrganizationUser(
    organization: Organization,
    newOrgUser: RequestPayloads['createNewOrganizationUser'],
    onError: (e: Error) => void = (): void => undefined,
    onSuccess: (res: Organization) => void = (): void => undefined,
  ): Promise<void> {
    dispatch(Actions.createNewOrganizationUser(organization, newOrgUser, onError, onSuccess));
  }
  async function addExistingUserToOrganization(
    organization: Organization,
    user: RequestPayloads['addExistingUserToOrganization'] | null,
    onError: (e: AxiosError) => void = (): void => undefined,
    onSuccess: (res: Organization) => void = (): void => undefined,
  ): Promise<void> {
    if (!user) {
      return;
    }
    dispatch(Actions.addExistingUserToOrganization(organization, user, onError, onSuccess));
  }

  return {
    createOrganization,
    createNewOrganizationUser,
    addExistingUserToOrganization,
    updateOrganization,
  };
}
