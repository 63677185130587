import { ReactElement } from 'react';
import { Checkbox, isChecked } from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

import './styles.scss';

function TrustedContactPerson(): ReactElement {
  const formFields = useKYCFormFields();
  function clearTCPFields(e: React.ChangeEvent<HTMLInputElement>, value?: Checkbox): void {
    formFields.tcpDoNotDesignateCheckbox.onChange(e, value);
    if (isChecked(value)) {
      formFields.tcpEmail.onChange(e, undefined);
      formFields.tcpMailingAddressCity.onChange(e, undefined);
      formFields.tcpMailingAddressCountry.onChange(e, undefined);
      formFields.tcpMailingAddressPostalCode.onChange(e, undefined);
      formFields.tcpMailingAddressProvince.onChange(e, undefined);
      formFields.tcpMailingAddressStreet.onChange(e, undefined);
      formFields.tcpName.onChange(e, undefined);
      formFields.tcpPhone.onChange(e, undefined);
      formFields.tcpPhoneCountryCode.onChange(e, undefined);
      formFields.tcpRelationshipWithClient.onChange(e, undefined);
      formFields.tcpTitle.onChange(e, undefined);
    } else {
      formFields.tcpDoNotDesignateReason.onChange(e, undefined);
    }
  }
  return (
    <div className="TrustedContactPerson form-section">
      <div className="form-section">
        <h2 className="title">Trusted Contact Person</h2>
        <p>
          For clarity, if you have provided consent, EquiGenesis might contact your TCP if EquiGenesis notices signs of
          financial exploitation or if you exhibit signs that you lack mental capacity to make decisions involving
          financial matters. EquiGenesis might also contact your TCP to confirm your contact information if EquiGenesis
          is unsuccessful in contacting you after repeated attempts and where failure to contact you would be unusual.
          EquiGenesis may also ask your TCP to confirm the name and contact information of your legal guardian,
          executor, trustee, attorney under a power of attorney or any other legal representative.
        </p>
        <div className="form-section" />
        <div className="form-section">
          <div className="flex form-row spacer">
            <div className="no-flex">
              <EquiInput {...formFields.tcpDoNotDesignateCheckbox} onChange={clearTCPFields} />
            </div>
          </div>
          {isChecked(formFields.tcpDoNotDesignateCheckbox.value as Checkbox) && (
            <div className="flex form-row max-width">
              <EquiInput {...formFields.tcpDoNotDesignateReason} />
            </div>
          )}
          {!isChecked(formFields.tcpDoNotDesignateCheckbox.value as Checkbox) && (
            <>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpTitle} className="no-flex" noMaxWidth />
              </div>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpName} />
                <EquiInput {...formFields.tcpPhone} />
              </div>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpEmail} />
              </div>
              <h2 className="title header spacer">Mailing Address</h2>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpMailingAddressStreet} />
              </div>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpMailingAddressCity} />
                <EquiInput {...formFields.tcpMailingAddressProvince} />
              </div>
              <div className="flex form-row">
                <EquiInput {...formFields.tcpMailingAddressCountry} />
                <EquiInput {...formFields.tcpMailingAddressPostalCode} />
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.tcpRelationshipWithClient} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrustedContactPerson;
