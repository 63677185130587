import { FullUser } from 'shared';
import { ActionTypes } from '@actions';
import * as UsersActions from '@actions/users';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = {
  hasFetched: boolean;
  isLoading: boolean;
  data: FullUser[];
};

const initialState: State = {
  isLoading: false,
  data: [],
  hasFetched: false,
};

const getUsersReducer: Reducer<State> = (_state, action: UsersActions.GetUsers): State => {
  const users = action.payload;
  return {
    hasFetched: true,
    isLoading: false,
    data: users,
  };
};

const startGetUsersReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    isLoading: true,
    hasFetched: true,
  };
};

const resetHasFetchedUsers: Reducer<State> = (state = initialState) => {
  return {
    ...state,
    hasFetched: false,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return { ...initialState, hasFetched: true };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.GET_USERS]: getUsersReducer,
  [ActionTypes.START_GET_USERS]: startGetUsersReducer,
  [ActionTypes.RESET_HAS_FETCHED_USERS]: resetHasFetchedUsers,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
