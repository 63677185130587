import { TitleRadio, TrueFalseRadio, ValidationErrorItem, TCPNotes, KYCNotes } from 'shared';
import { Input } from '@hooks/useOfficeUseFormFields/types';

export const titleRadioField = {
  type: 'radio',
  formLabel: 'Title',
  options: [
    { displayName: 'Dr', value: TitleRadio.dr },
    { displayName: 'Mr', value: TitleRadio.mr },
    { displayName: 'Ms', value: TitleRadio.ms },
    { displayName: 'Mrs', value: TitleRadio.mrs },
    { displayName: 'Miss', value: TitleRadio.miss },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrors<T extends Record<string, any> & { errors: ValidationErrorItem[] }>(
  state: T,
  field: keyof Omit<T, 'errors'>,
): string {
  return (
    state?.errors
      .filter((error) => error.path === field)
      .map((error) => error.message)
      .join('. ') || ''
  );
}

export function OfficeUseYesNoRadioField<
  T extends keyof Omit<TCPNotes & KYCNotes, 'dealerKnownClientYearsTime' | 'tcpNotes' | 'tcpNotes'>
>(formLabel: string): Input<T> {
  return {
    type: 'radio',
    formLabel,
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  };
}
