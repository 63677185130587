import { ReactElement, useContext } from 'react';
import classNames from 'classnames';
import { BrandingContext } from '@contexts';
import { useScrollPosition } from '@hooks';
import Icon from '../Icon';

import './styles.scss';

export default function ScrollToBottomButton(): ReactElement | null {
  const { scrollPositionFromBottom, scrollToBottom } = useScrollPosition();
  const { showBrandingInNav } = useContext(BrandingContext);
  if (scrollPositionFromBottom < 300) {
    return null;
  }
  return (
    <div
      className={classNames('ScrollToBottomButton', { 'with-branding': showBrandingInNav })}
      onClick={scrollToBottom}
    >
      <div className="actual-button">
        <div>Scroll To Bottom</div>
        <Icon type="downArrow" />
      </div>
    </div>
  );
}
