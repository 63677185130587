import { ReactElement } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import BackButton from '@components/common/BackButton';
import OrgBrandingFloater from '@components/common/OrgBrandingFloater';
import FlashMessageOverlay from '@components/FlashMessageOverlay';
import Footer from '@components/Footer';
import Header from '@components/Header';
import LoadingOverlay from '@components/LoadingOverlay';
import SessionExpiredOverlay from '@components/SessionExpiredOverlay';
import { BrandingContext } from '@contexts';
import { useCurrentUser, useSmartBackButton } from '@hooks';
import { ClosedDocumentPage } from '@pages';
import { PATHS } from '@routes/lib';

import './styles.scss';

const App: React.FunctionComponent = ({ children }): ReactElement => {
  const { goBackLocation } = useSmartBackButton();
  const history = useHistory();
  const { doesCurrentUserOrgRequireBranding, currentUser } = useCurrentUser();
  const isDocuSignCallbackPage = history.location.pathname === PATHS.CLOSED_DOCUMENT_CALLBACK;
  if (isDocuSignCallbackPage) {
    return (
      <div className="App DocuSignCallback">
        <div className="main-container">
          <ClosedDocumentPage />
        </div>
      </div>
    );
  }
  return (
    <BrandingContext.Provider
      value={{ showBrandingInNav: doesCurrentUserOrgRequireBranding(), organization: currentUser?.organization }}
    >
      <div className={classNames('App', { branded: doesCurrentUserOrgRequireBranding() })}>
        <Header />
        <div className="main-container">
          {!goBackLocation && children}
          {goBackLocation && (
            <main className="PageLayout">
              <BackButton defaultLocation={PATHS.HOME} />
            </main>
          )}
          <Footer />
        </div>
        <div className="mobile-warning">
          <main className="PageLayout">
            <h1>Mobile devices and smaller screens are not supported.</h1>
            <h3>Please access this application from a Desktop/Laptop device</h3>
            <h3>
              (<a href={window.location.origin}>{window.location.origin}</a>)
            </h3>
          </main>
        </div>
        <LoadingOverlay />
        <SessionExpiredOverlay />
        <FlashMessageOverlay />
        {doesCurrentUserOrgRequireBranding() && currentUser?.organization && (
          <OrgBrandingFloater organization={currentUser.organization} />
        )}
      </div>
    </BrandingContext.Provider>
  );
};

export default App;
