import { ReactElement } from 'react';
import classnames from 'classnames';
import { FullUser, Organization } from 'shared';
import EquiButton from '@components/common/EquiButton';
import { useCurrentUser, useModal } from '@hooks';
import SetCCOModal from './SetCCOModal';

interface Props {
  organization: Organization;
  orgAdmins: FullUser[];
  onSubmit: (userId: string, onSuccess: (org: Organization) => void) => void;
}

function OrgCCO({ organization, orgAdmins, onSubmit }: Props): ReactElement {
  const { currentUser } = useCurrentUser();
  const { closeModal, isOpen, openModal } = useModal();
  const { cco } = organization;
  const hasCCO = !!cco;
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <h3 className="whitespace-nowrap">Chief Compliance Officer</h3>
        <span className="mx-4"> - </span>
        {cco ? (
          <div className={classnames({ 'font-bold': cco.user_id === currentUser?.user_id }, 'flex items-center')}>
            <div>
              {cco.user_id === currentUser?.user_id && '(you)'} {cco.name} ({cco.email})
            </div>
          </div>
        ) : (
          <em>No CCO Set</em>
        )}
      </div>
      <EquiButton onClick={openModal} className="ml-4" value={cco ? 'Change CCO' : 'Set a CCO'} />
      {isOpen && (
        <SetCCOModal
          closeModal={closeModal}
          organization={organization}
          users={orgAdmins}
          hasCCO={hasCCO}
          onSubmit={(userId): void => onSubmit(userId, closeModal)}
        />
      )}
    </div>
  );
}

export default OrgCCO;
