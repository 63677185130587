export function getModalRoot(rootId: string): HTMLElement | null {
  const root = document.getElementById(rootId);
  if (root) {
    return root;
  }
  const div = document.createElement('div');
  div.id = rootId;
  document.body.appendChild(div);
  return div;
}
