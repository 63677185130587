import { KYC } from 'shared';
import { ActionTypes } from '@actions';
import * as KYCFormActions from '@actions/kycForm';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  form: KYC | null;
  isLoading: boolean;
  hasFetched: boolean;
}

const initialState: State = { form: null, isLoading: false, hasFetched: false };

const setFormDataReducer: Reducer<State> = (state = initialState, action: KYCFormActions.SetKYCForm) => {
  return { ...state, form: action.payload, isLoading: false };
};

const resetFormReducer: Reducer<State> = () => {
  return {
    ...initialState,
  };
};

const startGetKYCFormReducer: Reducer<State> = (_, action: KYCFormActions.StartGetKYCForm) => {
  return {
    ...initialState,
    isLoading: action.payload,
    hasFetched: true,
  };
};

const setKYCFormDataReducer: Reducer<State> = (state = initialState, action: KYCFormActions.SetKYCFormData) => {
  if (!state.form) {
    return state;
  }
  return {
    ...state,
    form: {
      ...state.form,
      formData: action.payload,
    },
  };
};

const resetHasFetchedKYC: Reducer<State> = (state = initialState) => {
  return {
    ...state,
    hasFetched: false,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return {
    ...initialState,
    hasFetched: true,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.SET_KYC_FORM]: setFormDataReducer,
  [ActionTypes.RESET_KYC_FORM]: resetFormReducer,
  [ActionTypes.START_GET_KYC_FORM]: startGetKYCFormReducer,
  [ActionTypes.SET_KYC_FORM_DATA]: setKYCFormDataReducer,
  [ActionTypes.RESET_HAS_FETCHED_KYC]: resetHasFetchedKYC,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
