import { ReactElement } from 'react';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';
import './styles.scss';

function AccreditedInvestorStatus(): ReactElement {
  const formFields = useKYCFormFields();
  return (
    <div className="AccreditedInvestorStatus form-section flex column align-center">
      <h2 className="title">Accredited Investor Status</h2>
      <p className="text-center ai-description">
        You must meet at least one of the following criteria to be able to make this investment. Please select all
        criteria that apply to you.
      </p>
      <h3 className="text-left max-width">
        <strong>You are:</strong>
      </h3>
      <div className="max-width">
        <div className="flex form-row max-width">
          <EquiInput {...formFields.individualIncome200000} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.withSpouseIncome300000} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.financialAssetsOneMillion} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.beneficialFinancialAssetsFiveMillion} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.netAssetsFiveMillion} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.registeredRepresentative} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.formerlyRegisteredRepresentative} labelClassName="wrap" />
        </div>
        <div className="flex form-row max-width">
          <EquiInput {...formFields.designatedAccreditedInvestor} labelClassName="wrap" />
        </div>
      </div>
    </div>
  );
}

export default AccreditedInvestorStatus;
