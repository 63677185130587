import { ReactElement } from 'react';
import { filter } from 'shared';
import Brand from '@components/common/Brand';
import { renderCards, LinkCardData } from '@components/common/LinkCard';
import { useCurrentUser, useKYCByEmail, useSubscriptionPackagesByEmail } from '@hooks';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';
import './styles.scss';

export default function Home(): ReactElement {
  const {
    isCurrentUserSubscriber,
    isCurrentUserAdmin,
    isCurrentUserAgent,
    isCurrentUserEQ,
    currentUser,
  } = useCurrentUser();
  const { kyc } = useKYCByEmail((isCurrentUserSubscriber && currentUser?.email) || '');
  const { subscriptionPackages, isLoading } = useSubscriptionPackagesByEmail(
    (isCurrentUserSubscriber && currentUser?.email) || '',
    'completed',
  );
  const isUserNotSubscriber = isCurrentUserAdmin || isCurrentUserAgent || isCurrentUserEQ;
  const subscriberCards: LinkCardData[] = isCurrentUserSubscriber
    ? filter([
        {
          title: (
            <div className="flex column align-center">
              <h2>View My</h2>
              <h2>Client Registration</h2>
              <h2>Form</h2>
            </div>
          ),
          icon: 'kyc',
          to: kyc?.id
            ? (`${PATHS.SUBSCRIBERS}/${currentUser?.email}/client-registration` as ValidPath)
            : PATHS.NO_DOCUMENTS,
        },
        subscriptionPackages.length > 0 && {
          title: (
            <div className="flex column align-center">
              <h2>View Completed</h2>
              <h2>Documents</h2>
            </div>
          ),
          icon: 'signedDocument',
          to: PATHS.MY_DOCUMENTS,
        },
      ])
    : [];
  const cards = filter<LinkCardData>([
    isUserNotSubscriber && {
      title: (
        <div className="flex column align-center">
          <h2>New Client Registration</h2>
        </div>
      ),
      icon: 'newForm',
      to: PATHS.NEW_KYC,
      key: 'new-user',
    },
    isUserNotSubscriber && {
      title: (
        <div className="flex column align-center">
          <h2>View All</h2>
          <h2>Client Registration Forms</h2>
        </div>
      ),
      icon: 'kyc',
      to: PATHS.KYCS,
      key: 'kyc-forms',
    },
    isUserNotSubscriber && {
      title: (
        <div className="flex column align-center">
          <h2>View All</h2>
          <h2>Subscription Packages</h2>
        </div>
      ),
      icon: 'approveForm',
      to: PATHS.SUBSCRIPTION_PACKAGES,
      key: 'Subscription-Packages',
    },
  ]);
  return (
    <Page
      className="Home"
      renderHeader={(): ReactElement => (
        <div className="logo-container">
          <Brand size={278} />
        </div>
      )}
      isLoading={isLoading}
      showChildrenOnLoading={false}
    >
      {subscriberCards.length > 0 && renderCards(subscriberCards, `grid-${subscriberCards.length}`)}
      {renderCards(cards)}
    </Page>
  );
}
