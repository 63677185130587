import { SubscriptionPackage } from 'shared';
import { ActionTypes } from '@actions';
import * as SubscriptionPackageFormActions from '@actions/subscriptionPackageForm';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  form: SubscriptionPackage | null;
  isLoading: boolean;
  hasFetched: boolean;
}

const initialState: State = { form: null, isLoading: false, hasFetched: false };

const setFormDataReducer: Reducer<State> = (
  state = initialState,
  action: SubscriptionPackageFormActions.SetSubscriptionPackageForm,
) => {
  return { ...state, form: action.payload, isLoading: false };
};

const resetFormReducer: Reducer<State> = () => {
  return {
    ...initialState,
  };
};

const startGetSubscriptionPackageFormReducer: Reducer<State> = (
  _,
  action: SubscriptionPackageFormActions.StartGetSubscriptionPackageForm,
) => {
  return {
    ...initialState,
    isLoading: action.payload,
    hasFetched: true,
  };
};

const setSubscriptionPackageFormDataReducer: Reducer<State> = (
  state = initialState,
  action: SubscriptionPackageFormActions.SetSubscriptionPackageFormData,
) => {
  if (!state.form) {
    return state;
  }
  return {
    ...state,
    form: {
      ...state.form,
      formData: action.payload,
    },
  };
};

const resetHasFetchedSubscriptionPackage: Reducer<State> = (state = initialState) => {
  return {
    ...state,
    hasFetched: false,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return { ...initialState, hasFetched: true };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM]: setFormDataReducer,
  [ActionTypes.RESET_SUBSCRIPTION_PACKAGE_FORM]: resetFormReducer,
  [ActionTypes.START_GET_SUBSCRIPTION_PACKAGE_FORM]: startGetSubscriptionPackageFormReducer,
  [ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM_DATA]: setSubscriptionPackageFormDataReducer,
  [ActionTypes.RESET_HAS_FETCHED_SUBSCRIPTION_PACKAGE]: resetHasFetchedSubscriptionPackage,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
