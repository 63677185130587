import { ReactElement, useEffect, useState, createRef } from 'react';
import classnames from 'classnames';
import './styles.scss';
import { useOnClickOutside } from '@hooks';

interface Props {
  isOpen?: boolean;
  triggerComponent: React.ReactNode;
  menuComponent: React.ReactNode;
  className?: string;
  menuContainerClassName?: string;
  onTriggerClick?: (isOpen: boolean) => void;
}

export default function Dropdown({
  isOpen = false,
  triggerComponent,
  className,
  menuContainerClassName,
  menuComponent,
  onTriggerClick,
}: Props): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
  useEffect(() => {
    if (isOpen === isDropdownOpen) {
      setIsDropdownOpen(isOpen);
    }
  }, [isOpen]);
  function toggleDropdown(): void {
    const newIsOpen = !isDropdownOpen;
    setIsDropdownOpen(newIsOpen);
    if (onTriggerClick) {
      onTriggerClick(newIsOpen);
    }
  }

  function closeDropdown(): void {
    setIsDropdownOpen(false);
    if (onTriggerClick) {
      onTriggerClick(false);
    }
  }
  const ref = createRef<HTMLDivElement>();

  useOnClickOutside(ref, closeDropdown);

  return (
    <div ref={ref} className={classnames('Dropdown', className)}>
      <div className="trigger-container cursor-pointer" onClick={toggleDropdown}>
        {triggerComponent}
      </div>
      {isDropdownOpen && <div className={classnames(menuContainerClassName, 'menu-container')}>{menuComponent}</div>}
    </div>
  );
}
