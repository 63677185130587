import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { formatDateTime, regexEscape, Role, roleDisplay } from 'shared';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Table from '@components/common/Table';
import { useSort, useUsers } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

type sortByOptions = 'name' | 'email' | 'lastName';

export default function AdminViewUsers(): ReactElement {
  const { users, isLoading } = useUsers();
  const [roleFilter, setRoleFilter] = useState<Role | ''>('');
  const [search, setSearch] = useState<string>('');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('name', false);

  const filteredUsers = sortBy(
    users.filter(
      (user) =>
        (!roleFilter || user.app_metadata.authorization?.roles.includes(roleFilter)) &&
        (new RegExp(regexEscape(search?.trim()), 'i').test(user.name) ||
          new RegExp(regexEscape(search?.trim()), 'i').test(user.email)),
    ),
    (user) => {
      switch (field) {
        case 'email':
          return user.email.toLowerCase();
        case 'name':
        default:
          return (user.family_name.toLowerCase() || '') + (user.given_name.toLowerCase() || '');
      }
    },
  );

  if (isDescending) {
    filteredUsers.reverse();
  }
  return (
    <Page
      className="AdminViewUsers"
      renderHeader={(): ReactElement => <h1 className="text-center">Users</h1>}
      defaultBackLocation={PATHS.ADMIN_MANAGE_USERS}
      isLoading={isLoading}
      showChildrenOnLoading={users.length > 0}
    >
      <Table
        gridClassName="table-grid-template-columns"
        headers={[
          { text: 'Name', onClick: (): void => setSortField('name'), isSelected: field === 'name', isDescending },
          { text: 'Email', onClick: (): void => setSortField('email'), isSelected: field === 'email', isDescending },
          { text: 'Roles' },
          { text: 'Last Logged In' },
          { text: 'Account Active?' },
        ]}
        data={filteredUsers.map((user) => [
          <Link to={`${PATHS.ADMIN_MANAGE_USERS}/${user.email}`}>{user.name}</Link>,
          <Link to={`${PATHS.ADMIN_MANAGE_USERS}/${user.email}`}>{user.email}</Link>,
          user.app_metadata.authorization?.roles.map((role) => roleDisplay[role]).join(' | '),
          user.last_login ? formatDateTime(new Date(user.last_login)) : <em>never</em>,
          user.blocked ? 'No' : 'Yes',
        ])}
        renderFilters={(): ReactElement => (
          <>
            <GeneralInput
              formLabel="Search"
              placeholder="by name or email"
              onChange={((a: string): void => setSearch(a)) as OnChange}
              value={search}
            />
            <GeneralInput
              labelAsOption={false}
              formLabel="Filter by Role"
              onChange={((role: Role): void => setRoleFilter(role)) as OnChange}
              value={roleFilter}
              type="select"
              options={
                [
                  { displayName: 'All', value: '' },
                  { displayName: roleDisplay['admin'], value: 'admin' },
                  { displayName: roleDisplay['eq'], value: 'eq' },
                  { displayName: roleDisplay['agent'], value: 'agent' },
                  { displayName: roleDisplay['subscriber'], value: 'subscriber' },
                ] as { displayName: string; value: Role | '' }[]
              }
            />
          </>
        )}
      />
    </Page>
  );
}
