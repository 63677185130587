import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { canRemoveAgentRole, canRemoveSubscriberRole, FullUser, Role, roleDisplay } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useCurrentUser } from '@hooks';

import './styles.scss';

interface Props {
  className?: string;
  shouldShow: boolean;
  onClose: () => void;
  onSubmit: (modifiedRoles: { [Key in Role]: boolean }) => void;
  user: FullUser;
}

function ModifyRolesModal(props: Props): ReactElement {
  const { className, shouldShow, onClose, user, onSubmit } = props;
  const { currentUser } = useCurrentUser();
  const roles: Role[] = ['subscriber', 'agent', 'eq', 'admin'];
  const userRoles = user.app_metadata.authorization?.roles || [];
  const [assignedRoles, setAssignedRoles] = useState<{ [Key in Role]: boolean }>({
    admin: userRoles.includes('admin'),
    agent: userRoles.includes('agent'),
    eq: userRoles.includes('eq'),
    subscriber: userRoles.includes('subscriber'),
  });
  function toggleRole(role: Role): void {
    setAssignedRoles({
      ...assignedRoles,
      [role]: !assignedRoles[role],
    });
  }
  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={shouldShow}
      className={classnames('ModifyRolesModal', className)}
    >
      <Modal className="ModifyRolesModalBody" onOutsideClick={onClose} withClose>
        <h1>Modify Roles for {user.name}</h1>
        <div className="grid grid-2">
          {roles
            .filter((role) => user.user_id !== currentUser?.user_id || role !== 'admin')
            .map((role) => (
              <GeneralInput
                key={role}
                type="checkbox"
                name="role"
                value={role}
                formLabel={roleDisplay[role]}
                checked={assignedRoles[role]}
                onChange={(): void => toggleRole(role)}
                disabled={
                  (role === 'agent' && !canRemoveAgentRole(user)) ||
                  (role === 'subscriber' && !canRemoveSubscriberRole(user))
                }
              />
            ))}
        </div>
        {!canRemoveAgentRole(user) && (
          <p>
            <b>*</b>This user has clients assigned to them and so cannot be removed as an agent
          </p>
        )}
        {!canRemoveSubscriberRole(user) && (
          <p>
            <b>*</b>This user has agents assigned to them and so cannot be removed as a subscriber
          </p>
        )}
        <EquiButton value="Change Roles" onClick={(): void => onSubmit(assignedRoles)} />
      </Modal>
    </Overlay>
  );
}

export default ModifyRolesModal;
