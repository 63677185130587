import { ReactElement } from 'react';
import { LinkCardData, renderCards } from '@components/common/LinkCard';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

export default function AdminAddUsers(): ReactElement {
  const cards: LinkCardData[] = [
    {
      to: PATHS.ADMIN_ADD_ADMIN,
      icon: 'admin',
      title: 'Add Admin',
    },
    {
      to: PATHS.ADMIN_ADD_EQ,
      icon: 'logo',
      title: 'Add EQ Employee',
    },
    {
      to: PATHS.ADMIN_ADD_AGENT,
      icon: 'agent',
      title: 'Add Agent',
    },
  ];
  return (
    <Page
      className="AdminAddUsers"
      renderHeader={(): ReactElement => <h1 className="text-center">Select Which User Type To Add</h1>}
      defaultBackLocation={PATHS.ADMIN_MANAGE_USERS}
    >
      {renderCards(cards)}
    </Page>
  );
}
