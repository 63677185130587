import { ReactElement } from 'react';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function BankingInformation(): ReactElement {
  const formFields = useKYCFormFields();
  return (
    <div className="form-section">
      <h2 className="title">Banking Information</h2>
      <div className="flex form-row">
        <EquiInput {...formFields.bankName} />
        <EquiInput {...formFields.accountManager} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.branchNum} />
        <EquiInput {...formFields.accountManagerEmail} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.branchAddress} />
        <EquiInput {...formFields.bankPhone} />
      </div>
    </div>
  );
}

export default BankingInformation;
