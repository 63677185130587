import { ReactElement } from 'react';
import { RouteProps } from 'react-router-dom';
import { getEnvValue } from 'shared';
import { CurrentUserHook } from '@hooks';
import {
  Home,
  SubscriptionPackagePage,
  SubscriptionPackagesPage,
  SubscriberSubscriptionPackagesPage,
  NotFound,
  NoDocuments,
  AdminDashboard,
  AdminAddUsers,
  AdminManageUsers,
  WinterpegScenarioCPhase1,
  WinterpegScenarioCPhase2,
  AdminAddAdmins,
  AdminAddAgents,
  AdminAddEQEmployees,
  AdminViewUsers,
  AdminViewSingleUser,
  ViewAgentsPage,
  ViewSubscribersPage,
  ViewSubscriberPage,
  KYCPage,
  OfficeUsePage,
  AdminSiteSettings,
  MyProfile,
  SubscriptionPackageExecutedCopyPage,
  ErrorPage,
  ViewOrganizationPage,
  ViewOrganizationsPage,
  KYCsPage,
  NewKYCPage,
  ViewAgentPage,
  LogoutPage,
  MyDocumentsPage,
  WinterpegDashboard,
  WinterpegScenarioB,
  WinterpegScenarioCEQ2013,
} from '@pages';

export const BASE_PATHS = {
  HOME: '/',
  AGENTS: '/agents',
  KYCS: '/client-registration-forms',
  OFFICE_USES: '/office-uses',
  SUBSCRIBERS: '/subscribers',
  SUBSCRIPTION_PACKAGES: '/subscription-packages',
  ADMIN: '/admin',
  NOT_FOUND: '/404',
  NO_DOCUMENTS: '/no-documents',
  MY_DOCUMENTS: '/my-documents',
  MY_PROFILE: '/my-profile',
  CLOSED_DOCUMENT_CALLBACK: '/closed-document-callback',
  ERROR: '/error',
  ORGANIZATIONS: '/organizations',
  LOGOUT: '/logout',
} as const;

const winterpegRoutePrefix = `${BASE_PATHS.ADMIN}/winterpeg` as const;
const scenarioCRoutePrefix = `${winterpegRoutePrefix}/scenario-c` as const;

export const PATHS = {
  ...BASE_PATHS,
  MY_SUBSCRIPTION_PACKAGE: `${BASE_PATHS.MY_DOCUMENTS}/:slug`,
  SUBSCRIPTION_PACKAGE: `${BASE_PATHS.SUBSCRIPTION_PACKAGES}/:slug`,
  SUBSCRIPTION_PACKAGE_DOCUSIGN_DOCUMENT: `${BASE_PATHS.SUBSCRIPTION_PACKAGES}/:slug/docusign`,
  ADMIN_SITE_SETTINGS: `${BASE_PATHS.ADMIN}/site-settings`,
  ADMIN_MANAGE_USERS: `${BASE_PATHS.ADMIN}/users`,
  ADMIN_WINTERPEG: winterpegRoutePrefix,
  ADMIN_WINTERPEG_SCENARIO_B: `${winterpegRoutePrefix}/scenario-b`,
  ADMIN_WINTERPEG_SCENARIO_C_PHASE_1: `${scenarioCRoutePrefix}/phase-1`,
  ADMIN_WINTERPEG_SCENARIO_C_PHASE_2: `${scenarioCRoutePrefix}/phase-2`,
  ADMIN_WINTERPEG_SCENARIO_C_EQ_2013: `${scenarioCRoutePrefix}/eq-2013`,
  ADMIN_USERS_ADD: `${BASE_PATHS.ADMIN}/users/add`,
  ADMIN_ADD_ADMIN: `${BASE_PATHS.ADMIN}/users/add/admin`,
  ADMIN_ADD_AGENT: `${BASE_PATHS.ADMIN}/users/add/agent`,
  ADMIN_ADD_EQ: `${BASE_PATHS.ADMIN}/users/add/eq`,
  ADMIN_VIEW_USERS: `${BASE_PATHS.ADMIN}/users/all`,
  ADMIN_USER_ROLES: `${BASE_PATHS.ADMIN}/users/roles`,
  ADMIN_USER_SHOW: `${BASE_PATHS.ADMIN}/users/:email`,
  VIEW_AGENT: `${BASE_PATHS.AGENTS}/:email`,
  NEW_KYC: `${BASE_PATHS.KYCS}/new`,
  KYC: `${BASE_PATHS.SUBSCRIBERS}/:email/client-registration`,
  OFFICE_USE: `${BASE_PATHS.OFFICE_USES}/:email/office-use`,
  SUBSCRIBER_SUBSCRIPTION_PACKAGES: `${BASE_PATHS.SUBSCRIBERS}/:email/subscription-packages`,
  VIEW_SUBSCRIBER: `${BASE_PATHS.SUBSCRIBERS}/:email`,
  VIEW_ORGANIZATION: `${BASE_PATHS.ORGANIZATIONS}/:slug`,
} as const;

export type ValidPath = typeof PATHS[keyof typeof PATHS] | '#';

export interface Route extends RouteProps {
  path: string;
  component: React.ComponentType;
  isAuth?: (currentUserInfo: CurrentUserHook) => boolean;
}

export const routes: Route[] = [
  {
    exact: true,
    path: PATHS.HOME,
    component: Home,
  },
  {
    exact: true,
    path: PATHS.MY_PROFILE,
    component: MyProfile,
  },
  {
    exact: true,
    path: PATHS.SUBSCRIPTION_PACKAGES,
    component: SubscriptionPackagesPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriptionPackage']) || hasAllPermissions(['read:ownSubscriberSubscriptionPackage']),
  },
  {
    exact: true,
    path: PATHS.SUBSCRIPTION_PACKAGE,
    component: SubscriptionPackagePage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriptionPackage']) || hasAllPermissions(['read:ownSubscriberSubscriptionPackage']),
  },
  {
    exact: true,
    path: PATHS.SUBSCRIPTION_PACKAGE_DOCUSIGN_DOCUMENT,
    component: SubscriptionPackageExecutedCopyPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriptionPackage']) || hasAllPermissions(['read:ownSubscriberSubscriptionPackage']),
  },
  {
    exact: true,
    path: PATHS.MY_SUBSCRIPTION_PACKAGE,
    component: SubscriptionPackageExecutedCopyPage,
  },
  {
    exact: true,
    path: PATHS.NOT_FOUND,
    component: NotFound,
  },
  {
    exact: true,
    path: PATHS.NO_DOCUMENTS,
    component: NoDocuments,
  },
  {
    exact: true,
    path: PATHS.ADMIN,
    component: AdminDashboard,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    exact: true,
    path: PATHS.ADMIN_WINTERPEG,
    component: WinterpegDashboard,
    isAuth: ({ isCurrentUserAdmin, hasAllPermissions }: CurrentUserHook): boolean =>
      getEnvValue('SHOW_TERMINATION_ADD_ON') && isCurrentUserAdmin && hasAllPermissions(['admin:run-termination']),
  },
  {
    exact: true,
    path: PATHS.ADMIN_WINTERPEG_SCENARIO_B,
    component: WinterpegScenarioB,
    isAuth: ({ isCurrentUserAdmin, hasAllPermissions }: CurrentUserHook): boolean =>
      getEnvValue('SHOW_TERMINATION_ADD_ON') && isCurrentUserAdmin && hasAllPermissions(['admin:run-termination']),
  },
  {
    exact: true,
    path: PATHS.ADMIN_WINTERPEG_SCENARIO_C_PHASE_1,
    component: WinterpegScenarioCPhase1,
    isAuth: ({ isCurrentUserAdmin, hasAllPermissions }: CurrentUserHook): boolean =>
      getEnvValue('SHOW_TERMINATION_ADD_ON') && isCurrentUserAdmin && hasAllPermissions(['admin:run-termination']),
  },
  {
    exact: true,
    path: PATHS.ADMIN_WINTERPEG_SCENARIO_C_PHASE_2,
    component: WinterpegScenarioCPhase2,
    isAuth: ({ isCurrentUserAdmin, hasAllPermissions }: CurrentUserHook): boolean =>
      getEnvValue('SHOW_TERMINATION_ADD_ON') && isCurrentUserAdmin && hasAllPermissions(['admin:run-termination']),
  },
  {
    exact: true,
    path: PATHS.ADMIN_WINTERPEG_SCENARIO_C_EQ_2013,
    component: WinterpegScenarioCEQ2013,
    isAuth: ({ isCurrentUserAdmin, hasAllPermissions }: CurrentUserHook): boolean =>
      getEnvValue('SHOW_TERMINATION_ADD_ON') && isCurrentUserAdmin && hasAllPermissions(['admin:run-termination']),
  },
  {
    path: PATHS.ADMIN_MANAGE_USERS,
    exact: true,
    component: AdminManageUsers,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.NEW_KYC,
    exact: true,
    component: NewKYCPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['create:kyc', 'create:subscriber']) || hasAllPermissions(['create:ownSubscriberKYC']),
  },
  {
    path: PATHS.ADMIN_USERS_ADD,
    exact: true,
    component: AdminAddUsers,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_ADD_ADMIN,
    exact: true,
    component: AdminAddAdmins,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_ADD_AGENT,
    exact: true,
    component: AdminAddAgents,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_ADD_EQ,
    exact: true,
    component: AdminAddEQEmployees,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_VIEW_USERS,
    exact: true,
    component: AdminViewUsers,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_SITE_SETTINGS,
    exact: true,
    component: AdminSiteSettings,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_USER_ROLES,
    exact: true,
    component: AdminAddUsers,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ADMIN_USER_SHOW,
    exact: true,
    component: AdminViewSingleUser,
    isAuth: ({ isCurrentUserAdmin }: CurrentUserHook): boolean => isCurrentUserAdmin,
  },
  {
    path: PATHS.ORGANIZATIONS,
    exact: true,
    component: ViewOrganizationsPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean => hasAllPermissions(['read:organization']),
  },
  {
    path: PATHS.VIEW_ORGANIZATION,
    exact: true,
    component: ViewOrganizationPage,
    isAuth: ({
      hasAtLeastOnePermission,
      isCurrentUserOwnOrgAdmin,
      isCurrentUserOwnOrgAgent,
      isCurrentUserOwnOrgReviewer,
    }: CurrentUserHook): boolean =>
      hasAtLeastOnePermission(['read:organization', 'read:ownOrganization']) ||
      isCurrentUserOwnOrgAdmin() ||
      isCurrentUserOwnOrgAgent() ||
      isCurrentUserOwnOrgReviewer(),
  },
  {
    path: PATHS.AGENTS,
    exact: true,
    component: ViewAgentsPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean => hasAllPermissions(['read:agent', 'read:relationship']),
  },
  {
    path: PATHS.VIEW_AGENT,
    exact: true,
    component: ViewAgentPage,
    isAuth: ({ hasAllPermissions, isCurrentUserOwnOrgAdmin, isCurrentUserOwnOrgReviewer }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:agent', 'read:relationship']) ||
      (hasAllPermissions(['read:agent']) && (isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer())),
  },
  {
    path: PATHS.SUBSCRIBERS,
    exact: true,
    component: ViewSubscribersPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriber', 'read:relationship']) ||
      hasAllPermissions(['read:ownSubscriber', 'read:ownRelationship']),
  },
  {
    path: PATHS.VIEW_SUBSCRIBER,
    exact: true,
    component: ViewSubscriberPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriber', 'read:relationship']) ||
      hasAllPermissions(['read:ownSubscriber', 'read:ownRelationship']),
  },
  {
    path: PATHS.KYCS,
    exact: true,
    component: KYCsPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:kyc', 'read:subscriber']) ||
      hasAllPermissions(['read:ownSubscriber', 'read:ownSubscriberKYC']),
  },
  {
    path: PATHS.KYC,
    exact: true,
    component: KYCPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:kyc', 'read:subscriber']) ||
      hasAllPermissions(['read:ownSubscriber', 'read:ownSubscriberKYC']) ||
      hasAllPermissions(['read:ownKYC']),
  },
  {
    path: PATHS.OFFICE_USE,
    exact: true,
    component: OfficeUsePage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:officeUse', 'read:subscriber']) ||
      hasAllPermissions(['read:ownSubscriber', 'read:ownSubscriberOfficeUse']),
  },
  {
    path: PATHS.SUBSCRIBER_SUBSCRIPTION_PACKAGES,
    exact: true,
    component: SubscriberSubscriptionPackagesPage,
    isAuth: ({ hasAllPermissions }: CurrentUserHook): boolean =>
      hasAllPermissions(['read:subscriptionPackage']) || hasAllPermissions(['read:ownSubscriberSubscriptionPackage']),
  },
  {
    path: PATHS.MY_DOCUMENTS,
    exact: true,
    component: MyDocumentsPage,
  },
  {
    path: PATHS.ERROR,
    exact: true,
    component: (): ReactElement => <ErrorPage />,
  },
  {
    path: PATHS.LOGOUT,
    exact: true,
    component: LogoutPage,
  },
];
