import { ReactElement } from 'react';
import { MaritalStatusRadio } from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function FamilyInformation(): ReactElement {
  const formFields = useKYCFormFields();
  function updateMaritalStatus(e: React.ChangeEvent<HTMLInputElement>, value?: MaritalStatusRadio): void {
    const currentStatus = formFields.maritalStatus.value;
    formFields.maritalStatus.onChange(e, value);
    if (value === MaritalStatusRadio.single) {
      formFields.spouseName.onChange(e, 'N/A');
      formFields.spouseEmployer.onChange(e, 'N/A');
      formFields.spouseOccupation.onChange(e, 'N/A');
      formFields.spouseTypeOfBusiness.onChange(e, 'N/A');
      formFields.spouseLastYearTaxableIncome.onChange(e, undefined);
      formFields.spouseTwoYearTaxableIncome.onChange(e, undefined);
      formFields.spouseThisYearExpectedIncome.onChange(e, undefined);
    }
    if (value !== MaritalStatusRadio.single && currentStatus === MaritalStatusRadio.single) {
      formFields.spouseName.onChange(e, '');
      formFields.spouseEmployer.onChange(e, '');
      formFields.spouseOccupation.onChange(e, '');
      formFields.spouseTypeOfBusiness.onChange(e, '');
    }
  }
  return (
    <div className="form-section">
      <h2 className="title">Family Information</h2>
      <div className="flex form-row">
        <div className="justify-center align-center no-flex">
          <EquiInput {...formFields.maritalStatus} className="no-flex" onChange={updateMaritalStatus} noMaxWidth />
        </div>
      </div>
      {formFields.maritalStatus.value !== MaritalStatusRadio.single && (
        <>
          <div className="flex form-row">
            <EquiInput {...formFields.spouseName} />
            <EquiInput {...formFields.spouseEmployer} />
          </div>
          <div className="flex form-row">
            <EquiInput {...formFields.spouseOccupation} />
            <EquiInput {...formFields.spouseTypeOfBusiness} />
          </div>
        </>
      )}
      <div className="flex form-row">
        <EquiInput {...formFields.dependants} />
        <div className="max-width"></div>
      </div>
    </div>
  );
}

export default FamilyInformation;
