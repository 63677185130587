import { AxiosError } from 'axios';
import { Action } from '../types';

export enum ActionTypes {
  HANDLE_AXIOS_ERROR = 'HANDLE_AXIOS_ERROR',
}

export interface HandleAxiosError extends Action {
  type: typeof ActionTypes.HANDLE_AXIOS_ERROR;
  payload: AxiosError;
}
