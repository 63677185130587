import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from '@routes/lib';

interface Props {
  logout(): void;
}

export default function Menu({ logout }: Props): ReactElement {
  return (
    <div className="UserDropdownMenu">
      <Link className="nav-link" to={PATHS.MY_PROFILE}>
        Profile & Settings
      </Link>
      <div onClick={logout} className="nav-link">
        Logout
      </div>
    </div>
  );
}
