import { InsiderInformation, TrueFalseRadio } from 'shared';
import { Fields, ProtoFields } from '../types';

export const insiderInformationFields: ProtoFields<keyof Fields<keyof InsiderInformation>> = {
  insider: {
    type: 'radio',
    formLabel: 'Are you an insider of a reporting issuer or any other issuer whose securities are publicly traded?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  reportingIssuer: { formLabel: 'Identify Issuer' },
};
