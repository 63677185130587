import { useStore } from 'react-redux';
import { CreateUserReqPayload, CreateUserResPayload } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface ManageUserHook {
  createUser(
    user: CreateUserReqPayload,
    onError: (e: Error) => void,
    onSuccess: (res: CreateUserResPayload) => void,
  ): void;
}

export function useManageUser(): ManageUserHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;

  async function createUser(
    user: CreateUserReqPayload,
    onError: (e: Error) => void = (): void => undefined,
    onSuccess: (res: CreateUserResPayload) => void = (): void => undefined,
  ): Promise<void> {
    dispatch(Actions.createUser(user, onError, onSuccess));
  }

  return { createUser };
}
