/* eslint-disable @typescript-eslint/no-explicit-any */

export function debounce(
  func: (...args: any[]) => void | Promise<any>,
  wait: number,
  immediate = false,
): (...args: any[]) => void | Promise<any> {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]): any {
    return new Promise((resolve, reject) => {
      try {
        const later = function (): any {
          timeout = null;
          if (!immediate) {
            resolve(func(...args));
          }
        };
        const callNow = immediate && !timeout;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
        if (callNow) {
          resolve(func(...args));
        }
      } catch (e) {
        reject(e);
      }
    });
  };
}
