import { ReactElement } from 'react';
import { LinkCardData, renderCards } from '@components/common/LinkCard';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import './styles.scss';

export default function Winterpeg(): ReactElement {
  const cards: (LinkCardData | false)[] = [
    {
      title: 'Scenario C - Phase 1',
      to: PATHS.ADMIN_WINTERPEG_SCENARIO_C_PHASE_1,
      icon: 'forwardForm',
    },
    {
      title: 'Scenario C - Phase 2',
      to: PATHS.ADMIN_WINTERPEG_SCENARIO_C_PHASE_2,
      icon: 'forwardForm',
    },
    {
      title: 'Scenario C - EQ 2013',
      to: PATHS.ADMIN_WINTERPEG_SCENARIO_C_EQ_2013,
      icon: 'forwardForm',
    },
    {
      title: 'Scenario B',
      to: PATHS.ADMIN_WINTERPEG_SCENARIO_B,
      icon: 'forwardForm',
    },
  ];
  return (
    <Page
      className="Winterpeg"
      renderHeader={(): ReactElement => <h1 className="text-center">Winterpeg Add-On</h1>}
      defaultBackLocation={PATHS.HOME}
    >
      {renderCards(cards, 'admin-cards')}
    </Page>
  );
}
