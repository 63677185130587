import { ReactElement } from 'react';
import Icon from '@components/common/Icon';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import StartForm from '@components/StartForm';
import { useModal } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

export default function NewKYCPage(): ReactElement {
  const { isOpen, openModal, closeModal } = useModal();
  return (
    <Page
      defaultBackLocation={PATHS.HOME}
      renderHeader={(): ReactElement => (
        <div className="flex justify-center">
          <div className="">
            <h1>New Subscriber</h1>
            <h3 className="flex align-center justify-center mb-0">
              Use this form to create a new subscriber in the platform
              <Icon
                className="info-icon pointer flex justify-center align-center"
                onClick={openModal}
                type="infoIcon"
              />
            </h3>
          </div>
        </div>
      )}
      className="NewKYCPage"
    >
      <div className="flex flex-1 justify-center width-80">
        <StartForm />
      </div>
      {isOpen && (
        <Overlay showOverlay={isOpen} rootId="create-kyc-info">
          <Modal className="CreateNewKYCMoreInfoModal" onOutsideClick={closeModal} withClose>
            <h2 className="text-center">Information on Adding a new Subscriber</h2>
            <p>
              An account for the subscriber will be created when this form is successfully submitted, but they will not
              be invited to the platform until a later step. This will allow you to create and fill the subscriber's
              information into his/her Client Registration Form before inviting them to verify the Client Registration
              information (if any) and complete the missing info.
            </p>
            <p>If you are an agent, then you will be assigned as the agent for this subscriber.</p>
            <p>
              If a subscriber with that email already exists, then please find that subscriber's profile in the
              Subscribers tab. If you are an agent and do not see the subscriber in the Subscribers tab, it is because
              you are not currently assigned as his/her agent. Please reach out to EquiGenesis for help.
            </p>
            <p>
              NOTE: This will also begin a new Client Registration Form for the subscriber. The Subscriber's name and
              email will not be editable after this point.
            </p>
          </Modal>
        </Overlay>
      )}
    </Page>
  );
}
