import { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  CreateUserResPayload,
  Role,
  validateCreateUser,
  ValidationErrorItem,
  roleDisplay,
  isSequelizeValidationError,
  CreateUserReqPayload,
  Optional,
  slugify,
  EQUI_ORG_NAME,
  OrganizationRoles,
} from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Loading from '@components/common/Loading';
import OrganizationRolesFormFields from '@components/common/OrganizationRolesFormFields';
import UserPermissionTypesModal from '@components/Modals/UserPermissionTypesModal';
import { useManageUser, useOrganizations } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import { getErrors } from '@utils/formFields';
import { Option } from '@utils/select';

import './styles.scss';

interface State extends Optional<Omit<CreateUserReqPayload, 'organizationId'>, 'organizationRoles'> {
  selectedOrganization: Option<number> | undefined | null;
  errors: ValidationErrorItem[];
}

const getInitialFormState = ({ isAdmin }: { isAdmin: boolean }): State => ({
  firstName: '',
  lastName: '',
  email: '',
  selectedOrganization: null,
  organizationRoles: isAdmin
    ? {
        isAdmin: false,
        isAgent: false,
        isClient: false,
        isReviewer: false,
      }
    : undefined,
  errors: [],
  roles: [],
});

interface Props {
  role: Role;
}

function prepareStateForValidation(state: State): CreateUserReqPayload {
  return {
    ...state,
    organizationId: state.selectedOrganization?.value,
  };
}

export default function AdminAddUserType({ role }: Props): ReactElement {
  const { isLoading, organizations } = useOrganizations({ refresh: true });
  const isAdmin = role === 'admin';
  const isEq = role === 'eq';
  const { createUser } = useManageUser();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState<State>({ ...getInitialFormState({ isAdmin }), roles: [role] });
  useEffect(() => {
    if (isEq && organizations.length) {
      const equiOrg = organizations.find((org) => org.slug === slugify(EQUI_ORG_NAME));
      setState((state) => ({
        ...state,
        selectedOrganization: equiOrg ? { value: equiOrg.id, label: equiOrg.name } : null,
      }));
    }
  }, [isEq, organizations.length]);

  function openModal(): void {
    setIsModalOpen(true);
  }
  function closeModal(): void {
    setIsModalOpen(false);
  }
  function onChange(field: keyof Omit<State, 'errors'>): OnChange {
    return ((value?: string): void => {
      const newState = { ...state, [field]: value };
      try {
        validateCreateUser(prepareStateForValidation(newState), !isAdmin);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    }) as OnChange;
  }
  function addUser(): void {
    try {
      validateCreateUser(prepareStateForValidation(state), !isAdmin);
      if (!isAdmin && !state.selectedOrganization) {
        return setState((state) => ({
          ...state,
          errors: [new ValidationErrorItem('Please select an organization', undefined, 'selectedOrganization')],
        }));
      }
      setState({ ...state, errors: [] });
      createUser(
        prepareStateForValidation(state),
        () => undefined,
        (res: CreateUserResPayload) => {
          history.push(`${PATHS.ADMIN_MANAGE_USERS}/${res.email}`);
        },
      );
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        setState({ ...state, errors: e.errors });
      }
    }
  }
  function onChangeRole(field: keyof OrganizationRoles): OnChange {
    return ((value?: string): void => {
      const newState = {
        ...state,
        organizationRoles: {
          isAdmin: state.organizationRoles?.isAdmin || false,
          isClient: state.organizationRoles?.isClient || false,
          isAgent: state.organizationRoles?.isAgent || false,
          isReviewer: state.organizationRoles?.isReviewer || false,
          [field]: value || false,
        },
      };
      try {
        validateCreateUser(prepareStateForValidation(newState));
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    }) as OnChange;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Page
      className="AdminAddUserType"
      renderHeader={(): ReactElement => <h1 className="text-center">Add a new {roleDisplay[role]} User</h1>}
      defaultBackLocation={PATHS.ADMIN_USERS_ADD}
    >
      <p>
        To see what permissions this user will have,{' '}
        <Link to="#" onClick={openModal}>
          <strong>click here</strong>
        </Link>
      </p>
      <div className="flex justify-center max-width">
        <Form className="AddUserForm">
          <GeneralInput
            formLabel="First Name"
            value={state.firstName}
            onChange={onChange('firstName')}
            error={getErrors(state, 'firstName')}
          />
          <GeneralInput
            formLabel="Last Name"
            value={state.lastName}
            onChange={onChange('lastName') as OnChange}
            error={getErrors(state, 'lastName')}
          />
          <GeneralInput
            formLabel="Email"
            value={state.email}
            type="email"
            onChange={onChange('email') as OnChange}
            error={getErrors(state, 'email')}
          />
          {!isAdmin && (
            <>
              <div className="left w-full">
                <label className="label">Organization</label>
              </div>
              <div className="w-full text-black">
                <Select<Option<number>>
                  classNamePrefix="select"
                  value={state.selectedOrganization}
                  options={organizations.map((org) => ({ label: org.name, value: org.id }))}
                  onChange={(selectedOrganization): void => setState((state) => ({ ...state, selectedOrganization }))}
                  isDisabled={isEq}
                />
              </div>
              <OrganizationRolesFormFields
                organizationRoles={state.organizationRoles}
                onChangeRole={onChangeRole}
                isClientDisabled={true}
              />
            </>
          )}
          <EquiButton
            kind={ButtonType.secondary}
            value={`Create ${roleDisplay[role]} User`}
            onClick={addUser}
            disabled={state.errors.length > 0}
          />
        </Form>
      </div>
      <UserPermissionTypesModal shouldShow={isModalOpen} onClose={closeModal} />
    </Page>
  );
}
