import { isChecked, OfficeUse, TrueFalseRadio } from 'shared';

function isValidTrueFalseAnswer(val: TrueFalseRadio | undefined): val is TrueFalseRadio {
  if (val === undefined) {
    return false;
  }
  return [TrueFalseRadio.true, TrueFalseRadio.false].includes(val);
}

export function isFormComplete(form?: OfficeUse['formData']): boolean {
  if (!form) return false;
  return (
    isValidTrueFalseAnswer(form.accreditedInvestorStatusConfirmed) &&
    isValidTrueFalseAnswer(form.clientsInsiderStatusExplainedToHaveNoBearing) &&
    isValidTrueFalseAnswer(form.clientsRoleExplained) &&
    isValidTrueFalseAnswer(form.conflictsOfInterestCanvasedWithClient) &&
    isValidTrueFalseAnswer(form.dealerMetClientInPerson) &&
    isValidTrueFalseAnswer(form.pepStatusExplained) &&
    !!form.dealerKnownClientYearsTime &&
    form.dealerKnownClientYearsTime > 0 &&
    !!form.kycNotes &&
    (isChecked(form.shouldOmitTCP) ||
      (isValidTrueFalseAnswer(form.explainedTCPDesignationPurpose) &&
        isValidTrueFalseAnswer(form.describeEquigenesisInformationDisclosureCircumstances) &&
        isValidTrueFalseAnswer(form.encouragedClientToNameTCP) &&
        isValidTrueFalseAnswer(form.askedClientToDesignateTCP) &&
        isValidTrueFalseAnswer(form.encouragedClientToSelectNonInvolvedIndividual) &&
        !!form.tcpNotes))
  );
}
