import { State } from '@reducers/auth';
import { Action } from '../types';

export enum ActionTypes {
  SAVE_AUTH = 'SAVE_AUTH',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SETUP_AXIOS = 'SETUP_AXIOS',
  START_LOGOUT = 'START_LOGOUT',
  START_GET_AUTH_TOKEN = 'START_GET_AUTH_TOKEN',
  RESET_HAS_FETCHED_TOKEN = 'RESET_HAS_FETCHED_TOKEN',
  RESET_APP = 'RESET_APP',
}

export interface SaveAuth extends Action {
  type: typeof ActionTypes.SAVE_AUTH;
  payload: Pick<State, 'token' | 'user' | 'isAuthenticated'>;
}

export interface SetupAxios extends Action {
  type: typeof ActionTypes.SETUP_AXIOS;
}

export interface SessionExpired extends Action {
  type: typeof ActionTypes.SESSION_EXPIRED;
}

export interface StartLogout extends Action {
  type: typeof ActionTypes.START_LOGOUT;
}

export interface StartGetAuthToken extends Action {
  type: typeof ActionTypes.START_GET_AUTH_TOKEN;
}
export interface ResetHasFetchedToken extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_TOKEN;
}

export interface ResetApp extends Action {
  type: typeof ActionTypes.RESET_APP;
}
