import { useEffect, useReducer } from 'react';
import { useStore } from 'react-redux';
import {
  FullUser,
  isAxiosError,
  isSequelizeValidationError,
  Organization,
  OrganizationRoles,
  ValidationError,
} from 'shared';
import { Actions as AppActions, Dispatch } from '@actions';
import { ManageOrganizationAPI } from '@api';
import { State } from '@store';
import { usePageChange } from '../usePageChange';

export interface SingleOrganizationHookState {
  organization: Organization | null;
  isLoading: boolean;
  hasFetched: boolean;
  error: boolean;
}

export interface SingleOrganizationHook extends SingleOrganizationHookState {
  replaceOrganization: (org: Organization) => void;
  updateOrgUserRoles: (
    user: FullUser,
    organizationRoles: OrganizationRoles,
    onSuccess: (org: Organization) => void,
    onError: (e: ValidationError) => void,
  ) => Promise<void>;
  setOrgCCO: (userId: string, onSuccess: (org: Organization) => void) => Promise<void>;
}

type Actions =
  | { type: 'START_GET_ORGANIZATION' }
  | { type: 'RESET' }
  | { type: 'GET_ORGANIZATION'; payload: Organization | null }
  | { type: 'ERROR_404' }
  | { type: 'ERROR' };

const initialState: SingleOrganizationHookState = {
  hasFetched: false,
  isLoading: false,
  organization: null,
  error: false,
};

export function useSingleOrganization(slug?: string): SingleOrganizationHook {
  const { dispatch: reduxDispatch } = useStore<State, AppActions>();
  const appDispatch = reduxDispatch as Dispatch;
  const [state, dispatch] = useReducer(
    (state: SingleOrganizationHookState, action: Actions): SingleOrganizationHookState => {
      switch (action.type) {
        case 'RESET':
          return initialState;
        case 'START_GET_ORGANIZATION':
          return {
            ...state,
            isLoading: true,
          };
        case 'GET_ORGANIZATION':
          return {
            hasFetched: true,
            isLoading: false,
            organization: action.payload,
            error: false,
          };
        case 'ERROR_404':
          return {
            ...state,
            hasFetched: true,
            isLoading: false,
          };
        case 'ERROR':
          return {
            ...state,
            error: true,
          };
        default:
          return state;
      }
    },
    initialState,
  );

  async function getOrganizationBySlug(slug: string): Promise<void> {
    dispatch({ type: 'START_GET_ORGANIZATION' });
    try {
      const organization = await ManageOrganizationAPI.getOrganization({ slug });
      dispatch({ type: 'GET_ORGANIZATION', payload: organization });
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 404) {
        return dispatch({ type: 'ERROR_404' });
      }
      console.error(e);
      dispatch({ type: 'ERROR' });
    }
  }

  function replaceOrganization(organization: Organization): void {
    dispatch({ type: 'GET_ORGANIZATION', payload: organization });
  }

  async function updateOrgUserRoles(
    user: FullUser,
    organizationRoles: OrganizationRoles,
    onSuccess: (org: Organization) => void,
    onError: (e: ValidationError) => void,
  ): Promise<void> {
    if (!state.organization) {
      return;
    }
    try {
      appDispatch(AppActions.showLoadingOverlay());

      const updatedOrganization = await ManageOrganizationAPI.updateUserOrganizationalRoles(state.organization, {
        user_id: user.user_id,
        organizationRoles,
      });
      replaceOrganization(updatedOrganization);
      appDispatch(AppActions.hideLoadingOverlay());
      onSuccess(updatedOrganization);
    } catch (e) {
      appDispatch(AppActions.handleAxiosError(e));
      appDispatch(AppActions.hideLoadingOverlay());
      if (isSequelizeValidationError(e)) {
        onError(e);
      }
    }
  }

  async function setOrgCCO(userId: string, onSuccess: (org: Organization) => void): Promise<void> {
    if (!state.organization) {
      return;
    }
    try {
      appDispatch(AppActions.showLoadingOverlay());
      const updatedOrganization = await ManageOrganizationAPI.setOrgCCO(state.organization, {
        user_id: userId,
      });
      replaceOrganization(updatedOrganization);
      onSuccess(updatedOrganization);
    } catch (e) {
      appDispatch(AppActions.handleAxiosError(e));
      appDispatch(AppActions.hideLoadingOverlay());
    } finally {
      appDispatch(AppActions.hideLoadingOverlay());
    }
  }

  useEffect(() => {
    if (slug) {
      getOrganizationBySlug(slug);
    }
    return (): void => {
      resetHasFetchedOrganization();
    };
  }, [slug]);

  function resetHasFetchedOrganization(): void {
    dispatch({ type: 'RESET' });
  }

  usePageChange(() => {
    resetHasFetchedOrganization();
  });

  return { ...state, setOrgCCO, replaceOrganization, updateOrgUserRoles };
}
