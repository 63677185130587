import { FullUser } from 'shared';
import { RelationshipAPI } from '@api';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { Actions as GeneralActions, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import {
  ActionTypes,
  GetRelationships,
  StartGetRelationships,
  AssignSubscriberToAgent,
  ResetHasFetchedRelationships,
  UnassignSubscriberFromAgent,
} from './types';

export type Actions =
  | GetRelationships
  | StartGetRelationships
  | AssignSubscriberToAgent
  | ResetHasFetchedRelationships
  | UnassignSubscriberFromAgent;

export const Actions = {
  resetHasFetchedRelationships(): AsyncAction<ResetHasFetchedRelationships> {
    return async (dispatch, getState): Promise<void> => {
      const {
        relationships: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_RELATIONSHIPS, payload: undefined });
    };
  },
  getRelationships(opts?: {
    agentEmail?: string;
    subscriberEmail?: string;
  }): AsyncAction<GetRelationships | StartGetRelationships | HandleAxiosError> {
    return async (dispatch, getState): Promise<void> => {
      try {
        const {
          relationships: { hasFetched },
        } = getState();
        if (hasFetched) {
          return;
        }
        dispatch({ type: ActionTypes.START_GET_RELATIONSHIPS, payload: undefined });
        const relationships = await RelationshipAPI.getRelationships(opts);
        dispatch({ type: ActionTypes.GET_RELATIONSHIPS, payload: relationships });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
      }
    };
  },
  assignSubscriberToAgent(
    subscriber: FullUser,
    agent: FullUser,
    changeSubscriberOrganization: boolean,
    onSuccess: () => void = (): void => undefined,
    onError: (e: Error) => void = (): void => undefined,
  ): AsyncAction<GetRelationships | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const {
        relationships: { data },
      } = getState();
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const relationship = await RelationshipAPI.assignSubscriberToAgent(
          subscriber,
          agent,
          changeSubscriberOrganization,
        );
        dispatch({ type: ActionTypes.GET_RELATIONSHIPS, payload: [relationship].concat(data) });
        onSuccess();
      } catch (e) {
        onError(e as Error);
        dispatch(ApiActions.handleAxiosError(e));
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  unassignSubscriberFromAgent(
    relationshipId: number,
    onSuccess: () => void = (): void => undefined,
    onError: (e: Error) => void = (): void => undefined,
  ): AsyncAction<GetRelationships | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const {
        relationships: { data },
      } = getState();
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        await RelationshipAPI.unassignSubscriberFromAgent(relationshipId);
        dispatch({ type: ActionTypes.GET_RELATIONSHIPS, payload: data.filter((r) => r.id !== relationshipId) });
        onSuccess();
      } catch (e) {
        onError(e as Error);
        dispatch(ApiActions.handleAxiosError(e));
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
};

export * from './types';
