import { ReactElement } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { doesSubscriberNeedToBeInvited, filter, formatDateTime, FullUser, Role } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Icon from '@components/common/Icon';
import { ICON_LIBRARY } from '@components/common/Icon/lib';
import { CurrentUserHook } from '@hooks';
import { PATHS } from '@routes/lib';

export function buildUserDetails(
  user: FullUser | null,
  currentUser: CurrentUserHook['currentUser'],
  toggleUserBlocked: () => void,
  deleteUser: () => void,
  flashWarningMessage: (message: string) => void,
  inviteUser: () => void,
): {
  label: string | ReactElement;
  value: ReactElement | string | number | Date;
  valueClassName?: string;
  valueOnClick?: () => void;
}[] {
  if (!user) {
    return [];
  }
  return filter([
    {
      label: 'Invited By',
      value: user.app_metadata.added_by ? (
        <Link to={`${PATHS.ADMIN_MANAGE_USERS}/${user.app_metadata.added_by.email}`}>
          {user.app_metadata.added_by.name}
        </Link>
      ) : (
        <em>No one</em>
      ),
    },
    {
      label: 'Number of Logins',
      value: user.logins_count || '0',
    },
    {
      label: 'Last Login',
      value: user.last_login ? formatDateTime(new Date(user.created_at)) : <em>never</em>,
    },
    {
      label: 'Created At',
      value: formatDateTime(new Date(user.created_at)),
    },
    {
      label: 'Last Updated At',
      value: formatDateTime(new Date(user.updated_at)),
    },
    {
      label: 'Organization',
      value: user.organization ? (
        <Link to={`${PATHS.ORGANIZATIONS}/${user.organization.slug}`}>{user.organization.name}</Link>
      ) : (
        <em>None</em>
      ),
    },
    {
      label: 'Account Active?',
      value: (
        <Icon
          onClick={
            currentUser?.user_id !== user.user_id
              ? toggleUserBlocked
              : (): void => flashWarningMessage('Warning: You cannot disable your own account.')
          }
          className={classnames({ 'toggle-off': user.blocked, pointer: currentUser?.user_id !== user.user_id })}
          type={!user.blocked ? 'toggleOn' : 'toggleOff'}
        />
      ),
    },
    currentUser?.user_id !== user.user_id &&
      doesSubscriberNeedToBeInvited(user) && {
        label: 'Re-Invite User',
        value: (
          <EquiButton
            value="Re-Invite"
            className={classnames({ pointer: currentUser?.user_id !== user.user_id })}
            onClick={inviteUser}
          />
        ),
      },
    {
      label: 'Delete User',
      value: (
        <EquiButton
          value="Delete"
          className={classnames({ pointer: currentUser?.user_id !== user.user_id })}
          onClick={
            currentUser?.user_id !== user.user_id
              ? deleteUser
              : (): void => flashWarningMessage('Warning: You cannot delete your own account.')
          }
        />
      ),
    },
  ]);
}

export const userRoleIconLib: { [Key in Role]: keyof typeof ICON_LIBRARY } = {
  admin: 'admin',
  agent: 'agent',
  eq: 'logo',
  subscriber: 'subscriber',
};
