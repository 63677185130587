import { ReactElement } from 'react';
import classnames from 'classnames';
import { ProgressBar } from 'primereact/progressbar';
import LoadingSpinner from '@components/common/Loading';
import Overlay from '@components/common/Overlay';
import { useGeneral } from '@hooks';

interface Props {
  className?: string;
}

function LoadingOverlay(props: Props): ReactElement | null {
  const { className } = props;

  const {
    general: { showLoadingOverlay, processingProgress },
  } = useGeneral();

  return (
    <Overlay
      rootId="loading-overlay-root"
      showOverlay={showLoadingOverlay}
      className={classnames('LoadingOverlay', className)}
    >
      {typeof processingProgress === 'number' && (
        <div className="absolute w-screen top-1/3 flex justify-center items-center">
          <ProgressBar value={Math.floor(processingProgress * 100)} className="w-7/12" color="rgb(120, 98, 39)" />
        </div>
      )}
      <LoadingSpinner size="3x" />
    </Overlay>
  );
}

export default LoadingOverlay;
