import { SubscriptionPackage } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  GET_SUBSCRIPTION_PACKAGES = 'GET_SUBSCRIPTION_PACKAGES',
  START_GET_SUBSCRIPTION_PACKAGES = 'START_GET_SUBSCRIPTION_PACKAGES',
}

export interface GetSubscriptionPackages extends Action {
  type: typeof ActionTypes.GET_SUBSCRIPTION_PACKAGES;
  payload: SubscriptionPackage[];
}

export interface StartGetSubscriptionPackages extends Action {
  type: typeof ActionTypes.START_GET_SUBSCRIPTION_PACKAGES;
}
