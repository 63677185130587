import { PDFPage } from 'pdf-lib';
import { OfficeUseFormData } from 'shared';
import { drawYesNoRadio } from './subLib';

const tcpPositions = {
  explainedTCPDesignationPurpose: 215,
  describeEquigenesisInformationDisclosureCircumstances: 197,
  askedClientToDesignateTCP: 176,
  encouragedClientToNameTCP: 154,
  encouragedClientToSelectNonInvolvedIndividual: 132,
  tcpNotes: { x: 30, y: 100 },
};

const tcpXPosition = {
  yes: 502,
  no: 528,
};

export function drawTCPOfficeUseNotes(tcpPage: PDFPage, formData: OfficeUseFormData): void {
  drawYesNoRadio(
    tcpPage,
    formData.explainedTCPDesignationPurpose,
    tcpPositions.explainedTCPDesignationPurpose,
    tcpXPosition,
  );
  drawYesNoRadio(
    tcpPage,
    formData.describeEquigenesisInformationDisclosureCircumstances,
    tcpPositions.describeEquigenesisInformationDisclosureCircumstances,
    tcpXPosition,
  );
  drawYesNoRadio(tcpPage, formData.askedClientToDesignateTCP, tcpPositions.askedClientToDesignateTCP, tcpXPosition);
  drawYesNoRadio(tcpPage, formData.encouragedClientToNameTCP, tcpPositions.encouragedClientToNameTCP, tcpXPosition);
  drawYesNoRadio(
    tcpPage,
    formData.encouragedClientToSelectNonInvolvedIndividual,
    tcpPositions.encouragedClientToSelectNonInvolvedIndividual,
    tcpXPosition,
  );
  tcpPage.drawText(`${formData.tcpNotes}`, {
    x: tcpPositions.tcpNotes.x,
    y: tcpPositions.tcpNotes.y,
    size: 9,
    maxWidth: 550,
    lineHeight: 13,
  });
}
