import { ReactElement } from 'react';
import classnames from 'classnames';
import { PhoneTabs, countryCodes, PhoneCountryCodeTabs } from 'shared';
import { useKYCFormFields, useSubscriptionPackageFormFields } from '@hooks';

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: typeof PhoneTabs[number];
}
export default function CountryCodesSelect({ className, disabled, name }: Props): ReactElement {
  const kycFields = useKYCFormFields();
  const subscriptionPackageFields = useSubscriptionPackageFormFields();
  const fields = { ...subscriptionPackageFields, ...kycFields };
  const realName: typeof PhoneCountryCodeTabs[number] = `${name}CountryCode`;
  const field = fields[realName];
  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    const { value } = e.target;
    field.onChange((e as unknown) as React.ChangeEvent<HTMLInputElement>, value);
  }
  return (
    <select
      onChange={handleOnChange}
      disabled={disabled}
      className={classnames('CountryCodesSelect', className)}
      value={field.value}
    >
      {countryCodes.map(({ value, displayName, disabled, countryCode }) => (
        <option key={displayName} data-country-code={countryCode} value={value} disabled={disabled}>
          {displayName}
        </option>
      ))}
    </select>
  );
}
