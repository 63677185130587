import { User } from 'shared';
import { ActionTypes } from '@actions';
import * as AuthActions from '@actions/auth';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  token: string | null;
  user: User | null;
  isAuthenticated: boolean;
  forceLogout?: boolean;
  isAxiosSetupComplete?: boolean;
  hasFetched: boolean;
}

const initialState: State = {
  token: null,
  user: null,
  isAuthenticated: false,
  isAxiosSetupComplete: false,
  hasFetched: false,
};

const startGetAuthReducer: Reducer<State> = (state: State = initialState) => ({
  ...state,
  hasFetched: true,
});

const resetHasFetchedTokenReducer: Reducer<State> = (state: State = initialState) => ({
  ...state,
  hasFetched: false,
});

const saveAuthReducer: Reducer<State> = (state: State = initialState, action: AuthActions.SaveAuth) => ({
  ...state,
  ...action.payload,
});

const startLogoutReducer: Reducer<State> = (state: State = initialState) => ({
  ...state,
  ...initialState,
  forceLogout: true,
});

const setupAxiosReducer: Reducer<State> = (state: State = initialState) => ({
  ...state,
  isAxiosSetupComplete: true,
});

const resetAppReducer: Reducer<State> = () => ({
  ...initialState,
  isAxiosSetupComplete: true,
  isAuthenticated: true,
  hasFetched: true,
});

const Reducers: ReducersType<State> = {
  [ActionTypes.SAVE_AUTH]: saveAuthReducer,
  [ActionTypes.START_LOGOUT]: startLogoutReducer,
  [ActionTypes.SETUP_AXIOS]: setupAxiosReducer,
  [ActionTypes.START_GET_AUTH_TOKEN]: startGetAuthReducer,
  [ActionTypes.RESET_HAS_FETCHED_TOKEN]: resetHasFetchedTokenReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
