import { SubscriptionPackageFormData } from 'shared';
import { State } from '@reducers/subscriptionPackageForm';
import { Action } from '../types';

export enum ActionTypes {
  SUBMIT_SUBSCRIPTION_PACKAGE = 'SUBMIT_SUBSCRIPTION_PACKAGE',
  SET_SUBSCRIPTION_PACKAGE_FORM = 'SET_SUBSCRIPTION_PACKAGE_FORM',
  RESET_SUBSCRIPTION_PACKAGE_FORM = 'RESET_SUBSCRIPTION_PACKAGE_FORM',
  START_GET_SUBSCRIPTION_PACKAGE_FORM = 'START_GET_SUBSCRIPTION_PACKAGE_FORM',
  SET_SUBSCRIPTION_PACKAGE_FORM_DATA = 'SET_SUBSCRIPTION_PACKAGE_FORM_DATA',
  RESET_HAS_FETCHED_SUBSCRIPTION_PACKAGE = 'RESET_HAS_FETCHED_SUBSCRIPTION_PACKAGE',
}

export interface SubmitSubscriptionPackage extends Action {
  type: typeof ActionTypes.SUBMIT_SUBSCRIPTION_PACKAGE;
}

export interface SetSubscriptionPackageForm extends Action {
  type: typeof ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM;
  payload: State['form'];
}

export interface ResetSubscriptionPackageForm extends Action {
  type: typeof ActionTypes.RESET_SUBSCRIPTION_PACKAGE_FORM;
}

export interface StartGetSubscriptionPackageForm extends Action {
  type: typeof ActionTypes.START_GET_SUBSCRIPTION_PACKAGE_FORM;
  payload: boolean;
}

export interface SetSubscriptionPackageFormData extends Action {
  type: typeof ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM_DATA;
  payload: SubscriptionPackageFormData;
}

export interface ResetHasFetchedSubscriptionPackage extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_SUBSCRIPTION_PACKAGE;
}
