import axios, { AxiosError } from 'axios';
import { devLog } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';
import { getHeaders } from './endpoints';

interface SetupAxiosInterceptorsArgs {
  auth: State['auth'];
  dispatch: Dispatch;
}

export function setupAxiosInterceptors({ auth, dispatch }: SetupAxiosInterceptorsArgs): void {
  axios.interceptors.request.use((request) => {
    request.headers = getHeaders(auth.token);
    devLog(request.headers);
    return request;
  });
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        dispatch(Actions.sessionExpired());
      } else if (!window.navigator.onLine) {
        dispatch(Actions.notConnectedToInternet());
      } else if (error.response?.status === 500 || !error.response) {
        dispatch(Actions.serverError());
      }
      throw error;
    },
  );
}
