import { FormEvent, ReactElement, useState } from 'react';
import Select from 'react-select';
import { FullUser, Organization } from 'shared';
import EquiButton from '@components/common/EquiButton';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { Option } from '@utils/select';

interface Props {
  users: FullUser[];
  organization: Organization;
  hasCCO: boolean;
  closeModal: () => void;
  onSubmit: (userId: string) => void;
}

function SetCCOModal({ users, closeModal, organization, onSubmit }: Props): ReactElement {
  const [selectedUser, setSelectedUser] = useState<{ value: string; label: string } | null>(null);
  function submitForm(e: FormEvent): void {
    e.preventDefault();
    if (!selectedUser) {
      return;
    }
    const selectedUserId = selectedUser.value;
    if (organization.ccoUserId === selectedUserId) {
      closeModal();
      return;
    }
    onSubmit(selectedUserId);
  }
  return (
    <Overlay showOverlay rootId="set-cco-modal">
      <Modal withClose onOutsideClick={closeModal} className="flex column align-center EditUserModal">
        <h2 className="mb-0">Set CCO for</h2>
        <h3 className="mt-1">{organization.name}</h3>
        <form className="max-width" method="POST" onSubmit={submitForm}>
          <label>Select an Admin to be CCO</label>
          <Select<Option>
            className="mb-8"
            value={selectedUser}
            onChange={setSelectedUser}
            options={users.map((u) => ({ value: u.user_id, label: u.name }))}
          />
          <div className="button-container max-width flex space-around">
            <EquiButton value="Cancel" onClick={closeModal} />
            <EquiButton value="Update" type="submit" disabled={!selectedUser} />
          </div>
        </form>
      </Modal>
    </Overlay>
  );
}

export default SetCCOModal;
