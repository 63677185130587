import { ReactElement } from 'react';
import { OrganizationRoles } from 'shared';
import GeneralInput, { OnChange } from '../GeneralInput';

interface Props {
  onChangeRole: (field: keyof OrganizationRoles) => OnChange;
  organizationRoles?: OrganizationRoles;
  isAdminDisabled?: boolean;
  isAgentDisabled?: boolean;
  isClientDisabled?: boolean;
}

function OrganizationRolesFormFields({
  onChangeRole,
  organizationRoles,
  isAdminDisabled,
  isAgentDisabled,
  isClientDisabled,
}: Props): ReactElement {
  return (
    <>
      <h3>Organization Roles</h3>
      <div className="flex w-full justify-around max-w-lg">
        <GeneralInput
          name="isAdmin"
          type="checkbox"
          formLabel="Admin"
          onChange={onChangeRole('isAdmin')}
          noMaxWidth
          checked={organizationRoles?.isAdmin}
          disabled={isAdminDisabled}
        />
        <GeneralInput
          name="isReviewer"
          type="checkbox"
          formLabel="Reviewer"
          onChange={onChangeRole('isReviewer')}
          noMaxWidth
          checked={organizationRoles?.isReviewer}
        />
        <GeneralInput
          name="isAgent"
          type="checkbox"
          formLabel="Agent"
          onChange={onChangeRole('isAgent')}
          noMaxWidth
          checked={organizationRoles?.isAgent}
          disabled={isAgentDisabled}
        />
        <GeneralInput
          name="isClient"
          type="checkbox"
          formLabel="Client"
          onChange={onChangeRole('isClient')}
          noMaxWidth
          checked={organizationRoles?.isClient}
          disabled={isClientDisabled}
        />
      </div>
    </>
  );
}

export default OrganizationRolesFormFields;
