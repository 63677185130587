/**
 * @param ts epoch timestamp
 * @returns JS Date Object
 */
export function epochToJsDate(ts: number): Date {
  return new Date(ts * 1000);
}

/**
 * @param d JS Date Object
 * @returns epoch timestamp
 */
export function jsDateToEpoch(d: Date): number {
  return (d.getTime() - d.getMilliseconds()) / 1000;
}
