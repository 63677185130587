import {
  InvestmentInformation,
  TrueFalseRadio,
  MarketKnowledge,
  ExemptMarketTimeHorizon,
  ExemptMarketRiskTolerance,
  GeneralTimeHorizon,
  GeneralRiskTolerance,
} from 'shared';
import { ProtoFields } from '../types';

export const investmentInformationFields: ProtoFields<keyof InvestmentInformation> = {
  tenFromRetirement: {
    type: 'radio',
    formLabel: 'Are you less than 10 years away from retirement?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  assistParents: {
    type: 'radio',
    formLabel: "Do you anticipate having to assist your parents' financial needs?",
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  fundChildrensEducation: {
    type: 'radio',
    formLabel: "Do you anticipate having to fund your children's education?",
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  addToSavings: {
    type: 'radio',
    formLabel: 'Is your disposable income such that you can consistently add to your savings?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  exemptMarketInvestmentKnowledge: {
    type: 'radio',
    formLabel: 'Product Knowledge',
    options: [
      { displayName: 'Poor/Nil', value: MarketKnowledge.poor },
      { displayName: 'Limited', value: MarketKnowledge.limited },
      { displayName: 'Good', value: MarketKnowledge.good },
      { displayName: 'Sophisticated', value: MarketKnowledge.sophisticated },
    ],
  },
  generalInvestmentKnowledge: {
    type: 'radio',
    formLabel: 'Investment Knowledge',
    options: [
      { displayName: 'Poor/Nil', value: MarketKnowledge.poor },
      { displayName: 'Limited', value: MarketKnowledge.limited },
      { displayName: 'Good', value: MarketKnowledge.good },
      { displayName: 'Sophisticated', value: MarketKnowledge.sophisticated },
    ],
  },
  exemptMarketInvestmentObjectivesGrowth: { formLabel: 'Growth', type: 'checkbox' },
  exemptMarketInvestmentObjectivesSpeculation: { formLabel: 'Speculation', type: 'checkbox' },
  exemptMarketInvestmentObjectivesTaxPlanning: { formLabel: 'Tax Planning', type: 'checkbox' },
  exemptMarketInvestmentTimeHorizon: {
    type: 'radio',
    formLabel: 'Investment Time Horizon',
    options: [
      { displayName: '3 to 5 years', value: ExemptMarketTimeHorizon.threeToFive },
      { displayName: '5 to 10 years', value: ExemptMarketTimeHorizon.fiveToTen },
      { displayName: '10+ years', value: ExemptMarketTimeHorizon.tenPlus },
    ],
  },
  exemptMarketRiskTolerance: {
    type: 'radio',
    formLabel: 'Exempt Market Products',
    options: [
      { displayName: 'Not willing to accept high risk', value: ExemptMarketRiskTolerance.none },
      { displayName: 'High', value: ExemptMarketRiskTolerance.high },
    ],
  },
  generalInvestmentObjectivesGrowth: { formLabel: 'Growth', type: 'checkbox' },
  generalInvestmentObjectivesSpeculation: { formLabel: 'Speculation', type: 'checkbox' },
  generalInvestmentObjectivesIncome: { formLabel: 'Income', type: 'checkbox' },
  generalInvestmentObjectivesSafety: { formLabel: 'Safety', type: 'checkbox' },
  generalInvestmentTimeHorizon: {
    type: 'radio',
    formLabel: 'Investment Time Horizon',
    options: [
      { displayName: 'less than 1 year', value: GeneralTimeHorizon.lessThanOne },
      { displayName: '1 to 3 years', value: GeneralTimeHorizon.oneToThree },
      { displayName: '3 to 5 years', value: GeneralTimeHorizon.threeToFive },
      { displayName: '5 to 10 years', value: GeneralTimeHorizon.fiveToTen },
      { displayName: '10+ years', value: GeneralTimeHorizon.tenPlus },
    ],
  },
  generalRiskTolerance: {
    type: 'radio',
    formLabel: 'General',
    options: [
      { displayName: 'Low', value: GeneralRiskTolerance.low },
      { displayName: 'Medium', value: GeneralRiskTolerance.medium },
      { displayName: 'High', value: GeneralRiskTolerance.high },
    ],
  },
};
