import { KYCFormDataAllRequired, KYCFormField } from 'shared';
import { useKYCFormState } from '../useKYCFormState';
import { ProtoFields, Fields, InputWithoutName } from './types';

export function useKYCSubFormFields<T extends keyof KYCFormDataAllRequired>(fields: ProtoFields<T>): Fields<T> {
  const { updateField, form, isFormDisabledForCurrentUser, shouldAllowAutocompleteForCurrentUser } = useKYCFormState();
  const shouldBeReadOnlyToCurrentUser = isFormDisabledForCurrentUser;
  function createFormFields(): Fields<T> {
    return (Object.entries(fields) as [T, InputWithoutName<T>][]).reduce(
      (acc, [name, { disabled, readOnly, value, type, autoComplete, ...restField }]) => {
        acc[name] = new KYCFormField(
          {
            name,
            type,
            value,
            readOnly: shouldBeReadOnlyToCurrentUser || readOnly,
            autoComplete: !shouldAllowAutocompleteForCurrentUser ? 'whatever' : autoComplete,
            ...(restField as { formLabel: string }),
          },
          updateField,
          (
            form || {
              formData: {
                email: '',
                errors: [],
                firstName: '',
                lastName: '',
                numberOfFinancialDebts: 1,
                numberOfNonFinancialDebts: 1,
              },
            }
          ).formData,
          () =>
            disabled ||
            (['radio', 'checkbox', 'file'].includes(type as string) && shouldBeReadOnlyToCurrentUser) ||
            false,
        );
        return acc;
      },
      {} as Fields<T>,
    );
  }
  return createFormFields();
}
