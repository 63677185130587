import { ReactElement } from 'react';
import EquiInput from '@components/common/SubscriptionPackageEquiInput';
import { useSubscriptionPackageFormFields, useSubscriptionPackageFormState } from '@hooks';

export default function SSPFields(): ReactElement {
  const { form } = useSubscriptionPackageFormState();
  const formFields = useSubscriptionPackageFormFields();
  return (
    <>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Subscription Date Cheque Amount
            <br />
            $1,000 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.subscriptionDateChequeAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Feb 1st {new Date(form?.createdAt || '').getFullYear() + 1} Cheque Amount
            <br />
            $1,400 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.feb1st2022ChequeAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Feb 1st {new Date(form?.createdAt || '').getFullYear() + 2} Cheque Amount
            <br />
            $1,800 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.feb1st2023ChequeAmount} />
      </div>
      <hr />
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            A. Initial Loan Amount
            <br />
            $32,000 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.initialLoanAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            B. Total Initial Subscription Price
            <br />
            (A + $875) × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.totalInitialSubscriptionPrice} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            C. Total Capital Contributions
            <br />
            $13,550 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.totalCapitalContributions} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Risk of Loss Amount
            <br />B - A + C
          </strong>
        </h3>
        <EquiInput {...formFields.riskOfLossAmount} />
      </div>
    </>
  );
}
