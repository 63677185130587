import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { filter, formatDateTime, SubscriptionPackage } from 'shared';
import GeneralInput from '@components/common/GeneralInput';
import Table from '@components/common/Table';
import { useCurrentUser, useProductsList, useSingleUser, useSort, useSubscriptionPackagesByEmail } from '@hooks';
import { Page } from '@layouts';
import { ErrorPage } from '@pages';
import { PATHS, ValidPath } from '@routes/lib';
import { displaySubscriptionPackageState } from '@utils/formState';
import { displayStepInfo } from '@utils/subscriptionPackages';
import './styles.scss';

type sortByOptions = 'lastUpdated' | 'numberOfUnits';

export default function SubscriberSubscriptionPackagesPage(): ReactElement {
  const { email } = useParams<{ email: string }>();
  const { subscriptionPackages, isLoading } = useSubscriptionPackagesByEmail(email);
  const { products } = useProductsList();
  const { error, isLoading: userIsLoading, user } = useSingleUser(email);
  const { isCurrentUserAdmin } = useCurrentUser();
  const [statusFilter, setStatusFilter] = useState<SubscriptionPackage['state'] | 'all'>('all');
  const [productFilter, setProductFilter] = useState<string | 'all'>('all');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('lastUpdated', true);
  const sortedAndFiltered: SubscriptionPackage[] = sortBy(
    subscriptionPackages.filter(
      (subscriptionPackage) =>
        (statusFilter === 'all' || statusFilter === subscriptionPackage.state) &&
        (statusFilter !== 'testSentToDocuSign' || isCurrentUserAdmin) &&
        (productFilter === 'all' || productFilter === subscriptionPackage.productId),
    ),
    (subPackage) => {
      switch (field) {
        case 'numberOfUnits':
          return subPackage.formData.unitAmount;
        case 'lastUpdated':
        default:
          return subPackage.formUpdatedAt;
      }
    },
  );
  if (isDescending) {
    sortedAndFiltered.reverse();
  }
  if (error) {
    return <ErrorPage forceBackLocation={`${PATHS.SUBSCRIBERS}/${email}` as ValidPath} />;
  }
  return (
    <Page
      className="SubscriberSubscriptionPackagesPage"
      isLoading={isLoading || userIsLoading}
      defaultBackLocation={`${PATHS.SUBSCRIBERS}/${email}` as ValidPath}
      showChildrenOnLoading={false}
      renderHeader={
        user
          ? (): ReactElement => (
              <>
                <h1>Subscription Packages</h1>
                <h2>Client: {user.name}</h2>
              </>
            )
          : undefined
      }
    >
      {!!subscriptionPackages.length && (
        <>
          <Table
            gridClassName="table-grid-template-columns"
            headers={[
              { text: 'Product Name' },
              {
                text: '# of Units',
                onClick: (): void => setSortField('numberOfUnits'),
                isSelected: field === 'numberOfUnits',
                isDescending,
              },
              { text: 'Status' },
              {
                text: 'Last Updated',
                onClick: (): void => setSortField('lastUpdated'),
                isSelected: field === 'lastUpdated',
                isDescending,
              },
            ]}
            data={sortedAndFiltered.map((form) => [
              <Link to={`${PATHS.SUBSCRIPTION_PACKAGES}/${form.slug}`}>
                {form.product?.name || form.formData.programName}
              </Link>,
              form.formData.unitAmount,
              `${displayStepInfo(form)}${displaySubscriptionPackageState(form.state)}`,
              formatDateTime(new Date(form.formUpdatedAt)),
            ])}
            renderFilters={(): ReactElement => (
              <>
                <GeneralInput
                  className="filter-by-status"
                  labelAsOption={false}
                  formLabel="Filter by Status"
                  onChange={(value): void => setStatusFilter(value as SubscriptionPackage['state'])}
                  name="status"
                  value={statusFilter}
                  type="select"
                  options={filter<{
                    value: SubscriptionPackage['state'] | 'all';
                    displayName: string;
                  }>([
                    { value: 'all', displayName: 'All' },
                    { value: 'inProgressByAgent', displayName: displaySubscriptionPackageState('inProgressByAgent') },
                    { value: 'sentToDocuSign', displayName: displaySubscriptionPackageState('sentToDocuSign') },
                    isCurrentUserAdmin && {
                      value: 'testSentToDocuSign',
                      displayName: displaySubscriptionPackageState('testSentToDocuSign'),
                    },
                    {
                      displayName: displaySubscriptionPackageState('completed'),
                      value: 'completed',
                    },
                    {
                      displayName: displaySubscriptionPackageState('voided'),
                      value: 'voided',
                    },
                  ])}
                />
                <GeneralInput
                  className="filter-by-product-name"
                  labelAsOption={false}
                  formLabel="Filter by Product Name"
                  onChange={(value): void => setProductFilter(value as string)}
                  name="status"
                  value={productFilter}
                  type="select"
                  options={[
                    { value: 'all', displayName: 'All' },
                    ...products.map((products) => ({ value: products.id, displayName: products.name })),
                  ]}
                />
              </>
            )}
          />
        </>
      )}
      {subscriptionPackages.length === 0 && (
        <div className="flex align-center column no-subscription-packages">
          <h1>There are no Subscription Packages to display for this subscriber.</h1>
        </div>
      )}
    </Page>
  );
}
