import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { Link, Redirect, useParams } from 'react-router-dom';
import { filter, FullUser, Organization } from 'shared';
import OrganizationMenu from '@components/common/OrganizationMenu';
import OrganizationSettingsMenu from '@components/common/OrganizationSettingsMenu';
import SectionCard from '@components/common/SectionCard';
import SectionMax80 from '@components/common/SectionMax80';
import AddExistingUserToOrganization from '@components/Modals/AddExistingUserToOrganization';
import AddNewUserToOrganization from '@components/Modals/AddNewUserToOrganizationModal';
import ChangeUserOrganizationRolesModal from '@components/Modals/ChangeUserOrganizationRolesModal';
import OrganizationModal from '@components/Modals/OrganizationModal';
import OrgCCO from '@components/OrgCCO';
import { useCurrentUser, useModal, useSingleOrganization, useUsers } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import PageTitle from './PageTitle';

import './styles.scss';

type addingUserType = 'admin' | 'agent' | 'reviewer' | 'client';

export default function ViewOrganizationPage(): ReactElement {
  const { slug } = useParams<{ slug: string }>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const {
    isLoading,
    organization,
    hasFetched,
    replaceOrganization,
    updateOrgUserRoles,
    setOrgCCO,
  } = useSingleOrganization(slug);
  const {
    hasAllPermissions,
    isCurrentUserAdminForOrg,
    isCurrentUserReviewerForOrg,
    isCurrentUserAgentForOrg,
    currentUser,
    doesCurrentUserManageUser,
  } = useCurrentUser();
  const { isOpen: isAddUserModalOpen, openModal: openAddUserModal, closeModal: closeAddUserModal } = useModal();
  const { isLoading: isLoadingUsers, users } = useUsers();
  const {
    isOpen: isAddExistingUserModalOpen,
    openModal: openAddExistingUserModal,
    closeModal: closeAddExistingUserModal,
  } = useModal();
  const {
    isOpen: isChangeUserOrgRolesModalOpen,
    openModal: openChangeUserOrgRolesModal,
    closeModal: closeChangeUserOrgRolesModal,
  } = useModal();
  const { isOpen: isSettingsModalOpen, openModal: openSettingsModal, closeModal: closeSettingsModal } = useModal();
  const [selectedUserToChange, setSelectedUserToChange] = useState<FullUser | null>(null);
  if (
    hasFetched &&
    (!organization ||
      (!hasAllPermissions(['read:organization']) &&
        !(
          isCurrentUserAdminForOrg(organization) ||
          isCurrentUserReviewerForOrg(organization) ||
          isCurrentUserAgentForOrg(organization)
        )))
  ) {
    return <Redirect to={PATHS.NOT_FOUND} />;
  }

  function onModalSuccess(org: Organization): void {
    replaceOrganization(org);
    closeAddExistingUserModal();
    closeAddUserModal();
    closeSettingsModal();
    setSelectedUserToChange(null);
    closeChangeUserOrgRolesModal();
  }

  const membersData: { label: string; users?: FullUser[]; type: addingUserType }[] = filter([
    { label: 'Admins', users: organization?.admins, type: 'admin' },
    { label: 'Reviewers', users: organization?.reviewers, type: 'reviewer' },
    { label: 'Agents', users: organization?.agents, type: 'agent' },
    (hasAllPermissions(['read:subscriber']) ||
      isCurrentUserAdminForOrg(organization) ||
      isCurrentUserReviewerForOrg(organization)) && {
      label: 'Clients',
      users: organization?.clients,
      type: 'client',
    },
  ]);
  return (
    <Page
      className="ViewOrganizationPage"
      renderHeader={
        organization
          ? (): ReactElement => <PageTitle organization={organization} onUpdateSuccess={onModalSuccess} />
          : undefined
      }
      defaultBackLocation={hasAllPermissions(['read:organization']) ? PATHS.ORGANIZATIONS : PATHS.HOME}
      isLoading={isLoading}
      showChildrenOnLoading={false}
    >
      <SectionMax80>
        {(hasAllPermissions(['read:organization']) ||
          isCurrentUserAdminForOrg(organization) ||
          isCurrentUserReviewerForOrg(organization)) && (
          <SectionCard>
            <div className="text-left max-width px-4">
              <div className="flex justify-center align-center h-fit-content relative">
                <h2 className="text-center">Organization Settings</h2>
                {organization && (
                  <OrganizationSettingsMenu organization={organization} openSettingsModal={openSettingsModal} />
                )}
              </div>
              <ul>
                <li>
                  Requires Client Registration Forms be reviewed by Organization Reviewer?{' '}
                  <b>{organization?.requiresKYCReviewByReviewer ? 'YES' : 'NO'}</b>
                </li>
                <li>
                  Portal is Branded for Organization?{' '}
                  <b>{organization?.shouldBrandPortalForOrganization ? 'YES' : 'NO'}</b>
                </li>
                <li>
                  Opt out of using EquiGenesis' KYC form? <b>{organization?.isNotUsingEqKYC ? 'YES' : 'NO'}</b>
                </li>
              </ul>
            </div>
          </SectionCard>
        )}
        <SectionCard>
          <div className="text-left max-width px-4">
            <div className="flex justify-center align-center h-fit-content relative">
              <h2 className="text-center">Organization Members</h2>
              <div className="absolute right-0">
                {organization && (
                  <OrganizationMenu
                    organization={organization}
                    openAddUserModal={openAddUserModal}
                    openAddExistingUserModal={openAddExistingUserModal}
                  />
                )}
              </div>
            </div>
            {organization && (
              <OrgCCO organization={organization} orgAdmins={[...(organization.admins || [])]} onSubmit={setOrgCCO} />
            )}
            {membersData.map((item) => (
              <div key={item.label}>
                <h3>{item.label}</h3>
                {(item.users?.length || 0) === 0 && <div>empty</div>}
                <ul className="pl-0">
                  {item.users?.map((user, i) => (
                    <li
                      className={classnames(
                        { 'font-bold': user.user_id === currentUser?.user_id },
                        'flex items-center',
                      )}
                      key={user.user_id}
                    >
                      {doesCurrentUserManageUser(user) && (
                        <Link
                          className="mr-4 w-24 flex-shrink-0 whitespace-nowrap"
                          to={`${item.type === 'client' ? PATHS.SUBSCRIBERS : PATHS.AGENTS}/${user.email}`}
                        >
                          (View Profile)
                        </Link>
                      )}
                      <div
                        className={classnames(
                          {
                            'cursor-pointer underline bg-gray hover:bg-equigenesis-gold hover:text-white':
                              isCurrentUserAdminForOrg(organization) || hasAllPermissions(['update:subscriber']),
                            'bg-opacity-10': i % 2 === 0,
                            'bg-opacity-5': i % 2 === 1,
                          },
                          'py-2 px-4 rounded-lg flex-1',
                        )}
                        onClick={(): void => {
                          if (isCurrentUserAdminForOrg(organization) || hasAllPermissions(['update:subscriber'])) {
                            setSelectedUserToChange(user);
                            openChangeUserOrgRolesModal();
                          }
                        }}
                      >
                        {user.user_id === currentUser?.user_id && '(you)'} {user.name} ({user.email})
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </SectionCard>
        {isAddUserModalOpen && organization && (
          <AddNewUserToOrganization
            onClose={closeAddUserModal}
            organization={organization}
            onSuccess={onModalSuccess}
          />
        )}
        {isAddExistingUserModalOpen && organization && (
          <AddExistingUserToOrganization
            onClose={closeAddExistingUserModal}
            organization={organization}
            onSuccess={onModalSuccess}
            users={users}
            isLoadingUsers={isLoadingUsers}
          />
        )}
        {isSettingsModalOpen && organization && (
          <OrganizationModal
            organization={organization}
            closeModal={closeSettingsModal}
            isOpen={isSettingsModalOpen}
            onSuccess={onModalSuccess}
          />
        )}
        {isChangeUserOrgRolesModalOpen && selectedUserToChange && (
          <ChangeUserOrganizationRolesModal
            onClose={(): void => {
              closeChangeUserOrgRolesModal();
              setSelectedUserToChange(null);
            }}
            shouldShow={isChangeUserOrgRolesModalOpen}
            user={selectedUserToChange}
            onSubmit={(orgRoles): void => {
              updateOrgUserRoles(selectedUserToChange, orgRoles, onModalSuccess, (e) =>
                setFormError(e.errors.join('. ')),
              );
            }}
            error={formError}
          />
        )}
      </SectionMax80>
    </Page>
  );
}
