import { ReactElement } from 'react';
import classnames from 'classnames';
import { Children } from '@custom-types';
import './styles.scss';

interface Props {
  children: Children;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
  turnOffAutoComplete?: boolean;
}

export default function Form({ onSubmit, className, children, turnOffAutoComplete }: Props): ReactElement {
  return (
    <form
      className={classnames('Form', 'flex', 'flex-1', 'column', 'align-center', 'justify-center', className)}
      onSubmit={onSubmit}
      autoComplete={turnOffAutoComplete ? 'whatever' : undefined}
    >
      {turnOffAutoComplete && (
        <input autoComplete="false" name="whatever" type="hidden" value="hidden" style={{ display: 'none' }} />
      )}
      {children}
    </form>
  );
}
