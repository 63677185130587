import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { agentSubPkgEditableStates, RequestPayloads, SubscriptionPackageFormDataAllRequired } from 'shared';
import { Actions, Dispatch } from '@actions';
import { PATHS } from '@routes/lib';
import { State } from '@store';
import { usePageChange } from '../usePageChange';

export interface SubscriptionPackageFormHook {
  form: State['subscriptionPackageForm']['form'];
  isLoading: State['subscriptionPackageForm']['isLoading'];
  hasFetched: State['subscriptionPackageForm']['hasFetched'];
  updateField(
    field: keyof SubscriptionPackageFormDataAllRequired,
    value: SubscriptionPackageFormDataAllRequired[keyof SubscriptionPackageFormDataAllRequired] | undefined,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void;
  deleteForm(): void;
  sendSubscriptionPackageToDocuSign(payload: RequestPayloads['sendSubscriptionPackageToDocuSign']): void;
  voidDocument(): void;
  voidAndResendDocument(): void;
  isFormDisabledForCurrentUser: boolean;
}

function setIsFormDisabledForCurrentUser(
  subscriptionPackage: State['subscriptionPackageForm']['form'],
  currentUser: State['currentUser']['data'],
): boolean {
  if (
    !subscriptionPackage ||
    !['inProgressByAgent'].includes(subscriptionPackage.state) ||
    !currentUser?.user_id ||
    subscriptionPackage.product?.is_active === false
  ) {
    return true;
  }

  if (
    !currentUser?.organization?.userProperties?.isAdmin &&
    !currentUser?.organization?.userProperties?.isReviewer &&
    !(currentUser.roles.includes('admin') || currentUser.roles.includes('eq')) &&
    currentUser.roles.includes('agent') &&
    !agentSubPkgEditableStates.includes(subscriptionPackage.state)
  ) {
    return true;
  }
  return false;
}

export function useSubscriptionPackageFormState(slug?: string): SubscriptionPackageFormHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const { data: currentUser } = useSelector<State>((state) => state.currentUser) as State['currentUser'];
  const subscriptionPackageForm = useSelector<State>(
    (state) => state.subscriptionPackageForm,
  ) as State['subscriptionPackageForm'];
  const dispatch = reduxDispatch as Dispatch;
  const history = useHistory();
  function updateField(
    field: keyof SubscriptionPackageFormDataAllRequired,
    value: SubscriptionPackageFormDataAllRequired[keyof SubscriptionPackageFormDataAllRequired],
    e: React.MouseEvent | React.ChangeEvent,
  ): void {
    dispatch(Actions.updateSubscriptionPackageFormField(field, value, e as React.ChangeEvent<HTMLInputElement>));
  }
  function getSubscriptionPackage(slug: string): void {
    dispatch(Actions.getSubscriptionPackage(slug));
  }

  function resetHasFetchedSubscriptionPackage(): void {
    dispatch(Actions.resetHasFetchedSubscriptionPackage());
  }

  useEffect(() => {
    if (slug) {
      getSubscriptionPackage(slug);
    }
    return (): void => {
      resetHasFetchedSubscriptionPackage();
    };
  }, [slug]);

  usePageChange(() => {
    resetHasFetchedSubscriptionPackage();
  });

  function deleteForm(): void {
    const userEmail = subscriptionPackageForm.form?.kyc?.subscriber?.email;
    dispatch(
      Actions.deleteSubscriptionPackage(() => {
        if (userEmail) {
          history.replace(`${PATHS.SUBSCRIBERS}/${userEmail}/subscription-packages`);
        } else {
          history.replace('/');
        }
      }),
    );
  }

  function sendSubscriptionPackageToDocuSign(payload: RequestPayloads['sendSubscriptionPackageToDocuSign']): void {
    dispatch(Actions.sendSubscriptionPackageToDocuSign(payload));
  }

  function voidDocument(): void {
    dispatch(Actions.voidDocuSignSubscriptionPackage());
  }
  function voidAndResendDocument(): void {
    dispatch(Actions.voidAndResendDocuSignSubscriptionPackage());
  }

  return {
    ...subscriptionPackageForm,
    updateField,
    deleteForm,
    sendSubscriptionPackageToDocuSign,
    voidDocument,
    voidAndResendDocument,
    isFormDisabledForCurrentUser: setIsFormDisabledForCurrentUser(subscriptionPackageForm.form, currentUser),
  };
}
