import { TaxResidenceInformation } from 'shared';
import { ProtoFields } from '../types';

export const taxResidenceFields: ProtoFields<keyof TaxResidenceInformation> = {
  US: { type: 'checkbox', formLabel: 'US' },
  birthCountry: { formLabel: 'Country of Birth' },
  canada: { type: 'checkbox', formLabel: 'Canada' },
  citizenship: { formLabel: 'Citizenship or Nationality (List all)' },
  hasOtherTaxResidence: { type: 'checkbox', formLabel: 'Other' },
  otherTaxResidence: { formLabel: 'Other Tax Residences' },
  taxIdNum: { formLabel: 'Tax Identification Number(s) (Other than SIN)' },
};
