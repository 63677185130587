import { controllingPersonTypes, KYCFormDataAllRequired } from 'shared';
import anyWindow from '@utils/anyWindow';
import {
  accreditedInvestorStatusFields,
  bankingInformationFields,
  clientInformationFields,
  controllingPersonFields,
  employmentInformationFields,
  familyInformationFields,
  financialInformationFields,
  insiderInformationFields,
  investmentInformationFields,
  leveragedTaxShelterInformationFields,
  professionalAdvisorsInformationFields,
  taxResidenceFields,
  trustedContactPersonFields,
} from './formSections';
import { Fields } from './types';
import { useKYCSubFormFields } from './useSubFormFields';

anyWindow.controllingPersonTypes = controllingPersonTypes;

export function useKYCFormFields(): Fields<keyof KYCFormDataAllRequired> {
  const formFields = useKYCSubFormFields({
    ...accreditedInvestorStatusFields,
    ...bankingInformationFields,
    ...clientInformationFields,
    ...controllingPersonFields,
    ...employmentInformationFields,
    ...familyInformationFields,
    ...financialInformationFields,
    ...insiderInformationFields,
    ...investmentInformationFields,
    ...leveragedTaxShelterInformationFields,
    ...professionalAdvisorsInformationFields,
    ...taxResidenceFields,
    ...trustedContactPersonFields,
  });
  return formFields;
}
