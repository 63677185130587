import { Actions, ActionTypes } from '@actions';
import { Reducer } from '..';

export type ReducersType<T> = Partial<
  {
    [key in keyof ActionTypes]: Reducer<T> | undefined;
  }
>;

export function createReducers<State>(Reducers: ReducersType<State>, initialState: State) {
  return (state: State = initialState, action: Actions): State => {
    const reducer = Reducers[action.type as keyof ActionTypes];
    if (reducer) {
      return reducer(state, action);
    }
    return state;
  };
}
