import { Organization } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  GET_ORGANIZATIONS = 'GET_ORGANIZATIONS',
  START_GET_ORGANIZATIONS = 'START_GET_ORGANIZATIONS',
}

export interface GetOrganizations extends Action {
  type: typeof ActionTypes.GET_ORGANIZATIONS;
  payload: Organization[];
}

export interface StartGetOrganizations extends Action {
  type: typeof ActionTypes.START_GET_ORGANIZATIONS;
}
