import { KYC } from 'shared';
import { ActionTypes } from '@actions';
import * as KYCsActions from '@actions/kycs';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = {
  isLoading: boolean;
  data: KYC[];
};

const initialState: State = {
  isLoading: false,
  data: [],
};

const getKYCsReducer: Reducer<State> = (_state, action: KYCsActions.GetKYCs): State => {
  return {
    isLoading: false,
    data: action.payload,
  };
};

const startGetKYCsReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    isLoading: true,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return {
    ...initialState,
    hasFetched: true,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.GET_KYCS]: getKYCsReducer,
  [ActionTypes.START_GET_KYCS]: startGetKYCsReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
