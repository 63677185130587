import { ReactElement } from 'react';
import { FullUser } from 'shared';
import Icon from '@components/common/Icon';
import EditUserModal from '@components/Modals/EditUserModal';
import { useModal } from '@hooks';

interface Props {
  user: FullUser;
}

function PageTitle({ user }: Props): ReactElement {
  const { closeModal, isOpen, openModal } = useModal();

  return (
    <>
      <h1 className="text-center">
        {user.name} <Icon onClick={openModal} className="edit-icon pointer" type="edit" />
      </h1>
      <p className="text-center mt-0">{user.email}</p>
      <EditUserModal closeModal={closeModal} isOpen={isOpen} user={user} isSubscriber={false} />
    </>
  );
}

export default PageTitle;
