import { ReactElement } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FullUser, OrganizationRoles } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput from '@components/common/GeneralInput';
import SectionCard from '@components/common/SectionCard';
import Table, { TableHeader, TableRow } from '@components/common/Table';
import ChangeUserOrganizationRolesModal from '@components/Modals/ChangeUserOrganizationRolesModal';
import { useCurrentUser, useModal } from '@hooks';
import { PATHS } from '@routes/lib';
import './styles.scss';

interface Props {
  user?: FullUser | null;
  openAssignModal: () => void;
  headers?: TableHeader[];
  tableData: TableRow[];
  assignType: 'Agent' | 'Subscriber'; // title for table of assigned users
  updateOrgUserRoles: (orgRoles: OrganizationRoles, onError: (e: Error) => void, onSuccess: () => void) => void;
}

function UserOrganizationSection({
  user,
  openAssignModal,
  headers,
  assignType,
  tableData,
  updateOrgUserRoles,
}: Props): ReactElement {
  const {
    canCurrentUserSeeOrg,
    hasAllPermissions,
    doesCurrentUserManageUser,
    doesCurrentUserManageOrgUser,
  } = useCurrentUser();
  const { closeModal, isOpen, openModal } = useModal();
  return (
    <SectionCard className="UserOrganizationSection">
      {canCurrentUserSeeOrg(user?.organization) ? (
        <>
          <h4 className="mb-0">Organization</h4>
          <h2 className="mt-0">
            <Link to={`${PATHS.ORGANIZATIONS}/${user?.organization.slug}`}>{user?.organization.name}</Link>
          </h2>
          <h4 className="mt-0">Organizational Roles</h4>
          <div className="flex justify-between w-4/5 mb-2">
            <GeneralInput
              formLabel="Admin"
              name="Admin"
              onChange={(): void => undefined}
              checked={user?.organization.userProperties.isAdmin}
              readOnly
              type="checkbox"
              noMaxWidth
              disabled
              className="text-sm"
            />
            <GeneralInput
              formLabel="Reviewer"
              name="Reviewer"
              onChange={(): void => undefined}
              checked={user?.organization.userProperties.isReviewer}
              readOnly
              type="checkbox"
              noMaxWidth
              disabled
              className="text-sm"
            />
            <GeneralInput
              formLabel="Agent"
              name="Agent"
              onChange={(): void => undefined}
              checked={user?.organization.userProperties.isAgent}
              readOnly
              type="checkbox"
              noMaxWidth
              disabled
              className="text-sm"
            />
            <GeneralInput
              formLabel="Client"
              name="Client"
              onChange={(): void => undefined}
              checked={user?.organization.userProperties.isClient}
              readOnly
              type="checkbox"
              noMaxWidth
              disabled
              className="text-sm"
            />
          </div>
          {doesCurrentUserManageUser(user) && user?.organization && (
            <>
              <EquiButton value="Change Organizational Roles" className="mt-3" onClick={openModal} />
              {isOpen && (
                <ChangeUserOrganizationRolesModal
                  user={user}
                  shouldShow={isOpen}
                  onClose={closeModal}
                  onSubmit={(orgRoles): void => updateOrgUserRoles(orgRoles, () => undefined, closeModal)}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <h4>Organization</h4>
          <em>This user is not assigned to any organization</em>
        </>
      )}
      <h4 className="mt-10">{assignType}s</h4>
      {(hasAllPermissions(['create:relationship']) || doesCurrentUserManageOrgUser(user)) && (
        <EquiButton value={`Assign ${assignType}`} onClick={openAssignModal} />
      )}
      <Table
        gridClassName={classnames({
          'table-grid-template-columns-with-delete':
            hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user),
          'table-grid-template-columns-without-delete': !(
            hasAllPermissions(['delete:relationship']) || doesCurrentUserManageOrgUser(user)
          ),
        })}
        headers={headers}
        data={tableData}
        noResultsText={`There are no ${assignType}s`}
      />
    </SectionCard>
  );
}

export default UserOrganizationSection;
