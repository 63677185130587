import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  filter,
  FullUser,
  KYC,
  SubscriptionPackage,
  SubscriptionPackageFormData,
  SubscriptionPackageFormFormatting,
  SubscriptionPackageFormValidation,
  validateSubscriptionPackageForm,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Loading from '@components/common/Loading';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useCurrentUser, useProductsList, useSubscriptionPackagesByEmail } from '@hooks';
import { PATHS } from '@routes/lib';
import { GroupedOption, groupUsersByOrgForSelect, Option } from '@utils/select';

import './styles.scss';

interface Props {
  currentSubscriberAgents: FullUser[];
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
  user: FullUser;
  kyc?: KYC;
}

function NewSubscriptionPackageFormModal(props: Props): ReactElement {
  const { className, onClose, user, onSubmit, currentSubscriberAgents, kyc } = props;
  const { isLoading: productsIsLoading, products } = useProductsList();
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { createSubscriptionPackage } = useSubscriptionPackagesByEmail(user.email);
  const [errors, setErrors] = useState<SubscriptionPackageFormData['errors']>([]);
  const sortedAgents = currentSubscriberAgents.sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    if (a?.user_id && a?.user_id === currentUser?.user_id) {
      return 1;
    } else if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }
    return 0;
  }) as FullUser[];
  const [salespersonPhone, setSalespersonPhone] = useState(sortedAgents[0].user_metadata?.phone_number || '');
  const [salespersonFirm, setSalespersonFirm] = useState(
    sortedAgents[0].organization?.name || sortedAgents[0].user_metadata?.firm || '',
  );
  const [selectedProductId, setSelectedProductId] = useState(products.filter((p) => p.is_active)[0]?.id || '');
  const [selectedUserOption, setSelectedUserOption] = useState<Option | null>({
    label: `${sortedAgents[0].name} (${sortedAgents[0].email})`,
    value: sortedAgents[0].user_id,
  });
  const selectedProduct = products.find((product) => product.id === selectedProductId) || null;
  const selectedUser = sortedAgents.find((agent) => agent.user_id === selectedUserOption?.value) || null;
  const salespersonName = selectedUser?.name || '';
  const salespersonEmail = selectedUser?.email || '';
  useEffect(() => {
    if (!selectedProductId && products.length) {
      setSelectedProductId(products.filter((p) => p.is_active)[0]?.id || '');
    }
  }, [products]);
  useEffect(() => {
    onPhoneChange(selectedUser?.user_metadata?.phone_number || '', { nativeEvent: {} } as ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >);
    onAgentFirmChange(selectedUser?.organization?.name || selectedUser?.user_metadata?.firm || '', {
      nativeEvent: {},
    } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
  }, [selectedUser?.user_id]);

  function onSubscriptionPackageFormSuccess(subscriptionPackage: SubscriptionPackage): void {
    onSubmit();
    history.push(`${PATHS.SUBSCRIPTION_PACKAGES}/${subscriptionPackage.slug}`);
  }

  function onPhoneChange(
    phone: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void {
    const formatted = SubscriptionPackageFormFormatting(
      'salespersonPhone',
      e as React.ChangeEvent<HTMLInputElement>,
    )(phone);
    const validator = SubscriptionPackageFormValidation.salespersonPhone as (
      v: typeof phone,
      f: SubscriptionPackageFormData,
      isSubscriber: boolean,
    ) => SubscriptionPackageFormData['errors'][number] | void;
    const _errors = validator(
      formatted,
      { salespersonEmail, salespersonName, salespersonPhone, errors, programName: selectedProduct?.name || '' },
      false,
    );
    setSalespersonPhone(formatted);
    const newErrors = errors.filter((e) => !e.fieldCausingError.includes('salespersonPhone')).concat(filter([_errors]));
    setErrors(newErrors);
  }
  function onAgentFirmChange(
    firm: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void {
    const formatted = SubscriptionPackageFormFormatting(
      'salespersonFirm',
      e as React.ChangeEvent<HTMLInputElement>,
    )(firm);
    const validator = SubscriptionPackageFormValidation.salespersonFirm as (
      v: typeof firm,
      f: SubscriptionPackageFormData,
      isSubscriber: boolean,
    ) => SubscriptionPackageFormData['errors'][number] | void;
    const _errors = validator(
      formatted,
      {
        salespersonEmail,
        salespersonName,
        salespersonPhone,
        salespersonFirm,
        errors,
        programName: selectedProduct?.name || '',
      },
      false,
    );
    setSalespersonFirm(formatted);
    const newErrors = errors.filter((e) => !e.fieldCausingError.includes('salespersonFirm')).concat(filter([_errors]));
    setErrors(newErrors);
  }

  function create(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();

    console.log(salespersonName, salespersonEmail, salespersonPhone, salespersonFirm);
    const newSubscriptionPackage: SubscriptionPackageFormData = {
      salespersonName,
      salespersonEmail,
      salespersonPhone,
      salespersonFirm,
      programName: selectedProduct?.name || '',
      errors: [],
    };
    if (!kyc) {
      return;
    }
    const errors = validateSubscriptionPackageForm(newSubscriptionPackage, false);
    if (errors.length) {
      setErrors(errors);
      return;
    }
    createSubscriptionPackage(
      kyc.id,
      { formData: newSubscriptionPackage, productId: selectedProductId },
      onSubscriptionPackageFormSuccess,
      (error) => setErrors([error]),
    );
  }

  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={true}
      className={classnames('NewSubscriptionPackageFormModal', className)}
    >
      <Modal
        className="NewSubscriptionPackageFormModalBody flex column align-center"
        onOutsideClick={onClose}
        withClose
      >
        <h1 className="text-center">
          New Subscription Package for
          <br />
          {user.name}
        </h1>
        {productsIsLoading && (
          <div className="loading-container">
            <Loading size="2x" />
          </div>
        )}
        {!productsIsLoading && selectedUser && (
          <form className="flex column align-center">
            <Select<Option, false, GroupedOption>
              placeholder="Pick an Agent"
              value={selectedUserOption}
              onChange={setSelectedUserOption}
              classNamePrefix="select"
              className="w-full mb-4"
              options={groupUsersByOrgForSelect(sortedAgents, 'No Organization', user?.organization)}
            />
            <div className="flex max-width">
              <GeneralInput
                formLabel="Agent Phone"
                error={errors.find((error) => error.field === 'salespersonPhone')?.message}
                value={salespersonPhone}
                onChange={onPhoneChange as OnChange}
              />
              <GeneralInput
                formLabel="Agent Firm/Organization"
                value={salespersonFirm}
                error={errors.find((error) => error.field === 'salespersonFirm')?.message}
                onChange={onAgentFirmChange as OnChange}
                readOnly={!!selectedUser?.organization?.name}
              />
            </div>
            <div className="flex max-width">
              <GeneralInput
                labelAsOption={false}
                formLabel="Product"
                value={selectedProductId}
                error={errors.find((error) => error.field === 'programName')?.message}
                onChange={setSelectedProductId as OnChange}
                type="select"
                options={products.map((product) => ({
                  displayName: product.name,
                  value: product.id,
                  disabled: !product.is_active,
                }))}
              />
            </div>
            <EquiButton
              className="spacer"
              value="Create Subscription Package"
              type="submit"
              onClick={create}
              disabled={!selectedUserOption || !salespersonPhone || !selectedProduct || !salespersonFirm}
            />
          </form>
        )}
      </Modal>
    </Overlay>
  );
}

export default NewSubscriptionPackageFormModal;
