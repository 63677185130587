import { ResponsePayloads } from 'shared';
import { ActionTypes } from '@actions';
import * as RelationshipsActions from '@actions/relationships';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = {
  data: ResponsePayloads['getRelationships'];
  isLoading: boolean;
  hasFetched: boolean;
};

const initialState: State = {
  data: [],
  isLoading: false,
  hasFetched: false,
};

const startGetRelationships: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    isLoading: true,
    hasFetched: true,
  };
};
const getRelationships: Reducer<State> = (
  state = initialState,
  action: RelationshipsActions.GetRelationships,
): State => {
  return {
    ...state,
    isLoading: false,
    data: action.payload,
  };
};

const resetHasFetchedReducer: Reducer<State> = (state = initialState): State => {
  return {
    ...state,
    hasFetched: false,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return { ...initialState, hasFetched: true };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.START_GET_RELATIONSHIPS]: startGetRelationships,
  [ActionTypes.GET_RELATIONSHIPS]: getRelationships,
  [ActionTypes.RESET_HAS_FETCHED_RELATIONSHIPS]: resetHasFetchedReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
