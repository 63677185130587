import {
  OfficeUseFormDataAllRequired,
  OfficeUseFormFormatting,
  OfficeUseFormValidation,
  OfficeUseFormData,
  OfficeUseFormValidationError,
  OfficeUse,
  isAxiosError,
} from 'shared';
import { OfficeUseAPI } from '@api';
import { focusErrorField } from '@utils/domHelpers';
import { debounce } from '@utils/lib';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { Actions as CurrentUserActions, GetCurrentUser, ResetHasFetchedCurrentUser } from '../currentUser';
import { Actions as GeneralActions, FlashMessage, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import {
  ActionTypes,
  SubmitOfficeUse,
  SetOfficeUseForm,
  ResetOfficeUseForm,
  StartGetOfficeUseForm,
  SetOfficeUseFormData,
  ResetHasFetchedOfficeUse,
} from './types';

export type Actions =
  | SubmitOfficeUse
  | SetOfficeUseForm
  | ResetOfficeUseForm
  | StartGetOfficeUseForm
  | SetOfficeUseFormData
  | ResetHasFetchedOfficeUse;

async function updateOfficeUse(officeUseId: number, formData: OfficeUseFormData): Promise<OfficeUse> {
  const officeUse = await OfficeUseAPI.updateOfficeUse(officeUseId, { formData });
  return officeUse;
}

const debouncedUpdateOfficeUse: (officeUseId: number, formData: OfficeUseFormData) => Promise<OfficeUse> = debounce(
  updateOfficeUse,
  1000,
) as (officeUseId: number, formData: OfficeUseFormData) => Promise<OfficeUse>;

export const Actions = {
  updateOfficeUseFormField(
    field: keyof OfficeUseFormDataAllRequired,
    value: OfficeUseFormDataAllRequired[keyof OfficeUseFormDataAllRequired],
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): AsyncAction<SetOfficeUseForm> {
    return async (dispatch, getState): Promise<void> => {
      const {
        officeUseForm,
        currentUser: { data: currentUser },
      } = getState();
      if (!officeUseForm.form) return;
      const isCurrentUserTheSubscriber = currentUser?.user_id === officeUseForm.form.subscriberUserId;
      if (isCurrentUserTheSubscriber && !(currentUser?.roles.includes('eq') || currentUser?.roles.includes('admin'))) {
        return;
      }
      const formattedValue = OfficeUseFormFormatting(field, e)(value);
      const validator = OfficeUseFormValidation[field] as (
        v: typeof value,
        f: OfficeUseFormData,
        isSubscriber: boolean,
      ) => OfficeUseFormData['errors'][number] | void;
      const error = validator(formattedValue, officeUseForm.form.formData, isCurrentUserTheSubscriber);
      const newErrors = officeUseForm.form.formData.errors
        .filter((error) => !error.fieldCausingError.includes(field))
        .concat(error ? [error] : []);
      const newForm: OfficeUseFormData = {
        ...officeUseForm.form.formData,
        [field]: formattedValue,
        errors: newErrors,
      };
      dispatch(Actions.updateOfficeUseFormData(newForm));
    };
  },
  updateOfficeUseFormData(
    newOfficeUseFormData: OfficeUseFormData,
  ): AsyncAction<
    SetOfficeUseFormData | HandleAxiosError | SetOfficeUseForm | GetCurrentUser | ResetHasFetchedCurrentUser
  > {
    return async (dispatch, getState): Promise<void> => {
      const {
        officeUseForm: { form },
        currentUser,
      } = getState();
      if (!form) return;
      try {
        dispatch({ type: ActionTypes.SET_OFFICE_USE_FORM_DATA, payload: newOfficeUseFormData });
        const officeUse = await debouncedUpdateOfficeUse(form.id, newOfficeUseFormData);
        const {
          officeUseForm: { form: currentForm },
        } = getState();
        dispatch({
          type: ActionTypes.SET_OFFICE_USE_FORM,
          payload: { ...officeUse, formData: currentForm?.formData || newOfficeUseFormData },
        });
        if (officeUse.subscriberUserId === currentUser.data?.user_id) {
          dispatch(CurrentUserActions.resetHasFetchedCurrentUser());
          dispatch(CurrentUserActions.getCurrentUserInfo());
        }
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        return;
      }
    };
  },
  getOfficeUseByUserEmail(
    email: string,
  ): AsyncAction<SetOfficeUseForm | ResetOfficeUseForm | StartGetOfficeUseForm | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_OFFICE_USE_FORM, payload: true });
        const data = await OfficeUseAPI.getOfficeUseByUserEmail(email);
        dispatch({ type: ActionTypes.SET_OFFICE_USE_FORM, payload: data });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_OFFICE_USE_FORM, payload: undefined });
      }
    };
  },
  createOfficeUseForExistingSubscriber(
    email: string,
    onSuccess: () => void,
  ): AsyncAction<SetOfficeUseForm | StartGetOfficeUseForm | HandleAxiosError | FlashMessage | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        dispatch({ type: ActionTypes.START_GET_OFFICE_USE_FORM, payload: false });
        const data = await OfficeUseAPI.createOfficeUse({ email });
        dispatch({ type: ActionTypes.SET_OFFICE_USE_FORM, payload: data });
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess();
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      }
    };
  },
  handleOfficeUseFormValidationError(
    e: OfficeUseFormValidationError,
  ): AsyncAction<SetOfficeUseFormData | FlashMessage> {
    return async (dispatch, getState): Promise<void> => {
      const {
        officeUseForm: { form },
      } = getState();
      if (!form) return;
      const { formData } = form;
      dispatch(Actions.updateOfficeUseFormData({ ...formData, errors: e.errors || [] }));
      requestAnimationFrame(() => focusErrorField());
      dispatch(GeneralActions.flashErrorMessage(e.message));
    };
  },
  resetHasFetchedOfficeUse(): AsyncAction<ResetHasFetchedOfficeUse> {
    return async (dispatch, getState): Promise<void> => {
      const {
        officeUseForm: { hasFetched },
      } = getState();
      if (!hasFetched) return;
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_OFFICE_USE, payload: undefined });
    };
  },
};

// TODO: add back when submitting form functions are created
//
// function showErrorsToUser(dispatch: Dispatch<SetOfficeUseForm | FlashMessage>, newForm: OfficeUseFormData): void {
//   dispatch(Actions.updateOfficeUseFormData(newForm));
//   dispatch(GeneralActions.flashErrorMessage('There are errors with the form. Please correct them below.'));
//   focusErrorField();
//   return;
// }

export * from './types';
