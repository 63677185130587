import { ValidPath } from '@routes/lib';
import { ActionTypes, NavigationEnded, StartNavigation } from './types';

export type Actions = NavigationEnded | StartNavigation;

export const Actions = {
  startNavigation(currentLocation: ValidPath, goBackLocation: ValidPath): StartNavigation {
    return { type: ActionTypes.START_NAVIGATION, payload: { currentLocation, goBackLocation } };
  },
  navigationEnded(): NavigationEnded {
    return { type: ActionTypes.NAVIGATION_ENDED, payload: undefined };
  },
};

export * from './types';
