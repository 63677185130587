import {
  KYCFormDataAllRequired,
  KYCFormFormatting,
  KYCFormValidation,
  KYCFormData,
  validateKYCForm,
  KYCFormValidationError,
  RequestPayloads,
  KYC,
  updateComputedKYCValues,
  KYCState,
  isAxiosError,
  isKYCFormValidationError,
  Organization,
} from 'shared';
import { KYCAPI } from '@api';
import { focusErrorField } from '@utils/domHelpers';
import { debounce } from '@utils/lib';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { Actions as CurrentUserActions, GetCurrentUser, ResetHasFetchedCurrentUser } from '../currentUser';
import { Actions as GeneralActions, FlashMessage, SetShowLoadingOverlay } from '../general';
import { AsyncAction, Dispatch } from '../types';
import {
  ActionTypes,
  SubmitKYC,
  SetKYCForm,
  ResetKYCForm,
  StartGetKYCForm,
  SetKYCFormData,
  ResetHasFetchedKYC,
} from './types';

export type Actions = SubmitKYC | SetKYCForm | ResetKYCForm | StartGetKYCForm | SetKYCFormData | ResetHasFetchedKYC;

async function updateKYC(kycId: number, formData: KYCFormData): Promise<KYC> {
  const kyc = await KYCAPI.updateKYC(kycId, { formData });
  return kyc;
}

const debouncedUpdateKYC: (kycId: number, formData: KYCFormData) => Promise<KYC> = debounce(updateKYC, 1000) as (
  kycId: number,
  formData: KYCFormData,
) => Promise<KYC>;

export const Actions = {
  updateKYCFormField(
    field: keyof KYCFormDataAllRequired,
    value: KYCFormDataAllRequired[keyof KYCFormDataAllRequired],
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): AsyncAction<SetKYCForm> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm,
        currentUser: { data: currentUser },
      } = getState();
      if (!kycForm.form) {
        return;
      }
      const isCurrentUserTheSubscriber = currentUser?.user_id === kycForm.form.subscriberUserId;
      if (kycForm.form.state === 'complete') {
        return;
      }
      if (
        !(['inProgress', 'changesRequestedByAgent'] as KYCState[]).includes(kycForm.form.state) &&
        isCurrentUserTheSubscriber &&
        !(currentUser?.roles.includes('eq') || currentUser?.roles.includes('admin'))
      ) {
        return;
      }
      if ((['email'] as (keyof KYCFormDataAllRequired)[]).includes(field)) {
        return;
      }
      const formattedValue = KYCFormFormatting(field, e)(value);
      const validator = KYCFormValidation[field] as (
        v: typeof value,
        f: KYCFormData,
        isSubscriber: boolean,
        org?: Organization,
      ) => KYCFormData['errors'][number] | void;
      const error = validator(
        formattedValue,
        kycForm.form.formData,
        kycForm.form.state !== 'initialized',
        kycForm.form.subscriber?.organization,
      );
      const newErrors = kycForm.form.formData.errors
        .filter((error) => !error.fieldCausingError.includes(field))
        .concat(error ? [error] : []);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        [field]: formattedValue,
        errors: newErrors,
      };
      const newFormWithComputed = updateComputedKYCValues(newForm);
      dispatch(Actions.updateKYCFormData(newFormWithComputed));
    };
  },
  updateKYCFormData(
    newKYCFormData: KYCFormData,
  ): AsyncAction<SetKYCFormData | HandleAxiosError | SetKYCForm | GetCurrentUser | ResetHasFetchedCurrentUser> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm: { form },
        currentUser,
      } = getState();
      if (!form) {
        return;
      }
      try {
        dispatch({ type: ActionTypes.SET_KYC_FORM_DATA, payload: newKYCFormData });
        const kyc = await debouncedUpdateKYC(form.id, newKYCFormData);
        const {
          kycForm: { form: currentForm },
        } = getState();
        dispatch({
          type: ActionTypes.SET_KYC_FORM,
          payload: {
            ...kyc,
            formData: currentForm?.formData || newKYCFormData,
          },
        });
        if (kyc.subscriberUserId === currentUser.data?.user_id) {
          dispatch(CurrentUserActions.resetHasFetchedCurrentUser());
          dispatch(CurrentUserActions.getCurrentUserInfo());
        }
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        return;
      }
    };
  },
  getKYC(id: number, shouldReload = true): AsyncAction<SetKYCForm | ResetKYCForm | StartGetKYCForm | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_KYC_FORM, payload: shouldReload });
        const data = await KYCAPI.getKYC(id);
        const defaultedData = {
          ...data,
          formData: {
            ...data.formData,
            numberOfFinancialDebts: data.formData.numberOfFinancialDebts || 1,
            numberOfNonFinancialDebts: data.formData.numberOfNonFinancialDebts || 1,
            mobilePhone: data.formData.mobilePhone || data.subscriber?.user_metadata?.phone_number,
          },
        } as KYC;
        dispatch({
          type: ActionTypes.SET_KYC_FORM,
          payload: defaultedData,
        });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      }
    };
  },
  getKYCByUserEmail(email: string): AsyncAction<SetKYCForm | ResetKYCForm | StartGetKYCForm | HandleAxiosError> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_KYC_FORM, payload: true });
        const data = await KYCAPI.getKYCByUserEmail(email);
        const defaultedData = {
          ...data,
          formData: {
            ...data.formData,
            numberOfFinancialDebts: data.formData.numberOfFinancialDebts || 1,
            numberOfNonFinancialDebts: data.formData.numberOfNonFinancialDebts || 1,
            mobilePhone: data.formData.mobilePhone || data.subscriber?.user_metadata?.phone_number,
          },
        } as KYC;
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: defaultedData });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      }
    };
  },
  inviteSubscriber(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form) {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, false, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.inviteSubscriber(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `Successfully invited ${newForm.firstName} ${newForm.lastName} to fill out the Client Registration Form. You have been CC'ed on the invitation email.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  inviteSubscriberToRenewKYC(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form) {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, false, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.inviteSubscriber(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `Successfully invited ${newForm.firstName} ${newForm.lastName} to renew their Client Registration Form. You have been CC'ed on the invitation email.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  requestApproval(
    onSuccess = (): undefined => undefined,
  ): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      try {
        if (
          !kycForm.form ||
          (kycForm.form.state !== 'inProgress' && kycForm.form.state !== 'changesRequestedByAgent')
        ) {
          return;
        }
        const errors = validateKYCForm(kycForm.form.formData, true, kycForm.form.subscriber?.organization);
        const newForm: KYCFormData = {
          ...kycForm.form.formData,
          errors,
        };
        if (errors.length) {
          dispatch(Actions.updateKYCFormData(newForm));
          dispatch(GeneralActions.flashErrorMessage('There are errors with the form. Please correct them below.'));
          focusErrorField();
          return;
        }
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.requestApproval(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        onSuccess();
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  requestChanges(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const requestableStates: KYCState[] = ['underReviewByAgent', 'underReviewByOrgReviewer'];
      const { kycForm } = getState();
      if (!kycForm.form || !requestableStates.includes(kycForm.form.state)) {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, true, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.requestChanges(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `Notified ${newForm.firstName} ${newForm.lastName} to that changes are needed to his/her Client Registration Form.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  agentApproveForm(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || !['underReviewByAgent'].includes(kycForm.form.state)) {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, true, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.agentApproveForm(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        if (kyc.state === 'complete') {
          dispatch(
            GeneralActions.flashSuccessMessage(
              `${newForm.firstName} ${newForm.lastName}'s Client Registration Form is complete. His/Her Agent has been notified. A Subscription Package may now be completed on their behalf and sent to DocuSign.`,
            ),
          );
        } else if (kyc.state === 'underReviewByOrgReviewer') {
          dispatch(
            GeneralActions.flashSuccessMessage(
              `${newForm.firstName} ${newForm.lastName}'s Client Registration Form is now under review by ${
                kycForm.form.subscriber?.organization?.name || 'Organization'
              }.`,
            ),
          );
        }
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  orgReviewerApproveForm(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || kycForm.form.state !== 'underReviewByOrgReviewer') {
        return;
      }
      kycForm.form.subscriber?.organization?.name;
      const errors = validateKYCForm(kycForm.form.formData, true, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.orgReviewerApproveForm(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `${newForm.firstName} ${newForm.lastName}'s Client Registration Form is complete. His/Her Agent has been notified. A Subscription Package may now be completed on their behalf and sent to DocuSign.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  quickModification(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || kycForm.form.state !== 'complete') {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, false, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.quickModification(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `${newForm.firstName} ${newForm.lastName}'s Client Registration Form is ready for editing.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  markComplete(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || kycForm.form.state !== 'quickModification') {
        return;
      }
      const errors = validateKYCForm(kycForm.form.formData, true, kycForm.form.subscriber?.organization);
      const newForm: KYCFormData = {
        ...kycForm.form.formData,
        errors,
      };
      if (errors.length) {
        return showErrorsToUser(dispatch, newForm);
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.markComplete(kycForm.form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
        dispatch(
          GeneralActions.flashSuccessMessage(
            `${newForm.firstName} ${newForm.lastName}'s Client Registration Form is now complete. A Subscription Package may now be completed on their behalf and sent to DocuSign.`,
          ),
        );
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  renewForm(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || kycForm.form.state !== 'complete') {
        return;
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.renewForm(kycForm.form.id);
        dispatch(
          GeneralActions.flashSuccessMessage(
            `${kycForm.form.formData.firstName} ${kycForm.form.formData.lastName}'s Client Registration Form is ready for editing. Please make any updates you may need. When finished, click the button at the bottom to notify the client that their Client Registration Form is ready for their input.`,
          ),
        );
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  cancelRenewalProcess(): AsyncAction<SetKYCForm | ResetKYCForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const { kycForm } = getState();
      if (!kycForm.form || kycForm.form.state !== 'renewing') {
        return;
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const kyc = await KYCAPI.cancelRenewalProcess(kycForm.form.id);
        dispatch(
          GeneralActions.flashSuccessMessage(
            `${kycForm.form.formData.firstName} ${kycForm.form.formData.lastName}'s Client Registration Form is no longer renewing. It has been reverted to the "Complete" state.`,
          ),
        );
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: kyc });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_KYC_FORM, payload: undefined });
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  createKYC(
    newKYC: RequestPayloads['createKYC'],
    onError: (error: KYCFormValidationError) => void,
    onSuccess: () => void,
  ): AsyncAction<SetKYCForm | StartGetKYCForm | HandleAxiosError | FlashMessage | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      try {
        const errors = validateKYCForm(newKYC.formData, false);
        if (errors.length) {
          throw new KYCFormValidationError(errors);
        }
        dispatch(GeneralActions.showLoadingOverlay());
        dispatch({ type: ActionTypes.START_GET_KYC_FORM, payload: true });
        const data = await KYCAPI.createKYC(newKYC);
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: data });
        onSuccess();
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
        if (isKYCFormValidationError(e)) {
          onError(e);
          requestAnimationFrame(() => {
            focusErrorField();
          });
          return;
        }
      }
    };
  },
  createKYCForExistingSubscriber(
    email: string,
    onSuccess: () => void,
  ): AsyncAction<SetKYCForm | StartGetKYCForm | HandleAxiosError | FlashMessage | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        dispatch({ type: ActionTypes.START_GET_KYC_FORM, payload: false });
        const data = await KYCAPI.createKYCForExistingSubscriber({ email });
        dispatch({ type: ActionTypes.SET_KYC_FORM, payload: data });
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess();
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      }
    };
  },
  handleKYCFormValidationError(e: KYCFormValidationError): AsyncAction<SetKYCFormData | FlashMessage> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm: { form },
      } = getState();
      if (!form) {
        return;
      }
      const { formData } = form;
      dispatch(
        Actions.updateKYCFormData({
          ...formData,
          errors: e.errors || [],
        }),
      );
      requestAnimationFrame(() => {
        focusErrorField();
      });
      dispatch(GeneralActions.flashErrorMessage(e.message));
    };
  },
  resetHasFetchedKYC(): AsyncAction<ResetHasFetchedKYC> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_KYC, payload: undefined });
    };
  },
  uploadPhotoID(file: File): AsyncAction<SetKYCFormData | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm: { form },
      } = getState();
      if (!form) {
        return;
      }
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        dispatch(
          GeneralActions.flashWarningMessage(
            'This may take up to 30 seconds. Please do not exit or refresh the page until the upload is complete.',
          ),
        );
        const newKYC = await KYCAPI.uploadPhotoId(form.id, file);
        dispatch({ type: ActionTypes.SET_KYC_FORM_DATA, payload: newKYC });
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  fillWithFakeData(): AsyncAction<SetKYCFormData | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      const {
        kycForm: { form },
      } = getState();
      if (!form) {
        return;
      }
      try {
        const newKYC = await KYCAPI.fillWithFakeData(form.id);
        dispatch({ type: ActionTypes.SET_KYC_FORM_DATA, payload: newKYC });
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      }
    };
  },
};

function showErrorsToUser(dispatch: Dispatch<SetKYCForm | FlashMessage>, newForm: KYCFormData): void {
  dispatch(Actions.updateKYCFormData(newForm));
  dispatch(GeneralActions.flashErrorMessage('There are errors with the form. Please correct them below.'));
  focusErrorField();
  return;
}

export * from './types';
