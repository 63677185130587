import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch, Actions } from '@actions';
import { KYCAPI } from '@api';
import { PATHS } from '@routes/lib';
import { State } from '@store';

export interface KYCEmailFormHook {
  kyc: State['kycForm']['form'];
  isLoading: State['kycForm']['isLoading'];
  error: Error | null;
  createKYCForExistingSubscriber(): void;
}

export function useKYCByEmail(email: string): KYCEmailFormHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [kyc, setKYC] = useState<State['kycForm']['form'] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const stillValid = useRef(true);

  async function getKYCByUserEmail(email: string): Promise<void> {
    try {
      setIsLoading(true);
      const kyc = await KYCAPI.getKYCByUserEmail(email);
      if (stillValid.current) {
        setKYC(kyc);
      }
    } catch (e) {
      if (stillValid.current) {
        setError(e as Error);
      }
    } finally {
      if (stillValid.current) {
        setIsLoading(false);
      }
    }
  }

  function createKYCForExistingSubscriber(): void {
    if (!isLoading && !kyc) {
      dispatch(
        Actions.createKYCForExistingSubscriber(email, () => {
          history.push(`${PATHS.SUBSCRIBERS}/${email}/client-registration`);
        }),
      );
    }
  }

  useEffect(() => {
    stillValid.current = true;
    if (email) {
      getKYCByUserEmail(email);
    }
    return (): void => {
      stillValid.current = false;
    };
  }, [email]);

  return {
    isLoading,
    kyc,
    error,
    createKYCForExistingSubscriber,
  };
}
