import { FullUser } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  GET_USERS = 'GET_USERS',
  START_GET_USERS = 'START_GET_USERS',
  RESET_HAS_FETCHED_USERS = 'RESET_HAS_FETCHED_USERS',
}

export interface GetUsers extends Action {
  type: typeof ActionTypes.GET_USERS;
  payload: FullUser[];
}

export interface StartGetUsers extends Action {
  type: typeof ActionTypes.START_GET_USERS;
}

export interface ResetHasFetchedUsers extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_USERS;
}
