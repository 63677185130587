import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsePayloads } from 'shared';
import * as SubscriptionPackageAPI from '@api/subscriptionPackage';
import { State } from '@store';

export interface UseDocuSignTemplatesForSubscriptionPackageResults {
  docusignTemplates: ResponsePayloads['getDocuSignTemplatesForSubscriptionPackage'];
}

export function useDocuSignTemplatesForSubscriptionPackage(): UseDocuSignTemplatesForSubscriptionPackageResults {
  const subscriptionPackageForm = useSelector<State>(
    (state) => state.subscriptionPackageForm,
  ) as State['subscriptionPackageForm'];
  const [docusignTemplates, setDocusignTemplates] = useState<
    ResponsePayloads['getDocuSignTemplatesForSubscriptionPackage']
  >([]);

  const getDocuSignTemplatesForSubscriptionPackage = useCallback(async (): Promise<void> => {
    if (!subscriptionPackageForm.form?.id) {
      return;
    }
    const docusignTemplates = await SubscriptionPackageAPI.getDocuSignTemplatesForSubscriptionPackage({
      subscriptionPackageId: subscriptionPackageForm.form.id,
    });
    setDocusignTemplates(docusignTemplates);
  }, [subscriptionPackageForm.form?.id]);

  useEffect(() => {
    getDocuSignTemplatesForSubscriptionPackage();
  }, [getDocuSignTemplatesForSubscriptionPackage]);

  return {
    docusignTemplates,
  };
}
