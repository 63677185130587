import { ReactElement } from 'react';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function EmploymentInformation(): ReactElement {
  const formFields = useKYCFormFields();
  return (
    <div className="form-section">
      <h2 className="title">Current Employment Information (Last 5 Years)</h2>
      <div className="flex form-row">
        <EquiInput {...formFields.occupation} />
        <EquiInput {...formFields.typeOfBusiness} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.employerName} />
        <EquiInput {...formFields.employerPhone} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields.employerSince} />
        <EquiInput {...formFields.previousEmployer} />
      </div>
    </div>
  );
}

export default EmploymentInformation;
