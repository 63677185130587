import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import {
  RequestPayloads,
  ResponsePayloads,
  SubscriptionPackage,
  SubscriptionPackageFormData,
  SubscriptionPackageState,
} from 'shared';
import { Actions, Dispatch } from '@actions';
import { SubscriptionPackageAPI } from '@api';
import { State } from '@store';

export interface SubscriptionPackageHook {
  subscriptionPackages: ResponsePayloads['getSubscriptionPackagesByUserEmail'];
  isLoading: boolean;
  error: Error | null;
  createSubscriptionPackage(
    kycId: number,
    newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
    onSuccess?: (subscriptionPackage: SubscriptionPackage) => void,
    onError?: (error: SubscriptionPackageFormData['errors'][number]) => void,
  ): void;
}

export function useSubscriptionPackagesByEmail(
  email: string,
  state?: SubscriptionPackageState,
): SubscriptionPackageHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    ResponsePayloads['getSubscriptionPackagesByUserEmail']
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const stillValid = useRef(true);

  const dispatch = reduxDispatch as Dispatch;

  async function getSubscriptionPackageByUserEmail(email: string, state?: SubscriptionPackageState): Promise<void> {
    try {
      setIsLoading(true);
      const subscriptionPackage = await SubscriptionPackageAPI.getSubscriptionPackagesByUserEmail(email, state);
      if (stillValid.current) {
        setSubscriptionPackages(subscriptionPackage);
        setIsLoading(false);
      }
    } catch (e) {
      if (stillValid.current) {
        setError(e as Error);
        setIsLoading(false);
      }
    } finally {
      if (stillValid.current) {
        setIsLoading(false);
      }
    }
  }
  function createSubscriptionPackage(
    kycId: number,
    newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
    onSuccess: (subscriptionPackage: SubscriptionPackage) => void = (): void => undefined,
    onError: (error: SubscriptionPackageFormData['errors'][number]) => void = (): void => undefined,
  ): void {
    dispatch(Actions.createSubscriptionPackage(kycId, newSubscriptionPackage, onSuccess, onError));
  }

  useEffect(() => {
    stillValid.current = true;
    if (email) {
      getSubscriptionPackageByUserEmail(email, state);
    }
    return (): void => {
      stillValid.current = false;
    };
  }, [email, state]);
  return { createSubscriptionPackage, subscriptionPackages, isLoading, error };
}
