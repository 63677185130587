import { ReactElement } from 'react';
import { EQ_LOGO_URL } from 'shared';

interface Props {
  size: number;
}

function Logo(props: Props): ReactElement {
  const { size } = props;
  return <img className="Logo" width={size} alt="EquiGenesis" src={EQ_LOGO_URL} />;
}

export default Logo;
