import React, { useState } from 'react';
import anyWindow from '@utils/anyWindow';

const anyDocument = document as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export function useFullscreen(
  elRef: React.RefObject<HTMLElement>,
): [isFullscreen: boolean, setFullscreen: () => Promise<void>] {
  const [isFullscreen, setIsFullscreen] = useState(!!getBrowserFullscreenElement());

  const setFullscreen = async (): Promise<void> => {
    if (!elRef.current || isFullscreen) {
      return;
    }
    try {
      await elRef.current.requestFullscreen();
      setIsFullscreen(!!getBrowserFullscreenElement());
    } catch {
      setIsFullscreen(false);
    }
  };

  React.useLayoutEffect(() => {
    document.onfullscreenchange = (): void => setIsFullscreen(!!getBrowserFullscreenElement());

    return (): void => {
      document.onfullscreenchange = null;
    };
  });
  return [isFullscreen, setFullscreen];
}

function getBrowserFullscreenElement(): Element | null {
  return (
    document.fullscreenElement ||
    anyDocument.mozFullScreenElement ||
    anyDocument.msFullscreenElement ||
    anyDocument.webkitFullscreenElement ||
    null
  );
}

anyWindow.getBrowserFullscreenElement = getBrowserFullscreenElement;
