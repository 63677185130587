import { ReactElement } from 'react';
import Icon from '@components/common/Icon';

import './styles.scss';

interface Props {
  text?: string;
}

export default function NoSearchResults({ text = 'No results' }: Props): ReactElement {
  return (
    <div className="NoSearchResults flex justify-center column align-center">
      <em>{text}</em>
      <Icon type="noResults" />
    </div>
  );
}
