import { useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

interface HookLockHook {
  hasLock: boolean;
  releaseLock: () => void;
  acquireLock: () => void;
}

export function useHookLock(lockId: string): HookLockHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const locks = useSelector<State>((state) => state.locks) as State['locks'];
  const lockUserId = useRef(lockId + Math.random());

  const dispatch = reduxDispatch as Dispatch;

  const lockHolder = locks[lockId];

  const hasLock = !!lockHolder && lockUserId.current === lockHolder;

  function acquireLock(): void {
    dispatch(Actions.acquireLock({ lockId, acquirerId: lockUserId.current }));
  }

  function releaseLock(): void {
    if (hasLock) {
      dispatch(Actions.releaseLock({ lockId, releaserId: lockUserId.current }));
    }
  }

  useEffect(() => {
    acquireLock();
    return (): void => {
      releaseLock();
    };
  }, [lockHolder]);

  return { hasLock, acquireLock, releaseLock };
}
