import { ReactElement, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useStore } from 'react-redux';
import { Winterpeg } from 'shared';
import { Actions, Dispatch } from '@actions';
import { Actions as ApiActions } from '@actions/api';
import { Actions as GeneralActions } from '@actions/general';
import { runWinterPegScenarioCPhase1TerminationProcess } from '@api/winterpeg';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import { useGeneral } from '@hooks';
import { Page } from '@layouts';
import { State } from '@reducers';
import { PATHS } from '@routes/lib';

const {
  winterpegScenarioCPhase1Data: data,
  programs,
  camelCaseToTitleCase,
  winterpegScenarioCPhase1Schema: adminTerminationAddOnSchema,
} = Winterpeg;

type TTableData = {
  key: keyof Winterpeg.TProgramData;
  value: Winterpeg.TProgramData[keyof Winterpeg.TProgramData];
};

export default function WinterpegScenarioCPhase1(): ReactElement {
  const { showLoadingOverlay, hideLoadingOverlay } = useGeneral();
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const [selectedProgram, setSelectedProgram] = useState(programs[0]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const programData = data[selectedProgram];

  const tableData = Object.entries(programData).map(([key, value]) => ({ key, value }));

  const handleSubmit = async (isTest: boolean): Promise<void> => {
    if (!selectedFile) {
      return;
    }
    if (
      !confirm(
        `${
          isTest ? '[TEST]' : ''
        } Warning: This will immediately generate a DocuSign Termination Package for all clients in the file "${
          selectedFile?.name
        }" for the program "${selectedProgram}". This cannot be undone. Are you sure you wish to proceed?`,
      )
    ) {
      return;
    }
    try {
      showLoadingOverlay();
      await runWinterPegScenarioCPhase1TerminationProcess(selectedProgram, selectedFile, isTest);
      dispatch(GeneralActions.flashSuccessMessage('Scenario C Phase 1 Termination Process Successfully Started'));
      setConfirmation(false);
    } catch (e) {
      dispatch(ApiActions.handleAxiosError(e));
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <Page
      className="Winterpeg"
      renderHeader={(): ReactElement => <h1 className="text-center">Winterpeg Scenario C Phase 1</h1>}
      defaultBackLocation={PATHS.ADMIN_WINTERPEG}
    >
      {!adminTerminationAddOnSchema.isValidSync(programData) ? (
        <div>Error</div>
      ) : (
        <>
          <div className="mt-10 mb-10">
            Use this form to generate DocuSign envelopes for the EQ Deal Termination Program
          </div>
          <Form turnOffAutoComplete={true} className="min-h-0">
            <GeneralInput
              formLabel="Program Name"
              name="programName"
              onChange={setSelectedProgram as OnChange}
              type="select"
              labelAsOption={false}
              value={selectedProgram}
              options={programs.map((value) => ({ displayName: value, value }))}
            />
            <h3>"{selectedProgram}" Program Information</h3>
            <DataTable value={tableData} className="w-full mb-10">
              <Column field="key" header="Field" body={(d: TTableData): string => camelCaseToTitleCase(d.key)} />
              <Column
                field="value"
                header="Value"
                body={(d: TTableData): string =>
                  d.key.includes('Fee') ? `$${d.value.toString()}` : d.value.toString()
                }
              />
            </DataTable>
            <GeneralInput
              name="file"
              formLabel="Client Data for Program"
              type="file"
              onChange={setSelectedFile as OnChange}
              accept=".csv"
            />
            <GeneralInput
              className="mt-4"
              wrapLabel
              type="checkbox"
              formLabel="I have double checked that all of the CSV and program information is correct. I understand that once I submit, there is no way to correct any mistakes."
              name="confirmation"
              onChange={(setConfirmation as unknown) as OnChange}
              checked={confirmation}
            />
            <div className="flex gap-10">
              <EquiButton
                value="Submit"
                className="mt-10"
                disabled={!selectedFile || !selectedProgram || !confirmation}
                kind={ButtonType.secondary}
                onClick={(): Promise<void> => handleSubmit(false)}
              />
              <EquiButton
                value="Submit Test"
                className="mt-10"
                disabled={!selectedFile || !selectedProgram || !confirmation}
                kind={ButtonType.secondary}
                onClick={(): Promise<void> => handleSubmit(true)}
              />
            </div>
          </Form>
        </>
      )}
    </Page>
  );
}
