import { useSelector, useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface GeneralHook {
  general: State['general'];
  showLoadingOverlay(): void;
  hideLoadingOverlay(): void;
  stopFlashMessage(): void;
}

export function useGeneral(): GeneralHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const general = useSelector<State>((state) => state.general) as State['general'];

  const dispatch = reduxDispatch as Dispatch;

  function showLoadingOverlay(): void {
    dispatch(Actions.showLoadingOverlay());
  }
  function hideLoadingOverlay(): void {
    dispatch(Actions.hideLoadingOverlay());
  }
  function stopFlashMessage(): void {
    dispatch(Actions.stopFlashMessage());
  }

  return { general, showLoadingOverlay, hideLoadingOverlay, stopFlashMessage };
}
