import { useRef, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SiteSetting, isAxiosError } from 'shared';
import { Actions, Dispatch } from '@actions';
import * as SiteSettings from '@api/siteSettings';
import { PATHS } from '@routes/lib';
import { State } from '@store';
import { displaySiteSettingName } from '@utils/siteSettings';

export interface SiteSettingsHook {
  siteSettings: SiteSetting[] | null;
  isLoading: boolean;
  error: boolean;
  updateSiteSetting: (settingId: number, newValue: string) => Promise<void | string>;
}

export function useSiteSettings(): SiteSettingsHook {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [siteSettings, setSiteSettings] = useState<SiteSetting[] | null>(null);
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const stillValid = useRef(true);

  async function updateSiteSetting(settingId: number, newValue: string): Promise<string | void> {
    try {
      dispatch(Actions.showLoadingOverlay());
      const updatedSetting = await SiteSettings.updateSiteSetting({ siteSettingId: settingId }, { newValue });
      setSiteSettings(
        siteSettings?.map((siteSetting) => (siteSetting.id === settingId ? updatedSetting : siteSetting)) || null,
      );
      dispatch(Actions.flashSuccessMessage(`${displaySiteSettingName(updatedSetting.settingName)} has been updated.`));
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e.response?.data.errors.map((err: Error) => err.message).join('. ');
        message && dispatch(Actions.flashErrorMessage(message));
        return message;
      }
    } finally {
      dispatch(Actions.hideLoadingOverlay());
    }
  }
  useEffect(() => {
    (async (): Promise<void> => {
      stillValid.current = true;
      setIsLoading(true);
      setError(false);
      setSiteSettings(null);
      try {
        const siteSettings = await SiteSettings.getSiteSettings();
        if (stillValid.current) {
          setSiteSettings(siteSettings);
          setIsLoading(false);
        }
      } catch (e) {
        if (isAxiosError(e) && e.response?.status !== 404) {
          dispatch(Actions.handleAxiosError(e));
        } else if (isAxiosError(e) && e.response?.status === 404) {
          history.replace(PATHS.NOT_FOUND);
        } else {
          if (stillValid.current) {
            setError(true);
          }
        }
        if (stillValid.current) {
          setIsLoading(false);
        }
      }
    })();
    return (): void => {
      stillValid.current = false;
    };
  }, []);

  return { siteSettings, isLoading, error, updateSiteSetting };
}
