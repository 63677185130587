import React, { ReactElement } from 'react';
import {
  Descriptions,
  Lenders,
  OutstandingDebts,
  PaymentAmounts,
  PaymentFrequencies,
  Terms,
  CorrespondingAssets,
} from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';
import { capitalize } from '@utils/stringUtils';

interface Props {
  debtType: 'financial' | 'nonFinancial';
  debtNumber: 1 | 2 | 3 | 4 | 5 | 6;
}

const createKey = ({
  debtType,
  debtNumber,
  name,
}: Props & {
  name:
    | 'description'
    | 'lender'
    | 'outstandingDebt'
    | 'paymentAmount'
    | 'paymentFrequency'
    | 'term'
    | 'correspondingAsset';
}): string => `${debtType}${capitalize(name)}${debtNumber}`;

function Debt({ debtType, debtNumber }: Props): ReactElement {
  const formFields = useKYCFormFields();
  const debtTypeDisplay = {
    financial: 'Financial',
    nonFinancial: 'Non-Financial',
  };

  const description = createKey({ debtType, debtNumber, name: 'description' }) as keyof Descriptions;
  const lender = createKey({ debtType, debtNumber, name: 'lender' }) as keyof Lenders;
  const outstandingDebt = createKey({ debtType, debtNumber, name: 'outstandingDebt' }) as keyof OutstandingDebts;
  const paymentAmount = createKey({ debtType, debtNumber, name: 'paymentAmount' }) as keyof PaymentAmounts;
  const paymentFrequency = createKey({ debtType, debtNumber, name: 'paymentFrequency' }) as keyof PaymentFrequencies;
  const term = createKey({ debtType, debtNumber, name: 'term' }) as keyof Terms;
  const correspondingAsset = createKey({
    debtType,
    debtNumber,
    name: 'correspondingAsset',
  }) as keyof CorrespondingAssets;

  return (
    <React.Fragment key={description}>
      <h3 className="title justify-center flex spacer">
        {debtTypeDisplay[debtType]} Debt {debtNumber}
      </h3>
      <div className="flex form-row">
        <EquiInput {...formFields[description]} />
        <EquiInput {...formFields[lender]} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields[paymentAmount]} />
        <EquiInput {...formFields[outstandingDebt]} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields[paymentFrequency]} />
        <EquiInput {...formFields[term]} />
      </div>
      <div className="flex form-row">
        <EquiInput {...formFields[correspondingAsset]} />
        <div className="max-width"></div>
      </div>
    </React.Fragment>
  );
}

export default Debt;
