import { ReactElement, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BrandingContext } from '@contexts';
import { PATHS } from '@routes/lib';
import Icon from '../Icon';

import './styles.scss';

interface Props {
  email: string;
}

export default function OfficeUseButton({ email }: Props): ReactElement {
  const { showBrandingInNav } = useContext(BrandingContext);
  return (
    <Link
      to={`${PATHS.OFFICE_USES}/${email}/office-use`}
      className={classNames('OfficeUseButton', { 'with-branding': showBrandingInNav })}
    >
      <div className="actual-button flex align-center bg-white shadow-lg p-4 rounded-lg hover:bg-opacity-100 transform hover:scale-105">
        <Icon type="officeUseIcon" />
        <span>Office Use Form</span>
      </div>
    </Link>
  );
}
