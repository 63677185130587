import { FinancialInformation } from 'shared';
import { ProtoFields } from '../types';

export const financialInformationFields: ProtoFields<keyof FinancialInformation> = {
  cash: { formLabel: 'Cash and Cash Equivalents', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt1: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt2: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt3: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt4: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt5: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialOutstandingDebt6: { formLabel: 'Outstanding Debt', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialOutstandingDebt1: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  nonFinancialOutstandingDebt2: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  nonFinancialOutstandingDebt3: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  nonFinancialOutstandingDebt4: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  nonFinancialOutstandingDebt5: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  nonFinancialOutstandingDebt6: {
    formLabel: 'Outstanding Debt',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  financialDescription1: { formLabel: 'Description' },
  financialDescription2: { formLabel: 'Description' },
  financialDescription3: { formLabel: 'Description' },
  financialDescription4: { formLabel: 'Description' },
  financialDescription5: { formLabel: 'Description' },
  financialDescription6: { formLabel: 'Description' },
  nonFinancialDescription1: { formLabel: 'Description' },
  nonFinancialDescription2: { formLabel: 'Description' },
  nonFinancialDescription3: { formLabel: 'Description' },
  nonFinancialDescription4: { formLabel: 'Description' },
  nonFinancialDescription5: { formLabel: 'Description' },
  nonFinancialDescription6: { formLabel: 'Description' },
  fixedIncome: { formLabel: 'Fixed Income', fieldPrefix: '$', underlyingClassName: 'text-right' },
  lastYearTaxableIncome: { formLabel: 'Last Year', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialLender1: { formLabel: 'Lender' },
  financialLender2: { formLabel: 'Lender' },
  financialLender3: { formLabel: 'Lender' },
  financialLender4: { formLabel: 'Lender' },
  financialLender5: { formLabel: 'Lender' },
  financialLender6: { formLabel: 'Lender' },
  nonFinancialLender1: { formLabel: 'Lender' },
  nonFinancialLender2: { formLabel: 'Lender' },
  nonFinancialLender3: { formLabel: 'Lender' },
  nonFinancialLender4: { formLabel: 'Lender' },
  nonFinancialLender5: { formLabel: 'Lender' },
  nonFinancialLender6: { formLabel: 'Lender' },
  nonRegisteredCash: { formLabel: 'Cash and Cash Equivalents', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonRegisteredFixedIncome: { formLabel: 'Fixed Income', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonRegisteredOther: { formLabel: 'Other', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonRegisteredPublicEquities: {
    formLabel: 'Public Equities / Investment Funds',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  otherFinancialAssets: { formLabel: 'Other', fieldPrefix: '$', underlyingClassName: 'text-right' },
  otherProperty1: { formLabel: 'Other Property' },
  otherPropertyValue1: { formLabel: 'Property Value', fieldPrefix: '$', underlyingClassName: 'text-right' },
  otherProperty2: { formLabel: 'Other Property' },
  otherPropertyValue2: { formLabel: 'Property Value', fieldPrefix: '$', underlyingClassName: 'text-right' },
  otherProperty3: { formLabel: 'Other Property' },
  otherPropertyValue3: { formLabel: 'Property Value', fieldPrefix: '$', underlyingClassName: 'text-right' },
  otherRealProperty: { formLabel: 'Other Real Property', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount1: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount2: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount3: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount4: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount5: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentAmount6: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount1: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount2: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount3: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount4: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount5: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  nonFinancialPaymentAmount6: { formLabel: 'Payment Amount', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialPaymentFrequency1: { formLabel: 'Payment Frequency' },
  financialPaymentFrequency2: { formLabel: 'Payment Frequency' },
  financialPaymentFrequency3: { formLabel: 'Payment Frequency' },
  financialPaymentFrequency4: { formLabel: 'Payment Frequency' },
  financialPaymentFrequency5: { formLabel: 'Payment Frequency' },
  financialPaymentFrequency6: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency1: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency2: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency3: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency4: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency5: { formLabel: 'Payment Frequency' },
  nonFinancialPaymentFrequency6: { formLabel: 'Payment Frequency' },
  personalResidence: { formLabel: 'Personal Residence', fieldPrefix: '$', underlyingClassName: 'text-right' },
  publicEquities: {
    formLabel: 'Public Equities / Investment Funds',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  spouseLastYearTaxableIncome: { formLabel: 'Last Year', fieldPrefix: '$', underlyingClassName: 'text-right' },
  spouseThisYearExpectedIncome: {
    formLabel: 'This Year (expected)',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  spouseTwoYearTaxableIncome: { formLabel: 'Two Years Ago', fieldPrefix: '$', underlyingClassName: 'text-right' },
  financialCorrespondingAsset1: { formLabel: 'Corresponding Asset (if applicable)' },
  financialCorrespondingAsset2: { formLabel: 'Corresponding Asset (if applicable)' },
  financialCorrespondingAsset3: { formLabel: 'Corresponding Asset (if applicable)' },
  financialCorrespondingAsset4: { formLabel: 'Corresponding Asset (if applicable)' },
  financialCorrespondingAsset5: { formLabel: 'Corresponding Asset (if applicable)' },
  financialCorrespondingAsset6: { formLabel: 'Corresponding Asset (if applicable)' },
  financialTerm1: { formLabel: 'Term (if applicable)' },
  financialTerm2: { formLabel: 'Term (if applicable)' },
  financialTerm3: { formLabel: 'Term (if applicable)' },
  financialTerm4: { formLabel: 'Term (if applicable)' },
  financialTerm5: { formLabel: 'Term (if applicable)' },
  financialTerm6: { formLabel: 'Term (if applicable)' },
  nonFinancialCorrespondingAsset1: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialCorrespondingAsset2: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialCorrespondingAsset3: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialCorrespondingAsset4: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialCorrespondingAsset5: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialCorrespondingAsset6: { formLabel: 'Corresponding Asset (if applicable)' },
  nonFinancialTerm1: { formLabel: 'Term (if applicable)' },
  nonFinancialTerm2: { formLabel: 'Term (if applicable)' },
  nonFinancialTerm3: { formLabel: 'Term (if applicable)' },
  nonFinancialTerm4: { formLabel: 'Term (if applicable)' },
  nonFinancialTerm5: { formLabel: 'Term (if applicable)' },
  nonFinancialTerm6: { formLabel: 'Term (if applicable)' },
  thisYearExpectedIncome: { formLabel: 'This Year (expected)', fieldPrefix: '$', underlyingClassName: 'text-right' },
  totalFinancialAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalLastYearTaxableIncome: {
    formLabel: 'Last Year',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalNetAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalNonFinancialAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalThisYearExpectedIncome: {
    formLabel: 'This Year (expected)',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalTwoYearTaxableIncome: {
    formLabel: 'Two Years Ago',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  twoYearTaxableIncome: { formLabel: 'Two Years Ago', fieldPrefix: '$', underlyingClassName: 'text-right' },
  vehicles: { formLabel: 'Vehicles', fieldPrefix: '$', underlyingClassName: 'text-right' },
  totalAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalDebtsLiabilities: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalFinancialDebts: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  totalNonFinancialDebts: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  netFinancialAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  netNonFinancialAssets: {
    formLabel: '',
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
    readOnly: true,
  },
  notes: { formLabel: 'Notes', type: 'textarea' },
  otherProgram: { formLabel: 'Non-EquiGenesis Program Name(s)' },
  numberOfFinancialDebts: { formLabel: 'This should never be used directly' },
  numberOfNonFinancialDebts: { formLabel: 'This should never be used directly' },
};
