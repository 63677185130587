import { applyMiddleware, compose, createStore, Store } from 'redux';
import reduxThunk from 'redux-thunk';
import { getEnvValue } from 'shared';
import { Dispatch } from '@actions';
import appReducer, { State as AppState } from '@reducers';
import anyWindow from '@utils/anyWindow';

export type State = AppState;

const devtoolsOpts = {
  features: {
    persist: false,
  },
};

const composeWithDevtools =
  (getEnvValue('NODE_ENV') !== 'production' &&
    anyWindow &&
    typeof anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devtoolsOpts)) ||
  compose;

const store: Store<State> = createStore(appReducer, composeWithDevtools(applyMiddleware(reduxThunk)));
export const dispatch = store.dispatch as Dispatch;

export default store;
