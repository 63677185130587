import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface KYCsHook {
  kycs: State['kycs']['data'];
  isLoading: State['kycs']['isLoading'];
}

export function useKYCs(options: { refresh?: boolean } = {}): KYCsHook {
  const { refresh } = options;
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const { isLoading, data: kycs } = useSelector<State>((state) => state.kycs) as State['kycs'];

  useEffect(() => {
    if (refresh) {
      dispatch(Actions.getKYCs());
    }
  }, [refresh]);
  return { kycs, isLoading };
}
