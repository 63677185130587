import { ReactElement } from 'react';
import classnames from 'classnames';
import { Children } from '@custom-types';
import './styles.scss';

interface Props {
  className?: string;
  children: Children;
}

export default function SectionCard({ children, className }: Props): ReactElement {
  return <section className={classnames('SectionCard', className)}>{children}</section>;
}
