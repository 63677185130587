import { CreateUserReqPayload, Role } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  ADD_NEW_USER = 'ADD_NEW_USER',
}

export interface AddNewUser extends Action {
  type: typeof ActionTypes.ADD_NEW_USER;
  payload: CreateUserReqPayload & { roles: Role[] };
}
