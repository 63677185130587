import { intersection } from 'lodash';
import { useSelector, useStore } from 'react-redux';
import { Permission, RequestPayloads, Organization, FullUser, areArraysTheSame } from 'shared';
import { Dispatch, Actions } from '@actions';
import * as UsersAPI from '@api/users';
import { State } from '@store';

export interface CurrentUserHook {
  currentUser: State['currentUser']['data'];
  isCurrentUserAdmin: boolean;
  isCurrentUserAgent: boolean;
  isCurrentUserEQ: boolean;
  isCurrentUserSubscriber: boolean;
  hasAllPermissions: (permissions: Permission[]) => boolean;
  hasAtLeastOnePermission: (permissions: Permission[]) => boolean;
  updateCurrentUser: (user: Omit<RequestPayloads['updateUser'], 'roles'>) => Promise<void>;
  isCurrentUserAdminForOrg: (organization: Organization | null) => boolean;
  isCurrentUserAgentForOrg: (organization: Organization | null) => boolean;
  isCurrentUserClientForOrg: (organization: Organization | null) => boolean;
  isCurrentUserReviewerForOrg: (organization: Organization | null) => boolean;
  isCurrentUserOwnOrgAdmin: () => boolean;
  isCurrentUserOwnOrgAgent: () => boolean;
  isCurrentUserOwnOrgClient: () => boolean;
  isCurrentUserOwnOrgReviewer: () => boolean;
  doesCurrentUserManageOrgUser: (user?: FullUser | null) => boolean;
  doesCurrentUserReviewOrgUser: (user?: FullUser | null) => boolean;
  doesCurrentUserManageUser: (user?: FullUser | null) => boolean;
  canCurrentUserSeeOrgUser: (user?: FullUser | null) => boolean;
  canCurrentUserSeeOrg: (organization?: Organization | null) => organization is Organization;
  doesCurrentUserOrgRequireBranding: () => boolean;
}

export function useCurrentUser(): CurrentUserHook {
  const { data: currentUser } = useSelector<State>((state) => state.currentUser) as State['currentUser'];
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const isCurrentUserAdmin = currentUser?.roles.includes('admin') || false;
  const isCurrentUserAgent = currentUser?.roles.includes('agent') || false;
  const isCurrentUserEQ = currentUser?.roles.includes('eq') || false;
  const isCurrentUserSubscriber = currentUser?.roles.includes('subscriber') || false;

  function hasAllPermissions(permissions: Permission[]): boolean {
    return areArraysTheSame(permissions, currentUser?.permissions || []);
  }
  function hasAtLeastOnePermission(permissions: Permission[]): boolean {
    return intersection(permissions, currentUser?.permissions || []).length > 0;
  }

  async function updateCurrentUser(user: RequestPayloads['updateUser']): Promise<void> {
    if (!currentUser || !currentUser.user_id) {
      return;
    }
    try {
      dispatch(Actions.showLoadingOverlay());
      await UsersAPI.updateUser(currentUser.user_id, user);
      dispatch(Actions.resetHasFetchedCurrentUser());
      dispatch(Actions.getCurrentUserInfo());
      dispatch(Actions.flashSuccessMessage('Successfully updated profile information.'));
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
      dispatch(Actions.hideLoadingOverlay());
    }
  }

  function isCurrentUserAdminForOrg(organization: Organization | null): boolean {
    return (
      (currentUser?.organization?.id === organization?.id && currentUser?.organization?.userProperties.isAdmin) || false
    );
  }
  function isCurrentUserAgentForOrg(organization: Organization | null): boolean {
    return (
      (currentUser?.organization?.id === organization?.id && currentUser?.organization?.userProperties.isAgent) || false
    );
  }
  function isCurrentUserClientForOrg(organization: Organization | null): boolean {
    return (
      (currentUser?.organization?.id === organization?.id && currentUser?.organization?.userProperties.isClient) ||
      false
    );
  }
  function isCurrentUserReviewerForOrg(organization: Organization | null): boolean {
    return (
      (currentUser?.organization?.id === organization?.id && currentUser?.organization?.userProperties.isReviewer) ||
      false
    );
  }

  function isCurrentUserOwnOrgAgent(): boolean {
    return !!currentUser?.organization?.userProperties.isAgent;
  }
  function isCurrentUserOwnOrgAdmin(): boolean {
    return !!currentUser?.organization?.userProperties.isAdmin;
  }
  function isCurrentUserOwnOrgClient(): boolean {
    return !!currentUser?.organization?.userProperties.isClient;
  }
  function isCurrentUserOwnOrgReviewer(): boolean {
    return !!currentUser?.organization?.userProperties.isReviewer;
  }
  function doesCurrentUserOrgRequireBranding(): boolean {
    return !!currentUser?.organization?.shouldBrandPortalForOrganization;
  }

  function isCurrentUserInOrg(organization?: Organization | null): boolean {
    return typeof currentUser?.organization?.id === 'number' && currentUser?.organization?.id === organization?.id;
  }

  function isCurrentUserInSameOrgAsUser(user?: FullUser | null): boolean {
    return isCurrentUserInOrg(user?.organization);
  }

  function doesCurrentUserReviewOrgUser(user?: FullUser | null): boolean {
    return isCurrentUserInSameOrgAsUser(user) && isCurrentUserOwnOrgReviewer();
  }

  function doesCurrentUserManageOrgUser(user?: FullUser | null): boolean {
    return isCurrentUserInSameOrgAsUser(user) && isCurrentUserOwnOrgAdmin();
  }

  function doesCurrentUserManageUser(user?: FullUser | null): boolean {
    return doesCurrentUserManageOrgUser(user) || isCurrentUserAdmin || isCurrentUserEQ;
  }

  function canCurrentUserSeeOrgUser(user?: FullUser | null): boolean {
    return isCurrentUserInSameOrgAsUser(user) && (isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer());
  }

  function canCurrentUserSeeOrg(organization?: Organization | null): organization is Organization {
    return (
      !!organization &&
      (isCurrentUserAdmin ||
        isCurrentUserEQ ||
        ((isCurrentUserOwnOrgAgent() || isCurrentUserOwnOrgAdmin() || isCurrentUserOwnOrgReviewer()) &&
          isCurrentUserInOrg(organization)))
    );
  }

  return {
    currentUser,
    isCurrentUserAdmin,
    hasAllPermissions,
    hasAtLeastOnePermission,
    isCurrentUserAgent,
    isCurrentUserEQ,
    isCurrentUserSubscriber,
    updateCurrentUser,
    isCurrentUserAdminForOrg,
    isCurrentUserAgentForOrg,
    isCurrentUserClientForOrg,
    isCurrentUserReviewerForOrg,
    isCurrentUserOwnOrgAgent,
    isCurrentUserOwnOrgAdmin,
    isCurrentUserOwnOrgClient,
    isCurrentUserOwnOrgReviewer,
    doesCurrentUserManageOrgUser,
    doesCurrentUserReviewOrgUser,
    doesCurrentUserManageUser,
    canCurrentUserSeeOrgUser,
    canCurrentUserSeeOrg,
    doesCurrentUserOrgRequireBranding,
  };
}
