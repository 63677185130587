import download from 'downloadjs';
import { PDFDocument } from 'pdf-lib';
import { isChecked, OfficeUse } from 'shared';
import { drawKYCOfficeUseNotes } from './kycLib';
import { onLoad } from './subLib';
import { drawTCPOfficeUseNotes } from './tcpLib';

export async function fillOfficeUseInPDF(file: File | undefined, form: OfficeUse | null): Promise<void> {
  if (!file || !form) {
    return;
  }
  const { formData } = form;
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);
  const existingPdfBytes = await onLoad(fileReader);

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Get officeUse pages from the document
  const kycPage = pdfDoc.getPage(7);
  const tcpPage = pdfDoc.getPage(13);

  // Draw values
  drawKYCOfficeUseNotes(kycPage, formData);
  if (!isChecked(formData.shouldOmitTCP)) {
    drawTCPOfficeUseNotes(tcpPage, formData);
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Trigger the browser to download the PDF document
  download(
    pdfBytes,
    `${file.name.replace('.pdf', '').replace(' ', '-') || 'kyc'}-with-office-use.pdf`,
    'application/pdf',
  );
}
