import { ReactElement, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import './styles.scss';
import SectionCard from '@components/common/SectionCard';
import NoSearchResults from '@components/NoSearchResults';

export interface TableHeader {
  text: string;
  onClick?: () => void;
  isSelected?: boolean;
  isDescending?: boolean;
}

export type TableData = ReactNode | string;

export type TableRow = TableData[];

interface Props {
  className?: string;
  gridClassName?: string;
  renderFilters?: () => ReactElement;
  headers?: TableHeader[];
  data: TableRow[];
  noResultsText?: string;
  showNoResultsOnEmptyData?: boolean;
}

export default function Table({
  renderFilters,
  className,
  data,
  headers,
  noResultsText = 'No results for current search and filters',
  showNoResultsOnEmptyData = true,
  gridClassName,
}: Props): ReactElement {
  if (data.length && headers && headers.length !== data[0].length) {
    throw new Error('number of header columns and number of data columns should be the same length');
  }
  const length = headers?.length || (data && data[0]?.length) || 0;
  return (
    <div className={classnames('Table', 'max-width', className)}>
      {renderFilters && <div className="filter-container flex space-between">{renderFilters()}</div>}
      <SectionCard>
        {headers && (
          <div
            className={classnames(
              'table-header-row table-row',
              'grid',
              { [`grid-${length}`]: !gridClassName },
              gridClassName,
            )}
          >
            {headers.map((headerCell, i) => (
              <div
                key={i}
                onClick={(): void => {
                  if (!headerCell.onClick) {
                    return;
                  }
                  headerCell.onClick();
                }}
                className={classnames('table-cell table-header', {
                  sortable: !!headerCell.onClick,
                  selected: headerCell.isSelected,
                })}
              >
                {headerCell.text}
                {headerCell.onClick && (
                  <FontAwesomeIcon
                    className="chevron"
                    icon={!headerCell.isSelected ? 'caret-right' : headerCell.isDescending ? 'caret-down' : 'caret-up'}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        <div className="table-data max-width">
          {data.map((row, i) => (
            <div
              key={i}
              className={classnames('table-row', 'grid', { [`grid-${length}`]: !gridClassName }, gridClassName)}
            >
              {row.map((cell, i) => (
                <div key={i} className={classnames('table-cell')}>
                  {cell}
                </div>
              ))}
            </div>
          ))}
        </div>
        {showNoResultsOnEmptyData && data.length === 0 && <NoSearchResults text={noResultsText} />}
      </SectionCard>
    </div>
  );
}
