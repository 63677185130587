import { PDFPage } from 'pdf-lib';
import { TrueFalseRadio } from 'shared';

export interface Location {
  yes: number;
  no: number;
}

export function onLoad(fileReader: FileReader): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    fileReader.onload = function (e): void {
      if (!e.target?.result) {
        reject();
        return;
      }
      resolve(e.target.result);
    };
  });
}

export function drawYesNoRadio(page: PDFPage, field: TrueFalseRadio | undefined, y: number, XPosition: Location): void {
  page.drawText('×', { x: field === TrueFalseRadio.true ? XPosition.yes : XPosition.no, y, size: 12 });
}
