import { OfficeUseFormDataAllRequired } from 'shared';
import { kycNotesFields, tcpNotesFields } from './formSections';
import { officeUsePDFFields } from './formSections/officeUsePDF';
import { Fields } from './types';
import { useOfficeUseSubFormFields } from './useSubFormFields';

export function useOfficeUseFormFields(): Fields<keyof OfficeUseFormDataAllRequired> {
  const formFields = useOfficeUseSubFormFields({
    ...kycNotesFields,
    ...tcpNotesFields,
    ...officeUsePDFFields,
  });
  return formFields;
}
