import { AccreditedInvestorStatus } from 'shared';
import { ProtoFields } from '../types';

export const accreditedInvestorStatusFields: ProtoFields<keyof AccreditedInvestorStatus> = {
  registeredRepresentative: {
    formLabel:
      'an individual registered under the securities legislation of a jurisdiction of Canada as a representative of a person registered under the securities legislation of a jurisdiction of Canada as an adviser or dealer;',
    type: 'checkbox',
  },
  formerlyRegisteredRepresentative: {
    formLabel:
      'an individual formerly registered under the securities legislation of a jurisdiction of Canada, other than an individual formerly registered solely as a representative of a limited market dealer under one or both of the Securities Act (Ontario) or the Securities Act (Newfoundland and Labrador);',
    type: 'checkbox',
  },
  financialAssetsOneMillion: {
    formLabel:
      'an individual who, either alone or with a spouse, beneficially owns financial assets having an aggregate realizable value that, before taxes, but net of any related liabilities, exceeds $1,000,000;',
    type: 'checkbox',
  },
  beneficialFinancialAssetsFiveMillion: {
    formLabel:
      'an individual who beneficially owns financial assets having an aggregate realizable value that, before taxes but net of related liabilities, exceeds $5,000,000',
    type: 'checkbox',
  },
  individualIncome200000: {
    formLabel: (
      <span>
        <strong>
          an individual whose net income before taxes exceeded $200,000 in each of the two most recent calendar years
          and who reasonably expects to exceed that net income level in the current calendar year;
        </strong>
      </span>
    ),
    type: 'checkbox',
  },
  withSpouseIncome300000: {
    formLabel: (
      <span>
        <strong>
          an individual whose net income before taxes combined with that of a spouse exceeded $300,000 in each of the
          two most recent calendar years and who reasonably expects to exceed that net income level in the current
          calendar year;
        </strong>
      </span>
    ),
    type: 'checkbox',
  },
  incomeMinimum: { formLabel: '___do_not_use__computed_value___' },
  netAssetsFiveMillion: {
    formLabel: 'an individual who, either alone or with a spouse, has net assets of at least $5,000,000;',
    type: 'checkbox',
  },
  designatedAccreditedInvestor: {
    formLabel:
      'a person that is recognized or designated by the securities regulatory authority or, except in Ontario and Québec, the regulator as an accredited investor;',
    type: 'checkbox',
  },
  otherAccreditedInvestorCriteria: { formLabel: '___do_not_use__computed_value___' },
};
