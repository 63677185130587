import { ReactElement } from 'react';
import { Organization } from 'shared';

interface Props {
  organization: Organization;
}

function NavBranding({ organization }: Props): ReactElement {
  return (
    <div className="bg-branded-bg p-3 w-full flex text-white justify-center items-center">
      <p className="m-0 text-sm font-medium mr-10">Distributed by</p>
      <img className="max-h-8 max-w-xxs" src={organization.darkLogoURL} />
    </div>
  );
}

export default NavBranding;
