import { ReactElement } from 'react';
import classnames from 'classnames';
import {
  SubscriptionPackageFormDataAllRequired,
  SubscriptionPackageEquiInputProps as Props,
  Checkbox,
  PhoneTabs,
  isChecked,
} from 'shared';
import './styles.scss';
import CountryCodesSelect from '../CountryCodesSelect';

type InternalOnChange<T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement = HTMLInputElement> = (
  e: React.ChangeEvent<T>,
) => void;

type OnChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  value: Checkbox | string | File | number,
) => void;

function textOnChange<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
  onChange: (e: React.ChangeEvent<T>, val: string) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { value } = e.currentTarget;
    onChange(e, value);
  };
}

function selectOnChange(
  onChange: (e: React.ChangeEvent<HTMLSelectElement>, val: string) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.currentTarget;
    onChange(e, value);
  };
}

function checkedOnChange<T extends HTMLInputElement>(
  onChange: (e: React.ChangeEvent<T>, value?: string) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { checked } = e.currentTarget;
    onChange(e, checked ? 'on' : undefined);
  };
}

function fileOnChange<T extends HTMLInputElement>(
  onChange: (e: React.ChangeEvent<T>, val?: File) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { files } = e.currentTarget;
    if (files) {
      onChange(e, files[0]);
    }
  };
}

function numberOnChange<T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
  onChange: (e: React.ChangeEvent<T>, val: number) => void = (): void => undefined,
) {
  return (e: React.ChangeEvent<T>): void => {
    const { value } = e.currentTarget;
    onChange(e, +value);
  };
}

function SpecialInput<T extends keyof SubscriptionPackageFormDataAllRequired>(
  props: Props<T, HTMLInputElement>,
): ReactElement {
  const { type, formLabel, name, onChange, value, readOnly, disabled, ...restProps } = props;
  const inputProps: React.HTMLProps<HTMLInputElement> = {
    type,
    name,
    value,
    ...restProps,
  };
  const id = `${name}-${value}`;
  const _onChange = onChange as OnChange;
  return (
    <div className="center SpecialInput">
      <div
        className={classnames('left max-width flex align-center', {
          'justify-center': type === 'range',
        })}
      >
        {type === 'file' && (
          <h3 className="title-label header flex">
            <strong>{formLabel}</strong>
          </h3>
        )}
        {type === 'range' && (
          <label className="label" htmlFor={name}>
            {formLabel}
          </label>
        )}
        <input
          {...inputProps}
          id={id}
          readOnly={readOnly}
          className={classnames(`equi-${type}`)}
          checked={type === 'checkbox' && isChecked(inputProps.value as Checkbox)}
          disabled={disabled || (type === 'checkbox' && readOnly)}
          onChange={
            (type === 'checkbox'
              ? checkedOnChange(_onChange)
              : type === 'file'
              ? fileOnChange(_onChange)
              : textOnChange(_onChange)) as InternalOnChange
          }
        />
        {type === 'checkbox' && (
          <label className="label" htmlFor={id}>
            {formLabel}
          </label>
        )}
      </div>
    </div>
  );
}

function EquiSelect<T extends keyof SubscriptionPackageFormDataAllRequired>(
  props: Props<T, HTMLSelectElement>,
): ReactElement {
  const { options, formLabel, underlyingClassName, onChange, labelAsOption, name, ...selectProps } = props;
  if (!options) {
    throw new Error('EquiInput with select requires options');
  }
  const _onChange = onChange as OnChange;
  return (
    <>
      {labelAsOption && (
        <label className="label" htmlFor={name}>
          {formLabel}
        </label>
      )}
      <select
        {...selectProps}
        name={name}
        className={classnames(underlyingClassName)}
        onChange={selectOnChange(_onChange)}
      >
        {!labelAsOption && (
          <option value="" selected disabled>
            {formLabel}
          </option>
        )}
        {options.map(({ value, displayName, disabled }) => (
          <option key={value} value={value} disabled={disabled}>
            {displayName}
          </option>
        ))}
      </select>
    </>
  );
}

function EquiRadio<T extends keyof SubscriptionPackageFormDataAllRequired>(
  props: Props<T, HTMLInputElement>,
): ReactElement {
  const { options, formLabel, onChange, type, value: baseValue, name, ...inputProps } = props;
  const _onChange = onChange as OnChange;
  return (
    <div className="flex">
      <h3 className="title-label header no-flex">
        <strong>{formLabel} </strong>
      </h3>
      {options?.map(({ displayName, value }) => (
        <div key={value} className="center EquiRadioCheck no-flex">
          <div className="left max-width flex justify-center align-center">
            <input
              {...inputProps}
              name={name}
              type={type}
              id={`radio-${name}-${value}`}
              value={value}
              className={classnames(`equi-${type}`)}
              checked={value === baseValue}
              onChange={
                (type === 'checkbox' ? checkedOnChange(_onChange) : textOnChange(_onChange)) as InternalOnChange
              }
            />
            <label className="label" htmlFor={`radio-${name}-${value}`}>
              {displayName}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
function DefaultInput<T extends keyof SubscriptionPackageFormDataAllRequired>(
  props: Props<T, HTMLInputElement>,
): ReactElement {
  const { underlyingClassName, onChange, formLabel, fieldPrefix, ...inputProps } = props;
  const { name, type } = inputProps;
  const _onChange = onChange as OnChange;
  return (
    <>
      <div className="left">
        <label className="label" htmlFor={name}>
          {formLabel}
        </label>
      </div>
      <div className="input-container">
        {type === 'tel' && (
          <CountryCodesSelect
            name={name as typeof PhoneTabs[number]}
            disabled={inputProps.disabled}
            className={underlyingClassName}
          />
        )}
        {fieldPrefix && <div className="field-prefix">{fieldPrefix}</div>}
        <input
          {...inputProps}
          className={classnames(underlyingClassName, { withFieldPrefix: !!fieldPrefix })}
          onChange={
            ((type === 'number' ? numberOnChange(_onChange) : textOnChange(_onChange)) as unknown) as InternalOnChange
          }
        />
      </div>
    </>
  );
}

function EquiInputChild<T extends keyof SubscriptionPackageFormDataAllRequired>(props: Props<T>): ReactElement {
  const { type = 'text', formLabel, name, underlyingClassName, onChange, ...restProps } = props;
  const selectProps = { ...props } as Props<T, HTMLSelectElement>;
  const textareaProps = { ...props } as Props<T, HTMLTextAreaElement>;
  const inputProps = { ...restProps, name, type } as Props<T, HTMLInputElement>;
  const specialInputProps = { ...restProps, name, formLabel, type, onChange } as Props<T, HTMLInputElement>;
  const _onChange = onChange as OnChange;
  switch (type) {
    case 'select':
      return <EquiSelect {...selectProps} />;
    case 'textarea':
      return (
        <textarea
          {...textareaProps}
          placeholder={formLabel as string}
          className={classnames(underlyingClassName)}
          onChange={(textOnChange(_onChange) as unknown) as InternalOnChange<HTMLTextAreaElement>}
        />
      );
    case 'radio':
      return <EquiRadio {...specialInputProps} />;
    case 'checkbox':
    case 'range':
    case 'file':
      return <SpecialInput {...specialInputProps} />;
    case 'time':
    case 'date':
    case 'month':
    case 'week':
    case 'datetime-local':
      return (
        <>
          <div className="left">
            <label className="label min-width-120 flex align-center justify" htmlFor={name}>
              {formLabel}
            </label>
          </div>
          <input
            {...inputProps}
            className={classnames(underlyingClassName)}
            onChange={(textOnChange(_onChange) as unknown) as InternalOnChange}
          />
        </>
      );
    default:
      return (
        <DefaultInput
          {...inputProps}
          formLabel={formLabel}
          underlyingClassName={classnames(underlyingClassName)}
          onChange={
            ((type === 'number' ? numberOnChange(_onChange) : textOnChange(_onChange)) as unknown) as InternalOnChange
          }
        />
      );
  }
}

function EquiInput<T extends keyof SubscriptionPackageFormDataAllRequired>(props: Props<T>): ReactElement {
  const { error, className, disabled, noMaxWidth, underlyingClassName, ...restProps } = props;
  return (
    <div
      className={classnames('EquiInput', className, {
        'equi-input-error': error,
        disabled,
        'max-width': !noMaxWidth,
      })}
    >
      <EquiInputChild
        {...restProps}
        disabled={disabled}
        underlyingClassName={classnames('equi-input', underlyingClassName)}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default EquiInput;
