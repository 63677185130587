import { ReactElement } from 'react';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';
import './styles.scss';

function InvestmentInformation(): ReactElement {
  const formFields = useKYCFormFields();
  return (
    <div className="InvestmentInformation">
      <h2 className="title">Investment Information</h2>
      <div className="flex column">
        <div className="form-section max-width">
          <h3 className="justify-center text-center">
            <span className="border-bottom-1">Exempt Market Products</span>
          </h3>
          <p className="text-center">
            (Including EquiGenesis 2022 SMART Savings Plan
            <sup>TM</sup>)
          </p>
          <div className="flex space-between">
            <div className="separate-children spacer justify-center align-center">
              <EquiInput
                {...formFields.exemptMarketInvestmentKnowledge}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
            </div>
            <div className="separate-children EquiInput spacer justify-center align-center max-3_10">
              <h3 className="title-label">
                <strong>Investment Objectives</strong>
              </h3>
              <EquiInput {...formFields.exemptMarketInvestmentObjectivesGrowth} />
              <EquiInput {...formFields.exemptMarketInvestmentObjectivesSpeculation} />
              <EquiInput {...formFields.exemptMarketInvestmentObjectivesTaxPlanning} />
            </div>
            <div className="separate-children spacer justify-center align-center">
              <EquiInput
                {...formFields.exemptMarketInvestmentTimeHorizon}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
            </div>
          </div>
        </div>
        <div className="form-section max-width separate-children">
          <h3 className="center text-center text-underline">General</h3>
          <div className="flex space-between">
            <div className="spacer justify-center align-center">
              <EquiInput
                {...formFields.generalInvestmentKnowledge}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
            </div>
            <div className="EquiInput spacer justify-center align-center max-3_10">
              <h3 className="title-label">
                <strong>Investment Objectives</strong>
              </h3>
              <EquiInput {...formFields.generalInvestmentObjectivesSafety} />
              <EquiInput {...formFields.generalInvestmentObjectivesIncome} />
              <EquiInput {...formFields.generalInvestmentObjectivesGrowth} />
              <EquiInput {...formFields.generalInvestmentObjectivesSpeculation} />
            </div>
            <div className="spacer justify-center align-center">
              <EquiInput
                {...formFields.generalInvestmentTimeHorizon}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
            </div>
          </div>
        </div>
        <h2 className="title">Risk Profile</h2>
        <div className="form-section max-width separate-children">
          <h3 className="center text-center text-underline">Risk Tolerance</h3>
          <div className="flex center align-start">
            <div className="flex spacer justify-center align-center">
              <EquiInput
                {...formFields.exemptMarketRiskTolerance}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
              <div className="space-right" />
              <div className="space-right" />
            </div>
            <div className="flex spacer justify-center align-center">
              <div className="space-left" />
              <div className="space-left" />
              <EquiInput
                {...formFields.generalRiskTolerance}
                radioWrapperClassName="column align-start"
                wrapperClassName="column align-start"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-section max-width">
        <h3 className="center text-center text-underline">Risk Capacity</h3>
        <div className="flex column align-start">
          <div className="flex spacer justify-center align-center max-width">
            <EquiInput {...formFields.tenFromRetirement} wrapperClassName="max-width space-between" />
          </div>
          <div className="flex spacer justify-center align-center max-width">
            <EquiInput {...formFields.assistParents} wrapperClassName="max-width space-between" />
          </div>
          <div className="flex spacer justify-center align-center max-width">
            <EquiInput {...formFields.fundChildrensEducation} wrapperClassName="max-width space-between" />
          </div>
          <div className="flex spacer justify-center align-center max-width">
            <EquiInput {...formFields.addToSavings} wrapperClassName="max-width space-between" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestmentInformation;
