import { ReactElement } from 'react';
import Icon from '@components/common/Icon';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

export default function NoDocuments(): ReactElement {
  return (
    <Page
      className="NoDocuments"
      renderHeader={(): ReactElement => <h1 className="text-center">You have no documents</h1>}
      defaultBackLocation={PATHS.HOME}
    >
      <div className="flex column align-center no-documents-container">
        <p>No documents could be found. If you believe this is incorrect, please reach out to your agent for help</p>
        <Icon type="notFound" />
      </div>
    </Page>
  );
}
