import { ActionTypes } from '@actions';
import * as LocksActions from '@actions/locks';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export type State = Record<string, string>;

const initialState: State = {};

const acquireLockReducer: Reducer<State> = (state = initialState, action: LocksActions.AcquireLock) => {
  const { acquirerId, lockId } = action.payload;
  if (state[lockId]) {
    return state;
  }
  return {
    ...state,
    [lockId]: acquirerId,
  };
};

const releaseLockReducer: Reducer<State> = (state = initialState, action: LocksActions.ReleaseLock) => {
  const { releaserId, lockId } = action.payload;
  if (!state[lockId] || state[lockId] !== releaserId) {
    return state;
  }
  const { [lockId]: _removedLock, ...restState } = state; // eslint-disable-line @typescript-eslint/no-unused-vars
  return restState;
};

const resetAppReducer: Reducer<State> = () => {
  return initialState;
};

const Reducers: ReducersType<State> = {
  [ActionTypes.ACQUIRE_LOCK]: acquireLockReducer,
  [ActionTypes.RELEASE_LOCK]: releaseLockReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
