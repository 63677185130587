import { ReactComponent as NotFoundIcon } from '@assets/icons/404-not-found.svg';
import { ReactComponent as AddUserIcon } from '@assets/icons/addUser.svg';
import { ReactComponent as AdminIcon } from '@assets/icons/admin.svg';
import { ReactComponent as ApproveFormIcon } from '@assets/icons/approve-form.svg';
import { ReactComponent as BackArrowIcon } from '@assets/icons/back-arrow.svg';
import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark.svg';
import { ReactComponent as CloseHoverIcon } from '@assets/icons/close-hover.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as ContinueFormIcon } from '@assets/icons/continue-form.svg';
import { ReactComponent as DownArrowIcon } from '@assets/icons/down-arrow.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as FemaleAgent } from '@assets/icons/female-agent.svg';
import { ReactComponent as ForwardFormIcon } from '@assets/icons/forward-script.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/info-icon.svg';
import { ReactComponent as KYCIcon } from '@assets/icons/kyc.svg';
import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg';
import { ReactComponent as NewFormIcon } from '@assets/icons/new-form.svg';
import { ReactComponent as NoSearchResultsIcon } from '@assets/icons/no-search-results.svg';
import { ReactComponent as OfficeUseIcon } from '@assets/icons/office-use.svg';
import { ReactComponent as RejectFormIcon } from '@assets/icons/reject-form.svg';
import { ReactComponent as ReviseIcon } from '@assets/icons/revise.svg';
import { ReactComponent as SecureMailIcon } from '@assets/icons/secure-mail.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ReactComponent as SignedDocumentIcon } from '@assets/icons/signed-document.svg';
import { ReactComponent as SubscriberIcon } from '@assets/icons/subscriber.svg';
import { ReactComponent as ToggleOffIcon } from '@assets/icons/toggle-off.svg';
import { ReactComponent as ToggleOnIcon } from '@assets/icons/toggle-on.svg';
import { ReactComponent as UpArrowIcon } from '@assets/icons/up-arrow.svg';
import { ReactComponent as UserRoleChangeIcon } from '@assets/icons/user-role-change.svg';
import { ReactComponent as UserSettingsIcon } from '@assets/icons/user-settings.svg';
import { ReactComponent as UserIcon } from '@assets/icons/user.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/users.svg';

export const ICON_LIBRARY = {
  continueForm: ContinueFormIcon,
  newForm: NewFormIcon,
  admin: AdminIcon,
  agent: FemaleAgent,
  logo: LogoIcon,
  user: UserIcon,
  addUser: AddUserIcon,
  backArrow: BackArrowIcon,
  noResults: NoSearchResultsIcon,
  notFound: NotFoundIcon,
  approveForm: ApproveFormIcon,
  forwardForm: ForwardFormIcon,
  rejectForm: RejectFormIcon,
  revise: ReviseIcon,
  secureMail: SecureMailIcon,
  toggleOn: ToggleOnIcon,
  toggleOff: ToggleOffIcon,
  userSettings: UserSettingsIcon,
  users: UsersIcon,
  close: CloseIcon,
  closeHover: CloseHoverIcon,
  subscriber: SubscriberIcon,
  kyc: KYCIcon,
  signedDocument: SignedDocumentIcon,
  duplicate: DuplicateIcon,
  infoIcon: InfoIcon,
  settings: SettingsIcon,
  edit: EditIcon,
  checkmark: CheckmarkIcon,
  upArrow: UpArrowIcon,
  downArrow: DownArrowIcon,
  userRoleChange: UserRoleChangeIcon,
  officeUseIcon: OfficeUseIcon,
} as const;
