import { EmploymentInformation } from 'shared';
import { ProtoFields } from '../types';

export const employmentInformationFields: ProtoFields<keyof EmploymentInformation> = {
  employerName: { formLabel: 'Employer Name' },
  employerPhone: { formLabel: 'Employer Phone', type: 'tel' },
  employerPhoneCountryCode: { formLabel: 'Employer Phone', type: 'tel' },
  employerSince: { formLabel: 'Since' },
  occupation: { formLabel: 'Occupation' },
  previousEmployer: { formLabel: 'Previous Employer' },
  typeOfBusiness: { formLabel: 'Type Of Business' },
};
