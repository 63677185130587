import { Actions as ApiActions, ActionTypes as ApiActionTypes } from './api';
import { Actions as AuthActions, ActionTypes as AuthActionTypes } from './auth';
import { Actions as CurrentUserActions, ActionTypes as CurrentUserActionTypes } from './currentUser';
import { Actions as GeneralActions, ActionTypes as GeneralActionTypes } from './general';
import { Actions as KYCFormActions, ActionTypes as KYCFormActionTypes } from './kycForm';
import { Actions as KYCsActions, ActionTypes as KYCsActionTypes } from './kycs';
import { Actions as LocksActions, ActionTypes as LocksActionTypes } from './locks';
import {
  Actions as ManageOrganizationsActions,
  ActionTypes as ManageOrganizationsActionTypes,
} from './manageOrganizations';
import { Actions as ManageUsersActions, ActionTypes as ManageUsersActionTypes } from './manageUsers';
import { Actions as NavigationActions, ActionTypes as NavigationActionTypes } from './navigation';
import { Actions as OfficeUseFormActions, ActionTypes as OfficeUseFormActionTypes } from './officeUseForm';
import { Actions as OrganizationsActions, ActionTypes as OrganizationsActionTypes } from './organizations';
import { Actions as RelationshipsActions, ActionTypes as RelationshipsActionTypes } from './relationships';
import {
  Actions as SubscriptionPackageActions,
  ActionTypes as SubscriptionPackageActionTypes,
} from './subscriptionPackageForm';
import {
  Actions as SubscriptionPackagesActions,
  ActionTypes as SubscriptionPackagesActionTypes,
} from './subscriptionPackages';
import { Dispatch as DispatchT } from './types';
import { Actions as UsersActions, ActionTypes as UsersActionTypes } from './users';

export type Actions =
  | ApiActions
  | AuthActions
  | CurrentUserActions
  | GeneralActions
  | ManageUsersActions
  | NavigationActions
  | RelationshipsActions
  | SubscriptionPackageActions
  | SubscriptionPackagesActions
  | UsersActions
  | KYCFormActions
  | OfficeUseFormActions
  | KYCsActions
  | LocksActions
  | ManageOrganizationsActions
  | OrganizationsActions;

export type Dispatch = DispatchT<Actions>;

export const ActionTypes = {
  ...ApiActionTypes,
  ...AuthActionTypes,
  ...CurrentUserActionTypes,
  ...GeneralActionTypes,
  ...ManageUsersActionTypes,
  ...NavigationActionTypes,
  ...RelationshipsActionTypes,
  ...SubscriptionPackageActionTypes,
  ...SubscriptionPackagesActionTypes,
  ...UsersActionTypes,
  ...KYCFormActionTypes,
  ...OfficeUseFormActionTypes,
  ...KYCsActionTypes,
  ...LocksActionTypes,
  ...ManageOrganizationsActionTypes,
  ...OrganizationsActionTypes,
};

export type ActionTypes = typeof ActionTypes;

export const Actions = {
  ...ApiActions,
  ...AuthActions,
  ...CurrentUserActions,
  ...GeneralActions,
  ...ManageUsersActions,
  ...NavigationActions,
  ...RelationshipsActions,
  ...SubscriptionPackageActions,
  ...SubscriptionPackagesActions,
  ...UsersActions,
  ...KYCFormActions,
  ...OfficeUseFormActions,
  ...KYCsActions,
  ...LocksActions,
  ...ManageOrganizationsActions,
  ...OrganizationsActions,
};

export * from './types';
