import { ReactElement } from 'react';
import { TrueFalseRadio } from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function ProfessionalAdvisors(): ReactElement {
  const formFields = useKYCFormFields();
  function updateHaveFinancialAdvisor(e: React.ChangeEvent<HTMLInputElement>, value?: TrueFalseRadio): void {
    formFields.haveFinancialAdvisor.onChange(e, value);
    if (value === TrueFalseRadio.false) {
      formFields.financialAdvisorName.onChange(e, undefined);
      formFields.financialAdvisorFirm.onChange(e, undefined);
    }
  }
  function updateHaveAccountant(e: React.ChangeEvent<HTMLInputElement>, value?: TrueFalseRadio): void {
    formFields.haveAccountant.onChange(e, value);
    if (value === TrueFalseRadio.false) {
      formFields.accountantName.onChange(e, undefined);
      formFields.accountantFirm.onChange(e, undefined);
    }
  }
  function updateHaveLawyer(e: React.ChangeEvent<HTMLInputElement>, value?: TrueFalseRadio): void {
    formFields.haveLawyer.onChange(e, value);
    if (value === TrueFalseRadio.false) {
      formFields.lawyerName.onChange(e, undefined);
      formFields.lawyerFirm.onChange(e, undefined);
    }
  }
  return (
    <div>
      <h2 className="title">Professional Advisors</h2>
      <div className="flex">
        <div className="spacer justify-center align-center">
          <EquiInput {...formFields.haveFinancialAdvisor} onChange={updateHaveFinancialAdvisor} />
        </div>
      </div>
      {formFields.haveFinancialAdvisor.value === TrueFalseRadio.true && (
        <div className="flex">
          <EquiInput {...formFields.financialAdvisorName} />
          <EquiInput {...formFields.financialAdvisorFirm} />
        </div>
      )}
      <div className="flex">
        <div className="spacer justify-center align-center">
          <EquiInput {...formFields.haveAccountant} onChange={updateHaveAccountant} />
        </div>
      </div>
      {formFields.haveAccountant.value === TrueFalseRadio.true && (
        <div className="flex">
          <EquiInput {...formFields.accountantName} />
          <EquiInput {...formFields.accountantFirm} />
        </div>
      )}
      <div className="flex">
        <div className="spacer justify-center align-center">
          <EquiInput {...formFields.haveLawyer} onChange={updateHaveLawyer} />
        </div>
      </div>
      {formFields.haveLawyer.value === TrueFalseRadio.true && (
        <div className="flex">
          <EquiInput {...formFields.lawyerName} />
          <EquiInput {...formFields.lawyerFirm} />
        </div>
      )}
    </div>
  );
}

export default ProfessionalAdvisors;
