import { ReactElement } from 'react';
import { getEnvValue } from 'shared';
import { LinkCardData, renderCards } from '@components/common/LinkCard';
import { useCurrentUser } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import './styles.scss';

export default function AdminDashboard(): ReactElement {
  const { hasAllPermissions } = useCurrentUser();
  const cards: (LinkCardData | false)[] = [
    {
      title: 'Manage Users',
      to: PATHS.ADMIN_MANAGE_USERS,
      icon: 'user',
    },
    getEnvValue('SHOW_TERMINATION_ADD_ON') &&
      hasAllPermissions(['admin:run-termination']) && {
        title: 'Winterpeg Add-On',
        to: PATHS.ADMIN_WINTERPEG,
        icon: 'forwardForm',
      },
    {
      title: 'Site Settings',
      to: PATHS.ADMIN_SITE_SETTINGS,
      icon: 'settings',
    },
  ];
  return (
    <Page
      className="AdminDashboard"
      renderHeader={(): ReactElement => <h1 className="text-center">Admin Dashboard</h1>}
      defaultBackLocation={PATHS.HOME}
    >
      {renderCards(cards, 'admin-cards')}
    </Page>
  );
}
