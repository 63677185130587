import { ActionTypes } from '@actions';
import * as NavigationActions from '@actions/navigation';
import { ValidPath } from '@routes/lib';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  goBackLocation: ValidPath | null;
  currentLocation: ValidPath | null;
}

const initialState: State = {
  goBackLocation: null,
  currentLocation: null,
};

const startNavigationReducer: Reducer<State> = (_state, action: NavigationActions.StartNavigation) => {
  return {
    ...action.payload,
  };
};

const navigationEndedReducer: Reducer<State> = () => {
  return initialState;
};

const resetAppReducer: Reducer<State> = () => {
  return initialState;
};

const Reducers: ReducersType<State> = {
  [ActionTypes.START_NAVIGATION]: startNavigationReducer,
  [ActionTypes.NAVIGATION_ENDED]: navigationEndedReducer,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
