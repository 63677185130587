import { ReactElement, createRef } from 'react';
import classNames from 'classnames';
import { Timeline } from 'primereact/timeline';
import { Tooltip } from 'primereact/tooltip';
import { filter, KYC, KYCState, Organization, getOrderNumberOfState } from 'shared';
import './styles.scss';

interface Props {
  kyc: KYC;
  organization?: Organization | null;
}

interface TimelineEvent {
  states: KYCState[];
  title: string;
  description: string;
  isComplete: boolean;
  isCurrent: boolean;
}

function KYCTimeline({ kyc, organization }: Props): ReactElement {
  const stateOrderNumber = getOrderNumberOfState(kyc.state, !!organization?.requiresKYCReviewByReviewer);
  const events = getTimelineEventData(stateOrderNumber, organization);
  const divRef = createRef<HTMLDivElement>();
  return (
    <div ref={divRef}>
      <h3 className="mb-8 text-left">Timeline</h3>
      <Timeline
        className="KYCTimeline"
        value={events}
        marker={(item: TimelineEvent): JSX.Element => (
          <i
            className={classNames('pi', {
              'pi-check-circle text-green-500': item.isComplete,
              'pi-circle': !item.isComplete,
              'opacity-10': !item.isCurrent && !item.isComplete,
              'text-warning': item.isCurrent,
            })}
          />
        )}
        content={(item: TimelineEvent): JSX.Element => (
          <>
            <span
              className={classNames({
                'opacity-80': item.isComplete,
                'opacity-20': !item.isCurrent && !item.isComplete,
                'font-bold': item.isCurrent || (item.isComplete && item.states.includes('complete')),
              })}
            >
              {item.title}
              <i className={classNames('ml-2 pi pi-question-circle cursor-help', `tooltip-${item.states[0]}`)} />
            </span>
            <Tooltip appendTo={divRef.current} position="right" target={`.tooltip-${item.states[0]}`} autoHide={false}>
              <div className="max-w-sm">{item.description}</div>
            </Tooltip>
          </>
        )}
      ></Timeline>
    </div>
  );
}

export default KYCTimeline;

function getTimelineEventData(stateOrderNumber: number, organization?: Organization | null): TimelineEvent[] {
  return filter([
    {
      states: ['initialized', 'renewing'],
      title: 'Pre-fill and Invite Client',
      description:
        'To save the client time, pre-fill any desired information and then click the "Invite" button at the bottom of the form to invite the client to fill out their Client Registration Form.',
      isComplete: stateOrderNumber > getOrderNumberOfState('initialized', !!organization?.requiresKYCReviewByReviewer),
      isCurrent: stateOrderNumber === getOrderNumberOfState('initialized', !!organization?.requiresKYCReviewByReviewer),
    },
    {
      states: ['inProgress', 'changesRequestedByAgent'],
      title: 'In Progress By Client',
      description:
        'The Client enters information for all required fields. This information is validated in real-time, eliminating mistakes.',
      isComplete: stateOrderNumber > getOrderNumberOfState('inProgress', !!organization?.requiresKYCReviewByReviewer),
      isCurrent: stateOrderNumber === getOrderNumberOfState('inProgress', !!organization?.requiresKYCReviewByReviewer),
    },
    {
      states: ['underReviewByAgent'],
      title: 'Agent Review',
      description:
        'The Agent reviews the clients responses to ensure that they are an accredited investor and that this product is appropriate for them.',
      isComplete:
        stateOrderNumber > getOrderNumberOfState('underReviewByAgent', !!organization?.requiresKYCReviewByReviewer),
      isCurrent:
        stateOrderNumber === getOrderNumberOfState('underReviewByAgent', !!organization?.requiresKYCReviewByReviewer),
    },
    organization?.requiresKYCReviewByReviewer && {
      states: ['underReviewByOrgReviewer'],
      title: `${organization.name} Review`,
      description: `${organization.name} reviews the clients responses to ensure that the client meets their specific criteria.`,
      isComplete:
        stateOrderNumber >
        getOrderNumberOfState('underReviewByOrgReviewer', !!organization?.requiresKYCReviewByReviewer),
      isCurrent:
        stateOrderNumber ===
        getOrderNumberOfState('underReviewByOrgReviewer', !!organization?.requiresKYCReviewByReviewer),
    },
    {
      states: ['complete', 'quickModification'],
      title: 'Client Registration Complete',
      description:
        'The Client Registration Process is complete. A Subscription Package may now be created and sent to DocuSign for signing.',
      isComplete: stateOrderNumber === getOrderNumberOfState('complete', !!organization?.requiresKYCReviewByReviewer),
      isCurrent: false,
    },
  ]);
}
