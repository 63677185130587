import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { filter, formatDateTime, KYC, regexEscape } from 'shared';
import GeneralInput from '@components/common/GeneralInput';
import Table from '@components/common/Table';
import { useSort, useKYCs } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';
import { displayKYCState } from '@utils/formState';
import './styles.scss';

function displayMaybeEmptyItem(str?: string): string | React.ReactNode {
  if (!str) {
    return <em>(not provided)</em>;
  }
  return str;
}
type sortByOptions = 'name' | 'email' | 'lastUpdated';

export default function KYCsPage(): ReactElement {
  const { kycs, isLoading } = useKYCs({ refresh: true });
  const [statusFilter, setStatusFilter] = useState<KYC['state'] | 'all'>('all');
  const [searchBy, setSearchBy] = useState('');
  const { field, isDescending, setSortField } = useSort<sortByOptions>('lastUpdated', true);
  const sortedAndFiltered: KYC[] = sortBy(
    kycs.filter(
      (kyc) =>
        (statusFilter === 'all' || statusFilter === kyc.state) &&
        (new RegExp(regexEscape(searchBy?.trim()), 'i').test(kyc.subscriber?.name || '') ||
          new RegExp(regexEscape(searchBy?.trim()), 'i').test(kyc.subscriber?.email.toLowerCase() || '')),
    ),
    (kyc) => {
      switch (field) {
        case 'name':
          return (kyc.subscriber?.family_name.toLowerCase() || '') + (kyc.subscriber?.given_name.toLowerCase() || '');
        case 'email':
          return kyc.subscriber?.email;
        default:
          return kyc.updatedAt;
      }
    },
  );
  if (isDescending) {
    sortedAndFiltered.reverse();
  }

  return (
    <Page
      className="KYCsPage"
      isLoading={isLoading}
      defaultBackLocation={PATHS.HOME}
      showChildrenOnLoading={false}
      renderHeader={(): ReactElement => <h1>Client Registration Forms</h1>}
    >
      {!!kycs?.length && (
        <>
          <Table
            gridClassName="table-grid-template-columns"
            headers={[
              {
                text: 'Subscriber Name',
                isSelected: field === 'name',
                onClick: (): void => setSortField('name'),
                isDescending,
              },
              {
                text: 'Email',
                isSelected: field === 'email',
                onClick: (): void => setSortField('email'),
                isDescending,
              },
              { text: 'Status' },
              {
                text: 'Last Updated',
                isSelected: field === 'lastUpdated',
                onClick: (): void => setSortField('lastUpdated'),
                isDescending,
              },
            ]}
            data={sortedAndFiltered.map((form) => [
              <div className="grid subscriber-name">
                <span>
                  {
                    <Link
                      to={
                        form.subscriber
                          ? `${PATHS.SUBSCRIBERS}/${form.subscriber.email}/client-registration`
                          : `${PATHS.NOT_FOUND}`
                      }
                    >
                      (View Form)
                    </Link>
                  }{' '}
                </span>
                <Link to={form.subscriber ? `${PATHS.SUBSCRIBERS}/${form.subscriber?.email}` : `${PATHS.NOT_FOUND}`}>
                  {displayMaybeEmptyItem(form.subscriber?.name)}
                </Link>
              </div>,
              displayMaybeEmptyItem(form.subscriber?.email),
              displayKYCState(form.state, form.subscriber?.organization),
              formatDateTime(new Date(form.updatedAt)),
            ])}
            renderFilters={(): ReactElement => (
              <>
                <GeneralInput
                  formLabel="Search"
                  className="search-input"
                  placeholder="by name or email"
                  onChange={(value): void => setSearchBy(value as string)}
                  name="search"
                  value={searchBy}
                />
                <GeneralInput
                  className="filter-by-status"
                  labelAsOption={false}
                  formLabel="Filter by Status"
                  onChange={(value): void => setStatusFilter(value as KYC['state'])}
                  name="status"
                  value={statusFilter}
                  type="select"
                  options={filter<{ value: KYC['state'] | 'all'; displayName: string }>([
                    { value: 'all', displayName: 'All' },
                    { value: 'initialized', displayName: displayKYCState('initialized') },
                    { value: 'inProgress', displayName: displayKYCState('inProgress') },
                    { value: 'underReviewByAgent', displayName: displayKYCState('underReviewByAgent') },
                    { value: 'changesRequestedByAgent', displayName: displayKYCState('changesRequestedByAgent') },
                    { value: 'underReviewByOrgReviewer', displayName: displayKYCState('underReviewByOrgReviewer') },
                    { value: 'complete', displayName: displayKYCState('complete') },
                    { value: 'renewing', displayName: displayKYCState('renewing') },
                  ])}
                />
              </>
            )}
          />
        </>
      )}
      {kycs?.length === 0 && (
        <div className="flex align-center column no-kycs">
          <h1>You have no Subscribers with a Client Registration Form.</h1>
        </div>
      )}
    </Page>
  );
}
