import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Page } from '@layouts';
import { PATHS, ValidPath } from '@routes/lib';

interface Props {
  forceBackLocation?: ValidPath;
  message?: string;
  description?: string;
}
function hasMessage(state: unknown): state is { message: string } {
  return !!state && typeof state === 'object' && 'message' in state;
}

export default function ErrorPage({
  forceBackLocation,
  message = 'Something Went Wrong',
  description,
}: Props): ReactElement {
  const { state } = useLocation();
  return (
    <Page
      className="ErrorPage"
      renderHeader={(): ReactElement => <h1>{message}</h1>}
      defaultBackLocation={forceBackLocation || PATHS.HOME}
      doNotRecurse
    >
      {hasMessage(state) && <h3 className="text-center max-w-80">{state.message}</h3>}
      {description && <p>{description}</p>}
    </Page>
  );
}
