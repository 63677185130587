import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import {
  canRemoveOrgAdminRole,
  canRemoveOrgAgentRole,
  canRemoveOrgSubscriberRole,
  Checkbox,
  FullUser,
  isChecked,
  OrganizationRoles,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import OrganizationRolesFormFields from '@components/common/OrganizationRolesFormFields';
import Overlay from '@components/common/Overlay';

import './styles.scss';

interface Props {
  className?: string;
  shouldShow: boolean;
  onClose: () => void;
  onSubmit: (orgRoles: OrganizationRoles) => void;
  user: FullUser;
  error?: string;
}

function ChangeUserOrganizationRolesModal(props: Props): ReactElement {
  const { className, shouldShow, onClose, user, onSubmit, error } = props;
  const [state, setState] = useState<OrganizationRoles>(() => {
    const orgRoles: OrganizationRoles = {
      ...user.organization?.userProperties,
      isAdmin: user.organization?.userProperties.isAdmin || !canRemoveOrgAdminRole(user),
      isReviewer: user.organization?.userProperties.isReviewer || false,
      isAgent: user.organization?.userProperties.isAgent || !canRemoveOrgAgentRole(user),
      isClient: user.organization?.userProperties.isClient || !canRemoveOrgSubscriberRole(user),
    };
    return orgRoles;
  });
  function changeOrgRoles(field: keyof OrganizationRoles): OnChange {
    return ((value?: Checkbox): void => {
      setState((state) => ({ ...state, [field]: isChecked(value) }));
    }) as OnChange;
  }
  return (
    <Overlay
      rootId="ChangeUserOrganizationRolesModal-overlay-root"
      showOverlay={shouldShow}
      className={classnames('ChangeUserOrganizationRolesModal', className)}
    >
      <Modal
        className="ChangeUserOrganizationRolesModalBody flex flex-col items-center"
        onOutsideClick={onClose}
        withClose
      >
        <h1 className="text-center">Modify Organizational Roles for</h1>
        <h2 className="mt-2 underline">{user.name}</h2>
        <OrganizationRolesFormFields
          organizationRoles={state}
          onChangeRole={changeOrgRoles}
          isAdminDisabled={!canRemoveOrgAdminRole(user)}
          isAgentDisabled={!canRemoveOrgAgentRole(user)}
          isClientDisabled={!canRemoveOrgSubscriberRole(user)}
        />
        {error && <p className="error">{error}</p>}
        {!canRemoveOrgAdminRole(user) && (
          <p>
            <b>*</b>This user is the only admin for this organization
          </p>
        )}
        {!canRemoveOrgAgentRole(user) && (
          <p>
            <b>*</b>This user has clients assigned to them and so cannot be removed as an agent
          </p>
        )}
        {!canRemoveOrgSubscriberRole(user) && (
          <p>
            <b>*</b>This user has agents assigned to them and so cannot be removed as a subscriber
          </p>
        )}
        <EquiButton
          value="Change Roles"
          className="mt-4"
          onClick={(): void => onSubmit(state)}
          disabled={!state.isAdmin && !state.isAgent && !state.isClient && !state.isReviewer}
        />
      </Modal>
    </Overlay>
  );
}

export default ChangeUserOrganizationRolesModal;
