import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RequestPayloads } from 'shared';
import { Actions, Dispatch } from '@actions';
import * as UsersAPI from '@api/users';
import { PATHS } from '@routes/lib';
import { State } from '@store';

export interface UpdateUserHook {
  updateUser: (
    user_id: string,
    user: Omit<RequestPayloads['updateUser'], 'roles' | 'user_id'>,
    email: string,
    isSubscriber: boolean,
  ) => Promise<void>;
}

export function useUpdateUser(): UpdateUserHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const history = useHistory();
  const dispatch = reduxDispatch as Dispatch;
  async function updateUser(
    user_id: string,
    user: RequestPayloads['updateUser'],
    email: string,
    isSubscriber: boolean,
  ): Promise<void> {
    try {
      dispatch(Actions.showLoadingOverlay());
      await UsersAPI.updateUser(user_id, user);
      dispatch(Actions.flashSuccessMessage('Successfully updated user information.'));
      history.replace(`${isSubscriber ? PATHS.SUBSCRIBERS : PATHS.AGENTS}/${email}`);
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
    } finally {
      dispatch(Actions.hideLoadingOverlay());
    }
  }

  return {
    updateUser,
  };
}
