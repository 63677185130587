import { Role } from 'shared';

export const permissions: { [key in Role]: string[] } = {
  admin: [
    'do everything any other user can do',
    'invite new users, read/edit/submit KYC, OfficeUse, and Subscription forms, change other user permission levels, etc',
    'add or remove admin users',
    'promote or demote other users to/from admin privileges',
  ],
  agent: [
    "see his/her invited/assigned subscriber's completed or partially completed KYC, OfficeUse, and Subscription forms",
    "modify his/her subscriber's forms",
    'invite new subscribers to the platform',
  ],
  eq: [
    'invite agents to the platform',
    'see all forms ever created',
    'invite new agents',
    'invite other EQ Employees',
    'demote EQ Employees to Agent privileges',
    'promote Agents to EQ Employee privileges',
  ],
  subscriber: ['only see forms that have been started for them or that they have completed'],
};
