import { ReactElement } from 'react';
import { doesSubscriberNeedToBeInvited, FullUser, KYCAttributes } from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { Children } from '@custom-types';
import { useCurrentUser, useFlashMessage, useKYCFormState, useModal } from '@hooks';
import AccreditedInvestorStatus from './AccreditedInvestorStatus';
import BankingInformation from './BankingInformation';
import ClientInformation from './ClientInformation';
import ControllingPerson from './ControllingPerson';
import EmploymentInformation from './EmploymentInformation';
import FamilyInformation from './FamilyInformation';
import FinancialInformation from './FinancialInformation';
import InsiderInformation from './InsiderInformation';
import InvestmentInformation from './InvestmentInformation';
import LeveragedTaxShelterInformation from './LeveragedTaxShelterInformation';
import ProfessionalAdvisors from './ProfessionalAdvisors';
import TaxResidence from './TaxResidence';
import TrustedContactPerson from './TrustedContactPerson';
import './styles.scss';

interface Props {
  doesSubscriberHaveAgent: boolean;
  permissionCheck: (state: KYCAttributes['state']) => boolean;
  user: FullUser | null;
}

function KYC({ permissionCheck, doesSubscriberHaveAgent, user }: Props): ReactElement {
  const { currentUser } = useCurrentUser();
  const { closeModal, isOpen, openModal } = useModal();
  const { flashWarningMessage } = useFlashMessage();
  const {
    form,
    isFormForCurrentUser,
    inviteSubscriber,
    inviteSubscriberToRenewKYC,
    requestApproval,
    requestChanges,
    agentApproveForm,
    orgReviewerApproveForm,
    renewForm,
    cancelRenewalProcess,
    quickModification,
    markComplete,
    shouldAllowAutocompleteForCurrentUser,
  } = useKYCFormState();
  const userForForm = user || form?.subscriber;
  const org = user?.organization;
  const hasSubscriberBeenInvited = !doesSubscriberNeedToBeInvited(userForForm);
  function inviteSubscriberOnClick(): void {
    if (!doesSubscriberHaveAgent) {
      flashWarningMessage(
        `${
          userForForm?.name || 'This Subscriber'
        } does not have any agent's assigned to them. Please assign them an agent before inviting them.`,
      );
      return;
    }
    if (
      confirm(
        hasSubscriberBeenInvited
          ? `This will send an email notification to ${
              userForForm?.name || 'the subscriber'
            } to fill out their Client Registration Form. Are you sure you wish to proceed?`
          : `This will send an email inviting ${
              userForForm?.name || 'the subscriber'
            } to the Portal. Are you sure you wish to proceed?`,
      )
    ) {
      inviteSubscriber();
    }
  }
  function renewSubscriberOnClick(): void {
    if (!doesSubscriberHaveAgent) {
      flashWarningMessage(
        `${
          userForForm?.name || 'This Subscriber'
        } does not have any agent's assigned to them. Please assign them an agent before inviting them.`,
      );
      return;
    }
    if (
      confirm(
        `This will send an email notification to ${
          userForForm?.name || 'the subscriber'
        } to renew their Client Registration. Are you sure you wish to proceed?`,
      )
    ) {
      inviteSubscriberToRenewKYC();
    }
  }
  function requestApprovalOnClick(): void {
    if (!form) {
      return;
    }
    if (
      currentUser?.user_id === form.subscriberUserId ||
      confirm(
        'This will send an email to the agent for this Subscriber notifying them that they need to review this Client Registration. Are you sure you want to proceed?',
      )
    ) {
      requestApproval((): undefined => {
        if (currentUser?.user_id === form.subscriberUserId) {
          openModal();
        }
        return;
      });
    }
  }
  function requestChangesOnClick(): void {
    if (
      !confirm(
        `This will send an email to ${
          userForForm?.name || 'the subscriber'
        } informing them that modifications are needed to his/her Client Registration. Please contact ${
          userForForm?.name || 'the subscriber'
        } to explain and elaborate on those modifications. Are you sure you would like to proceed?`,
      )
    ) {
      return;
    }
    requestChanges();
  }
  return (
    <>
      <Form className="KYC" turnOffAutoComplete={!shouldAllowAutocompleteForCurrentUser}>
        <FormSection>
          <h1 className="text-center">Client Registration Form</h1>
          <hr className="section-separator" />
          <ClientInformation />
          <hr className="small-separator" />
          <ControllingPerson />
          <hr className="small-separator" />
          <TaxResidence />
          <hr className="small-separator" />
          <EmploymentInformation />
          <hr className="small-separator" />
          <FamilyInformation />
          <hr className="small-separator" />
          <BankingInformation />
          {!org?.isNotUsingEqKYC && (
            <>
              <hr className="section-separator" />
              <InsiderInformation />
              <hr className="section-separator" />
              <ProfessionalAdvisors />
            </>
          )}
          <hr className="section-separator" />
          <FinancialInformation />
          <hr className="section-separator" />
          <TrustedContactPerson />
          <hr className="section-separator" />
          <LeveragedTaxShelterInformation />
          {!org?.isNotUsingEqKYC && (
            <>
              <hr className="section-separator" />
              <InvestmentInformation />
            </>
          )}
          <hr className="section-separator" />
          <AccreditedInvestorStatus />
          <hr className="section-separator" />
        </FormSection>
        <div className="submit-buttons-container max-width flex space-around">
          {permissionCheck('initialized') && !isFormForCurrentUser && (
            <EquiButton
              kind={ButtonType.secondary}
              onClick={inviteSubscriberOnClick}
              value={
                hasSubscriberBeenInvited ? 'Collaborate with Subscriber' : 'Invite Subscriber to EQ Subscription Portal'
              }
            />
          )}
          {permissionCheck('renewing') && !isFormForCurrentUser && (
            <>
              <EquiButton
                className="mr-2"
                kind={ButtonType.secondary}
                onClick={cancelRenewalProcess}
                value="Cancel Renewal Process"
              />
              <EquiButton
                kind={ButtonType.secondary}
                onClick={renewSubscriberOnClick}
                value="Notify Client to Renew Client Registration"
              />
            </>
          )}
          {(permissionCheck('inProgress') || permissionCheck('changesRequestedByAgent')) && (
            <EquiButton kind={ButtonType.secondary} onClick={requestApprovalOnClick} value="Send Responses To Agent" />
          )}
          {permissionCheck('inProgress') && !isFormForCurrentUser && (
            <EquiButton
              kind={ButtonType.secondary}
              onClick={inviteSubscriberOnClick}
              value={hasSubscriberBeenInvited ? 'Re-send Collaboration Email' : 'Re-Invite Subscriber'}
            />
          )}
          {permissionCheck('underReviewByAgent') && (
            <>
              <EquiButton kind={ButtonType.secondary} onClick={requestChangesOnClick} value="Request Changes" />
              <EquiButton kind={ButtonType.secondary} onClick={agentApproveForm} value="Add Agent Approval" />
            </>
          )}
          {permissionCheck('underReviewByOrgReviewer') && (
            <>
              <EquiButton kind={ButtonType.secondary} onClick={requestChangesOnClick} value="Request Changes" />
              <EquiButton
                kind={ButtonType.secondary}
                onClick={orgReviewerApproveForm}
                value={`Add ${userForForm?.organization?.name || 'Organization'} Approval`}
              />
            </>
          )}
          {permissionCheck('complete') && (
            <>
              <EquiButton kind={ButtonType.secondary} onClick={renewForm} value="Prepare for Renewal" />
              <EquiButton kind={ButtonType.secondary} onClick={quickModification} value="Make Quick Changes" />
            </>
          )}
          {permissionCheck('quickModification') && (
            <>
              <EquiButton kind={ButtonType.secondary} onClick={markComplete} value="Mark Complete" />
            </>
          )}
        </div>
      </Form>
      {isOpen && (
        <Overlay showOverlay={isOpen} rootId="request-approval-success-modal-root">
          <Modal className="RequestApprovalSuccessModal" onOutsideClick={closeModal} withClose>
            <h2>Success!</h2>
            <p>
              Your Agent has been notified that your Client Registration Form is ready for review. They will review your
              responses and be in touch with you if they need any additional information.
            </p>
            <p>No further action is required from you at this point.</p>
          </Modal>
        </Overlay>
      )}
    </>
  );
}

function FormSection(props: { children: Children }): ReactElement {
  const { children } = props;
  return <div className="bottom-space max-width overflow-hidden">{children}</div>;
}

export default KYC;
