import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { SubscriptionPackageAPI } from '@api';
import { State } from '@store';

export interface SubscriptionPackageDocuSignViewURLHook {
  isLoading: boolean;
  hasFetched: boolean;
  docuSignViewUrl: string | null;
}

export function useSubscriptionPackageDocuSignURL(slug?: string): SubscriptionPackageDocuSignViewURLHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const [docuSignViewUrl, setDocuSignViewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  async function getSubscriptionPackageViewUrl(slug: string): Promise<void> {
    setIsLoading(true);
    setHasFetched(true);
    try {
      const docuSignURL = await SubscriptionPackageAPI.getSubscriptionPackageDocuSignViewUrl(slug);
      setDocuSignViewUrl(docuSignURL);
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (slug) {
      getSubscriptionPackageViewUrl(slug);
    }
  }, [slug]);

  return {
    docuSignViewUrl,
    isLoading,
    hasFetched,
  };
}
