import { SubscriptionPackageFormDataAllRequired } from 'shared';
import { useSubscriptionPackageFormState } from '..';
import {
  salespersonInformationFields,
  subscriptionInformationFields,
  subscriptionPackageComputedInformationFields,
} from './formSections';
import { Fields } from './types';
import { useSubscriptionPackageSubFormFields } from './useSubFormFields';

export function useSubscriptionPackageFormFields(): Fields<keyof SubscriptionPackageFormDataAllRequired> {
  const { form } = useSubscriptionPackageFormState();
  const programName = form?.product?.name || form?.formData?.programName || 'EQ 2021 SMART Savings Plan';
  const formFields = useSubscriptionPackageSubFormFields({
    ...salespersonInformationFields,
    ...subscriptionInformationFields(programName),
    ...subscriptionPackageComputedInformationFields,
  });
  return formFields;
}
