import { OfficeUse } from 'shared';
import { ActionTypes } from '@actions';
import * as OfficeUseFormActions from '@actions/officeUseForm';
import { createReducers, ReducersType } from '../helpers';
import { Reducer } from '../index';

export interface State {
  form: OfficeUse | null;
  isLoading: boolean;
  hasFetched: boolean;
}

const initialState: State = { form: null, isLoading: false, hasFetched: false };

const setFormDataReducer: Reducer<State> = (state = initialState, action: OfficeUseFormActions.SetOfficeUseForm) => {
  return { ...state, form: action.payload, isLoading: false };
};

const resetFormReducer: Reducer<State> = () => {
  return {
    ...initialState,
  };
};

const startGetOfficeUseFormReducer: Reducer<State> = (_, action: OfficeUseFormActions.StartGetOfficeUseForm) => {
  return {
    ...initialState,
    isLoading: action.payload,
    hasFetched: true,
  };
};

const setOfficeUseFormDataReducer: Reducer<State> = (
  state = initialState,
  action: OfficeUseFormActions.SetOfficeUseFormData,
) => {
  if (!state.form) {
    return state;
  }
  return {
    ...state,
    form: {
      ...state.form,
      formData: action.payload,
    },
  };
};

const resetHasFetchedOfficeUse: Reducer<State> = (state = initialState) => {
  return {
    ...state,
    hasFetched: false,
  };
};

const resetAppReducer: Reducer<State> = () => {
  return {
    ...initialState,
    hasFetched: true,
  };
};

const Reducers: ReducersType<State> = {
  [ActionTypes.SET_OFFICE_USE_FORM]: setFormDataReducer,
  [ActionTypes.RESET_OFFICE_USE_FORM]: resetFormReducer,
  [ActionTypes.START_GET_OFFICE_USE_FORM]: startGetOfficeUseFormReducer,
  [ActionTypes.SET_OFFICE_USE_FORM_DATA]: setOfficeUseFormDataReducer,
  [ActionTypes.RESET_HAS_FETCHED_OFFICE_USE]: resetHasFetchedOfficeUse,
  [ActionTypes.RESET_APP]: resetAppReducer,
};

export default createReducers(Reducers, initialState);
