import { ReactElement } from 'react';
import './styles.scss';

function Footer(): ReactElement {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="copyright">
          © Copyright {new Date().getFullYear()} EquiGenesis Corporation. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
