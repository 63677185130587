import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { PDFDocument } from 'pdf-lib';
import { OfficeUse } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useOfficeUseFormFields, useOfficeUseFormState } from '@hooks';
import { fillOfficeUseInPDF } from './lib';
import { onLoad } from './lib/subLib';

import './styles.scss';

interface Props {
  className?: string;
  shouldShow: boolean;
  onClose: () => void;
}

function OfficeUseSubmitPDFModal(props: Props): ReactElement {
  const { className, shouldShow, onClose } = props;
  const { form } = useOfficeUseFormState();
  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string | undefined>();
  const formFields = useOfficeUseFormFields();

  async function onFileChange(file: File | undefined): Promise<void> {
    try {
      await validateUploadedFile(file, form);
      setError('');
      setFile(file);
    } catch (e) {
      if (!(e instanceof Error)) {
        throw e;
      }
      setError(e.message);
    }
  }

  function handleClose(): void {
    setFile(undefined);
    setError(undefined);
    onClose();
  }

  function fillOfficeUseOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();
    fillOfficeUseInPDF(file, form);
    onClose();
  }

  return (
    <Overlay
      rootId="office-use-submit-pdf-overlay-root"
      showOverlay={shouldShow}
      className={classnames('OfficeUseSubmitPDFModal', className)}
    >
      <Modal className="UserPermissionsModalBody" onOutsideClick={handleClose} withClose>
        <h1>Submit PDF</h1>
        <p>
          Select an executed Subscription Package (that contains a KYC{!formFields.shouldOmitTCP.value && 'and TCP'})
        </p>
        <div className="center-file-select flex flex-col justify-center align-center">
          <GeneralInput
            onChange={onFileChange as OnChange}
            className="spacer"
            type="file"
            accept="application/pdf"
            formLabel={formFields.officeUsePDF.formLabel as string}
            error={error}
          />
          <EquiButton
            className="spacer"
            disabled={!file}
            onClick={fillOfficeUseOnClick}
            value="Download Modified PDF"
          />
        </div>
      </Modal>
    </Overlay>
  );
}

export default OfficeUseSubmitPDFModal;

async function validateUploadedFile(file: File | undefined, form: OfficeUse | null): Promise<void> {
  if (!file) {
    return;
  }
  const NUMBER_OF_PAGES_IN_COMPLETED_DOC = 49;
  const NUMBER_OF_PAGES_IN_COMPLETED_DOC_WITHOUT_TCP = 48;
  const expectedDocumentLength = form?.formData?.shouldOmitTCP
    ? NUMBER_OF_PAGES_IN_COMPLETED_DOC_WITHOUT_TCP
    : NUMBER_OF_PAGES_IN_COMPLETED_DOC;

  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);
  const existingPdfBytes = await onLoad(fileReader);

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const totalPages = pdfDoc.getPageCount();
  if (totalPages !== expectedDocumentLength) {
    throw new Error(`This document should have ${expectedDocumentLength} pages but instead had ${totalPages} pages.`);
  }
}
