import { useRef, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FullUser, Role, roleHierarchy, isAxiosError, OrganizationRoles } from 'shared';
import { Actions, Dispatch } from '@actions';
import * as UsersAPI from '@api/users';
import { PATHS } from '@routes/lib';
import { State } from '@store';
import { useCurrentUser } from '../useCurrentUser';

export interface SingleUserHook {
  user: FullUser | null;
  isLoading: boolean;
  error: boolean;
  toggleUserBlocked: () => void;
  deleteUser: () => void;
  inviteUser: () => void;
  modifyRoles: (modifiedRoles: { [Key in Role]: boolean }, onSuccess?: () => void, onError?: () => void) => void;
  updateOrgUserRoles: (
    organizationRoles: OrganizationRoles,
    onError: (e: Error) => void,
    onSuccess: () => void,
  ) => Promise<void>;
}

export function useSingleUser(email: string): SingleUserHook {
  const { isCurrentUserAdmin } = useCurrentUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useCurrentUser();
  const [error, setError] = useState(false);
  const [user, setUser] = useState<FullUser | null>(null);
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const stillValid = useRef(true);
  useEffect(() => {
    (async (): Promise<void> => {
      stillValid.current = true;
      setIsLoading(true);
      setError(false);
      setUser(null);
      try {
        const user = await UsersAPI.getSingleUserByEmail(email);
        if (stillValid.current) {
          setUser(user);
          setIsLoading(false);
        }
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          history.replace(PATHS.NOT_FOUND);
        } else if (isAxiosError(e) && e.response?.status === 500) {
          if (stillValid.current) {
            setError(true);
          }
        } else if (isAxiosError(e) && e.response?.status !== 404) {
          dispatch(Actions.handleAxiosError(e));
        } else {
          if (stillValid.current) {
            setError(true);
          }
        }
        if (stillValid.current) {
          setIsLoading(false);
        }
      }
    })();
    return (): void => {
      stillValid.current = false;
    };
  }, [email]);

  async function toggleUserBlocked(): Promise<void> {
    const unblockMsg =
      'Unblocking this user will give them access to the Subscription Portal with their currently assigned roles. Are you sure you want to do this?';
    const blockMsg =
      'Blocking this user will prevent them from accessing the Subscription Portal. Are you sure you want to do this?';
    if (user && confirm(user?.blocked ? unblockMsg : blockMsg)) {
      try {
        dispatch(Actions.showLoadingOverlay());
        const u = await UsersAPI.blockUser(user.user_id, !user.blocked);
        setUser(u);
        dispatch(Actions.hideLoadingOverlay());
      } catch (e) {
        dispatch(Actions.handleAxiosError(e));
      }
    }
  }

  async function deleteUser(): Promise<void> {
    if (
      !user ||
      !isCurrentUserAdmin ||
      !confirm(
        'Deleting this user will remove them from the Subscription Portal and delete all of their user information. This cannot be undone. Are you absolutely sure you wish to proceed?',
      )
    ) {
      return;
    }
    try {
      dispatch(Actions.showLoadingOverlay());
      await UsersAPI.deleteUser(user.user_id);
      history.push(PATHS.ADMIN_VIEW_USERS);
      dispatch(Actions.hideLoadingOverlay());
      dispatch(
        Actions.flashSuccessMessage(
          `${user.name} has been deleted. All of his/her information was removed from the Subscription Portal.`,
        ),
      );
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
    }
  }

  async function inviteUser(): Promise<void> {
    if (
      !user ||
      !isCurrentUserAdmin ||
      !confirm(`This will send a new invitation email to ${user.name}. Are you absolutely sure you wish to proceed?`)
    ) {
      return;
    }
    try {
      dispatch(Actions.showLoadingOverlay());
      await UsersAPI.inviteUser(user.user_id);
      dispatch(Actions.hideLoadingOverlay());
      dispatch(
        Actions.flashSuccessMessage(`${user.name} has been invited. You have been CC'ed on the invitation email.`),
      );
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
    }
  }

  async function modifyRoles(
    modifiedRoles: { [Key in Role]: boolean },
    onSuccess: () => void = (): void => undefined,
    onError: () => void = (): void => undefined,
  ): Promise<void> {
    if (!user) {
      return;
    }
    try {
      dispatch(Actions.showLoadingOverlay());
      const roles: Role[] = (roleHierarchy as Role[])
        .filter((role) => modifiedRoles[role])
        .filter((role) => user.user_id !== currentUser?.user_id || role !== 'admin');
      const u = await UsersAPI.updateUserRoles(user.user_id, roles);
      setUser(u);
      dispatch(Actions.hideLoadingOverlay());
      onSuccess();
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
      onError();
    }
  }

  async function updateOrgUserRoles(
    organizationRoles: OrganizationRoles,
    onError: (e: Error) => void,
    onSuccess: () => void,
  ): Promise<void> {
    try {
      if (!user) {
        return;
      }
      dispatch(Actions.showLoadingOverlay());
      const u = await UsersAPI.updateOrgUserRoles(user.user_id, { organizationRoles });
      setUser(u);
      dispatch(Actions.hideLoadingOverlay());
      onSuccess();
    } catch (e) {
      dispatch(Actions.handleAxiosError(e));
      if (e instanceof Error) {
        onError(e);
      }
    }
  }

  return {
    user,
    isLoading,
    error,
    toggleUserBlocked,
    deleteUser,
    modifyRoles,
    inviteUser,
    updateOrgUserRoles,
  };
}
