import { ReactElement, useContext } from 'react';
import NavBranding from '@components/common/NavBranding';
import { BrandingContext } from '@contexts';
import NavBar from '../NavBar';
import './styles.scss';

function Header(): ReactElement {
  const { showBrandingInNav, organization } = useContext(BrandingContext);
  return (
    <header>
      {showBrandingInNav && organization && <NavBranding organization={organization} />}
      <NavBar />
    </header>
  );
}

export default Header;
