import axios from 'axios';
import { RequestParams, RequestPayloads, ResponsePayloads, SubscriptionPackageState } from 'shared';

export function sendSubscriptionPackageToDocuSign(
  params: RequestParams['sendSubscriptionPackageToDocuSign'],
  { isTest }: RequestPayloads['sendSubscriptionPackageToDocuSign'],
): Promise<ResponsePayloads['sendSubscriptionPackageToDocuSign']> {
  return axios.post(`/envelopes/${params.subscriptionPackageId}/sendToDocuSign`, { isTest }).then((res) => res.data);
}

export function voidDocuSignSubscriptionPackage(
  params: RequestParams['voidDocuSignSubscriptionPackage'],
): Promise<ResponsePayloads['voidDocuSignSubscriptionPackage']> {
  return axios.patch(`/envelopes/${params.subscriptionPackageId}/void`).then((res) => res.data);
}

export function voidAndResendDocuSignSubscriptionPackage(
  params: RequestParams['voidAndResendDocuSignSubscriptionPackage'],
): Promise<ResponsePayloads['voidAndResendDocuSignSubscriptionPackage']> {
  return axios.patch(`/envelopes/${params.subscriptionPackageId}/resend`).then((res) => res.data);
}

export function getProcessingSubscriptionPackageStatus(
  params: RequestParams['getLatestStatus'],
): Promise<{ data?: ResponsePayloads['getLatestStatus']; status: number }> {
  return axios
    .get(`/envelopes/${params.subscriptionPackageId}/status`)
    .then((res) => ({ data: res.data, status: res.status }));
}

export function updateSubscriptionPackage(
  params: RequestParams['updateSubscriptionPackage'],
  subscriptionPackage: RequestPayloads['updateSubscriptionPackage'],
): Promise<ResponsePayloads['updateSubscriptionPackage']> {
  return axios
    .patch(`/subscription-packages/${params.subscriptionPackageId}`, subscriptionPackage)
    .then((res) => res.data);
}

export function getSubscriptionPackagesByUserEmail(
  email: string,
  state?: SubscriptionPackageState,
): Promise<ResponsePayloads['getSubscriptionPackagesByUserEmail']> {
  const query = state ? `?state=${state}` : '';
  return axios.get(`/users/${email}/subscription-packages${query}`).then((res) => res.data);
}

export function getSubscriptionPackage(slug: string): Promise<ResponsePayloads['getSubscriptionPackage']> {
  return axios.get(`/subscription-packages/${slug}`).then((res) => res.data);
}

export function getSubscriptionPackageDocuSignViewUrl(
  slug: string,
): Promise<ResponsePayloads['getSubscriptionPackageDocuSignViewUrl']> {
  return axios.get(`/subscription-packages/${slug}/docusign-view-url`).then((res) => res.data);
}

export function getSubscriptionPackages(): Promise<ResponsePayloads['getSubscriptionPackages']> {
  return axios.get(`/subscription-packages`).then((res) => res.data);
}

export function requestApproval(id: number): Promise<ResponsePayloads['requestApprovalSubscriptionPackage']> {
  return axios.patch(`/subscription-packages/${id}/request-approval`).then((res) => res.data);
}

export function requestChanges(id: number): Promise<ResponsePayloads['changesRequestedSubscriptionPackage']> {
  return axios.patch(`/subscription-packages/${id}/changes-requested`).then((res) => res.data);
}

export function approveForm(id: number): Promise<ResponsePayloads['approveSubscriptionPackage']> {
  return axios.patch(`/subscription-packages/${id}/approve`).then((res) => res.data);
}

export function deleteForm({ subscriptionPackageId }: RequestParams['deleteSubscriptionPackage']): Promise<void> {
  return axios.delete(`/subscription-packages/${subscriptionPackageId}`);
}

export function createSubscriptionPackage(
  kycId: number,
  newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
): Promise<ResponsePayloads['createSubscriptionPackage']> {
  return axios.post(`/kycs/${kycId}/subscription-packages`, newSubscriptionPackage).then((res) => res.data);
}

export function getDocuSignTemplatesForSubscriptionPackage(
  params: RequestParams['getDocuSignTemplatesForSubscriptionPackage'],
): Promise<ResponsePayloads['getDocuSignTemplatesForSubscriptionPackage']> {
  return axios.get(`/subscription-packages/${params.subscriptionPackageId}/docusign-templates`).then((res) => res.data);
}
