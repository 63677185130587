import { isAxiosError } from 'shared';
import * as CurrentUserAPI from '@api/currentUser';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { Actions as GeneralActions, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import { ActionTypes, GetCurrentUser, StartGetCurrentUser, ResetHasFetchedCurrentUser } from './types';

export type Actions = GetCurrentUser | StartGetCurrentUser | ResetHasFetchedCurrentUser;

export const Actions = {
  getCurrentUserInfo(): AsyncAction<StartGetCurrentUser | GetCurrentUser | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch, getState): Promise<void> => {
      try {
        const {
          auth: { user },
          currentUser: { hasFetched },
        } = getState();
        if (!user || hasFetched) {
          return;
        }
        dispatch({ type: ActionTypes.START_GET_CURRENT_USER, payload: undefined });
        const currentUser = await CurrentUserAPI.getCurrentUser();
        dispatch({ type: ActionTypes.GET_CURRENT_USER, payload: currentUser });
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  resetHasFetchedCurrentUser(): AsyncAction<ResetHasFetchedCurrentUser> {
    return async (dispatch, getState): Promise<void> => {
      const {
        currentUser: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_CURRENT_USER, payload: undefined });
    };
  },
};

export * from './types';
