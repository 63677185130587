import { ReactElement } from 'react';
import { TrueFalseRadio } from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function InsiderInformation(): ReactElement {
  const formFields = useKYCFormFields();
  const isInsider = formFields.insider.value === TrueFalseRadio.true;
  function updateInsider(e: React.ChangeEvent<HTMLInputElement>, value?: TrueFalseRadio): void {
    formFields.insider.onChange(e, value);
    if (value === TrueFalseRadio.false) {
      formFields.reportingIssuer.onChange(e, undefined);
    }
  }
  return (
    <div>
      <h2 className="title">Insider Information</h2>
      <div className="flex">
        <div className="spacer justify-center align-center">
          <EquiInput {...formFields.insider} onChange={updateInsider} />
        </div>
      </div>
      {isInsider && (
        <>
          <div className="spacer justify-center align-center">
            <EquiInput {...formFields.reportingIssuer} />
          </div>
          <div className="max-width"></div>
        </>
      )}
    </div>
  );
}

export default InsiderInformation;
