import { FlashMessageType } from '@components/common/FlashMessage';
import { AsyncAction } from '../types';
import {
  ActionTypes,
  SetShowLoadingOverlay,
  SetShowSessionExpiredOverlay,
  FlashMessage,
  SetError,
  NotConnectedError,
  UpdateProcessingProgress,
} from './types';

export type Actions = SetShowLoadingOverlay | SetShowSessionExpiredOverlay | FlashMessage;

let stopTimeout: NodeJS.Timeout;
let startTimeout: NodeJS.Timeout;

export const Actions = {
  showLoadingOverlay(): SetShowLoadingOverlay {
    return { type: ActionTypes.SET_SHOW_LOADING_OVERLAY, payload: true };
  },
  hideLoadingOverlay(): SetShowLoadingOverlay {
    return { type: ActionTypes.SET_SHOW_LOADING_OVERLAY, payload: false };
  },
  showSessionExpiredOverlay(): SetShowSessionExpiredOverlay {
    return { type: ActionTypes.SET_SHOW_SESSION_EXPIRED_OVERLAY, payload: true };
  },
  flashErrorMessage(message: string): AsyncAction<FlashMessage> {
    return (dispatch): void => {
      dispatch(Actions.flashMessage(FlashMessageType.danger, message));
    };
  },
  flashSuccessMessage(message: string): AsyncAction<FlashMessage> {
    return (dispatch): void => {
      dispatch(Actions.flashMessage(FlashMessageType.success, message));
    };
  },
  flashWarningMessage(message: string): AsyncAction<FlashMessage> {
    return (dispatch): void => {
      dispatch(Actions.flashMessage(FlashMessageType.warning, message));
    };
  },
  flashMessage(type: FlashMessageType, message: string): AsyncAction<FlashMessage> {
    return (dispatch): void => {
      dispatch(Actions.stopFlashMessage());
      startTimeout = setTimeout(() => {
        dispatch({
          type: ActionTypes.FLASH_MESSAGE,
          payload: {
            type,
            message,
          },
        });
      }, 200);
      stopTimeout = setTimeout(() => {
        dispatch(Actions.stopFlashMessage());
      }, 7000);
    };
  },
  stopFlashMessage(): FlashMessage {
    clearTimeout(stopTimeout);
    clearTimeout(startTimeout);
    return { type: ActionTypes.FLASH_MESSAGE, payload: null };
  },
  setError(errorMessage: string): SetError {
    return { type: ActionTypes.SET_ERROR, payload: errorMessage };
  },
  notConnectedToInternet(): AsyncAction<NotConnectedError> {
    return (dispatch): void => {
      dispatch({ type: ActionTypes.NOT_CONNECTED_TO_INTERNET, payload: undefined });
    };
  },
  updateProgress(progress: number | null = null): UpdateProcessingProgress {
    return { type: ActionTypes.UPDATE_PROCESSING_PROGRESS, payload: { progress } };
  },
};

export * from './types';
