import { useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions, Dispatch } from '@actions';
import { ValidPath } from '@routes/lib';
import { State } from '@store';
import { useHookLock } from '../useHookLock';

export interface NavigationHook {
  goBack(event: React.MouseEvent): void;
  goBackLocation: ValidPath | null;
}

export function useSmartBackButton(forceBackLocation?: ValidPath, defaultLocation?: ValidPath): NavigationHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const navigation = useSelector<State>((state) => state.navigation) as State['navigation'];
  const { hasLock } = useHookLock('useSmartBackButton');
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const stillValid = useRef(true);

  function startNavigation(path: ValidPath): void {
    dispatch(Actions.startNavigation(pathname as ValidPath, path));
  }
  function navigationEnded(): void {
    dispatch(Actions.navigationEnded());
  }
  const { goBackLocation, currentLocation } = navigation;

  useEffect(() => {
    if (
      hasLock &&
      currentLocation &&
      pathname === currentLocation &&
      pathname !== goBackLocation &&
      stillValid.current
    ) {
      stillValid.current = false;
      navigationEnded();
      requestAnimationFrame(() => {
        history.goBack();
      });
    } else if (hasLock && currentLocation) {
      requestAnimationFrame(() => {
        history.push(currentLocation);
      });
    }
  }, [pathname, goBackLocation]);

  function goBack(event: React.MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (forceBackLocation) {
      startNavigation(forceBackLocation);
    }
    history.goBack();
    setTimeout(() => {
      // if after 50 ms we have no navigated
      // and we were supposed to have navigated...
      if (
        defaultLocation &&
        history.location.pathname === pathname &&
        pathname !== defaultLocation &&
        stillValid.current
      ) {
        history.replace(defaultLocation);
        history.push(pathname);
        history.goBack();
      }
    }, 70);
  }

  return { goBack, goBackLocation };
}
