import { ReactElement } from 'react';
import classnames from 'classnames';
import EquiButton from '@components/common/EquiButton';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useAuth, useGeneral } from '@hooks';

import './styles.scss';

interface Props {
  className?: string;
}

function SessionExpiredOverlay(props: Props): ReactElement {
  const { className } = props;

  const {
    general: { showSessionExpiredOverlay },
  } = useGeneral();
  const { startLogout } = useAuth();

  return (
    <Overlay
      rootId="session-expired-overlay-root"
      showOverlay={showSessionExpiredOverlay}
      className={classnames('SessionExpiredOverlay', className)}
    >
      <Modal className="SessionExpiredModal">
        <h1>Session Expired</h1>
        <p>Your session has expired. The Form details have been saved.</p>
        <p>Please re-login to continue.</p>
        <EquiButton value="Re-login" onClick={startLogout} />
      </Modal>
    </Overlay>
  );
}

export default SessionExpiredOverlay;
