import axios from 'axios';
import { ResponsePayloads } from 'shared';

const Products = {
  base: '/products',
};

export function getProducts(): Promise<ResponsePayloads['getProducts']> {
  return axios.get(`${Products.base}`).then((res) => res.data);
}
