import { useRef, useEffect, ReactElement } from 'react';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { Children } from '@custom-types';
import { enablePageScrolling, disablePageScrolling } from '@utils/domHelpers';
import { getModalRoot } from './lib';

import './styles.scss';

interface Props {
  className?: string;
  showOverlay: boolean;
  withoutBackground?: boolean;
  alwaysShow?: boolean;
  blockScroll?: boolean;
  rootId: string;
  children: Children;
}

function Overlay(props: Props): ReactElement | null {
  const { className, showOverlay, children, rootId, withoutBackground, alwaysShow, blockScroll = true } = props;
  const elRef = useRef(document.createElement('div'));
  useEffect(() => {
    const modalRoot = getModalRoot(rootId);
    if (modalRoot) {
      modalRoot.appendChild(elRef.current);
    }
    return (): void => {
      if (modalRoot) {
        modalRoot.removeChild(elRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showOverlay && blockScroll) {
      disablePageScrolling();
    } else {
      enablePageScrolling();
    }
    return (): void => {
      enablePageScrolling();
    };
  }, [showOverlay]);

  if (!alwaysShow && !showOverlay) {
    return null;
  }

  return createPortal(
    <div className={classnames('Overlay', className, { withoutBackground, isOpen: showOverlay })}>{children}</div>,
    elRef.current,
  );
}

export default Overlay;
