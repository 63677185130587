import { ReactElement } from 'react';
import classnames from 'classnames';
import { NavLink as ReactNavLink, NavLinkProps } from 'react-router-dom';
import './styles.scss';

interface Props extends NavLinkProps {
  content: string | React.ReactChild;
}

function NavLink(props: Props): ReactElement {
  const { content, className, ...restProps } = props;
  return (
    <ReactNavLink {...restProps} className={classnames('NavLink', 'nav-link', 'flex', className)}>
      {content}
    </ReactNavLink>
  );
}

export default NavLink;
