import { ReactElement } from 'react';
import Loading from '@components/common/Loading';
import { Page } from '@layouts';
import { ValidPath } from '@routes/lib';

import './styles.scss';

interface Props {
  defaultBackLocation?: ValidPath;
}

export default function LoadingPage({ defaultBackLocation }: Props): ReactElement {
  return (
    <Page className="LoadingPage" defaultBackLocation={defaultBackLocation}>
      <div className="loading-container flex justify-center align-center">
        <Loading />
      </div>
    </Page>
  );
}
