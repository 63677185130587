import { TrustedContactPerson } from 'shared';
import { titleRadioField } from '@utils/formFields';
import { ProtoFields } from '../types';

export const trustedContactPersonFields: ProtoFields<keyof TrustedContactPerson> = {
  tcpDoNotDesignateCheckbox: {
    type: 'checkbox',
    formLabel: 'I do not wish to designate a TCP or update my TCP at this time',
  },
  tcpDoNotDesignateReason: {
    formLabel: 'Reason(s):',
    type: 'textarea',
    maxLength: 200,
    note: '(max 200 characters)',
  },
  tcpEmail: { formLabel: 'E-mail', type: 'email' },
  tcpMailingAddressCity: { formLabel: 'City' },
  tcpMailingAddressCountry: { formLabel: 'Country' },
  tcpMailingAddressPostalCode: { formLabel: 'Postal Code' },
  tcpMailingAddressProvince: { formLabel: 'Province' },
  tcpMailingAddressStreet: { formLabel: 'Street Address' },
  tcpName: { formLabel: 'Name' },
  tcpPhone: { formLabel: 'Phone Number', type: 'tel' },
  tcpPhoneCountryCode: { formLabel: 'Phone Number' },
  tcpRelationshipWithClient: {
    formLabel: 'Nature of Relationship with Client',
    type: 'textarea',
    maxLength: 300,
    note: '(max 300 characters)',
  },
  tcpTitle: titleRadioField,
};
