import { useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface FlashMessageHook {
  flashSuccessMessage(message: string): void;
  flashWarningMessage(message: string): void;
  flashErrorMessage(message: string): void;
  stopFlashMessage(): void;
}

export function useFlashMessage(): FlashMessageHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;

  function flashSuccessMessage(message: string): void {
    dispatch(Actions.flashSuccessMessage(message));
  }
  function flashWarningMessage(message: string): void {
    dispatch(Actions.flashWarningMessage(message));
  }
  function flashErrorMessage(message: string): void {
    dispatch(Actions.flashErrorMessage(message));
  }
  function stopFlashMessage(): void {
    dispatch(Actions.stopFlashMessage());
  }

  return { flashSuccessMessage, flashWarningMessage, flashErrorMessage, stopFlashMessage };
}
