import { ReactElement } from 'react';
import { Page } from '@layouts';
// NOTE: this page has no nav bar
// used as a callback page when viewing the docusign document online
function ClosedDocumentPage(): ReactElement {
  return (
    <Page>
      <h2>Document has been closed</h2>
      <h3>
        Please refresh the page in order to view it again, or press the back button to return to the previous page.
      </h3>
    </Page>
  );
}

export default ClosedDocumentPage;
