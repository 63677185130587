import { combineReducers, Reducer as ReduxReducer } from 'redux';
import { Action } from '@actions';
import auth, { State as AuthState } from './auth';
import currentUser, { State as CurrentUserState } from './currentUser';
import general, { State as GeneralState } from './general';
import kycForm, { State as KYCFormState } from './kycForm';
import kycs, { State as KYCsState } from './kycs';
import locks, { State as LocksState } from './locks';
import navigation, { State as NavigationState } from './navigation';
import officeUseForm, { State as OfficeUseFormState } from './officeUseForm';
import organizations, { State as OrganizationsState } from './organizations';
import relationships, { State as RelationshipsState } from './relationships';
import subscriptionPackageForm, { State as SubscriptionPackageFormState } from './subscriptionPackageForm';
import subscriptionPackages, { State as SubscriptionPackagesState } from './subscriptionPackages';
import users, { State as UsersState } from './users';

export type Reducer<S> = ReduxReducer<S, Action>;

export interface State {
  auth: AuthState;
  currentUser: CurrentUserState;
  general: GeneralState;
  kycForm: KYCFormState;
  officeUseForm: OfficeUseFormState;
  kycs: KYCsState;
  locks: LocksState;
  navigation: NavigationState;
  relationships: RelationshipsState;
  subscriptionPackageForm: SubscriptionPackageFormState;
  subscriptionPackages: SubscriptionPackagesState;
  users: UsersState;
  organizations: OrganizationsState;
}

const stateReducer: Reducer<State> = combineReducers({
  auth,
  currentUser,
  general,
  kycForm,
  officeUseForm,
  kycs,
  locks,
  navigation,
  relationships,
  subscriptionPackageForm,
  subscriptionPackages,
  users,
  organizations,
});

export default stateReducer;
