import { ReactElement, useState } from 'react';
import { useStore } from 'react-redux';
import { Actions, Dispatch } from '@actions';
import { Actions as ApiActions } from '@actions/api';
import { Actions as GeneralActions } from '@actions/general';
import { runWinterPegScenarioBTerminationProcess } from '@api/winterpeg';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import { useGeneral } from '@hooks';
import { Page } from '@layouts';
import { State } from '@reducers';
import { PATHS } from '@routes/lib';

export default function WinterpegScenarioB(): ReactElement {
  const { showLoadingOverlay, hideLoadingOverlay } = useGeneral();
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const handleSubmit = async (isTest: boolean): Promise<void> => {
    if (!selectedFile) {
      return;
    }
    if (
      !confirm(
        `${
          isTest ? '[TEST]' : ''
        } Warning: This will immediately generate a DocuSign Scenario B documents for all clients in the file "${
          selectedFile?.name
        }". This cannot be undone. Are you sure you wish to proceed?`,
      )
    ) {
      return;
    }
    try {
      showLoadingOverlay();
      await runWinterPegScenarioBTerminationProcess(selectedFile, isTest);
      dispatch(GeneralActions.flashSuccessMessage('Scenario B Termination Process Successfully Started'));
      setConfirmation(false);
    } catch (e) {
      dispatch(ApiActions.handleAxiosError(e));
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <Page
      className="WinterpegScenarioB"
      renderHeader={(): ReactElement => <h1 className="text-center">Winterpeg Scenario B</h1>}
      defaultBackLocation={PATHS.ADMIN_WINTERPEG}
    >
      <div className="mt-10 mb-10">
        Use this form to generate DocuSign envelopes for the Scenario B Winterpeg Termination Process
      </div>
      <Form turnOffAutoComplete={true} className="min-h-0">
        <GeneralInput
          name="file"
          formLabel="Client Data"
          type="file"
          onChange={setSelectedFile as OnChange}
          accept=".csv"
        />
        <GeneralInput
          className="mt-4"
          wrapLabel
          type="checkbox"
          formLabel="I have double checked that all of the CSV and program information is correct. I understand that once I submit, there is no way to correct any mistakes."
          name="confirmation"
          onChange={(setConfirmation as unknown) as OnChange}
          checked={confirmation}
        />
        <div className="flex gap-10">
          <EquiButton
            value="Submit"
            className="mt-10"
            disabled={!selectedFile || !confirmation}
            kind={ButtonType.secondary}
            onClick={(): Promise<void> => handleSubmit(false)}
          />
          <EquiButton
            value="Submit Test"
            className="mt-10"
            disabled={!selectedFile || !confirmation}
            kind={ButtonType.secondary}
            onClick={(): Promise<void> => handleSubmit(true)}
          />
        </div>
      </Form>
    </Page>
  );
}
