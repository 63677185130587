import { OfficeUseFormData } from 'shared';
import { State } from '@reducers/officeUseForm';
import { Action } from '../types';

export enum ActionTypes {
  SUBMIT_OFFICE_USE = 'SUBMIT_OFFICE_USE',
  SET_OFFICE_USE_FORM = 'SET_OFFICE_USE_FORM',
  RESET_OFFICE_USE_FORM = 'RESET_OFFICE_USE_FORM',
  START_GET_OFFICE_USE_FORM = 'START_GET_OFFICE_USE_FORM',
  SET_OFFICE_USE_FORM_DATA = 'SET_OFFICE_USE_FORM_DATA',
  RESET_HAS_FETCHED_OFFICE_USE = 'RESET_HAS_FETCHED_OFFICE_USE',
}

export interface SubmitOfficeUse extends Action {
  type: typeof ActionTypes.SUBMIT_OFFICE_USE;
}

export interface SetOfficeUseForm extends Action {
  type: typeof ActionTypes.SET_OFFICE_USE_FORM;
  payload: State['form'];
}

export interface ResetOfficeUseForm extends Action {
  type: typeof ActionTypes.RESET_OFFICE_USE_FORM;
}

export interface StartGetOfficeUseForm extends Action {
  type: typeof ActionTypes.START_GET_OFFICE_USE_FORM;
  payload: boolean;
}

export interface SetOfficeUseFormData extends Action {
  type: typeof ActionTypes.SET_OFFICE_USE_FORM_DATA;
  payload: OfficeUseFormData;
}

export interface ResetHasFetchedOfficeUse extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_OFFICE_USE;
}
