import { ReactElement } from 'react';
import { LinkCardData, renderCards } from '@components/common/LinkCard';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

export default function AdminManageUsers(): ReactElement {
  const cards: LinkCardData[] = [
    {
      title: 'Add Users',
      to: PATHS.ADMIN_USERS_ADD,
      icon: 'addUser',
    },
    {
      title: 'View Users',
      to: PATHS.ADMIN_VIEW_USERS,
      icon: 'users',
    },
  ];
  return (
    <Page
      className="AdminManageUsers"
      renderHeader={(): ReactElement => <h1 className="text-center">Manage Users</h1>}
      defaultBackLocation={PATHS.ADMIN}
    >
      {renderCards(cards)}
    </Page>
  );
}
