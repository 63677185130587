import { ReactElement } from 'react';
import Icon from '@components/common/Icon';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

export default function NotFound(): ReactElement {
  return (
    <Page
      className="NotFound"
      renderHeader={(): ReactElement => <h1 className="text-center">404 Not Found</h1>}
      defaultBackLocation={PATHS.HOME}
    >
      <div className="flex column align-center not-found-container">
        <p>The page you are looking for could not be found</p>
        <Icon type="notFound" />
      </div>
    </Page>
  );
}
