import { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CurrentUserHook, useCurrentUser } from '@hooks';
import { LoadingPage } from '@pages';
import { PATHS, Route as RouteProps } from './lib';

interface Props extends RouteProps {
  isAuth: ({ isCurrentUserAdmin }: CurrentUserHook) => boolean;
}

function AuthRoute({ isAuth, component: Component, ...restProps }: Props): ReactElement | null {
  const { currentUser, ...rest } = useCurrentUser();
  return (
    <Route
      {...restProps}
      render={(): ReactElement =>
        !currentUser ? (
          <LoadingPage />
        ) : !isAuth({ currentUser, ...rest }) ? (
          <Redirect to={PATHS.NOT_FOUND} />
        ) : (
          <Component />
        )
      }
    ></Route>
  );
}

export default AuthRoute;
