import { ReactElement } from 'react';
import EquiInput from '@components/common/SubscriptionPackageEquiInput';
import { useSubscriptionPackageFormFields, useSubscriptionPackageFormState } from '@hooks';

export default function MasterLPSSPFields(): ReactElement {
  const { form } = useSubscriptionPackageFormState();
  const formFields = useSubscriptionPackageFormFields();
  return (
    <>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Subscription Date Cheque Amount
            <br />
            $1,100 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.masterlpSubscriptionDateChequeAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Feb 1st {new Date(form?.createdAt || '').getFullYear() + 1} Cheque Amount
            <br />
            $1,450 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.masterlpFeb1stYear1ChequeAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Feb 1st {new Date(form?.createdAt || '').getFullYear() + 2} Cheque Amount
            <br />
            $1,800 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.masterlpFeb1stYear2ChequeAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>Risk of Loss</strong>
        </h3>
        <EquiInput {...formFields.masterlpRiskOfLossAmount} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>
            Loan Amount
            <br />
            $3,168.22 × number of units
          </strong>
        </h3>
        <EquiInput {...formFields.masterlpLoanAmount} />
      </div>
    </>
  );
}
