import { AxiosError } from 'axios';
import { RequestPayloads, Organization } from 'shared';
import { ManageOrganizationAPI } from '@api';
import { Actions as APIActions } from '../api';
import { Actions as GeneralActions, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import {
  ActionTypes,
  CreateNewOrganization,
  CreateNewOrganizationUser,
  AddExistingUserToOrganization,
  UpdateOrganization,
} from './types';

export type Actions =
  | CreateNewOrganization
  | CreateNewOrganizationUser
  | AddExistingUserToOrganization
  | UpdateOrganization;

export const Actions = {
  createOrganization(
    organization: RequestPayloads['createOrganization'],
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ): AsyncAction<CreateNewOrganization | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      dispatch({ type: ActionTypes.CREATE_NEW_ORGANIZATION, payload: { ...organization } });
      dispatch(GeneralActions.showLoadingOverlay());
      try {
        const res = await ManageOrganizationAPI.createOrganization(organization);
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess(res);
      } catch (e) {
        dispatch(APIActions.handleAxiosError(e));
        onError(e as Error);
      }
    };
  },
  updateOrganization(
    organizationId: number,
    organization: RequestPayloads['updateOrganization'],
    needToUpdateCurrentUser: boolean,
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ): AsyncAction<UpdateOrganization | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      const {
        name,
        requiresKYCReviewByReviewer,
        logoURL,
        shouldBrandPortalForOrganization,
        isNotUsingEqKYC,
        darkLogoURL,
      } = organization;
      const updatedOrg = {
        name,
        requiresKYCReviewByReviewer,
        logoURL,
        shouldBrandPortalForOrganization,
        isNotUsingEqKYC,
        darkLogoURL,
      };
      dispatch({ type: ActionTypes.UPDATE_ORGANIZATION, payload: { id: organizationId, ...updatedOrg } });
      dispatch(GeneralActions.showLoadingOverlay());
      try {
        const res = await ManageOrganizationAPI.updateOrganization({ organizationId }, updatedOrg);
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess(res);
        if (needToUpdateCurrentUser) {
          window.location.reload();
        }
      } catch (e) {
        dispatch(APIActions.handleAxiosError(e));
        onError(e as Error);
      }
    };
  },
  createNewOrganizationUser(
    organization: Organization,
    newOrgUser: RequestPayloads['createNewOrganizationUser'],
    onError: (e: Error) => void,
    onSuccess: (res: Organization) => void,
  ): AsyncAction<CreateNewOrganizationUser | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      dispatch({ type: ActionTypes.CREATE_NEW_ORGANIZATION_USER, payload: { ...newOrgUser } });
      dispatch(GeneralActions.showLoadingOverlay());
      try {
        const res = await ManageOrganizationAPI.createNewOrganizationUser(organization, newOrgUser);
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess(res);
      } catch (e) {
        dispatch(APIActions.handleAxiosError(e));
        onError(e as Error);
      }
    };
  },
  addExistingUserToOrganization(
    organization: Organization,
    user: RequestPayloads['addExistingUserToOrganization'],
    onError: (e: AxiosError) => void,
    onSuccess: (res: Organization) => void,
  ): AsyncAction<AddExistingUserToOrganization | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      dispatch({ type: ActionTypes.ADD_EXISTING_USER_TO_ORGANIZATION, payload: user });
      dispatch(GeneralActions.showLoadingOverlay());
      try {
        const res = await ManageOrganizationAPI.addExistingUserToOrganization(organization, user);
        dispatch(GeneralActions.hideLoadingOverlay());
        onSuccess(res);
      } catch (e) {
        dispatch(APIActions.handleAxiosError(e));
        onError(e as AxiosError);
      }
    };
  },
};

export * from './types';
