import { ReactElement } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Children } from '@custom-types';
import { ValidPath } from '@routes/lib';
import Icon from '../Icon';
import { ICON_LIBRARY } from '../Icon/lib';

import './styles.scss';

export interface Props {
  to: ValidPath;
  children?: Children;
  icon?: keyof typeof ICON_LIBRARY;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

function LinkCard({ to, children, icon, className, onClick }: Props): ReactElement {
  return (
    <Link className={classnames('LinkCard relative', className)} onClick={onClick} to={to}>
      <div className="flex flex-col justify-center align-center flex-1 children-container">{children}</div>
      {icon && (
        <div className="icon-container">
          <Icon type={icon} />
        </div>
      )}
    </Link>
  );
}

export default LinkCard;

export type LinkCardData = (Props & { title: React.ReactNode; className?: string; key?: string }) | null;
export function renderCards(cards: (LinkCardData | false)[], className?: string): ReactElement {
  const filteredCards = cards.filter((card): card is LinkCardData => !!card);
  const cols = filteredCards.length > 3 ? 3 : filteredCards.length;
  return (
    <div className={classnames('LinkCard-container', 'max-width', 'grid', `grid-${cols}`, className)}>
      {filteredCards
        .filter((a) => a)
        .map((card, i) =>
          !card ? (
            <div key={i} />
          ) : (
            <LinkCard
              key={card.key || card.to + card.title}
              className={classnames('light', card.className)}
              to={card.to}
              icon={card.icon}
              onClick={card.onClick}
            >
              {typeof card.title === 'string' ? <h2>{card.title}</h2> : card.title}
            </LinkCard>
          ),
        )}
    </div>
  );
}
