import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import {
  validateOrganizationNewUserDetails,
  ValidationErrorItem,
  isSequelizeValidationError,
  Organization,
  CreateUserReqPayload,
  OrganizationRoles,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import OrganizationRolesFormFields from '@components/common/OrganizationRolesFormFields';
import Overlay from '@components/common/Overlay';
import { useManageOrganization } from '@hooks';
import { getErrors } from '@utils/formFields';

import './styles.scss';

interface Props {
  organization: Organization;
  className?: string;
  onClose: () => void;
  onSuccess?: (organization: Organization) => void;
}

interface State extends Omit<CreateUserReqPayload, 'roles'> {
  errors: ValidationErrorItem[];
}

const initialFormState: Omit<State, 'organizationId'> = {
  firstName: '',
  lastName: '',
  email: '',
  organizationRoles: {
    isAdmin: false,
    isAgent: false,
    isReviewer: false,
    isClient: false,
  },
  errors: [],
};

function AddNewUserToOrganization(props: Props): ReactElement {
  const { createNewOrganizationUser } = useManageOrganization();
  const { className, onClose, organization, onSuccess } = props;
  const [state, setState] = useState<State>({ ...initialFormState, organizationId: organization.id });

  function addUserToOrganization(): void {
    try {
      validateOrganizationNewUserDetails(state);
      setState({ ...state, errors: [] });
      createNewOrganizationUser(
        organization,
        { ...state },
        () => undefined,
        (res: Organization) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
      );
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        setState({ ...state, errors: e.errors });
      }
    }
  }

  function onChange(field: keyof Omit<typeof initialFormState, 'errors'>): OnChange {
    return ((value?: string): void => {
      const newState = { ...state, [field]: value };
      try {
        validateOrganizationNewUserDetails(newState);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    }) as OnChange;
  }

  function onChangeRole(field: keyof OrganizationRoles): OnChange {
    return ((value?: string): void => {
      const newState = {
        ...state,
        organizationRoles: {
          isAdmin: state.organizationRoles?.isAdmin || false,
          isClient: state.organizationRoles?.isClient || false,
          isAgent: state.organizationRoles?.isAgent || false,
          isReviewer: state.organizationRoles?.isReviewer || false,
          [field]: value || false,
        },
      };
      try {
        validateOrganizationNewUserDetails(newState);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    }) as OnChange;
  }

  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={true}
      className={classnames('AddNewUserToOrganization', className)}
    >
      <Modal className="AddNewUserToOrganizationBody flex column align-center" onOutsideClick={onClose} withClose>
        <h1>Add a new Organization Member</h1>
        <form className="flex column align-center w-full">
          <GeneralInput
            type="text"
            formLabel="First Name"
            value={state.firstName}
            onChange={onChange('firstName')}
            error={getErrors(state, 'firstName')}
            className="max-w-sm mb-4"
          />
          <GeneralInput
            type="text"
            formLabel="Last Name"
            value={state.lastName}
            onChange={onChange('lastName')}
            error={getErrors(state, 'lastName')}
            className="max-w-sm mb-4"
          />
          <GeneralInput
            type="text"
            formLabel="Email"
            value={state.email}
            onChange={onChange('email')}
            error={getErrors(state, 'email')}
            className="max-w-sm"
          />
          <OrganizationRolesFormFields organizationRoles={state.organizationRoles} onChangeRole={onChangeRole} />
          <p className="error">{getErrors(state, 'organizationRoles')}</p>
          <div className="form-section mt-4">
            <EquiButton
              value={'Add to Organization'}
              onClick={addUserToOrganization}
              disabled={!state.lastName || !state.firstName || !state.email || state.errors.length > 0}
            />
          </div>
        </form>
      </Modal>
    </Overlay>
  );
}

export default AddNewUserToOrganization;
