import { ReactElement } from 'react';
import { Debts, MaritalStatusRadio } from 'shared';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields, useKYCFormState } from '@hooks';
import Debt from './Debt';
import './styles.scss';

function FinancialInformation(): ReactElement {
  const formFields = useKYCFormFields();
  const { isFormDisabledForCurrentUser } = useKYCFormState();
  function subtractNonFinancialDebt(): void {
    const currentDebt = formFields.numberOfNonFinancialDebts.value as Debts['numberOfNonFinancialDebts'];
    if (currentDebt === 1) {
      return;
    }
    const newDebt = (currentDebt - 1) as Debts['numberOfNonFinancialDebts'];
    formFields.numberOfNonFinancialDebts.onChange(
      { nativeEvent: {} } as React.ChangeEvent<HTMLInputElement>,
      newDebt as Debts['numberOfNonFinancialDebts'],
    );

    for (let i = currentDebt; i <= 6; i++) {
      const nonFinancialTerm = `nonFinancialTerm${i}` as 'nonFinancialTerm1';
      const nonFinancialLender = `nonFinancialLender${i}` as 'nonFinancialLender1';
      const nonFinancialDescription = `nonFinancialDescription${i}` as 'nonFinancialDescription1';
      const nonFinancialPaymentAmount = `nonFinancialPaymentAmount${i}` as 'nonFinancialPaymentAmount1';
      const nonFinancialOutstandingDebt = `nonFinancialOutstandingDebt${i}` as 'nonFinancialOutstandingDebt1';
      const nonFinancialPaymentFrequency = `nonFinancialPaymentFrequency${i}` as 'nonFinancialPaymentFrequency1';
      const nonFinancialCorrespondingAsset = `nonFinancialCorrespondingAsset${i}` as keyof Debts;
      [
        nonFinancialTerm,
        nonFinancialLender,
        nonFinancialDescription,
        nonFinancialPaymentAmount,
        nonFinancialOutstandingDebt,
        nonFinancialPaymentFrequency,
        nonFinancialCorrespondingAsset,
      ].map((field) =>
        formFields[field].onChange({ nativeEvent: {} } as React.ChangeEvent<HTMLInputElement>, undefined),
      );
    }
  }
  function addNonFinancialDebt(): void {
    const currentDebt = formFields.numberOfNonFinancialDebts.value as Debts['numberOfNonFinancialDebts'];
    if (currentDebt === 6) {
      return;
    }
    formFields.numberOfNonFinancialDebts.onChange(
      {} as React.ChangeEvent<HTMLInputElement>,
      (currentDebt + 1) as Debts['numberOfNonFinancialDebts'],
    );
  }
  function subtractFinancialDebt(): void {
    const currentDebt = formFields.numberOfFinancialDebts.value as Debts['numberOfFinancialDebts'];
    if (currentDebt === 1) {
      return;
    }
    formFields.numberOfFinancialDebts.onChange(
      {} as React.ChangeEvent<HTMLInputElement>,
      (currentDebt - 1) as Debts['numberOfFinancialDebts'],
    );
    for (let i = currentDebt; i <= 6; i++) {
      const financialTerm = `financialTerm${i}` as keyof Debts;
      const financialLender = `financialLender${i}` as keyof Debts;
      const financialDescription = `financialDescription${i}` as keyof Debts;
      const financialPaymentAmount = `financialPaymentAmount${i}` as keyof Debts;
      const financialOutstandingDebt = `financialOutstandingDebt${i}` as keyof Debts;
      const financialPaymentFrequency = `financialPaymentFrequency${i}` as keyof Debts;
      const financialCorrespondingAsset = `financialCorrespondingAsset${i}` as keyof Debts;
      [
        financialTerm,
        financialLender,
        financialDescription,
        financialPaymentAmount,
        financialOutstandingDebt,
        financialPaymentFrequency,
        financialCorrespondingAsset,
      ].map((field) =>
        formFields[field].onChange({ nativeEvent: {} } as React.ChangeEvent<HTMLInputElement>, undefined),
      );
    }
  }
  function addFinancialDebt(): void {
    const currentDebt = formFields.numberOfFinancialDebts.value as Debts['numberOfFinancialDebts'];
    if (currentDebt === 6) {
      return;
    }
    formFields.numberOfFinancialDebts.onChange(
      {} as React.ChangeEvent<HTMLInputElement>,
      (currentDebt + 1) as Debts['numberOfFinancialDebts'],
    );
  }
  return (
    <div className="FinancialInformation">
      <h2 className="title">Financial Information</h2>
      <div className="form-section">
        <h3 className="title spacer text-center">Total Annual Income</h3>
        <div className="flex form-row">
          <h3 className="title header spacer max-width flex align-center">
            <strong>Total Personal Income</strong>
          </h3>
          <EquiInput {...formFields.twoYearTaxableIncome} />
          <EquiInput {...formFields.lastYearTaxableIncome} />
          <EquiInput {...formFields.thisYearExpectedIncome} />
        </div>
        {formFields.maritalStatus.value !== MaritalStatusRadio.single && (
          <div className="flex form-row justify-center align-center">
            <h3 className="title header spacer max-width flex align-center">
              <strong>Total Spouse's Income (if applicable)</strong>
            </h3>
            <EquiInput {...formFields.spouseTwoYearTaxableIncome} />
            <EquiInput {...formFields.spouseLastYearTaxableIncome} />
            <EquiInput {...formFields.spouseThisYearExpectedIncome} />
          </div>
        )}
        <hr />
        <div className="flex form-row justify-center align-center">
          <h3 className="title header spacer max-width flex align-center">
            <strong>Total Household Income</strong>
          </h3>
          <EquiInput {...formFields.totalTwoYearTaxableIncome} />
          <EquiInput {...formFields.totalLastYearTaxableIncome} />
          <EquiInput {...formFields.totalThisYearExpectedIncome} />
        </div>
        <hr />
      </div>
      <div className="form-section">
        <h2 className="title header spacer">Gross Assets</h2>
        <p className="text-center">
          Note: If any assets/liabilities are shared with your spouse, please ONLY include the portion of the
          asset/liability attributable to you.
        </p>
        <div className="flex column align-center">
          <div className="flex column align-center align-self-start max-width form-section">
            <h3 className="title header flex spacer justify-center">Financial Assets</h3>
            <div className="max-width financial-assets">
              <div className="flex form-row max-width">
                <h3 className="title-label header text-center">
                  Registered Investments
                  <br />
                  (RRSP, RRIF, etc.)
                </h3>
                <h3 className="title-label header text-center">Non Registered Investments</h3>
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.cash} />
                <EquiInput {...formFields.nonRegisteredCash} />
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.fixedIncome} />
                <EquiInput {...formFields.nonRegisteredFixedIncome} />
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.publicEquities} />
                <EquiInput {...formFields.nonRegisteredPublicEquities} />
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.otherFinancialAssets} />
                <EquiInput {...formFields.nonRegisteredOther} />
              </div>
            </div>
          </div>
          <hr />
          <div className="flex form-row align-center form-sections max-width">
            <h3 className="title header spacer max-width text-center">
              <strong>A. Total Financial Assets</strong>
            </h3>
            <EquiInput {...formFields.totalFinancialAssets} />
          </div>
          <hr className="small-separator" />
          <div className="flex column spacer align-center align-center-self-start max-width">
            <h3 className="title flex spacer justify-center">Non-Financial Assets</h3>
            <div className="flex column align-center max-width">
              <div className="flex max-width spacer">
                <h3 className="title header flex max-width">Real Property</h3>
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.personalResidence} />
                <EquiInput {...formFields.otherRealProperty} />
              </div>
              <div className="flex max-width spacer">
                <h3 className="title header flex">Other Assets</h3>
              </div>
              <div className="flex form-row max-width">
                <EquiInput {...formFields.vehicles} />
                <div className="max-width"></div>
              </div>
              <div className="flex form-row justify-center max-width">
                <EquiInput {...formFields.otherProperty1} />
                <EquiInput {...formFields.otherPropertyValue1} />
              </div>
              <div className="flex form-row justify-center max-width">
                <EquiInput {...formFields.otherProperty2} />
                <EquiInput {...formFields.otherPropertyValue2} />
              </div>
              <div className="flex form-row justify-center max-width">
                <EquiInput {...formFields.otherProperty3} />
                <EquiInput {...formFields.otherPropertyValue3} />
              </div>
            </div>
          </div>
          <hr />
          <div className="flex form-row align-center form-sections max-width">
            <h3 className="title header spacer max-width text-center">
              <strong>B. Total Non-Financial Assets</strong>
            </h3>
            <EquiInput {...formFields.totalNonFinancialAssets} />
          </div>
          <div className="flex form-row align-center form-sections max-width">
            <h3 className="title header spacer max-width text-center">
              <strong>[1]. Total Assets (A + B)</strong>
            </h3>
            <EquiInput {...formFields.totalAssets} />
          </div>
        </div>
        <hr />
      </div>

      <div className="form-section">
        <h2 className="title header spacer">Debts / Liabilities</h2>
        <div className="form-section">
          {Array.from({ length: (formFields.numberOfFinancialDebts.value as number) || 1 }).map((_, i) => (
            <Debt key={i} debtNumber={(i + 1) as 1 | 2 | 3 | 4 | 5 | 6} debtType="financial" />
          ))}
          <div className="debt-button-container">
            <div className="left-button-container">
              {(formFields.numberOfFinancialDebts.value as number) > 1 && (
                <EquiButton
                  disabled={isFormDisabledForCurrentUser}
                  onClick={subtractFinancialDebt}
                  value="- Debt"
                  kind={ButtonType.secondary}
                />
              )}
            </div>
            <div className="right-button-container">
              {(formFields.numberOfFinancialDebts.value as number) < 6 && (
                <EquiButton
                  disabled={isFormDisabledForCurrentUser}
                  onClick={addFinancialDebt}
                  value="+ Debt"
                  kind={ButtonType.secondary}
                />
              )}
            </div>
          </div>
          <div className="spacer flex max-width"></div>
          <div className="flex form-row align-center form-sections max-width">
            <h3 className="title header spacer max-width text-center">
              <strong>C. Total Financial Debts</strong>
            </h3>
            <EquiInput {...formFields.totalFinancialDebts} />
          </div>
        </div>
        <hr className="small-separator" />
        <div className="form-section">
          {Array.from({ length: (formFields.numberOfNonFinancialDebts.value as number) || 1 }).map((_, i) => (
            <Debt key={i} debtNumber={(i + 1) as 1 | 2 | 3 | 4 | 5 | 6} debtType="nonFinancial" />
          ))}
          <div className="debt-button-container">
            <div className="left-button-container">
              {(formFields.numberOfNonFinancialDebts.value as number) > 1 && (
                <EquiButton
                  disabled={isFormDisabledForCurrentUser}
                  onClick={subtractNonFinancialDebt}
                  value="- Debt"
                  kind={ButtonType.secondary}
                />
              )}
            </div>
            <div className="right-button-container">
              {(formFields.numberOfNonFinancialDebts.value as number) < 6 && (
                <EquiButton
                  disabled={isFormDisabledForCurrentUser}
                  onClick={addNonFinancialDebt}
                  value="+ Debt"
                  kind={ButtonType.secondary}
                />
              )}
            </div>
          </div>
          <div className="spacer flex max-width"></div>
          <div className="flex form-row align-center form-sections max-width">
            <h3 className="title header spacer max-width text-center">
              <strong>D. Total Non-Financial Debts</strong>
            </h3>
            <EquiInput {...formFields.totalNonFinancialDebts} />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex form-row max-width">
        <EquiInput {...formFields.notes} />
      </div>
      <hr />
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>A. Total Financial Assets</strong>
        </h3>
        <EquiInput {...formFields.totalFinancialAssets} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>B. Total Non-Financial Assets</strong>
        </h3>
        <EquiInput {...formFields.totalNonFinancialAssets} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>[1]. Total Assets (A + B)</strong>
        </h3>
        <EquiInput {...formFields.totalAssets} />
      </div>
      <hr />
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>C. Total Financial Debts</strong>
        </h3>
        <EquiInput {...formFields.totalFinancialDebts} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>D. Total Non-Financial Debts</strong>
        </h3>
        <EquiInput {...formFields.totalNonFinancialDebts} />
      </div>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>[2]. Total Debts and Liabilities (C + D)</strong>
        </h3>
        <EquiInput {...formFields.totalDebtsLiabilities} />
      </div>
      <hr />
      <h2 className="title">
        <strong>Net - Assets</strong>
      </h2>
      <div className="flex form-row align-center form-sections max-width">
        <h3 className="title header spacer max-width text-center">
          <strong>[3]. Total Net Assets ([1] - [2])</strong>
        </h3>
        <EquiInput {...formFields.totalNetAssets} />
      </div>
    </div>
  );
}

export default FinancialInformation;
