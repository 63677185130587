import { ReactElement } from 'react';
import classnames from 'classnames';
import BackButton from '@components/common/BackButton';
import Loading from '@components/common/Loading';
import ScrollToBottomButton from '@components/common/ScrollToBottomButton';
import ScrollToTopButton from '@components/common/ScrollToTopButton';
import { Children } from '@custom-types';
import { useGeneral } from '@hooks';
import { ErrorPage, NotConnectedPage } from '@pages';
import { ValidPath } from '@routes/lib';
import './styles.scss';

interface Props {
  className?: string;
  children?: Children;
  defaultBackLocation?: ValidPath;
  renderHeader?: () => ReactElement;
  isLoading?: boolean;
  showChildrenOnLoading?: boolean;
  withScrollToTop?: boolean;
  withScrollToBottom?: boolean;
  doNotRecurse?: boolean;
  noBackButton?: boolean;
  preHeader?: () => ReactElement;
}

export default function PageLayout({
  children,
  className,
  defaultBackLocation,
  renderHeader,
  isLoading,
  withScrollToTop,
  withScrollToBottom,
  doNotRecurse,
  showChildrenOnLoading = true,
  noBackButton,
  preHeader,
}: Props): ReactElement {
  const { general } = useGeneral();
  if (general.notConnectedToInternet && !doNotRecurse) {
    return <NotConnectedPage />;
  }
  if (general.error && !doNotRecurse) {
    return <ErrorPage description={general.error} />;
  }
  return (
    <main className={classnames('PageLayout', className)}>
      {defaultBackLocation && !noBackButton && <BackButton defaultLocation={defaultBackLocation} />}
      {withScrollToTop && <ScrollToTopButton />}
      {withScrollToBottom && <ScrollToBottomButton />}
      {preHeader && preHeader()}
      {renderHeader && <div className="page-title">{renderHeader()}</div>}
      {isLoading && showChildrenOnLoading && <Loading size="3x" />}
      {isLoading && !showChildrenOnLoading && (
        <div className="flex justify-center align-center flex-1 absolute-center">
          <Loading />
        </div>
      )}
      {(showChildrenOnLoading || !isLoading) && children}
    </main>
  );
}
