import { ReactElement, useContext } from 'react';
import classNames from 'classnames';
import { BrandingContext } from '@contexts';
import { useScrollPosition } from '@hooks';
import Icon from '../Icon';

import './styles.scss';

export default function ScrollToTopButton(): ReactElement | null {
  const { scrollPosition, scrollToTop } = useScrollPosition();
  const { showBrandingInNav } = useContext(BrandingContext);
  if (scrollPosition < 300) {
    return null;
  }
  return (
    <div className={classNames('ScrollToTopButton', { 'with-branding': showBrandingInNav })} onClick={scrollToTop}>
      <div className="actual-button">
        <Icon type="upArrow" />
        <div>Scroll To Top</div>
      </div>
    </div>
  );
}
