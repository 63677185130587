import axios from 'axios';
import { FullUser, RequestPayloads, ResponsePayloads, Role, isAxiosError } from 'shared';

export function createUser(user: RequestPayloads['createUser']): Promise<ResponsePayloads['createUser']> {
  return axios.post('/users', user).then((res) => res.data);
}

export function getUsers(role?: Role): Promise<ResponsePayloads['getUsers']> {
  const query = role ? `?role=${role}` : '';
  return axios.get(`/users${query}`).then((res) => res.data);
}

export function getSingleUserByEmail(email: string): Promise<ResponsePayloads['getSingleUser']> {
  return axios
    .get(`/users/${email}`)
    .then((res) => res.data)
    .catch((e) => {
      if (isAxiosError(e) && e.response?.status === 400) {
        e.response.status = 404;
      }
      throw e;
    });
}

export function updateUser(user_id: string, user: Partial<FullUser>): Promise<ResponsePayloads['updateUser']> {
  return axios.patch(`/users/${user_id}`, user as RequestPayloads['updateUser']).then((res) => res.data);
}

export function blockUser(user_id: string, blocked: boolean): Promise<ResponsePayloads['blockUser']> {
  return axios.patch(`/users/${user_id}/block`, { blocked } as RequestPayloads['blockUser']).then((res) => res.data);
}

export function updateUserRoles(user_id: string, roles: Role[]): Promise<ResponsePayloads['updateUserRoles']> {
  return axios
    .patch(`/users/${user_id}/roles`, { roles } as RequestPayloads['updateUserRoles'])
    .then((res) => res.data);
}

export function deleteUser(user_id: string): Promise<ResponsePayloads['deleteUser']> {
  return axios.delete(`/users/${user_id}`).then((res) => res.data);
}

export function inviteUser(user_id: string): Promise<void> {
  return axios.post(`/users/${user_id}`);
}

export function updateOrgUserRoles(
  user_id: string,
  payload: RequestPayloads['updateOrgUserRoles'],
): Promise<ResponsePayloads['updateOrgUserRoles']> {
  return axios.patch(`/users/${user_id}/org-roles`, payload).then((res) => res.data);
}
