import {
  SubscriptionPackageFormDataAllRequired,
  SubscriptionPackageFormFormatting,
  SubscriptionPackageFormValidation,
  SubscriptionPackageFormError,
  SubscriptionPackageFormData,
  SubscriptionPackageFormValidationError,
  SubscriptionPackage,
  RequestPayloads,
  updateComputedSubscriptionPackageValues,
  isAxiosError,
  RequestParams,
  validateSubscriptionPackageForm,
} from 'shared';
import { SubscriptionPackageAPI } from '@api';
import { focusErrorField } from '@utils/domHelpers';
import { debounce } from '@utils/lib';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { Actions as GeneralActions, FlashMessage, SetShowLoadingOverlay, UpdateProcessingProgress } from '../general';
import { AsyncAction, Dispatch } from '../types';
import {
  ActionTypes,
  SubmitSubscriptionPackage,
  SetSubscriptionPackageForm,
  ResetSubscriptionPackageForm,
  StartGetSubscriptionPackageForm,
  SetSubscriptionPackageFormData,
  ResetHasFetchedSubscriptionPackage,
} from './types';

export type Actions =
  | SubmitSubscriptionPackage
  | SetSubscriptionPackageForm
  | ResetSubscriptionPackageForm
  | StartGetSubscriptionPackageForm
  | SetSubscriptionPackageFormData
  | ResetHasFetchedSubscriptionPackage;

async function updateSubscriptionPackage(
  subscriptionPackageId: number,
  formData: SubscriptionPackageFormData,
): Promise<SubscriptionPackage> {
  const subscriptionPackage = await SubscriptionPackageAPI.updateSubscriptionPackage(
    { subscriptionPackageId },
    { formData },
  );
  return subscriptionPackage;
}

const debouncedUpdateSubscriptionPackage: (
  subscriptionPackageId: number,
  formData: SubscriptionPackageFormData,
) => Promise<SubscriptionPackage> = debounce(updateSubscriptionPackage, 1000) as (
  subscriptionPackageId: number,
  formData: SubscriptionPackageFormData,
) => Promise<SubscriptionPackage>;

export const Actions = {
  updateSubscriptionPackageFormField(
    field: keyof SubscriptionPackageFormDataAllRequired,
    value: SubscriptionPackageFormDataAllRequired[keyof SubscriptionPackageFormDataAllRequired],
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): AsyncAction<SetSubscriptionPackageForm> {
    return async (dispatch, getState): Promise<void> => {
      const { subscriptionPackageForm } = getState();
      const { form } = subscriptionPackageForm;
      if (!form) {
        return;
      }

      if (!['inProgressByAgent'].includes(form.state)) {
        return;
      }

      // some fields cannot be edited from here
      if ((['salespersonEmail'] as (keyof SubscriptionPackageFormDataAllRequired)[]).includes(field)) {
        return;
      }
      const formattedValue = SubscriptionPackageFormFormatting(field, e)(value);
      const validator = SubscriptionPackageFormValidation[field] as (
        v: typeof value,
        f: SubscriptionPackageFormData,
        isSubscriber: boolean,
      ) => SubscriptionPackageFormData['errors'][number] | void;
      const error = validator(formattedValue, form.formData, true);
      const newErrors = form.formData?.errors
        .filter((error) => !error.fieldCausingError.includes(field))
        .concat(error ? [error] : []);
      const newForm: SubscriptionPackageFormData = {
        ...form.formData,
        [field]: formattedValue,
        errors: newErrors,
      };
      const newFormWithComputed = updateComputedSubscriptionPackageValues(newForm);
      dispatch(Actions.updateSubscriptionPackageFormData(newFormWithComputed));
    };
  },
  updateSubscriptionPackageFormData(
    newSubscriptionPackageFormData: SubscriptionPackageFormData,
  ): AsyncAction<SetSubscriptionPackageFormData | HandleAxiosError> {
    return async (dispatch, getState): Promise<void> => {
      const {
        subscriptionPackageForm: { form },
      } = getState();
      if (!form) {
        return;
      }
      try {
        dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM_DATA, payload: newSubscriptionPackageFormData });
        await debouncedUpdateSubscriptionPackage(form.id, newSubscriptionPackageFormData);
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
      }
    };
  },
  getSubscriptionPackage(
    slug: string,
    shouldReload = true,
  ): AsyncAction<
    SetSubscriptionPackageForm | ResetSubscriptionPackageForm | StartGetSubscriptionPackageForm | HandleAxiosError
  > {
    return async (dispatch): Promise<void> => {
      try {
        dispatch({ type: ActionTypes.START_GET_SUBSCRIPTION_PACKAGE_FORM, payload: shouldReload });
        const data = await SubscriptionPackageAPI.getSubscriptionPackage(slug);
        dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM, payload: data });
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
        dispatch({ type: ActionTypes.RESET_SUBSCRIPTION_PACKAGE_FORM, payload: undefined });
      }
    };
  },
  createSubscriptionPackage(
    kycId: number,
    newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
    onSuccess: (subscriptionPackage: SubscriptionPackage) => void = (): void => undefined,
    onError: (e: SubscriptionPackageFormData['errors'][number]) => void = (): void => undefined,
  ): AsyncAction<SetSubscriptionPackageForm | HandleAxiosError | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      try {
        dispatch(GeneralActions.showLoadingOverlay());
        const subscriptionPackage = await SubscriptionPackageAPI.createSubscriptionPackage(
          kycId,
          newSubscriptionPackage,
        );
        dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM, payload: subscriptionPackage });
        onSuccess(subscriptionPackage);
      } catch (e) {
        if (e instanceof SubscriptionPackageFormError) {
          onError(e as SubscriptionPackageFormData['errors'][number]);
        }
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
        }
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
  handleSubscriptionPackageFormValidationError(
    e: SubscriptionPackageFormValidationError,
  ): AsyncAction<SetSubscriptionPackageFormData | FlashMessage> {
    return async (dispatch, getState): Promise<void> => {
      const {
        subscriptionPackageForm: { form },
      } = getState();
      if (!form) {
        return;
      }
      const { formData } = form;
      dispatch(
        Actions.updateSubscriptionPackageFormData({
          ...formData,
          errors: (e?.errors || []) as SubscriptionPackageFormData['errors'],
        }),
      );
      requestAnimationFrame(() => {
        focusErrorField();
      });
      dispatch(GeneralActions.flashErrorMessage(e.message));
    };
  },
  resetHasFetchedSubscriptionPackage(): AsyncAction<ResetHasFetchedSubscriptionPackage> {
    return async (dispatch, getState): Promise<void> => {
      const {
        subscriptionPackageForm: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_SUBSCRIPTION_PACKAGE, payload: undefined });
    };
  },
  deleteSubscriptionPackage(
    onSuccess: () => void,
  ): AsyncAction<SetSubscriptionPackageForm | ResetSubscriptionPackageForm | HandleAxiosError | SetShowLoadingOverlay> {
    return doSomethingToSubscriptionPackage(
      SubscriptionPackageAPI.deleteForm,
      'Subscription Package successfully deleted.',
      { onSuccess, resetOnError: true },
    );
  },
  sendSubscriptionPackageToDocuSign(
    payload: RequestPayloads['sendSubscriptionPackageToDocuSign'],
  ): AsyncAction<
    | SetSubscriptionPackageForm
    | ResetSubscriptionPackageForm
    | HandleAxiosError
    | SetShowLoadingOverlay
    | UpdateProcessingProgress
  > {
    return async (dispatch, getState): Promise<void> => {
      const { subscriptionPackageForm } = getState();
      if (!subscriptionPackageForm.form) {
        return;
      }
      const errors = validateSubscriptionPackageForm(subscriptionPackageForm.form.formData, true);
      if (errors.length) {
        dispatch(
          Actions.handleSubscriptionPackageFormValidationError(new SubscriptionPackageFormValidationError(errors)),
        );
        return;
      }
      const subscriberName = subscriptionPackageForm.form.kyc?.subscriber?.name;
      const timeout = setTimeout(() => {
        dispatch(
          GeneralActions.flashWarningMessage(
            'This may take a up to 60 seconds. Please do not exit or refresh the page until this operation finishes.',
          ),
        );
      }, 750);
      try {
        dispatch(GeneralActions.updateProgress(0));
        dispatch(GeneralActions.showLoadingOverlay());
        await SubscriptionPackageAPI.sendSubscriptionPackageToDocuSign(
          { subscriptionPackageId: subscriptionPackageForm.form.id },
          payload,
        );
        const subscriptionPackage = await checkStatusOfSubscriptionPackage(dispatch, {
          subscriptionPackageId: subscriptionPackageForm.form.id,
        });
        clearTimeout(timeout);
        dispatch(
          GeneralActions.flashSuccessMessage(
            `The Subscription Package has been sent to DocuSign! It is now en route to ${
              subscriberName ? subscriberName : 'the client'
            }'s email inbox to review and sign`,
          ),
        );
        dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM, payload: subscriptionPackage });
      } catch (e) {
        clearTimeout(timeout);
        dispatch(ApiActions.handleAxiosError(e));
        if (isAxiosError(e) && e.response && e.response.status === 422 && e.response.data.errors) {
          dispatch(
            Actions.handleSubscriptionPackageFormValidationError(
              new SubscriptionPackageFormValidationError(e.response.data.errors),
            ),
          );
        }
      } finally {
        dispatch(GeneralActions.hideLoadingOverlay());
        dispatch(GeneralActions.updateProgress(null));
      }
    };
  },
  voidDocuSignSubscriptionPackage(): AsyncAction<
    | SetSubscriptionPackageForm
    | ResetSubscriptionPackageForm
    | HandleAxiosError
    | SetShowLoadingOverlay
    | UpdateProcessingProgress
  > {
    return doSomethingToSubscriptionPackage(
      SubscriptionPackageAPI.voidDocuSignSubscriptionPackage,
      `This Subscription Package has been voided.`,
    );
  },
  voidAndResendDocuSignSubscriptionPackage(): AsyncAction<
    | SetSubscriptionPackageForm
    | ResetSubscriptionPackageForm
    | HandleAxiosError
    | SetShowLoadingOverlay
    | UpdateProcessingProgress
  > {
    return async (dispatch, getState): Promise<void> => {
      const { subscriptionPackageForm } = getState();
      if (!subscriptionPackageForm.form) {
        return;
      }
      dispatch(GeneralActions.showLoadingOverlay());
      const timeout = setTimeout(() => {
        dispatch(
          GeneralActions.flashWarningMessage(
            'This may take a up to 60 seconds. Please do not exit or refresh the page until this operation finishes.',
          ),
        );
      }, 700);
      try {
        dispatch(GeneralActions.updateProgress(0));
        await SubscriptionPackageAPI.voidAndResendDocuSignSubscriptionPackage({
          subscriptionPackageId: subscriptionPackageForm.form.id,
        });

        const subscriptionPackage = await checkStatusOfSubscriptionPackage(dispatch, {
          subscriptionPackageId: subscriptionPackageForm.form.id,
        });

        dispatch(
          GeneralActions.flashSuccessMessage(
            `The previous signing session has been voided and this Subscription Package has been resent to the client for signing.`,
          ),
        );
        if (subscriptionPackage) {
          dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM, payload: subscriptionPackage });
        }
      } catch (e) {
        dispatch(ApiActions.handleAxiosError(e));
      } finally {
        clearTimeout(timeout);
        dispatch(GeneralActions.updateProgress(null));
        dispatch(GeneralActions.hideLoadingOverlay());
      }
    };
  },
};

function doSomethingToSubscriptionPackage(
  apiMethod: typeof SubscriptionPackageAPI['voidDocuSignSubscriptionPackage' | 'deleteForm'],
  successMessage: string,
  params: { onSuccess?: () => void; resetOnError?: boolean } = {},
): AsyncAction<
  | SetSubscriptionPackageForm
  | ResetSubscriptionPackageForm
  | HandleAxiosError
  | SetShowLoadingOverlay
  | UpdateProcessingProgress
> {
  const { onSuccess = (): void => undefined, resetOnError = false } = params;
  return async (dispatch, getState): Promise<void> => {
    const { subscriptionPackageForm } = getState();
    if (!subscriptionPackageForm.form) {
      return;
    }
    dispatch(GeneralActions.showLoadingOverlay());
    const timeout = setTimeout(() => {
      dispatch(
        GeneralActions.flashWarningMessage(
          'This may take a up to 60 seconds. Please do not exit or refresh the page until this operation finishes.',
        ),
      );
    }, 700);
    try {
      const subscriptionPackage = await apiMethod({
        subscriptionPackageId: subscriptionPackageForm.form.id,
      });
      dispatch(GeneralActions.flashSuccessMessage(successMessage));
      onSuccess();
      if (subscriptionPackage) {
        dispatch({ type: ActionTypes.SET_SUBSCRIPTION_PACKAGE_FORM, payload: subscriptionPackage });
      }
    } catch (e) {
      dispatch(ApiActions.handleAxiosError(e));
      if (resetOnError) {
        dispatch({ type: ActionTypes.RESET_SUBSCRIPTION_PACKAGE_FORM, payload: undefined });
      }
    } finally {
      clearTimeout(timeout);
      dispatch(GeneralActions.hideLoadingOverlay());
      GeneralActions.updateProgress(null);
    }
  };
}

async function checkStatusOfSubscriptionPackage(
  dispatch: Dispatch<UpdateProcessingProgress>,
  params: RequestParams['getLatestStatus'],
): Promise<SubscriptionPackage> {
  const TIME_TO_WAIT_UNTIL_POLLING_AGAIN_IN_MS = 1250;
  const { data } = await SubscriptionPackageAPI.getProcessingSubscriptionPackageStatus(params);
  if (data) {
    dispatch(GeneralActions.updateProgress(data.progress));
  }
  if (data && data.rawError) {
    dispatch(GeneralActions.flashErrorMessage(JSON.parse(data.rawError)?.message));
    dispatch(GeneralActions.updateProgress(null));
    return data;
  }
  // not finished processing yet, wait a bit and then poll again
  if (!data || data.state === 'inProgressByAgent' || data.state === 'voided') {
    await delay(TIME_TO_WAIT_UNTIL_POLLING_AGAIN_IN_MS);
    return checkStatusOfSubscriptionPackage(dispatch, params);
  }
  dispatch(GeneralActions.updateProgress(1));
  await delay(TIME_TO_WAIT_UNTIL_POLLING_AGAIN_IN_MS);
  dispatch(GeneralActions.updateProgress(null));
  return data;
}

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export * from './types';
