import { useRef, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Product, isAxiosError } from 'shared';
import { Actions, Dispatch } from '@actions';
import * as Products from '@api/products';
import { PATHS } from '@routes/lib';
import { State } from '@store';

export interface UseProductsListHookResult {
  products: Product[];
  isLoading: boolean;
  error: boolean;
}

export function useProductsList(): UseProductsListHookResult {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const stillValid = useRef(true);

  useEffect(() => {
    (async (): Promise<void> => {
      stillValid.current = true;
      setIsLoading(true);
      setError(false);
      try {
        const fetchedProducts = await Products.getProducts();
        if (stillValid.current) {
          setProducts(fetchedProducts);
          setIsLoading(false);
        }
      } catch (e) {
        if (isAxiosError(e) && e.response?.status !== 404) {
          dispatch(Actions.handleAxiosError(e));
        } else if (isAxiosError(e) && e.response?.status === 404) {
          history.replace(PATHS.NOT_FOUND);
        } else {
          if (stillValid.current) {
            setError(true);
          }
        }
        if (stillValid.current) {
          setIsLoading(false);
        }
      }
    })();
    return (): void => {
      stillValid.current = false;
    };
  }, []);

  return { products, isLoading, error };
}
