import { ReactElement, useState } from 'react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import Select from 'react-select';
import { Checkbox, FullUser, isChecked } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useCurrentUser } from '@hooks';
import { GroupedOption, groupUsersByOrgForSelect, Option } from '@utils/select';

import './styles.scss';

interface Props {
  currentSubscriberAgents: FullUser[];
  className?: string;
  onClose: () => void;
  onSubmit: (selectedUser: FullUser, changeSubscriberOrganization: boolean) => void;
  user: FullUser;
  agents: FullUser[];
}

function AssignAgentToSubscriberModal(props: Props): ReactElement {
  const { className, onClose, user, onSubmit, currentSubscriberAgents, agents } = props;
  const { isCurrentUserAdmin, isCurrentUserEQ } = useCurrentUser();
  const agentsNotAssigned = sortBy(
    agents.filter((agent) => {
      const isAgentAlreadyAssigned = currentSubscriberAgents.map((a) => a.user_id).includes(agent.user_id);
      const userOrgId = user.organization?.id;
      const isUserInOrg = !!userOrgId;
      return (
        !isAgentAlreadyAssigned &&
        (!isUserInOrg || userOrgId !== agent.organization?.id || agent.organization?.userProperties.isAgent)
      );
    }),
    (user) => user.family_name,
  );
  const [selectedUser, setSelectedUser] = useState<Option | null>(null);
  const [changeSubscriberOrganization, setChangeSubscriberOrganization] = useState(false);

  const fullSelectedUser = agentsNotAssigned.find((u) => u.user_id === selectedUser?.value);

  function assign(): void {
    if (fullSelectedUser) {
      onSubmit(fullSelectedUser, changeSubscriberOrganization);
    }
  }
  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={true}
      className={classnames('AssignAgentToSubscriberModal', className)}
    >
      <Modal className="AssignAgentToSubscriberModalBody flex column align-center" onOutsideClick={onClose} withClose>
        <h1 className="text-center">Assign Agent to {user.name}</h1>
        <form className="w-full flex column align-center">
          <Select<Option, false, GroupedOption>
            placeholder="Pick an Agent"
            value={selectedUser}
            onChange={(selected): void => {
              setSelectedUser(selected);
              const fullSelectedUser = agentsNotAssigned.find((u) => u.user_id === selected?.value);
              if (!fullSelectedUser?.organization?.id || !user.organization?.id) {
                setChangeSubscriberOrganization(false);
              }
            }}
            classNamePrefix="select"
            className="w-full mb-4"
            options={groupUsersByOrgForSelect(agentsNotAssigned, 'No Organization', user?.organization)}
          />
          {(isCurrentUserAdmin || isCurrentUserEQ) && !!user.organization?.id && (
            <>
              <GeneralInput
                type="checkbox"
                checked={changeSubscriberOrganization}
                formLabel="Change Subscriber Organization to Agent Organization"
                onChange={
                  ((val: Checkbox): void => {
                    setChangeSubscriberOrganization(isChecked(val));
                  }) as OnChange
                }
                disabled={!user.organization?.id || !fullSelectedUser?.organization?.id}
              />
              <p>
                If checked, this subscriber will be set as a subscriber within the new organization. All current
                organization roles (admin, reviewer, etc) will be removed.
              </p>
            </>
          )}
          <EquiButton value="Assign" onClick={assign} disabled={!selectedUser} />
        </form>
      </Modal>
    </Overlay>
  );
}

export default AssignAgentToSubscriberModal;
