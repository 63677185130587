import { SubscriptionPackageComputedFields } from 'shared';
import { ProtoFields } from '../types';

export const subscriptionPackageComputedInformationFields: ProtoFields<keyof SubscriptionPackageComputedFields> = {
  subscriptionDateChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  feb1st2022ChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  feb1st2023ChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  initialLoanAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  totalInitialSubscriptionPrice: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  totalCapitalContributions: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  riskOfLossAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpSubscriptionDateChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpFeb1stYear1ChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpFeb1stYear2ChequeAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpRiskOfLossAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpTotalSubscriptionPrice: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
  masterlpLoanAmount: {
    formLabel: '',
    readOnly: true,
    fieldPrefix: '$',
    underlyingClassName: 'text-right',
  },
};
