import { PDFPage } from 'pdf-lib';
import { OfficeUseFormData } from 'shared';
import { drawYesNoRadio } from './subLib';

const kycVerticalOffset = -2;

const kycPositions = {
  dealerKnownClientYearsTime: { x: 505, y: 710 },
  dealerMetClientInPerson: 694 + kycVerticalOffset,
  clientsInsiderStatusExplainedToHaveNoBearing: 672 + kycVerticalOffset,
  accreditedInvestorStatusConfirmed: 651 + kycVerticalOffset,
  pepStatusExplained: 634 + kycVerticalOffset,
  conflictsOfInterestCanvasedWithClient: 598 + kycVerticalOffset,
  clientsRoleExplained: 563 + kycVerticalOffset,
  kycNotes: { x: 30, y: 353 },
};

const kycXPosition = {
  yes: 500,
  no: 530,
};

export function drawKYCOfficeUseNotes(kycPage: PDFPage, formData: OfficeUseFormData): void {
  kycPage.drawText(`${formData.dealerKnownClientYearsTime}`, {
    x: kycPositions.dealerKnownClientYearsTime.x,
    y: kycPositions.dealerKnownClientYearsTime.y,
    size: 9,
  });
  drawYesNoRadio(kycPage, formData.dealerMetClientInPerson, kycPositions.dealerMetClientInPerson, kycXPosition);
  drawYesNoRadio(
    kycPage,
    formData.clientsInsiderStatusExplainedToHaveNoBearing,
    kycPositions.clientsInsiderStatusExplainedToHaveNoBearing,
    kycXPosition,
  );
  drawYesNoRadio(
    kycPage,
    formData.accreditedInvestorStatusConfirmed,
    kycPositions.accreditedInvestorStatusConfirmed,
    kycXPosition,
  );
  drawYesNoRadio(kycPage, formData.pepStatusExplained, kycPositions.pepStatusExplained, kycXPosition);
  drawYesNoRadio(
    kycPage,
    formData.conflictsOfInterestCanvasedWithClient,
    kycPositions.conflictsOfInterestCanvasedWithClient,
    kycXPosition,
  );
  drawYesNoRadio(kycPage, formData.clientsRoleExplained, kycPositions.clientsRoleExplained, kycXPosition);
  kycPage.drawText(`${formData.kycNotes}`, {
    x: kycPositions.kycNotes.x,
    y: kycPositions.kycNotes.y,
    size: 9,
    maxWidth: 550,
    lineHeight: 17,
  });
}
