import { sortBy } from 'lodash';
import { FullUser, getValues, Organization } from 'shared';

export interface Option<T extends string | number = string> {
  readonly value: T;
  readonly label: string;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: Option[];
}

const NOT_ASSIGNED = 'Not Assigned';
export function groupUsersByOrgForSelect(
  users: readonly FullUser[],
  noOrgText = NOT_ASSIGNED,
  firstOrg?: Organization,
): GroupedOption[] {
  const groupLibrary: Record<string, GroupedOption> = {};
  users.forEach((user) => {
    const orgName = user.organization?.name || noOrgText;
    const newOption = { label: `${user.name} (${user.email})`, value: user.user_id };
    const newGroup: GroupedOption = {
      label: orgName,
      options: [],
    };
    const group = groupLibrary[orgName] || newGroup;
    group.options.push(newOption);
    groupLibrary[orgName] = group;
  });
  function sortBySelector(group: GroupedOption): string {
    if (!firstOrg) {
      // put no org first
      return group.label === noOrgText ? '.' : group.label;
    }
    return group.label === firstOrg.name ? '.' : group.label === noOrgText ? '..' : group.label;
  }
  return sortBy(getValues(groupLibrary), sortBySelector);
}
