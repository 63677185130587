import { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { Organization } from 'shared';
import Icon from '@components/common/Icon';

interface Props {
  organization: Organization;
}

function OrgBrandingFloater({ organization }: Props): ReactElement | null {
  const [showBranding, setShowBranding] = useState(true);
  return (
    <div
      className={classNames(
        'fixed min-w-xxs mr-16 max-w- -right-3 transform px-6 py-4 bottom-6 xl:p4 flex flex-col items-center bg-branded-bg rounded-md text-whitesmoke select-none bg-opacity-85 transition-padding-transform',
        {
          'translate-x-14 delay-padding-transform-in': showBranding,
          'translate-x-full pl-20 delay-padding-transform-out': !showBranding,
        },
      )}
    >
      <div
        className="absolute left-3 top-2 cursor-pointer opacity-40 hover:opacity-100"
        onClick={(): void => setShowBranding((s) => !s)}
      >
        <Icon className={classNames('h-2 w-8 transform', { 'rotate-180': showBranding })} type="backArrow" />
      </div>
      <p className="m-0 mb-1 text-sm font-medium">Distributed by</p>
      <img className="max-h-14 max-w-xxs" src={organization.darkLogoURL || organization.logoURL} />
    </div>
  );
}

export default OrgBrandingFloater;
