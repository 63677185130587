import { ReactElement, useState } from 'react';
import { sortBy } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { formatDateTime, Organization, regexEscape } from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Table from '@components/common/Table';
import OrganizationModal from '@components/Modals/OrganizationModal';
import { useCurrentUser, useModal, useSort, useOrganizations } from '@hooks';
import { Page } from '@layouts';
import { PATHS } from '@routes/lib';

import './styles.scss';

type sortByOptions = 'name' | 'createdAt';

export default function ViewOrganizationsPage(): ReactElement {
  const { hasAllPermissions } = useCurrentUser();
  const { organizations, isLoading } = useOrganizations({ refresh: true });
  const { openModal, closeModal, isOpen } = useModal();
  const history = useHistory();
  const { field, isDescending, setSortField } = useSort<sortByOptions>('name', false);
  const [search, setSearch] = useState<string>('');
  const filteredOrganizations = sortBy(
    organizations.filter((org) => new RegExp(regexEscape(search?.trim()), 'i').test(org.name)),
    (org) => {
      switch (field) {
        case 'createdAt':
          return org.createdAt;
        case 'name':
        default:
          return org.name.toLowerCase() || '';
      }
    },
  );

  if (isDescending) {
    filteredOrganizations.reverse();
  }
  return (
    <Page
      className="ViewOrganizationsPage"
      renderHeader={(): ReactElement => (
        <>
          <h1 className="text-center">Organizations</h1>
          {hasAllPermissions(['create:organization']) && <EquiButton value="Create New" onClick={openModal} />}
        </>
      )}
      defaultBackLocation={PATHS.HOME}
      isLoading={isLoading}
      showChildrenOnLoading={organizations.length > 0}
    >
      <Table
        gridClassName="table-grid-template-columns"
        headers={[
          { text: 'Name', onClick: (): void => setSortField('name'), isSelected: field === 'name', isDescending },
          {
            text: 'Created At',
            onClick: (): void => setSortField('createdAt'),
            isSelected: field === 'createdAt',
            isDescending,
          },
        ]}
        renderFilters={(): ReactElement => (
          <>
            <GeneralInput
              formLabel="Search"
              placeholder="by name"
              onChange={((a: string): void => setSearch(a)) as OnChange}
              value={search}
            />
          </>
        )}
        data={filteredOrganizations.map((org) => [
          <Link to={`${PATHS.ORGANIZATIONS}/${org.slug}`}>{org.name}</Link>,
          formatDateTime(new Date(org.createdAt)),
        ])}
        noResultsText={search ? `No organizations with name containing "${search}"` : 'There are no Organizations'}
      />
      {hasAllPermissions(['create:organization']) && isOpen && (
        <OrganizationModal
          onSuccess={(res: Organization): void => {
            history.push(`${PATHS.ORGANIZATIONS}/${res.slug}`);
            closeModal();
          }}
          closeModal={closeModal}
          isOpen={isOpen}
        />
      )}
    </Page>
  );
}
