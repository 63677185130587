import { ReactElement } from 'react';
import Dropdown from '@components/common/Dropdown';
import { useAuth, useCurrentUser } from '@hooks';
import Menu from './Menu';
import Trigger from './Trigger';
import './styles.scss';

export default function UserDropdown(): ReactElement | null {
  const { clearUserInfoLogout } = useAuth();
  const { currentUser } = useCurrentUser();
  if (!currentUser) {
    return null;
  }

  return (
    <Dropdown
      className="UserDropdown"
      triggerComponent={<Trigger user={currentUser} />}
      menuComponent={<Menu logout={clearUserInfoLogout} />}
      menuContainerClassName="right-0"
    />
  );
}
