import { ReactElement } from 'react';
import EquiInput from '@components/common/KYCEquiInput';
import { useCurrentUser, useKYCFormFields } from '@hooks';

import './styles.scss';

function LeveragedTaxShelterInformation(): ReactElement {
  const formFields = useKYCFormFields();
  const { isCurrentUserEQ, isCurrentUserAdmin, isCurrentUserAgent } = useCurrentUser();
  const canShowEQPrograms = isCurrentUserEQ || isCurrentUserAdmin || isCurrentUserAgent;
  return (
    <div className="form-section LeveragedTaxShelterInformation flex column align-center max-width">
      <div className="flex align-center column form-sections max-width">
        <h2 className="title">Leveraged Tax Shelter Program(s)</h2>
        <div className="max-width form-row">
          <EquiInput {...formFields.otherProgram} />
        </div>
        {canShowEQPrograms && (
          <div className="flex column align-center max-width">
            <h3 className="max-width text-center">
              <strong>
                Past EQ Program Names
                <br />
                (filled out by Agent/EquiGenesis)
              </strong>
            </h3>
            <div className="flex form-row space-around width-80">
              <EquiInput {...formFields.equigenesisProgramEQ2003} />
              <EquiInput {...formFields.equigenesisProgramEQ2003v2} />
              <EquiInput {...formFields.equigenesisProgramEQ2004} />
              <EquiInput {...formFields.equigenesisProgramEQ2004v2} />
            </div>
            <div className="flex form-row space-around width-80">
              <EquiInput {...formFields.equigenesisProgramEQ2005} />
              <EquiInput {...formFields.equigenesisProgramEQ2006} />
              <EquiInput {...formFields.equigenesisProgramEQ2009} />
              <EquiInput {...formFields.equigenesisProgramEQ2010} />
            </div>
            <div className="flex form-row space-around width-80">
              <EquiInput {...formFields.equigenesisProgramEQ2011} />
              <EquiInput {...formFields.equigenesisProgramEQ2012} />
              <EquiInput {...formFields.equigenesisProgramEQ2013} />
              <EquiInput {...formFields.equigenesisProgramEQ2020} />
            </div>
            <div className="flex form-row space-around width-80">
              <EquiInput {...formFields.equigenesisProgramEQ2021} />
              <EquiInput {...formFields.equigenesisProgramEQ2022} />
              <EquiInput {...formFields.equigenesisProgramEQ2022MasterLP} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeveragedTaxShelterInformation;
