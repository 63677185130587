import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { OfficeUseFormDataAllRequired } from 'shared';
import { Actions, Dispatch } from '../../actions';
import { State } from '../../store';
import { usePageChange } from '../usePageChange';

export interface OfficeUseFormHook {
  form: State['officeUseForm']['form'];
  isLoading: State['officeUseForm']['isLoading'];
  hasFetched: State['officeUseForm']['hasFetched'];
  updateField(
    field: keyof OfficeUseFormDataAllRequired,
    value: OfficeUseFormDataAllRequired[keyof OfficeUseFormDataAllRequired] | undefined,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void;
  createOfficeUse(email: string, onSuccess: () => void): void;
  getOfficeUse(email: string): void;
}

export function useOfficeUseFormState(email?: string): OfficeUseFormHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const officeUseForm = useSelector<State>((state) => state.officeUseForm) as State['officeUseForm'];
  const dispatch = reduxDispatch as Dispatch;
  function updateField(
    field: keyof OfficeUseFormDataAllRequired,
    value: OfficeUseFormDataAllRequired[keyof OfficeUseFormDataAllRequired],
    e: React.MouseEvent | React.ChangeEvent,
  ): void {
    dispatch(Actions.updateOfficeUseFormField(field, value, e as React.ChangeEvent<HTMLInputElement>));
  }

  function createOfficeUse(email: string, onSuccess: () => void): void {
    dispatch(Actions.createOfficeUseForExistingSubscriber(email, onSuccess));
  }

  function getOfficeUse(email: string): void {
    dispatch(Actions.getOfficeUseByUserEmail(email));
  }

  useEffect(() => {
    if (email) {
      getOfficeUse(email);
    }
    return (): void => {
      resetHasFetchedOfficeUse();
    };
  }, [email]);

  function resetHasFetchedOfficeUse(): void {
    dispatch(Actions.resetHasFetchedOfficeUse());
  }

  usePageChange(() => {
    resetHasFetchedOfficeUse();
  });

  return {
    ...officeUseForm,
    createOfficeUse,
    updateField,
    getOfficeUse,
  };
}
