import axios from 'axios';
import { getEnvValue } from 'shared';
import { State } from '@reducers';

export const API_VERSION = '1';

export const BASE_URI = `${getEnvValue('SERVER_URL')}/api/v${API_VERSION}`;
export const AUTH0_API_BASE_URI = `https://${getEnvValue('AUTH0_DOMAIN')}/api/v2`;

axios.defaults.baseURL = BASE_URI;

function createAuthHeader(token?: State['auth']['token']): string {
  return `Bearer ${token || ''}`;
}
export function getHeaders(token?: State['auth']['token']): { Authorization: string; 'Content-type': string } {
  return {
    Authorization: createAuthHeader(token),
    'Content-type': 'application/json',
  };
}
