import { KYCFormData } from 'shared';
import { State } from '@reducers/kycForm';
import { Action } from '../types';

export enum ActionTypes {
  SUBMIT_KYC = 'SUBMIT_KYC',
  SET_KYC_FORM = 'SET_KYC_FORM',
  RESET_KYC_FORM = 'RESET_KYC_FORM',
  START_GET_KYC_FORM = 'START_GET_KYC_FORM',
  SET_KYC_FORM_DATA = 'SET_KYC_FORM_DATA',
  RESET_HAS_FETCHED_KYC = 'RESET_HAS_FETCHED_KYC',
}

export interface SubmitKYC extends Action {
  type: typeof ActionTypes.SUBMIT_KYC;
}

export interface SetKYCForm extends Action {
  type: typeof ActionTypes.SET_KYC_FORM;
  payload: State['form'];
}

export interface ResetKYCForm extends Action {
  type: typeof ActionTypes.RESET_KYC_FORM;
}

export interface StartGetKYCForm extends Action {
  type: typeof ActionTypes.START_GET_KYC_FORM;
  payload: boolean;
}

export interface SetKYCFormData extends Action {
  type: typeof ActionTypes.SET_KYC_FORM_DATA;
  payload: KYCFormData;
}

export interface ResetHasFetchedKYC extends Action {
  type: typeof ActionTypes.RESET_HAS_FETCHED_KYC;
}
