import { State } from '@store';

function getLocationKey(user: State['auth']['user']): string | void {
  if (user && user.sub) {
    return btoa(`${user.sub}-location`);
  }
}

export function getPreviousUserLocation(user: State['auth']['user']): string | null {
  const locationKey = getLocationKey(user);
  if (!locationKey) {
    return null;
  }
  const location = localStorage.getItem(locationKey);
  return location ? atob(location) : null;
}

export function setPreviousUserLocation(user: State['auth']['user']): void {
  const locationKey = getLocationKey(user);
  if (!locationKey) {
    return;
  }
  localStorage.setItem(locationKey, btoa(window.location.pathname));
}

export function clearPreviousUserLocation(user: State['auth']['user']): void {
  const locationKey = getLocationKey(user);
  if (!locationKey) {
    return;
  }
  localStorage.removeItem(locationKey);
}
