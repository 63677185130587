import { KYC, Organization, SubscriptionPackage } from 'shared';

export function displaySubscriptionPackageState(state: SubscriptionPackage['state']): string {
  const stateObj: { [key in SubscriptionPackage['state']]: string } = {
    inProgressByAgent: 'In Progress By Agent',
    sentToDocuSign: 'Sent To DocuSign',
    testSentToDocuSign: '(TEST) Sent To DocuSign',
    completed: 'Fully Executed',
    voided: 'Voided',
  };
  return stateObj[state];
}

export function displayKYCState(state: KYC['state'], organization?: Organization): string {
  const stateObj: { [key in KYC['state']]: string } = {
    initialized: 'Pre-fill and Invite',
    inProgress: 'In Progress By Subscriber',
    underReviewByAgent: 'Under Review By Agent',
    changesRequestedByAgent: 'Changes Requested By Agent',
    underReviewByOrgReviewer: `Under Review By ${organization?.name || 'Organization'}`,
    quickModification: 'Making Quick Change',
    complete: 'Complete',
    renewing: 'Preparing for Renewal',
  };
  return stateObj[state];
}
