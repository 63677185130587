import { ReactElement, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import Select from 'react-select';
import {
  ValidationErrorItem,
  isSequelizeValidationError,
  Organization,
  OrganizationRoles,
  FullUser,
  ResponsePayloads,
  validateOrganizationExistingUserDetails,
  PickNullify,
  Checkbox,
  canRemoveAgentRole,
  canRemoveSubscriberRole,
  isChecked,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import { OnChange } from '@components/common/GeneralInput';
import Loading from '@components/common/Loading';
import Modal from '@components/common/Modal';
import OrganizationRolesFormFields from '@components/common/OrganizationRolesFormFields';
import Overlay from '@components/common/Overlay';
import { useManageOrganization } from '@hooks';
import { getErrors } from '@utils/formFields';
import { GroupedOption, groupUsersByOrgForSelect, Option } from '@utils/select';

interface Props {
  organization: Organization;
  className?: string;
  onClose: () => void;
  onSuccess?: (organization: Organization) => void;
  users: FullUser[];
  isLoadingUsers: boolean;
}

interface CompleteState {
  user_id: FullUser['user_id'];
  organizationRoles: OrganizationRoles;
  errors: ValidationErrorItem[];
  selectedUserOrg?: FullUser['organization'] | null;
}

type State = PickNullify<CompleteState, 'user_id'>;

const initialFormState: State = {
  user_id: null,
  selectedUserOrg: null,
  organizationRoles: {
    isAdmin: false,
    isAgent: false,
    isReviewer: false,
    isClient: false,
  },
  errors: [],
};

function isCompleteState(state: State): state is CompleteState {
  return !!state.user_id;
}

function AddNewUserToOrganization(props: Props): ReactElement {
  const { addExistingUserToOrganization } = useManageOrganization();
  const { className, onClose, organization, onSuccess, users, isLoadingUsers } = props;
  const [state, setState] = useState(initialFormState);

  const { admins = [], agents = [], reviewers = [], clients = [] } = organization;
  const currentOrgUserIds = Array.from(new Set(admins.concat(agents).concat(reviewers).concat(clients))).map(
    (u) => u.user_id,
  );
  const { user_id } = state;
  const getSelectedUser = useCallback(() => users.find((u) => u.user_id === user_id), [users.length, user_id]);
  const selectedUser = getSelectedUser();
  useEffect(() => {
    const selectedUser = getSelectedUser();
    if (!selectedUser) {
      return;
    }
    setState((state) => {
      const newState = {
        ...state,
        organizationRoles: {
          ...state.organizationRoles,
          isAgent: !canRemoveAgentRole(selectedUser),
          isClient: !canRemoveSubscriberRole(selectedUser),
        },
      };
      try {
        validateOrganizationExistingUserDetails(newState);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      return newState;
    });
  }, [getSelectedUser]);

  const sortedUsers = sortBy(users, (user) => user.family_name).filter((u) => !currentOrgUserIds.includes(u.user_id));

  function addUserToOrganization(): void {
    if (!isCompleteState(state) || state.errors.length > 0) {
      return;
    }
    try {
      addExistingUserToOrganization(
        organization,
        state,
        (e) => {
          setState((state) => ({
            ...state,
            errors: e.response?.data?.errors?.map(
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              (error: ValidationErrorItem | any) =>
                new ValidationErrorItem(error.message, undefined, error.field || error.path),
            ),
          }));
        },
        (res: ResponsePayloads['addExistingUserToOrganization']) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
      );
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        setState({ ...state, errors: e.errors });
      }
    }
  }

  function handleChangeUser(value: string): void {
    const newState = {
      ...state,
      user_id: value,
      selectedUserOrg: sortedUsers.find((user) => user.user_id === value)?.organization,
    };
    try {
      validateOrganizationExistingUserDetails(newState);
      newState.errors = [];
    } catch (e) {
      if (isSequelizeValidationError(e)) {
        newState.errors = e.errors;
      }
    }
    setState(newState);
  }

  function onChangeRole(field: keyof typeof initialFormState['organizationRoles']): OnChange {
    return ((value?: Checkbox): void => {
      const newState = { ...state, organizationRoles: { ...state.organizationRoles, [field]: isChecked(value) } };
      try {
        validateOrganizationExistingUserDetails(newState);
        newState.errors = [];
      } catch (e) {
        if (isSequelizeValidationError(e)) {
          newState.errors = e.errors;
        }
      }
      setState(newState);
    }) as OnChange;
  }

  function createLabel(user?: FullUser): string {
    return user ? `${user.name} (${user.email})` : '';
  }

  return (
    <Overlay
      rootId="modify-roles-overlay-root"
      showOverlay={true}
      className={classnames('AddNewUserToOrganization', className)}
    >
      <Modal className="AddNewUserToOrganizationBody flex column align-center" onOutsideClick={onClose} withClose>
        <h1>Add Existing User to Organization</h1>
        {isLoadingUsers && <Loading />}
        {!isLoadingUsers && (
          <form className="flex column align-center w-full">
            <label htmlFor="user_id">Select an Existing User</label>
            <Select<Option, false, GroupedOption>
              options={groupUsersByOrgForSelect(sortedUsers)}
              classNamePrefix="select"
              className="w-full max-w-sm"
              name="user_id"
              onChange={(selected): void => handleChangeUser(selected?.value || '')}
              value={{
                label: createLabel(sortedUsers.find((u) => u.user_id === state.user_id)),
                value: state.user_id || '',
              }}
            />
            {getErrors(state, 'user_id') && <p className="error">{getErrors(state, 'user_id')}</p>}
            {state.selectedUserOrg && (
              <p>
                This user already belongs to: <b>{state.selectedUserOrg.name}</b>. Assigning this user to this
                organization will remove their them from their current organization and re-assign to this organization
                with the roles selected below.{' '}
                {state.selectedUserOrg.userProperties.isAdmin && state.selectedUserOrg.roleNumbers?.admins === 1 && (
                  <span>
                    This user is the last admin for their organization so <b>this re-assignment request will fail</b>.
                  </span>
                )}
              </p>
            )}
            <p className="error">{state.errors.find((error) => error.path === null)?.message}</p>
            <OrganizationRolesFormFields
              organizationRoles={state.organizationRoles}
              onChangeRole={onChangeRole}
              isAgentDisabled={!canRemoveAgentRole(selectedUser)}
              isClientDisabled={!canRemoveSubscriberRole(selectedUser)}
            />
            <p className="error">{getErrors(state, 'organizationRoles')}</p>
            <div className="form-section mt-4">
              <EquiButton
                value={'Add to Organization'}
                onClick={addUserToOrganization}
                disabled={!state.user_id || state.errors.length > 0}
              />
            </div>
          </form>
        )}
      </Modal>
    </Overlay>
  );
}

export default AddNewUserToOrganization;
