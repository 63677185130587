import { RequestPayloads } from 'shared';
import { Action } from '../types';

export enum ActionTypes {
  CREATE_NEW_ORGANIZATION = 'CREATE_NEW_ORGANIZATION',
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
  CREATE_NEW_ORGANIZATION_USER = 'CREATE_NEW_ORGANIZATION_USER',
  ADD_EXISTING_USER_TO_ORGANIZATION = 'ADD_EXISTING_USER_TO_ORGANIZATION',
}

export interface CreateNewOrganization extends Action {
  type: typeof ActionTypes.CREATE_NEW_ORGANIZATION;
  payload: RequestPayloads['createOrganization'];
}
export interface UpdateOrganization extends Action {
  type: typeof ActionTypes.UPDATE_ORGANIZATION;
  payload: RequestPayloads['updateOrganization'];
}

export interface CreateNewOrganizationUser extends Action {
  type: typeof ActionTypes.CREATE_NEW_ORGANIZATION_USER;
  payload: RequestPayloads['createNewOrganizationUser'];
}
export interface AddExistingUserToOrganization extends Action {
  type: typeof ActionTypes.ADD_EXISTING_USER_TO_ORGANIZATION;
  payload: RequestPayloads['addExistingUserToOrganization'];
}
