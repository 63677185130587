import { useEffect } from 'react';

export function useKeyDown(callback: (ev: KeyboardEvent) => void): void {
  useEffect(() => {
    document.addEventListener('keydown', callback);
    return (): void => {
      document.removeEventListener('keydown', callback);
    };
  }, [callback]);
}
