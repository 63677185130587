import { useState } from 'react';
import { useKeyDown } from '..';

export interface ModalHook {
  openModal: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

export function useModal(defaultOpen = false): ModalHook {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { closeModal, openModal } = setupHelpers(setIsOpen);

  useKeyDown((e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      closeModal();
    }
  });

  return { openModal, closeModal, isOpen };
}

function setupHelpers(setIsOpen: React.Dispatch<React.SetStateAction<boolean>>): Omit<ModalHook, 'isOpen'> {
  function openModal(): void {
    setIsOpen(true);
  }

  function closeModal(): void {
    setIsOpen(false);
  }
  return { openModal, closeModal };
}
