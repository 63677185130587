import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  KYCFormDataAllRequired,
  KYCFormValidationError,
  RequestPayloads,
  orgReviewerKYCEditableStates,
  agentKYCEditableStates,
  subscriberKYCEditableStates,
} from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';
import { usePageChange } from '../usePageChange';

export interface KYCFormHook {
  form: State['kycForm']['form'];
  isLoading: State['kycForm']['isLoading'];
  hasFetched: State['kycForm']['hasFetched'];
  updateField(
    field: keyof KYCFormDataAllRequired,
    value: KYCFormDataAllRequired[keyof KYCFormDataAllRequired],
    e: React.ChangeEvent<HTMLInputElement>,
  ): void;
  createKYC(
    newKYC: RequestPayloads['createKYC'],
    onError: (error: KYCFormValidationError) => void,
    onSuccess: () => void,
  ): void;
  inviteSubscriberToRenewKYC(): void;
  inviteSubscriber(): void;
  requestApproval(onSuccess?: () => undefined): void;
  requestChanges(): void;
  agentApproveForm(): void;
  orgReviewerApproveForm(): void;
  quickModification(): void;
  markComplete(): void;
  renewForm(): void;
  cancelRenewalProcess(): void;
  getKYCByUserEmail(email: string): void;
  isFormDisabledForCurrentUser: boolean;
  shouldAllowAutocompleteForCurrentUser: boolean;
  isFormForCurrentUser: boolean;
  fillWithFakeData: () => void;
  uploadPhotoID(file: File): void;
}

function setIsFormDisabledForCurrentUser(
  kycForm: State['kycForm']['form'],
  currentUser: State['currentUser']['data'],
): boolean {
  if (kycForm?.state === 'complete' || !currentUser?.user_id || !kycForm) {
    return true;
  }
  if (
    (currentUser?.organization?.userProperties?.isAdmin || currentUser?.organization?.userProperties?.isReviewer) &&
    kycForm.subscriber?.organization?.id === currentUser?.organization?.id &&
    !orgReviewerKYCEditableStates.includes(kycForm.state)
  ) {
    return true;
  }
  if (
    !currentUser?.organization?.userProperties?.isAdmin &&
    !currentUser?.organization?.userProperties?.isReviewer &&
    !(currentUser.roles.includes('admin') || currentUser.roles.includes('eq')) &&
    currentUser.roles.includes('agent') &&
    !isFormForCurrentUser(kycForm, currentUser) &&
    !agentKYCEditableStates.includes(kycForm.state)
  ) {
    return true;
  }
  if (
    kycForm.state &&
    isFormForCurrentUser(kycForm, currentUser) &&
    !(currentUser.roles.includes('admin') || currentUser.roles.includes('eq')) &&
    !subscriberKYCEditableStates.includes(kycForm.state)
  ) {
    return true;
  }
  return false;
}

function isFormForCurrentUser(kycForm: State['kycForm']['form'], currentUser: State['currentUser']['data']): boolean {
  return !!currentUser?.user_id && currentUser?.user_id === kycForm?.subscriberUserId;
}

function setShouldAllowAutocompleteForCurrentUser(
  kycForm: State['kycForm']['form'],
  currentUser: State['currentUser']['data'],
): boolean {
  return isFormForCurrentUser(kycForm, currentUser);
}

export function useKYCFormState(email?: string): KYCFormHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const kycForm = useSelector<State>((state) => state.kycForm) as State['kycForm'];
  const { data: currentUser } = useSelector<State>((state) => state.currentUser) as State['currentUser'];
  const dispatch = reduxDispatch as Dispatch;
  function updateField(
    field: keyof KYCFormDataAllRequired,
    value: KYCFormDataAllRequired[keyof KYCFormDataAllRequired],
    e: React.MouseEvent | React.ChangeEvent,
  ): void {
    dispatch(Actions.updateKYCFormField(field, value, e as React.ChangeEvent<HTMLInputElement>));
  }

  function createKYC(
    newKYC: RequestPayloads['createKYC'],
    onError: (error: KYCFormValidationError) => void,
    onSuccess: () => void,
  ): void {
    dispatch(Actions.createKYC(newKYC, onError, onSuccess));
  }

  function getKYCByUserEmail(email: string): void {
    dispatch(Actions.getKYCByUserEmail(email));
  }

  function inviteSubscriber(): void {
    dispatch(Actions.inviteSubscriber());
  }
  function inviteSubscriberToRenewKYC(): void {
    dispatch(Actions.inviteSubscriberToRenewKYC());
  }
  function requestApproval(onSuccess = (): undefined => undefined): void {
    dispatch(Actions.requestApproval(onSuccess));
  }
  function requestChanges(): void {
    dispatch(Actions.requestChanges());
  }
  function agentApproveForm(): void {
    dispatch(Actions.agentApproveForm());
  }
  function orgReviewerApproveForm(): void {
    dispatch(Actions.orgReviewerApproveForm());
  }
  function quickModification(): void {
    dispatch(Actions.quickModification());
  }
  function markComplete(): void {
    dispatch(Actions.markComplete());
  }
  function renewForm(): void {
    dispatch(Actions.renewForm());
  }
  function cancelRenewalProcess(): void {
    dispatch(Actions.cancelRenewalProcess());
  }

  useEffect(() => {
    if (email) {
      getKYCByUserEmail(email);
    }
    return (): void => {
      resetHasFetchedKYC();
    };
  }, [email]);

  function resetHasFetchedKYC(): void {
    dispatch(Actions.resetHasFetchedKYC());
  }

  function uploadPhotoID(file: File): void {
    if (!file) {
      return;
    }
    if (file.size / 1024 / 1024 > 20) {
      dispatch(
        Actions.flashWarningMessage('This file is too large. Please upload another file. Max file size: 20 MB.'),
      );
      dispatch(Actions.hideLoadingOverlay());
      return;
    }
    dispatch(Actions.uploadPhotoID(file));
  }

  function fillWithFakeData(): void {
    if (!currentUser?.roles.includes('admin')) {
      return;
    }
    dispatch(Actions.fillWithFakeData());
  }

  usePageChange(() => {
    resetHasFetchedKYC();
  });

  return {
    ...kycForm,
    createKYC,
    inviteSubscriber,
    inviteSubscriberToRenewKYC,
    requestApproval,
    requestChanges,
    agentApproveForm,
    orgReviewerApproveForm,
    renewForm,
    cancelRenewalProcess,
    quickModification,
    markComplete,
    updateField,
    getKYCByUserEmail,
    isFormDisabledForCurrentUser: setIsFormDisabledForCurrentUser(kycForm.form, currentUser),
    shouldAllowAutocompleteForCurrentUser: setShouldAllowAutocompleteForCurrentUser(kycForm.form, currentUser),
    isFormForCurrentUser: isFormForCurrentUser(kycForm.form, currentUser),
    uploadPhotoID,
    fillWithFakeData,
  };
}
