import { useEffect } from 'react';
import { intersection } from 'lodash';
import { useSelector, useStore } from 'react-redux';
import { filter, Role } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';
import { useCurrentUser } from '../useCurrentUser';
import { useCurrentUserReadableRelationships } from '../useCurrentUserReadableRelationships';
import { usePageChange } from '../usePageChange';

export interface UsersHook {
  users: State['users']['data'];
  isLoading: State['users']['isLoading'];
}

export function useUsers(options: { role?: Role } = {}): UsersHook {
  const { role } = options;
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const dispatch = reduxDispatch as Dispatch;
  const { isLoading, data } = useSelector<State>((state) => state.users) as State['users'];
  const { hasAllPermissions, canCurrentUserSeeOrgUser } = useCurrentUser();
  const { isLoading: areRelationshipsLoading, relationships } = useCurrentUserReadableRelationships();
  useEffect(() => {
    dispatch(Actions.getUsers(role));
    return (): void => {
      resetHasFetchedUsers();
    };
  }, [role]);

  const canReadAdmins: false | Role = hasAllPermissions(['read:admin']) && 'admin';
  const canReadSubscribers: false | Role = hasAllPermissions(['read:subscriber']) && 'subscriber';
  const canReadAgents = hasAllPermissions(['read:agent']) && 'agent';
  const canReadEQ: false | Role = hasAllPermissions(['read:eq']) && 'eq';
  const canReadOwn: boolean = hasAllPermissions(['read:ownSubscriber']);
  const readableRoles = filter<Role>([canReadAdmins, canReadAgents, canReadSubscribers, canReadEQ]);
  const users = data
    .map((user) => ({
      ...user,
      app_metadata: {
        ...user.app_metadata,
        authorization: {
          ...user.app_metadata.authorization,
          permissions: user.app_metadata.authorization?.permissions || [],
          roles: user.app_metadata.authorization?.roles || [],
        },
      },
    }))
    .filter(
      (user) =>
        !user.app_metadata?.authorization?.roles?.length ||
        (canReadSubscribers && intersection(user.app_metadata?.authorization?.roles || [], readableRoles).length > 0) ||
        (canReadOwn && relationships.find((r) => r.subscriberUserId === user.user_id)) ||
        (['subscriber', 'agent'].includes(role || '') && canCurrentUserSeeOrgUser(user)),
    )
    .filter((user) => !role || user.app_metadata.authorization?.roles.includes(role));
  function resetHasFetchedUsers(): void {
    dispatch(Actions.resetHasFetchedUsers());
  }

  usePageChange(() => {
    resetHasFetchedUsers();
  });
  return { users, isLoading: isLoading || areRelationshipsLoading };
}
