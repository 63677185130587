import axios from 'axios';
import { RequestParams, RequestPayloads, ResponsePayloads } from 'shared';

export function getOfficeUse(id: number): Promise<ResponsePayloads['getOfficeUse']> {
  return axios.get(`/office-uses/${id}`).then((res) => res.data);
}

export function createOfficeUse({
  email,
}: RequestParams['createOfficeUse']): Promise<ResponsePayloads['createOfficeUse']> {
  return axios.post(`/users/${email}/office-use`).then((res) => res.data);
}

export function updateOfficeUse(
  id: number,
  formData: RequestPayloads['updateOfficeUse'],
): Promise<ResponsePayloads['updateOfficeUse']> {
  return axios.patch(`/office-uses/${id}`, formData).then((res) => res.data);
}

export function getOfficeUseByUserEmail(email: string): Promise<ResponsePayloads['getOfficeUseByUserEmail']> {
  return axios.get(`/users/${email}/office-use`).then((res) => res.data);
}
