import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

interface ScrollPosition {
  scrollPosition: number;
  scrollPositionFromBottom: number;
  scrollToTop: () => void;
  scrollToBottom: () => void;
}

export function useScrollPosition(): ScrollPosition {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [scrollPositionFromBottom, setScrollPositionFromBottom] = useState(window.scrollY);

  useEffect(() => {
    const updatePosition = throttle((): void => {
      setScrollPosition(window.scrollY);
      setScrollPositionFromBottom(
        Math.floor(Math.abs(document.body.scrollHeight - window.scrollY - window.innerHeight)),
      );
    }, 100);
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    const observer = new ResizeObserver(updatePosition);
    observer.observe(document.body);
    return (): void => {
      window.removeEventListener('scroll', updatePosition);
      observer.unobserve(document.body);
      observer.disconnect();
    };
  }, []);

  function scrollToTop(): void {
    scrollTo({ top: 0, behavior: 'smooth' });
  }

  function scrollToBottom(): void {
    scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  return { scrollPosition, scrollToTop, scrollToBottom, scrollPositionFromBottom };
}
