import { FamilyInformation, MaritalStatusRadio } from 'shared';
import { ProtoFields } from '../types';

export const familyInformationFields: ProtoFields<keyof FamilyInformation> = {
  dependants: { type: 'number', formLabel: 'Number of Dependants', min: 0, step: 1 },
  maritalStatus: {
    type: 'radio',
    formLabel: 'Marital Status',
    options: [
      { displayName: 'Single', value: MaritalStatusRadio.single },
      { displayName: 'Married', value: MaritalStatusRadio.married },
      { displayName: 'Divorced', value: MaritalStatusRadio.divorced },
      { displayName: 'Widowed', value: MaritalStatusRadio.widowed },
    ],
  },
  spouseEmployer: { formLabel: "Spouse's Employer" },
  spouseName: { formLabel: "Spouse's Name" },
  spouseOccupation: { formLabel: "Spouse's Occupation" },
  spouseTypeOfBusiness: { formLabel: 'Spouse Type of Business' },
};
