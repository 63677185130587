import { ReactElement } from 'react';
import { TrueFalseRadio } from 'shared';
import EquiInput from '@components/common/KYCEquiInput';
import { useKYCFormFields } from '@hooks';

function ControllingPerson(): ReactElement {
  const formFields = useKYCFormFields();
  function updateIsPurchaserCorporationOrTrust(e: React.ChangeEvent<HTMLInputElement>, value?: TrueFalseRadio): void {
    formFields.isPurchaserCorporationOrTrust.onChange(e, value);
    if (value === TrueFalseRadio.false) {
      formFields.controllingPersonType.onChange(e, undefined);
    }
  }
  return (
    <div>
      <div className="flex">
        <div className="spacer justify-center align-center">
          <EquiInput {...formFields.isPurchaserCorporationOrTrust} onChange={updateIsPurchaserCorporationOrTrust} />
        </div>
      </div>
      {formFields.isPurchaserCorporationOrTrust.value === TrueFalseRadio.true && (
        <div className="flex">
          <EquiInput {...formFields.controllingPersonType} />
        </div>
      )}
    </div>
  );
}

export default ControllingPerson;
