import { ReactElement, FunctionComponent, createRef } from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from '@hooks';

import './styles.scss';
import CloseIcon from '../CloseIcon';

interface Props {
  className?: string;
  onOutsideClick?: () => void;
  withClose?: boolean;
}

const Modal: FunctionComponent<Props> = (props): ReactElement => {
  const { children, className, onOutsideClick = (): void => undefined, withClose = false } = props;
  const ref = createRef<HTMLDivElement>();
  useOnClickOutside(ref, onOutsideClick);
  return (
    <div ref={ref} className={classnames('Modal', className)}>
      {withClose && <CloseIcon onClick={onOutsideClick} />}
      {children}
    </div>
  );
};

export default Modal;
