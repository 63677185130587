import { isAxiosError, Role } from 'shared';
import * as Users from '@api/users';
import { Actions as ApiActions, HandleAxiosError } from '../api';
import { AsyncAction } from '../types';
import { ActionTypes, GetUsers, StartGetUsers, ResetHasFetchedUsers } from './types';

export type Actions = GetUsers | StartGetUsers | ResetHasFetchedUsers;

export const Actions = {
  getUsers(role?: Role): AsyncAction<GetUsers | StartGetUsers | HandleAxiosError> {
    return async (dispatch, getState): Promise<void> => {
      try {
        const {
          auth: { user },
          users: { hasFetched },
        } = getState();
        if (!user || hasFetched) {
          return;
        }
        dispatch({ type: ActionTypes.START_GET_USERS, payload: undefined });
        const users = await Users.getUsers(role);
        dispatch({ type: ActionTypes.GET_USERS, payload: users });
      } catch (e) {
        if (isAxiosError(e)) {
          dispatch(ApiActions.handleAxiosError(e));
          return;
        }
      }
    };
  },
  resetHasFetchedUsers(): AsyncAction<ResetHasFetchedUsers> {
    return async (dispatch, getState): Promise<void> => {
      const {
        users: { hasFetched },
      } = getState();
      if (!hasFetched) {
        return;
      }
      dispatch({ type: ActionTypes.RESET_HAS_FETCHED_USERS, payload: undefined });
    };
  },
};

export * from './types';
