import { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Organization } from 'shared';
import { useCurrentUser } from '@hooks';
import Dropdown from '../Dropdown';
import './styles.scss';

interface Props {
  openAddUserModal: () => void;
  openAddExistingUserModal: () => void;
  organization: Organization;
}

function OrganizationMenu({ openAddUserModal, organization, openAddExistingUserModal }: Props): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const { hasAllPermissions, isCurrentUserAdminForOrg } = useCurrentUser();
  if (
    !(hasAllPermissions(['create:agent', 'create:subscriber']) || isCurrentUserAdminForOrg(organization)) &&
    !hasAllPermissions(['read:agent', 'read:subscriber'])
  ) {
    return null;
  }
  return (
    <Dropdown
      triggerComponent={
        <FontAwesomeIcon
          className="hover:text-equigenesis-gold text-2xl"
          icon={!isOpen ? 'chevron-circle-down' : 'chevron-circle-up'}
        />
      }
      menuComponent={
        <>
          <div className="whitespace-nowrap">
            {(hasAllPermissions(['create:agent', 'create:subscriber']) || isCurrentUserAdminForOrg(organization)) && (
              <a
                href="#add-new-user"
                className={classnames('organization-dropdown-item', 'nav-link')}
                onClick={openAddUserModal}
              >
                Add New Member
              </a>
            )}
          </div>
          <div className="whitespace-nowrap">
            {hasAllPermissions(['read:agent', 'read:subscriber']) && (
              <a
                href="#add-existing-user"
                className={classnames('organization-dropdown-item', 'nav-link')}
                onClick={openAddExistingUserModal}
              >
                Add Existing User To Organization
              </a>
            )}
          </div>
        </>
      }
      isOpen={isOpen}
      onTriggerClick={setIsOpen}
      menuContainerClassName="-right-2"
    />
  );
}

export default OrganizationMenu;
