import { ReactElement, useMemo } from 'react';
import EquiInput from '@components/common/SubscriptionPackageEquiInput';
import {
  useSubscriptionPackageFormFields,
  useSubscriptionPackageFormState,
  useDocuSignTemplatesForSubscriptionPackage,
} from '@hooks';
import MasterLPSSPFields from './MasterLPSSPFields';
import SSPFields from './SSPFields';

function SubscriptionInformation(): ReactElement {
  const { form } = useSubscriptionPackageFormState();
  const formFields = useSubscriptionPackageFormFields();

  const { docusignTemplates } = useDocuSignTemplatesForSubscriptionPackage();

  const FieldsComponent = useMemo(() => getFieldsComponentForProduct(form?.product?.id), [form?.product?.id]);

  return (
    <div className="form-section">
      <h2 className="title">Subscription Information</h2>
      <div className="flex form-row">
        <EquiInput {...formFields.programName} className="no-flex" />
        <EquiInput {...formFields.unitAmount} className="no-flex" />
      </div>
      <hr />
      <FieldsComponent />
      <hr />
      <div className="flex flex-col items-center">
        <h3 className="text-center">
          List of Documents{form?.state === 'inProgressByAgent' ? ' to be ' : ' '}included in this signing package:
        </h3>
        <ul>
          {docusignTemplates.map((template) => (
            <li key={template.id}>{template.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SubscriptionInformation;

function getFieldsComponentForProduct(productId: string | undefined): () => ReactElement {
  switch (true) {
    case productId?.includes('smart-savings-plan'):
      return SSPFields;
    case productId?.includes('masterlp-ssp'):
      return MasterLPSSPFields;
    default:
      return (): ReactElement => <div>The computed fields for this program cannot be displayed at this time.</div>;
  }
}
