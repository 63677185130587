import { EQ_SUPPORT_MESSAGE, isAxiosError } from 'shared';
import { focusErrorField } from '@utils/domHelpers';
import { Actions as GeneralActions, FlashMessage, SetError, SetShowLoadingOverlay } from '../general';
import { AsyncAction } from '../types';
import { HandleAxiosError } from './types';

export type Actions = HandleAxiosError;

function maybeAddPeriod(str: string): string {
  if (str[str.length - 1] !== '.') {
    return str + '.';
  }
  return str;
}

export const Actions = {
  handleAxiosError: (e: unknown): AsyncAction<FlashMessage | SetShowLoadingOverlay> => {
    return async (dispatch): Promise<void> => {
      if (!isAxiosError(e) || !e.response) {
        dispatch(GeneralActions.flashErrorMessage(`Something went wrong. ${EQ_SUPPORT_MESSAGE}`));
        dispatch(GeneralActions.hideLoadingOverlay());
        return;
      }
      if (e.response.status === 422) {
        dispatch(GeneralActions.flashErrorMessage('There are errors with the form. Please correct them below.'));
        focusErrorField();
      } else if (e.response.status === 500) {
        dispatch(
          GeneralActions.flashErrorMessage(
            maybeAddPeriod(`${e.response.data?.errors.map((error: { message: string }) => error.message).join('. ')}`),
          ),
        );
        dispatch(Actions.serverError());
      } else if (e.response.data && !e.response.data.notFoundRoute && e.response.data.errors) {
        dispatch(
          GeneralActions.flashErrorMessage(
            maybeAddPeriod(`${e.response.data.errors.map((error: { message: string }) => error.message).join('. ')}`),
          ),
        );
      } else if (e.response.status === 404 && e.response.data && e.response.data.notFoundRoute) {
        throw new Error(
          maybeAddPeriod(`${e.response.data.errors.map((error: { message: string }) => error.message).join('. ')}`),
        );
      }
      dispatch(GeneralActions.hideLoadingOverlay());
    };
  },
  serverError(): AsyncAction<SetError | SetShowLoadingOverlay> {
    return async (dispatch): Promise<void> => {
      dispatch(GeneralActions.hideLoadingOverlay());
      dispatch(GeneralActions.setError(EQ_SUPPORT_MESSAGE));
    };
  },
};

export * from './types';
