import { ValidPath } from '@routes/lib';
import { Action } from '../types';

export enum ActionTypes {
  START_NAVIGATION = 'START_NAVIGATION',
  NAVIGATION_ENDED = 'NAVIGATION_ENDED',
}
export interface StartNavigation extends Action {
  type: typeof ActionTypes.START_NAVIGATION;
  payload: { currentLocation: ValidPath; goBackLocation: ValidPath };
}

export interface NavigationEnded extends Action {
  type: typeof ActionTypes.NAVIGATION_ENDED;
}
