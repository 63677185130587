import { ChangeEvent, ReactElement, useState } from 'react';
import {
  FullUser,
  KYCFormData,
  KYCFormFormatting,
  KYCFormValidation,
  SubscriptionPackageFormData,
  SubscriptionPackageFormFormatting,
  SubscriptionPackageFormValidation,
  RequestPayloads,
  UserMetadata,
  validateUpdateUser,
  ValidationError,
  ValidationErrorItem,
  filter,
} from 'shared';
import EquiButton from '@components/common/EquiButton';
import GeneralInput, { OnChange } from '@components/common/GeneralInput';
import Modal from '@components/common/Modal';
import Overlay from '@components/common/Overlay';
import { useUpdateUser } from '@hooks';

interface Props {
  user: FullUser;
  closeModal: () => void;
  isOpen: boolean;
  isSubscriber: boolean;
}

function EditUserModal({ user, closeModal, isOpen, isSubscriber }: Props): ReactElement {
  const [errors, setErrors] = useState<
    (ValidationErrorItem | KYCFormData['errors'][number] | SubscriptionPackageFormData['errors'][number])[]
  >([]);
  const { updateUser } = useUpdateUser();
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.given_name);
  const [lastName, setLastName] = useState(user.family_name);
  const [mobilePhone, setPhoneNumber] = useState(user.user_metadata?.phone_number || '');
  const [firm, setFirm] = useState(user.organization?.name || user.user_metadata?.firm || '');
  const isAgent = user.app_metadata.authorization?.roles.includes('agent') || false;
  function submitForm(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const newUser: Omit<RequestPayloads['updateUser'], 'roles'> & { user_metadata: UserMetadata } = {
      email,
      given_name: firstName,
      family_name: lastName,
      name: `${firstName} ${lastName}`,
      user_metadata: {
        phone_number: mobilePhone,
        firm,
      },
    };
    try {
      validateUpdateUser(newUser, isAgent);
    } catch (e) {
      setErrors((e as ValidationError).errors);
      return;
    }
    setErrors([]);
    if (newUser.email === user.email) {
      delete newUser.email;
    }
    if (
      !newUser.email ||
      confirm(
        "Updating the user's email will change his/her login credentials. The user will need to use this new email in order to login and all future emails intended for this user will go to this new email. If the user does not have access to this email, it could lock them out of their account. Are you sure you want to proceed?",
      )
    ) {
      updateUser(user.user_id, newUser, newUser.email || user.email, isSubscriber);
    }
  }
  function onPhoneChange(
    phone: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void {
    const formatted = KYCFormFormatting('mobilePhone', e as React.ChangeEvent<HTMLInputElement>)(phone);
    const validator = KYCFormValidation.mobilePhone as (
      v: typeof phone,
      f: KYCFormData,
      isSubscriber: boolean,
    ) => KYCFormData['errors'][number] | void;
    const _errors = validator(
      formatted,
      {
        firstName,
        lastName,
        email,
        mobilePhone,
        numberOfFinancialDebts: 1,
        numberOfNonFinancialDebts: 1,
        errors: (errors as unknown) as KYCFormData['errors'],
      },
      false,
    );
    setPhoneNumber(formatted);
    const newErrors = errors
      .filter(
        (err) =>
          !(err as KYCFormData['errors'][number]).fieldCausingError ||
          !(err as KYCFormData['errors'][number]).fieldCausingError.includes('mobilePhone'),
      )
      .concat(filter([_errors]));
    setErrors(newErrors);
  }
  function onAgentFirmChange(
    firm: string,
    e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ): void {
    const formatted = SubscriptionPackageFormFormatting(
      'salespersonFirm',
      e as React.ChangeEvent<HTMLInputElement>,
    )(firm);
    const validator = SubscriptionPackageFormValidation.salespersonFirm as (
      v: typeof firm,
      f: SubscriptionPackageFormData,
      isSubscriber: boolean,
    ) => SubscriptionPackageFormData['errors'][number] | void;
    const _errors = validator(
      formatted,
      {
        programName: 'EQ 2021 SMART Savings Plan',
        salespersonEmail: email,
        salespersonName: `${firstName} ${lastName}`,
        salespersonPhone: mobilePhone,
        errors: (errors as unknown) as SubscriptionPackageFormData['errors'],
      },
      true,
    );
    setFirm(formatted);
    const newErrors = errors
      .filter(
        (err) =>
          !(err as SubscriptionPackageFormData['errors'][number]).fieldCausingError ||
          !(err as SubscriptionPackageFormData['errors'][number]).fieldCausingError.includes('salespersonFirm'),
      )
      .concat(filter([_errors]));
    setErrors(newErrors);
  }
  return (
    <>
      {isOpen && (
        <Overlay showOverlay={isOpen} rootId="edit-user-overlay">
          <Modal withClose onOutsideClick={closeModal} className="flex column align-center EditUserModal">
            <h2>Edit {user.name}</h2>
            <form className="max-width" method="POST" onSubmit={submitForm}>
              <div className="form-section max-width flex justify-center">
                <GeneralInput
                  formLabel="First Name"
                  name="given_name"
                  value={firstName}
                  onChange={((value: string) => setFirstName(value)) as OnChange}
                  error={errors.find((err) => (err as ValidationErrorItem).path === 'given_name')?.message}
                />
              </div>
              <div className="form-section max-width flex justify-center">
                <GeneralInput
                  formLabel="Last Name"
                  name="family_name"
                  value={lastName}
                  onChange={((value: string) => setLastName(value)) as OnChange}
                  error={errors.find((err) => (err as ValidationErrorItem).path === 'family_name')?.message}
                />
              </div>
              <div className="form-section max-width flex justify-center">
                <GeneralInput
                  formLabel="Email"
                  name="email"
                  value={email}
                  onChange={((value: string) => setEmail(value)) as OnChange}
                  error={errors.find((err) => (err as ValidationErrorItem).path === 'email')?.message}
                />
              </div>
              <div className="form-section max-width flex justify-center">
                <GeneralInput
                  formLabel="Mobile Phone Number"
                  name="phoneNumber"
                  value={mobilePhone}
                  onChange={onPhoneChange as OnChange}
                  error={
                    errors.find(
                      (err) =>
                        (err as KYCFormData['errors'][number]).fieldCausingError &&
                        (err as KYCFormData['errors'][number]).fieldCausingError.includes('mobilePhone'),
                    )?.message
                  }
                />
              </div>
              {isAgent && (
                <div className="form-section max-width flex justify-center">
                  <GeneralInput
                    formLabel="Agent Firm/Organization"
                    name="agentFirm"
                    value={firm}
                    onChange={onAgentFirmChange as OnChange}
                    error={
                      errors.find(
                        (err) =>
                          ((err as SubscriptionPackageFormData['errors'][number]).fieldCausingError &&
                            (err as SubscriptionPackageFormData['errors'][number]).fieldCausingError.includes(
                              'salespersonFirm',
                            )) ||
                          (err as ValidationErrorItem).path === 'firm',
                      )?.message
                    }
                    readOnly={!!user.organization?.name}
                  />
                </div>
              )}
              <div className="button-container max-width flex space-around">
                <EquiButton value="Cancel" onClick={closeModal} />
                <EquiButton value="Update" type="submit" />
              </div>
            </form>
          </Modal>
        </Overlay>
      )}
    </>
  );
}

export default EditUserModal;
