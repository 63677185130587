import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import './styles.scss';

export enum FlashMessageType {
  danger = 'danger',
  warning = 'warning',
  success = 'success',
}

interface Props {
  text: string;
  type: FlashMessageType;
  onCloseClick: () => void;
}

function FlashMessage(props: Props): ReactElement {
  const { text, type, onCloseClick } = props;
  return (
    <div className={classnames('FlashMessage', 'flash-message', `flash-message-${type}`)}>
      <span className="flash-message-text">{text}</span>
      <span className="close">
        <FontAwesomeIcon onClick={onCloseClick} icon="times" />
      </span>
    </div>
  );
}

export default FlashMessage;
