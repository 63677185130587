import { ReactElement, useEffect } from 'react';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEnvValue } from 'shared';
import { Children } from '@custom-types';
import { usePageChange } from '@hooks';
import { State } from '@store';
import anyWindow from '@utils/anyWindow';
import { epochToJsDate } from '@utils/dateTime';
import { getPreviousUserLocation, clearPreviousUserLocation } from '@utils/localStorage/userLocation';
import { Actions, Dispatch } from '../actions';
import { SaveAuth } from '../actions/auth';
import getConfig, { audience } from './config';
import Redirecting from './Redirecting';

export interface AppReduxActions {
  saveAuthDetails(auth: SaveAuth['payload']): void;
  sessionExpired(): void;
  getCurrentUserInfo(): void;
  startGetToken(): void;
  resetHasFetchedToken(): void;
}

export interface AppState {
  auth: State['auth'];
}

interface Props extends AppReduxActions {
  children: Children;
}
export const mapStateToProps = (state: State): AppState => ({
  auth: state.auth,
});

function AuthWrapper(props: Props): ReactElement {
  const { children, saveAuthDetails, sessionExpired, getCurrentUserInfo, startGetToken, resetHasFetchedToken } = props;
  const history = useHistory();
  // Currently setup assuming all routes need auth. May not be the case
  const Component = connect(mapStateToProps)(
    withAuthenticationRequired(
      (props: AppState) => {
        const { auth } = props;
        const { user, isAuthenticated, getAccessTokenSilently, logout, getIdTokenClaims } = useAuth0();
        useEffect(() => {
          (async (): Promise<void> => {
            if (auth.hasFetched) {
              return;
            }
            startGetToken();
            const previousLocation = getPreviousUserLocation(user);
            const token = await getAccessTokenSilently({ audience });
            if (getEnvValue('NODE_ENV') !== 'production' && getEnvValue('REACT_APP_DEBUG')) {
              anyWindow.token = token;
            }
            await saveAuthDetails({ token, isAuthenticated, user });
            getCurrentUserInfo();
            if (previousLocation) {
              history.push(previousLocation);
              clearPreviousUserLocation(user);
            }
          })();
        }, [auth]);

        usePageChange(() => {
          resetHasFetchedToken();
        });
        useEffect(() => {
          return (): void => {
            resetHasFetchedToken();
          };
        }, []);

        useEffect(() => {
          async function checkIsTokenValid(): Promise<void> {
            const idToken = await getIdTokenClaims();
            if (!idToken || epochToJsDate(idToken.exp || 0) <= new Date()) {
              sessionExpired();
            }
          }
          checkIsTokenValid();
          window.addEventListener('focus', checkIsTokenValid);
          return (): void => {
            window.removeEventListener('focus', checkIsTokenValid);
          };
        }, []);

        useEffect(() => {
          if (auth.forceLogout) {
            logout({ returnTo: window.location.origin });
          }
        }, [auth]);

        if (!isAuthenticated || !auth.isAxiosSetupComplete) {
          return null;
        }

        return <>{children}</>;
      },
      {
        // Show a message while the user waits to be redirected to the login page.
        onRedirecting: () => <Redirecting />,
      },
    ),
  );
  const config = getConfig();
  const onRedirectCallback = (appState?: { returnTo?: string }): void => {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };
  config.onRedirectCallback = onRedirectCallback;
  return (
    <Auth0Provider {...config}>
      <Component />
    </Auth0Provider>
  );
}

const mapDispatchToProps = (dispatch: Dispatch): AppReduxActions => {
  const saveAuthDetails: AppReduxActions['saveAuthDetails'] = (auth: SaveAuth['payload']) =>
    dispatch(Actions.saveAuth(auth));

  const sessionExpired: AppReduxActions['sessionExpired'] = () => dispatch(Actions.sessionExpired());
  const getCurrentUserInfo: AppReduxActions['getCurrentUserInfo'] = () => dispatch(Actions.getCurrentUserInfo());
  const startGetToken: AppReduxActions['startGetToken'] = () => dispatch(Actions.startGetToken());
  const resetHasFetchedToken: AppReduxActions['resetHasFetchedToken'] = () => dispatch(Actions.resetHasFetchedToken());

  return {
    saveAuthDetails,
    sessionExpired,
    getCurrentUserInfo,
    startGetToken,
    resetHasFetchedToken,
  };
};

export default connect(undefined, mapDispatchToProps)(AuthWrapper);
