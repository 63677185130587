import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RequestPayloads, SubscriptionPackageFormError } from 'shared';
import { Actions, Dispatch } from '@actions';
import { State } from '@store';

export interface SubscriptionPackagesHook {
  createSubscriptionPackage(
    kycId: number,
    newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
    onSuccess?: () => void,
    onError?: (error: SubscriptionPackageFormError) => void,
  ): void;
  subscriptionPackages: State['subscriptionPackages']['data'];
  isLoading: State['subscriptionPackages']['isLoading'];
}

export function useSubscriptionPackages(): SubscriptionPackagesHook {
  const { dispatch: reduxDispatch } = useStore<State, Actions>();
  const { isLoading, data: subscriptionPackages } = useSelector<State>(
    (state) => state.subscriptionPackages,
  ) as State['subscriptionPackages'];

  const dispatch = reduxDispatch as Dispatch;

  async function getSubscriptionPackages(): Promise<void> {
    dispatch(Actions.getSubscriptionPackages());
  }

  function createSubscriptionPackage(
    kycId: number,
    newSubscriptionPackage: RequestPayloads['createSubscriptionPackage'],
    onSuccess: () => void = (): void => undefined,
    onError: (error: SubscriptionPackageFormError) => void = (): void => undefined,
  ): void {
    dispatch(Actions.createSubscriptionPackage(kycId, newSubscriptionPackage, onSuccess, onError));
  }

  useEffect(() => {
    getSubscriptionPackages();
  }, []);

  return { createSubscriptionPackage, subscriptionPackages, isLoading };
}
