import { ReactElement } from 'react';
import EquiButton, { ButtonType } from '@components/common/EquiButton';
import Form from '@components/common/Form';
import { Children } from '@custom-types';
import { useSubscriptionPackageFormState, useCurrentUser } from '@hooks';
import SalespersonInformation from './SalesPersonInformation';
import SubscriptionInformation from './SubscriptionInformation';
import './styles.scss';

function SubscriptionPackage(): ReactElement {
  const {
    form,
    deleteForm,
    sendSubscriptionPackageToDocuSign,
    voidDocument,
    voidAndResendDocument,
  } = useSubscriptionPackageFormState();
  const { isCurrentUserAdmin } = useCurrentUser();
  function onClickDelete(): void {
    if (confirm('This will delete all subscription Package information. Are you sure you want to proceed?')) {
      deleteForm();
    }
  }
  return (
    <Form className="SubscriptionPackage">
      <FormSection>
        <SalespersonInformation />
        <SubscriptionInformation />
      </FormSection>
      <div className="flex max-width">
        <div className="flex max-width align-center space-around">
          {form?.state === 'inProgressByAgent' && (
            <EquiButton kind={ButtonType.secondary} value="Delete" onClick={onClickDelete} />
          )}

          {['sentToDocuSign', 'testSentToDocuSign'].includes(form?.state || '') && (
            <EquiButton
              kind={ButtonType.secondary}
              value="Void"
              onClick={(): void => {
                if (
                  confirm(
                    `This will void the current DocuSign signing process for this client. ${
                      form?.kyc?.subscriber?.name ? form?.kyc?.subscriber?.name : 'The client'
                    } will be notified by DocuSign that the document has been voided. Are you sure you want to proceed?`,
                  )
                ) {
                  voidDocument();
                }
              }}
            />
          )}
          {form?.product?.is_active === true && (
            <>
              {['sentToDocuSign', 'testSentToDocuSign'].includes(form?.state || '') && (
                <EquiButton
                  kind={ButtonType.secondary}
                  value="Void and Resend to DocuSign"
                  onClick={(): void => {
                    if (
                      confirm(
                        `This will void the current DocuSign signing process for this client and begin a new signing process with the current Client Registration Form and Subscription Package data. ${
                          form?.kyc?.subscriber?.name ? form?.kyc?.subscriber?.name : 'The client'
                        } will be notified by DocuSign that the document has been voided and then will immediately be emailed a link to begin signing. Are you sure you want to proceed?`,
                      )
                    ) {
                      voidAndResendDocument();
                    }
                  }}
                />
              )}
              {['inProgressByAgent'].includes(form?.state || '') && (
                <EquiButton
                  kind={ButtonType.secondary}
                  value="Send to DocuSign"
                  onClick={(): void => {
                    if (
                      confirm(
                        `This will begin the DocuSign signing process. ${
                          form?.kyc?.subscriber?.name ? form?.kyc?.subscriber?.name : 'The client'
                        } will immediately be emailed a link to begin signing. It is recommended that a meeting with the client is scheduled so that the you can walk him/her through the DocuSign signing process. Are you sure you want to proceed?`,
                      )
                    ) {
                      sendSubscriptionPackageToDocuSign({ isTest: false });
                    }
                  }}
                />
              )}
              {['inProgressByAgent'].includes(form?.state || '') && isCurrentUserAdmin && (
                <EquiButton
                  kind={ButtonType.secondary}
                  value="Send Test to DocuSign"
                  onClick={(): void => {
                    if (
                      confirm(
                        `This will begin the DocuSign signing process. ${
                          form?.kyc?.subscriber?.name ? form?.kyc?.subscriber?.name : 'The client'
                        } will immediately be emailed a link to begin signing. It is recommended that a meeting with the client is scheduled so that the you can walk him/her through the DocuSign signing process. Are you sure you want to proceed?`,
                      )
                    ) {
                      sendSubscriptionPackageToDocuSign({ isTest: true });
                    }
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Form>
  );
}

function FormSection(props: { children: Children }): ReactElement {
  const { children } = props;
  return <div className="bottom-space max-width">{children}</div>;
}

export default SubscriptionPackage;
