import { ProfessionalAdvisorsInformation, TrueFalseRadio } from 'shared';
import { ProtoFields } from '../types';

export const professionalAdvisorsInformationFields: ProtoFields<keyof ProfessionalAdvisorsInformation> = {
  accountantFirm: { formLabel: 'Firm' },
  accountantName: { formLabel: 'Name' },
  financialAdvisorFirm: { formLabel: 'Firm' },
  financialAdvisorName: { formLabel: 'Name' },
  haveAccountant: {
    type: 'radio',
    formLabel: 'Do you have an Accountant?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  haveFinancialAdvisor: {
    type: 'radio',
    formLabel: 'Do you have a Financial Advisor?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  haveLawyer: {
    type: 'radio',
    formLabel: 'Do you have a Lawyer?',
    options: [
      { displayName: 'Yes', value: TrueFalseRadio.true },
      { displayName: 'No', value: TrueFalseRadio.false },
    ],
  },
  lawyerFirm: { formLabel: 'Firm' },
  lawyerName: { formLabel: 'Name' },
};
